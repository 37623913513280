import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SidebarService } from './sidebar.service';
import { environment } from '../../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { IconGroup } from './sidebar.model';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
// export class SidebarComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//     $(document).ready(function () {
//       $(".sidebar li .dasboard").addClass('clickactive');
//       $(".sidebar li a").click(function () {
//         $(".sidebar li a").removeClass('clickactive');
//         $(this).addClass('clickactive');
//       });
//     });

//     $(".sidebar li a").click(function () {
//       var targetDiv = this.getElementsByClassName("fa")[1].className
//       var tar = this.getElementsByClassName("fa")[1]
//       // console.log(targetDiv.className)
//       if (targetDiv == 'fa fa-chevron-right pull-right') {
//         tar.className = "fa fa-chevron-down pull-right"
//       } else {
//         tar.className = "fa fa-chevron-right pull-right"
//       }
//     });

//   }

// }







export class SidebarComponent implements OnInit {
  activeRoute: string;
  currentTab: String = 'active';
  isActive: boolean = true;
  routerLink: string;
  iconGroup: IconGroup = {
    default: {
      dashboard: 'assets/img/menu/black/dashboard.svg',
      provider: 'assets/img/menu/black/provider.svg',
      user: 'assets/img/menu/black/user.svg',
      referal: 'assets/img/menu/black/refer.svg',
      doctypeView: 'assets/img/menu/black/document.svg',
      vehicle: 'assets/img/menu/black/vehicle.svg',
      service: 'assets/img/menu/black/service-type.svg',
      booking: 'assets/img/menu/black/bookings.svg',
      ridebooking: 'assets/img/menu/black/bookings.svg',
      adminbooking: 'assets/img/menu/black/bookings.svg',
      manualbooking: 'assets/img/menu/black/bookings.svg',
      review: 'assets/img/menu/black/review.svg',
      emaitemplate: 'assets/img/menu/black/email.svg',
      cancel: 'assets/img/menu/black/cancel-policy.svg',
      pushnotification: 'assets/img/menu/black/push-notification.svg',
      promocode: 'assets/img/menu/black/promo-code.svg',
      support: 'assets/img/menu/black/support.svg',
      god: 'assets/img/menu/black/support.svg',
      staticpage: 'assets/img/menu/black/static-page.svg',
      report: 'assets/img/menu/black/report.svg',
      admin: 'assets/img/menu/black/organization.svg',
      group: 'assets/img/menu/black/groups.svg',
      withdraw: 'assets/img/menu/black/widthdraw-request.png',
      setting: 'assets/img/menu/black/settings.svg',
      localize: 'assets/img/menu/black/localization.svg',
      satellite: 'assets/img/menu/black/satellite-view.svg'
    },
    whiteStroke: {
      dashboard: 'assets/img/menu/white/dashboard.svg',
      provider: 'assets/img/menu/white/provider.svg',
      user: 'assets/img/menu/white/user.svg',
      referal: 'assets/img/menu/white/refer.svg',
      doctypeView: 'assets/img/menu/white/document.svg',
      vehicle: 'assets/img/menu/white/vehicle.svg',
      service: 'assets/img/menu/white/service-type.svg',
      booking: 'assets/img/menu/white/bookings.svg',
      ridebooking: 'assets/img/menu/white/bookings.svg',
      adminbooking: 'assets/img/menu/white/bookings.svg',
      manualbooking: 'assets/img/menu/white/bookings.svg',
      review: 'assets/img/menu/white/review.svg',
      emaitemplate: 'assets/img/menu/white/email.svg',
      cancel: 'assets/img/menu/white/cancel-policy.svg',
      pushnotification: 'assets/img/menu/white/push-notification.svg',
      promocode: 'assets/img/menu/white/promo-code.svg',
      support: 'assets/img/menu/white/support.svg',
      god: 'assets/img/menu/white/support.svg',
      staticpage: 'assets/img/menu/white/static-page.svg',
      report: 'assets/img/menu/white/report.svg',
      admin: 'assets/img/menu/white/organization.svg',
      group: 'assets/img/menu/white/groups.svg',
      withdraw: 'assets/img/menu/white/widthdraw-request.png',
      setting: 'assets/img/menu/white/settings.svg',
      localize: 'assets/img/menu/white/localization.svg',
      satellite: 'assets/img/menu/white/satellite-view.svg'
    }
  };
  dashboardImageUrl: String;
  dashboardImageUrl1: String =this.iconGroup.default.dashboard;
  providerImageUrl: String =this.iconGroup.default.provider;
  UserImageUrl: String =this.iconGroup.default.user;
  ReferalImageUrl: String =this.iconGroup.default.referal;
  doctypeViewImageUrl: String =this.iconGroup.default.doctypeView;
  vehicleImageUrl: String =this.iconGroup.default.vehicle;
  serviceImageUrl: String =this.iconGroup.default.service;
  bookingImageUrl: String =this.iconGroup.default.booking;
  ridebookingImageUrl: String =this.iconGroup.default.ridebooking;
  adminbookingImageUrl: String =this.iconGroup.default.adminbooking;
  manualbookingImageUrl: String =this.iconGroup.default.manualbooking;
  reviewImageUrl: String =this.iconGroup.default.review;
  emaitemplateImageUrl: String =this.iconGroup.default.emaitemplate;
  cancelImageUrl: String =this.iconGroup.default.cancel;
  pushnotificationImageUrl: String =this.iconGroup.default.pushnotification;
  promocodeImageUrl: String =this.iconGroup.default.promocode;
  supportImageUrl: String =this.iconGroup.default.support;
  godImageUrl: String =this.iconGroup.default.god;
  staticpageImageUrl: String =this.iconGroup.default.staticpage;
  reportImageUrl: String =this.iconGroup.default.report;
  adminImageUrl: String =this.iconGroup.default.admin;
  groupImageUrl: String =this.iconGroup.default.group;
  withdrawImageUrl: String =this.iconGroup.default.withdraw;
  settingImageUrl: String =this.iconGroup.default.setting;
  localizeImageUrl: String =this.iconGroup.default.localize;
  satelliteImageUrl: String =this.iconGroup.default.satellite;
  x: Number = 4;
  // x:Number=3;
  // x:Number=0;
  LocalStoreageData: any
  Navigation: any
  LocalStoreageData1: any
  Navigation1: any

  isdashboard: false
  isusers: false
  isproviders: Boolean = false;
  isproviders1: Boolean = false;
  isdocument: false;
  isvehicle: false;
  isservicetype: false;
  issurgepayzones: false;
  isbookings: false;
  isemailtemplate: false;
  iscancelpolicy: false;
  ispushnotification: false;
  ispromocode: false;
  issatelite: false;
  isstaticpages: false;
  isreport: false;
  isorgusers: false;
  issettings: false;
  iswithdrawlrequest: false;
  islocalize: false;
  isreviewmanagement: false;
  isgroup: false;
  issupportticket: false;
  isreferral: false;
  issupportlist: false;
  isadminbooking: false;
  ismanualbooking: false;
  isridelaterbooking: false;
  sample: true;
  localStorageToken: String;
  roleName: any;
  condition: Boolean = false
  condition1: Boolean = false;
  orguserflag: Boolean = false;
  BookingFlag: Boolean = false;
  supportlistflag: Boolean = false;
  rideLaterBookingFlag: Boolean = false;
  adminBookingFlag: Boolean = false;
  rideLaterGroupFlag: Boolean = false;
  configFlag: Boolean = false;
  settingFlag: Boolean = false;
  AppConfigFlag: Boolean = false;
  userlinkactive: Boolean = false;
  referallinkactive: Boolean = false;
  menuFlag: Boolean = true;
  isAppConfigActive: boolean = false;
  userrouterLink: String;
  bookingrouterLink: String;
  grouprouterLink: String;
  Appconfigrouterlink: String;
  orguserrouterLink: String;
  supportlistrouterlink: String;
  // alert(this.condition1)
  y: Number = 1;
  routerparam: Number = 0;
  routerparamUser: Number = 0;
  routerparamReferal: Number = 0;
  routerparamBooking: Number = 0;
  routerparamRideLaterBooking: Number = 0;
  routerparamadminBooking: Number = 0;
  routerparamGroupList: Number = 0;
  routerparamconfig: Number = 0;
  routercheckActive: String;
  constructor(private router: Router, private Activatedroute: ActivatedRoute,
    private service: SidebarService,
    private appspinner: AppComponent) {
    //  this.router.routeReuseStrategy.shouldReuseRoute = () => false; 
  }
  ngAfterContentChecked() {
    // if(this.routercheckActive=="/providerVehicleView"){
      // this.providerImageUrl = this.iconGroup.whiteStroke.provider;
    //   //this.ProviderChangeImage()
    // //this.condition1=true
    // //this.ProviderChangeImage()
    //  }
  }
  ngOnChanges() {
    this.currentTab = sessionStorage.getItem('providertab')



    var ss1 = localStorage.getItem('adminBooking')

    if (this.bookingrouterLink == "/bookingsView" && ss1 == "booking") {
      this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
      this.bookingImageUrl = this.iconGroup.whiteStroke.booking;
      this.adminbookingImageUrl = this.iconGroup.default.ridebooking;
      //this.ProviderChangeImage()
      this.BookingFlag = true
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = false

      //this.ProviderChangeImage()
    }
    var ss = localStorage.getItem('adminBooking')

    if (this.bookingrouterLink == "/bookingsView" && ss == "ridelaterbooking") {
      this.ridebookingImageUrl = this.iconGroup.whiteStroke.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = true
      this.adminBookingFlag = false

      this.adminbookingImageUrl = this.iconGroup.default.adminbooking;

    }
    var ss2 = localStorage.getItem('adminBooking')
    if (this.bookingrouterLink == "/bookingsView" && ss2 == "admin") {
      this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.adminbookingImageUrl = this.iconGroup.whiteStroke.ridebooking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = true



    }
    //  alert(localStorage.getItem('adminBooking'))
    var ss2 = localStorage.getItem('adminBooking')
    if (this.bookingrouterLink == "/bookingsView" && ss2 == "admin") {
      this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.adminbookingImageUrl = this.iconGroup.whiteStroke.adminbooking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = true



    }

    //  var group=sessionStorage.getItem('groupList')
    //  alert("changes1")
    //  if(this.grouprouterLink=="/viewgroup" &&  group=="groupList"){
    //   alert("changes2")
      // this.groupImageUrl=this.iconGroup.whiteStroke.booking;
    //   this.rideLaterGroupFlag=true



    //  }
    // alert(this.currentTab)
    // this.currentTab=parseInt(sessionStorage.getItem('providerlistpage'))
    // this.currentTab='active'
    // alert("check1"+this.routercheckActive)
    if (this.routercheckActive == "/providerVehicleView") {
      this.providerImageUrl = this.iconGroup.whiteStroke.provider;
      //this.ProviderChangeImage()
      this.condition1 = true
      //this.ProviderChangeImage()
    }
    if (this.orguserrouterLink == "/addAdmin") {
      this.adminImageUrl = this.iconGroup.whiteStroke.admin;
      //this.ProviderChangeImage()
      this.orguserflag = true
      //this.ProviderChangeImage()
    }
    if (this.supportlistrouterlink == "/ticketdetails") {
      this.supportImageUrl = this.iconGroup.whiteStroke.support;
      //this.ProviderChangeImage()
      this.supportlistflag = true
      //this.ProviderChangeImage()
    }

    if (this.routercheckActive == "/userDetailView") {
      this.UserImageUrl = this.iconGroup.whiteStroke.user;
      //this.ProviderChangeImage()
      this.userlinkactive = true
      //this.ProviderChangeImage()
    }

    if (this.grouprouterLink == "/viewgroup") {

      this.groupImageUrl = this.iconGroup.whiteStroke.booking;
      this.rideLaterGroupFlag = true



    }
    if (this.grouprouterLink == "/ConfigView") {
      alert("5")
      // this.groupImageUrl=this.iconGroup.whiteStroke.booking;
      this.configFlag = true
      this.settingFlag = false



    }
    //  if(this.Appconfigrouterlink=="/appConfigView"){

    //   // this.groupImageUrl=this.iconGroup.whiteStroke.booking;
    //   this.AppConfigFlag=true



    //  }













    this.service.getRoles().subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        var rs = res.body['data'];
        //   localStorage.setItem('UserRole', JSON.stringify(res.body['data'][0]) );
        //  console.log("login111",rs[0].Navigation)
        //    this. LocalStoreageData1  = JSON.parse(localStorage.getItem('UserRole') )
        // this.Navigation=this.LocalStoreageData1.Navigation
        // console.log("navigaation111 ",this.Navigation[0])
        // alert("first")
        this.appspinner.spinnerAlert('hide');
      }
    })
  }
  // sessionStorage.getItem('bookinglistpage')

  isActiveRoute22(id: string): boolean {
    const currentId = this.Activatedroute.snapshot.paramMap.get('id');

    return this.router.isActive(id, true);
    // return currentId === id;
  }
  isActiveRoute88() {
    this.routerparam
    return this.router.isActive('/Providers', true)
  }
  isActiveRouteProvider() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparam = 0;
    // alert("gggg")
    this.condition1 = false

    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }

  isActiveRouteUser() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparamUser = 0;
    // alert("gggg")
    this.userlinkactive = false

    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }
  isActiveRouteReferal() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparamReferal = 0;
    // alert("gggg")
    this.referallinkactive = false

    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }
  isActiveRoutesupportlist() {

    this.supportlistflag = false
  }

  isActiveRouteorguser() {

    this.orguserflag = false


    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }
  isActiveRouteBooking() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparamBooking = 0;
    // alert("gggg")
    this.BookingFlag = false

    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }
  isActiveRouteRideLaterBooking() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparamRideLaterBooking = 0;
    // alert("gggg")
    this.rideLaterBookingFlag = false


    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }

  isActiveRouteGroupList() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparam = 0
    this.routerparamGroupList = 0;
    // alert("gggg")
    this.rideLaterGroupFlag = false


    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }
  isActiveRouteconfig() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparam = 0
    this.routerparamconfig = 0;
    // alert("gggg")
    this.configFlag = false


    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }
  isAppcofigview() {
    this.AppConfigFlag = false
  }
  isActiveRouteAdminBooking() {
    // alert("kkkkk")
    // sessionStorage()
    this.routerparamadminBooking = 0;
    // alert("gggg")
    this.adminBookingFlag = false


    // if(this.routerparam>0)
    // {
    // return this.router.isActive('/Providers',true)
    // }
  }
  isActiveRoute44() {

    this.routerparam = 0;

    // if(this.routerparam>0)
    // {
    return this.router.isActive('/Providers', true)
    // }
  }


  isActiveRoute11(route: string): boolean {
    // var slicedString = route.slice(0,20 );
    if (route.slice(0, 20) === "/providerVehicleView") {
      // if(this.y==1){
      // return true;
      //alert("true")
      return this.router.isActive(route, true);
    }
    // alert("sssss"+route)
    // var slicedString = route.slice(0,20 );
    // alert(slicedString)
    // if(route=='/providerVehicleView')
    // {
    //   return this.router.isActive(route, true);
    //   // alert("success")
    // }
    // console.log("checkjjjjj",slicedString)
    // return this.router.isActive(String(slicedString), true);
    // console.log("checkjjjjj",slicedString)

  }
  isActiveRoute(route: string): boolean {
    // alert("dfdsfd")
    this.routerparam = Number(sessionStorage.getItem('bookinglistpage11'))
    this.routerparam = Number(sessionStorage.getItem('orguser'))
    this.routerparam = Number(sessionStorage.getItem('groupList11'))
    this.routerparam = Number(sessionStorage.getItem('userdetailrouter'))
    this.routerparam = Number(sessionStorage.getItem('bookings'))
    this.routerparamRideLaterBooking = Number(sessionStorage.getItem('ridelaterbookings'))
    this.routerparamadminBooking == Number(sessionStorage.getItem('adminBooking'))
    this.routerparamGroupList == Number(sessionStorage.getItem('groupList'))
    this.routerparamconfig == Number(sessionStorage.getItem('config'))
    // if(route.slice(0,20)==="/providerVehicleView"){
    return this.router.isActive(route, true);
    // }
    // var slicedString = route.slice(0,20 );
    // if(this.y==1){
    // return true;
    // alert(route.slice(0,20))
    // return this.router.isActive(route.slice(0,20), true);
    // }
    // alert("sssss"+route)
    // var slicedString = route.slice(0,20 );
    // alert(slicedString)
    // if(route=='/providerVehicleView')
    // {
    //   return this.router.isActive(route, true);
    //   // alert("success")
    // }
    // console.log("checkjjjjj",slicedString)
    // return this.router.isActive(String(slicedString), true);
    // console.log("checkjjjjj",slicedString)

  }
  setActiveRoute(route: string) {
    this.activeRoute = route;
  }
  // this.routerLink= this.getActiveRouterLink()
  ngOnInit() {
    this.activeRoute = this.router.url;

    // this.isAppConfigActive=true
    this.isAppConfigActive = this.router.url === '/appConfigView';

    // if(this.bookingrouterLink=="/bookingsView" &&  ss2=="admin"){
    //   this.ridebookingImageUrl=this.iconGroup.default.ridebooking;
    //   this.bookingImageUrl=this.iconGroup.default.booking;
    //   this.adminbookingImageUrl=this.iconGroup.whiteStroke.booking;
    //   this.BookingFlag=false
    //   this.rideLaterBookingFlag=false
    //   this.adminBookingFlag=true



    //  }
    // console.log("checkjjjjj",)

    this.currentTab = sessionStorage.getItem('providertab')
    this.routerparamRideLaterBooking = Number(sessionStorage.getItem('ridelaterbookings'))
    this.routerparamadminBooking == Number(sessionStorage.getItem('adminBooking'))
    this.routerparamGroupList == Number(sessionStorage.getItem('groupList'))
    this.routerparamconfig == Number(sessionStorage.getItem('config'))
    // alert(this.currentTab)
    //alert(this.isActive)

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // this.isActive = this.router.isActive('/your-route');
    //     this.isActive = this.router.isActive(this.getActiveRouterLink());
    //   }
    // });

    this.routerLink = this.getActiveRouterLink()
    //  alert("routerlink"+this.routerLink)
    // this.routerLink.slice(0,20)

    // this.isActiveRoute(this.routerLink)
    //  sessionStorage.getItem('bookinglistpage11')
    //alert(sessionStorage.getItem('bookinglistpage11'))
    //  alert("aaaa"+this.routerLink.slice(0,20))
    this.routercheckActive = this.routerLink.slice(0, 20)
    this.userrouterLink = this.routerLink.slice(0, 15)
    this.bookingrouterLink = this.routerLink.slice(0, 13)
    this.grouprouterLink = this.routerLink.slice(0, 10)
    this.orguserrouterLink = this.routerLink
    this.Appconfigrouterlink = this.routerLink
    this.supportlistrouterlink = this.routerLink.slice(0, 14)

    // var group=localStorage.getItem('groupList')

    // if(this.grouprouterLink=="/viewgroup" &&  group=="groupList"){

    //  this.groupImageUrl=this.iconGroup.whiteStroke.booking;
    //  this.rideLaterGroupFlag=true



    // }
    // this.isActiveRoute(this.userrouterLink)
    // alert("gggggg"+this.userrouterLink)
    if (this.routercheckActive == "/providerVehicleView") {
      this.providerImageUrl = this.iconGroup.whiteStroke.provider;
      //this.ProviderChangeImage()
      this.condition1 = true
      //this.ProviderChangeImage()
    }
    if (this.userrouterLink == "/userDetailView") {
      this.UserImageUrl = this.iconGroup.whiteStroke.user;
      //this.ProviderChangeImage()
      this.userlinkactive = true
      //this.ProviderChangeImage()
    }
    if (this.orguserrouterLink == "/addAdmin") {
      this.adminImageUrl = this.iconGroup.whiteStroke.admin;
      //this.ProviderChangeImage()
      this.orguserflag = true
      //this.ProviderChangeImage()
    }
    if (this.supportlistrouterlink == "/ticketdetails") {
      this.supportImageUrl = this.iconGroup.whiteStroke.support;
      //this.ProviderChangeImage()
      this.supportlistflag = true
      //this.ProviderChangeImage()
    }

    if (this.grouprouterLink == "/viewgroup") {

      this.groupImageUrl = this.iconGroup.whiteStroke.booking;
      this.rideLaterGroupFlag = true



    }
    if (this.grouprouterLink == "/ConfigView") {

      alert("4")
      this.configFlag = true
      this.settingFlag = false



    }
    // if(this.Appconfigrouterlink=="/appConfigView"){


    //   this.AppConfigFlag=true



    //  }

    var ss1 = localStorage.getItem('adminBooking')
    if (this.bookingrouterLink == "/bookingsView" && ss1 == "booking") {
      this.ridebookingImageUrl = this.iconGroup.whiteStroke.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
      //this.ProviderChangeImage()whiteimg
      this.BookingFlag = true
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = false

      //this.ProviderChangeImage()
    }
    var ss = localStorage.getItem('adminBooking')

    if (this.bookingrouterLink == "/bookingsView" && ss == "ridelaterbooking") {
      this.ridebookingImageUrl = this.iconGroup.whiteStroke.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = true
      this.adminBookingFlag = false

      this.adminbookingImageUrl = this.iconGroup.default.adminbooking;

    }
    var ss2 = localStorage.getItem('adminBooking')
    if (this.bookingrouterLink == "/bookingsView" && ss2 == "admin") {
      this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.adminbookingImageUrl = this.iconGroup.whiteStroke.adminbooking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = true



    }
    var group = localStorage.getItem('groupList')
    //  alert("Init1")
    if (this.grouprouterLink == "/viewgroup" && group == "groupList") {
      //  alert("Init1")
      this.adminbookingImageUrl = this.iconGroup.whiteStroke.adminbooking;
      this.rideLaterGroupFlag = true



    }
    var config = localStorage.getItem('config')
    //  alert("Init1")

    if (this.grouprouterLink == "/ConfigView" && config == "config") {
      alert("Init1")
      this.configFlag = true
      this.settingFlag = false



    }
    //  if(this.Appconfigrouterlink=="/appConfigView"){


    //   this.AppConfigFlag=true



    //  }
    if (this.orguserrouterLink == "/addAdmin") {
      this.adminImageUrl = this.iconGroup.whiteStroke.admin;
      //this.ProviderChangeImage()
      this.orguserflag = true
      //this.ProviderChangeImage()
    }
    if (this.supportlistrouterlink == "/ticketdetails") {
      this.supportImageUrl = this.iconGroup.whiteStroke.support;
      //this.ProviderChangeImage()
      this.supportlistflag = true
      //this.ProviderChangeImage()
    }





    if (this.routerLink == "/dashboard") {



      this.dashboardImageUrl1 = this.iconGroup.whiteStroke.dashboard;

    }
    else {

      this.dashboardImageUrl1 = this.iconGroup.default.dashboard;

    }
    if (this.routerLink == "/Providers") {
      this.isActiveRoute(this.routerLink)

      this.providerImageUrl = this.iconGroup.whiteStroke.provider;
    }
    else {

      this.providerImageUrl = this.iconGroup.default.provider;
    }
    if (this.routerLink == "/Users") {

      this.UserImageUrl = this.iconGroup.whiteStroke.user;
    }
    else {

      this.UserImageUrl = this.iconGroup.default.user;
    }
    if (this.routerLink == "/Referal") {

      this.ReferalImageUrl = this.iconGroup.whiteStroke.referal;
    }
    else {

      this.ReferalImageUrl = this.iconGroup.default.referal;
    }
    if (this.routerLink == "/doctypeView") {

      this.doctypeViewImageUrl = this.iconGroup.whiteStroke.doctypeView;
    }
    else {

      this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    }

    if (this.routerLink == "/vehicleModelView") {
      //  alert("success")
      this.vehicleImageUrl = this.iconGroup.whiteStroke.vehicle;
      // this.VehicleImage()
    }
    else {

      this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }
    if (this.routerLink == "/appConfigView") {
      //  alert("success")
      // this.isAppConfigActive=true
      this.settingssubmenuChangeImage()
      // this.VehicleImage()
    }
    
    else {

      // this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }
    if (this.routerLink == "/bannerads") {

      this.settingssubmenuChangeImage()
      // this.VehicleImage()
    }
    else {

      // this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }

    if (this.routerLink == "/appSliderView") {

      this.settingssubmenuChangeImage()
      // this.VehicleImage()
    }
    else {

      // this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }
    if (this.routerLink == "/org") {
      //  alert("org")
      this.settingssubmenuChangeImage()
      // this.VehicleImage()
    }
    else {

      // this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }

    if (this.routerLink == "/countryView") {
      // alert("cou")
      this.localizesubmenuChangeImage()
      // this.VehicleImage()
    }
    else {

      // this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }
    if (this.routerLink == "/stateView") {

      this.localizesubmenuChangeImage()
      // this.VehicleImage()
    }
    else {

      // this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }
    if (this.routerLink == "/cityView") {

      this.localizesubmenuChangeImage()
      // this.VehicleImage()
    }
    else {

      // this.vehicleImageUrl = this.iconGroup.default.vehicle;
    }


    if (this.routerLink == "/rideVehicletypeView") {

      this.serviceImageUrl = this.iconGroup.whiteStroke.service;
    }
    else {

      this.serviceImageUrl = this.iconGroup.default.service;
    }
    if (this.routerLink == "/bookings") {
      // alert("true")

      this.bookingImageUrl = this.iconGroup.whiteStroke.booking;
    }
    else {

      this.bookingImageUrl = this.iconGroup.default.booking;
    }
    if (this.routerLink == "/ridelaterbook") {

      this.ridebookingImageUrl = this.iconGroup.whiteStroke.ridebooking;
    }
    else {

      this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    }

    if (this.routerLink == "/MapAdd") {

      this.adminbookingImageUrl = this.iconGroup.whiteStroke.admin;
    }
    else {

      this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    }

    if (this.routerLink == "/") {

      this.adminbookingImageUrl = this.iconGroup.whiteStroke.admin;
    }
    else {

      this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    }

    if (this.routerLink == "/manualBook") {

      this.manualbookingImageUrl = this.iconGroup.whiteStroke.manualbooking;
    }
    else {

      this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    }
    if (this.routerLink == "/reviewmanagement") {

      this.reviewImageUrl = this.iconGroup.whiteStroke.review;
    }
    else {

      this.reviewImageUrl = this.iconGroup.default.review;
    }
    if (this.routerLink == "/emailTemplateView") {

      this.emaitemplateImageUrl = this.iconGroup.whiteStroke.emaitemplate;
    }
    else {

      this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    }
    if (this.routerLink == "/cancellationPolicyView") {

      this.cancelImageUrl = this.iconGroup.whiteStroke.cancel;
    }
    else {

      this.cancelImageUrl = this.iconGroup.default.cancel;
    }
    if (this.routerLink == "/pushNotificationView") {

      this.pushnotificationImageUrl = this.iconGroup.whiteStroke.pushnotification;
    }
    else {

      this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    }

    if (this.routerLink == "/promoCodes") {

      this.promocodeImageUrl = this.iconGroup.whiteStroke.promocode;
    }
    else {

      this.promocodeImageUrl = this.iconGroup.default.promocode;
    }
    if (this.routerLink == "/supportlist") {

      this.supportImageUrl = this.iconGroup.whiteStroke.support;
    }
    else {

      this.supportImageUrl = this.iconGroup.default.support;
    }

    if (this.routerLink == "/godsView") {

      this.godImageUrl = this.iconGroup.whiteStroke.god;
    }
    else {

      this.godImageUrl = this.iconGroup.default.god;
    }
    if (this.routerLink == "/staticPagesView") {

      this.staticpageImageUrl = this.iconGroup.whiteStroke.staticpage;
    }
    else {

      this.staticpageImageUrl = this.iconGroup.default.staticpage;
    }


    if (this.routerLink == "/report") {

      this.reportImageUrl = this.iconGroup.whiteStroke.report;
    }
    else {

      this.reportImageUrl = this.iconGroup.default.report;
    }
    if (this.routerLink == "/adminview") {

      this.adminImageUrl = this.iconGroup.whiteStroke.admin;
    }
    else {

      this.adminImageUrl = this.iconGroup.default.admin;
    }

    if (this.routerLink == "/group") {
      this.AppConfigFlag = false
      this.groupImageUrl = this.iconGroup.whiteStroke.group;
    }
    else {

      this.groupImageUrl = this.iconGroup.default.group;
    }
    if (this.routerLink == "/withDrawlRequest") {

      this.withdrawImageUrl = this.iconGroup.whiteStroke.withdraw;
    }
    else {

      this.withdrawImageUrl = this.iconGroup.default.withdraw;
    }

    if (this.routerLink == "/godsView") {


      this.satelliteImageUrl = this.iconGroup.whiteStroke.satellite;
    }
    else {

      this.satelliteImageUrl = this.iconGroup.default.satellite;
    }



    //  this.settingImageUrl= this.iconGroup.default.setting;
    //this.localizeImageUrl= this.iconGroup.default.localize;

    ///////////////////////////////////


    var str = "Apples are round, and apples are juicy.";
    var sliced = str.slice(3, -2);
    console.log(sliced);
    // this.dashboardImageUrl1 = this.iconGroup.whiteStroke.dashboard;
    // alert(this.condition)
    this.getnavigation();
    // this.getnavigation();
    // setInterval(() => {
    //   this.getnavigation();
    //  }, 9000);
    //  this. LocalStoreageData  = JSON.parse(localStorage.getItem('AllData') )
    //  this.Navigation  = this.LocalStoreageData.Navigation
    //   console.log("navigaation ",this.Navigation[0])
    // console.log("navigaationcheck ",this.Navigation[0])
    this.LocalStoreageData1 = JSON.parse(localStorage.getItem('UserRole'))
    this.Navigation = this.LocalStoreageData1.Navigation
    console.log("navigaation111 ", this.Navigation)
    // alert("second")
    // this.sample=true;
    // this. LocalStoreageData1  = JSON.parse(localStorage.setItem('isproviders') )

    this.isdashboard = this.Navigation.includes("dashboard")
    this.isusers = this.Navigation.includes("users")
    this.isproviders = this.Navigation.includes("providers")
    //localStorage.setItem('isproviders1', String(this.isproviders));
    // this.isproviders= Boolean(localStorage.getItem('isproviders'))
    //alert(localStorage.getItem('isproviders'))
    //this.isproviders1 =Boolean(localStorage.getItem('isproviders1'))
    this.isdocument = this.Navigation.includes("documents")
    this.isvehicle = this.Navigation.includes("vehicle")
    this.isservicetype = this.Navigation.includes("servicetype")

    this.isbookings = this.Navigation.includes("bookings")
    this.isemailtemplate = this.Navigation.includes("emailtemplate")
    this.iscancelpolicy = this.Navigation.includes("cancelpolicy")
    this.ispushnotification = this.Navigation.includes("pushnotification")
    this.ispromocode = this.Navigation.includes("promocode")
    this.issatelite = this.Navigation.includes("satelite")
    this.isstaticpages = this.Navigation.includes("staticpages")
    this.isreport = this.Navigation.includes("report")
    this.isorgusers = this.Navigation.includes("orgusers")
    this.issettings = this.Navigation.includes("settings")
    this.iswithdrawlrequest = this.Navigation.includes("withdrawlrequest")
    this.islocalize = this.Navigation.includes("localize")
    this.isreviewmanagement = this.Navigation.includes("reviewmanagement")
    this.isgroup = this.Navigation.includes("group")
    this.issupportlist = this.Navigation.includes("supportlist")
    this.issupportticket = this.Navigation.includes("supportticket")
    this.isreferral = this.Navigation.includes("referral")
    this.issurgepayzones = this.Navigation.includes("surgepayzones")
    this.isridelaterbooking = this.Navigation.includes("ridelaterbooking")
    this.isadminbooking = this.Navigation.includes("adminbooking")
    this.ismanualbooking = this.Navigation.includes("manualbooking")

    // isActive(instruction: any[]): boolean {
    //   // Set the second parameter to true if you want to require an exact match.
    //   return this.router.isActive(this.router.createUrlTree(instruction), false);
    // }
    // main.find('.segment-key').each(function() {
    //   const key = $(this).html();
    //   if(key == replacement.key) {
    //     main.find('.segment-value').each(function() {
    //       let value = $(this).html();
    //       if(!_.startsWith(value, '<a')) {
    //         value = _.replace(value, '"', '');
    //         $(this).wrapInner(`<a href="${replacement.anchor}` + value + '" />');
    //       }
    //     });
    //   }
    // });
    console.log("")
    $(document).ready(function () {
      $(".sidebar li .dasboard").addClass('clickactive')
      $(".sidebar li a").click(function () {
        $(".sidebar li a").removeClass('clickactive');
        $(this).addClass('clickactive');
      });
      // alert()

      $(".sidebar li a").click(function () {
        if (this.getElementsByClassName("fa")[1]) {
          var targetDiv = this.getElementsByClassName("fa")[1].className
          //alert(targetDiv)
          var tar = this.getElementsByClassName("fa")[1]
          // console.log(targetDiv.className)
          if (targetDiv == 'fa fa-chevron-right pull-right') {
            tar.className = "fa fa-chevron-down pull-right"
          } else {
            tar.className = "fa fa-chevron-right pull-right"
          }
        }

      });

    });

  }
  dashboardChangeImage() {
    // alert("okay")
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteReferal()
    this.isActiveRouteUser()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteconfig()
    //  this.isAppcofigview()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()

    this.dashboardImageUrl1 = this.iconGroup.whiteStroke.dashboard;

    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;

  }
  ProviderChangeImage() {
    // alert("okay")
    // this.isActive=true
    // this.isAppcofigview()
    this.providerImageUrl = this.iconGroup.whiteStroke.provider;
    this.isActiveRouteconfig()
    this.isActiveRouteProvider()
    this.isActiveRouteReferal()
    this.isActiveRouteUser()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    // this.isAppcofigview()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.condition1 = true
    // alert("image change")
    // alert("paramid"+sessionStorage.getItem('bookinglistpage11'))
    // if()
    this.currentTab = sessionStorage.getItem('providertab')
    // alert(this.currentTab)
    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.serviceImageUrl = this.iconGroup.default.service;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;

    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
  }
  UserChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.userlinkactive = true

    this.UserImageUrl = this.iconGroup.whiteStroke.user;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.serviceImageUrl = this.iconGroup.default.service;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
  }
  ReferalChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.referallinkactive = true

    this.withdrawImageUrl = this.iconGroup.whiteStroke.withdraw;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.serviceImageUrl = this.iconGroup.default.service;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.UserImageUrl = this.iconGroup.default.user;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
  }
  DocChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    // this.isAppcofigview()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()

    this.doctypeViewImageUrl = this.iconGroup.whiteStroke.doctypeView;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.serviceImageUrl = this.iconGroup.default.service;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
  }
  VehicleImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    
    this.vehicleImageUrl = this.iconGroup.whiteStroke.vehicle;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.serviceImageUrl = this.iconGroup.default.service;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
  }
  serviceImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    
    this.serviceImageUrl = this.iconGroup.whiteStroke.service;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
  }

  bookingChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.BookingFlag = true;

    this.bookingImageUrl = this.iconGroup.whiteStroke.booking;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  ridebookingChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.rideLaterBookingFlag = true
    this.ridebookingImageUrl = this.iconGroup.whiteStroke.ridebooking;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  adminbookingChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.adminBookingFlag = true
    this.adminbookingImageUrl = this.iconGroup.whiteStroke.adminbooking;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }

  manualbookingChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    
    this.manualbookingImageUrl = this.iconGroup.whiteStroke.manualbooking;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")


  }



  reviewChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    
    this.reviewImageUrl = this.iconGroup.whiteStroke.review;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  emailtemplateChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.emaitemplateImageUrl = this.iconGroup.whiteStroke.emaitemplate;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")
  }
  cancelChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()

    this.cancelImageUrl = this.iconGroup.whiteStroke.cancel;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }

  pushnotoficationChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    
    this.pushnotificationImageUrl = this.iconGroup.whiteStroke.pushnotification;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    
    // alert("okay")
  }
  promocodeChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    
    this.promocodeImageUrl = this.iconGroup.whiteStroke.promocode;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  supportChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.supportlistflag = true;
    this.supportImageUrl = this.iconGroup.whiteStroke.support;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }


  staticpageChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()

    this.staticpageImageUrl = this.iconGroup.whiteStroke.staticpage;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  reportChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.reportImageUrl = this.iconGroup.whiteStroke.report;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  adminChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.orguserflag = true
    this.adminImageUrl = this.iconGroup.whiteStroke.adminbooking;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  surgepayChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")
    // Changed by ABS Doubt
  }
  groupChangeImage() {
    this.isActiveRouteconfig()
    this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.rideLaterGroupFlag = true;
    this.groupImageUrl = this.iconGroup.whiteStroke.group;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  withdrawChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.withdrawImageUrl = this.iconGroup.whiteStroke.withdraw;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  satelliteChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.satelliteImageUrl = this.iconGroup.whiteStroke.satellite;

    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  AppconfigviewChangeImage() {
    this.isActiveRouteconfig()
    this.isActiveRouteconfig()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.AppConfigFlag = true;
    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")
    // Changed by ABS Doubt

  }
  settingsChangeImage() {
    this.isActiveRouteconfig()
    // alert("hai")
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.settingImageUrl = this.iconGroup.whiteStroke.setting;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  settingssubmenuChangeImage() {
    this.isActiveRouteconfig()
    // this.isAppConfigActive=true
    // alert("hai")
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")
    // Changed by ABS Doubt

  }
  settingsConfigImage() {
    // this.isAppConfigActive=true
    // alert("hai")
    // this.isAppcofigview()
    this.configFlag = true
    this.settingFlag = false
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")
    // Changed by ABS Doubt

  }
  localizeChangeImage() {
    // alert("hai")
    // this.isAppcofigview()
    this.isActiveRouteconfig()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteProvider()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()
    this.localizeImageUrl = this.iconGroup.whiteStroke.localize;

    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  localizesubmenuChangeImage() {
    this.isActiveRouteconfig()
    // alert("hai")
    // this.isAppcofigview()
    this.isActiveRouteProvider()
    this.isActiveRouteUser()
    this.isActiveRouteReferal()
    this.isActiveRouteBooking()
    this.isActiveRouteRideLaterBooking()
    this.isActiveRouteAdminBooking()
    this.isActiveRouteGroupList()
    this.isActiveRouteorguser()
    this.isActiveRoutesupportlist()

    //this.settingImageUrl= this.  .default.booking;
    this.satelliteImageUrl = this.iconGroup.default.satellite;
    this.localizeImageUrl = this.iconGroup.default.localize;
    this.settingImageUrl = this.iconGroup.default.setting;
    this.withdrawImageUrl = this.iconGroup.default.withdraw;
    this.groupImageUrl = this.iconGroup.default.group;
    this.adminImageUrl = this.iconGroup.default.admin;
    this.reportImageUrl = this.iconGroup.default.report;
    this.staticpageImageUrl = this.iconGroup.default.staticpage;
    this.supportImageUrl = this.iconGroup.default.support;
    this.promocodeImageUrl = this.iconGroup.default.promocode;
    this.pushnotificationImageUrl = this.iconGroup.default.pushnotification;
    this.cancelImageUrl = this.iconGroup.default.cancel;
    this.emaitemplateImageUrl = this.iconGroup.default.emaitemplate;
    this.reviewImageUrl = this.iconGroup.default.review;
    this.manualbookingImageUrl = this.iconGroup.default.manualbooking;
    this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
    this.bookingImageUrl = this.iconGroup.default.booking;
    this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
    this.dashboardImageUrl1 = this.iconGroup.default.dashboard;
    this.providerImageUrl = this.iconGroup.default.provider;
    this.UserImageUrl = this.iconGroup.default.user;
    this.ReferalImageUrl = this.iconGroup.default.referal;
    this.doctypeViewImageUrl = this.iconGroup.default.doctypeView;
    this.vehicleImageUrl = this.iconGroup.default.vehicle;
    this.serviceImageUrl = this.iconGroup.default.service;
    // alert("okay")

  }
  //......................
  getnavigation() {


    this.service.getRoles().subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        var rs = res.body['data'];
        var menulength = Number(res.body['data'][0].Navigation.length)
        if (menulength > 10) {
          this.menuFlag = true
        }
        else {
          this.menuFlag = false
        }
        console.log("sidebar length", res.body['data'][0].Navigation.length)
        localStorage.setItem('UserRole', JSON.stringify(res.body['data'][0]));
        this.LocalStoreageData1 = JSON.parse(localStorage.getItem('UserRole'))
        this.roleName = res.body['data'][0].RoleName
        this.Navigation = this.LocalStoreageData1.Navigation
        this.isdashboard = this.Navigation.includes("dashboard")
        this.isusers = this.Navigation.includes("users")
        this.isproviders = this.Navigation.includes("providers")
        this.isdocument = this.Navigation.includes("documents")
        this.isvehicle = this.Navigation.includes("vehicle")
        this.isservicetype = this.Navigation.includes("servicetype")

        this.isbookings = this.Navigation.includes("bookings")
        this.isemailtemplate = this.Navigation.includes("emailtemplate")
        this.iscancelpolicy = this.Navigation.includes("cancelpolicy")
        this.ispushnotification = this.Navigation.includes("pushnotification")
        this.ispromocode = this.Navigation.includes("promocode")
        this.issatelite = this.Navigation.includes("satelite")
        this.isstaticpages = this.Navigation.includes("staticpages")
        this.isreport = this.Navigation.includes("report")
        this.isorgusers = this.Navigation.includes("orgusers")
        this.issettings = this.Navigation.includes("settings")
        this.iswithdrawlrequest = this.Navigation.includes("withdrawlrequest")
        this.islocalize = this.Navigation.includes("localize")
        this.isreviewmanagement = this.Navigation.includes("reviewmanagement")
        this.isgroup = this.Navigation.includes("group")
        this.issupportlist = this.Navigation.includes("supportlist")
        this.issupportticket = this.Navigation.includes("supportticket")
        this.isreferral = this.Navigation.includes("referral")
        this.issurgepayzones = this.Navigation.includes("surgepayzones")
        this.isridelaterbooking = this.Navigation.includes("ridelaterbooking")
        this.isadminbooking = this.Navigation.includes("adminbooking")
        this.ismanualbooking = this.Navigation.includes("manualbooking")
        //  alert("understand")
        // setInterval(() => {
        //   this.getnavigation();
        //  }, 9000);
        // alert("update")
        // window.onload = function() {
        //   if(!window.location.hash) {
        //     // window.location = window.location + '#loaded';
        //     window.location.reload();
        //   }
        // }
        //  console.log("login111",rs[0].Navigation)
        //    this. LocalStoreageData1  = JSON.parse(localStorage.getItem('UserRole') )
        // this.Navigation=this.LocalStoreageData1.Navigation
        // console.log("navigaation111 ",this.Navigation[0])
        // alert("repeate")
        this.appspinner.spinnerAlert('hide');
      }
    })
  }

  logout() {
    localStorage.removeItem('Token');
    // sessionStorage.removeItem('accessToken');
    // sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['']);
  }
  // AutogetBookingsView() {
  //   this.service.bookingslistpageview(this.page).subscribe((res:any) => {
  //     if (res.body['error']) {
  //       environment.swalalert('nodata', res['msg']);
  //     } else {
  //       this.bookingsview = new BookingsViewModel(res['error'], res['msg'], res['data']);
  //       if (res['data'] && res['data'].length > 0 && this.bookingsview['data'][0].data.length > 0) {
  //         this.pages = this.bookingsview['data'][0].Count;
  //         this.bookingslist = this.bookingsview['data'][0].data;
  //       } else {
  //         environment.swalalert('nodata', 'No Data Available ');
  //       }
  //     }
  //   },
  //     (err) => {
  //       console.log(err);
  //     })
  // }
  getActiveRouterLink(): string {
    return this.router.url;
  }

  ngOnDestroy() {
    clearInterval(this.setintervalid)
  }
  public setintervalid = setInterval(() => {
    this.routerLink = this.getActiveRouterLink()
    // alert("fff"+this.routerLink)
    this.routerLink.slice(0, 20)
    var ss56 = "/manualBook"
    var ss = "/bookings"
    var adminrouter = "/adminbookings"
    var adminmap = "/MapAdd"

    if (this.routerLink == "/group") {
      this.rideLaterGroupFlag = true
      this.groupChangeImage()

      this.AppConfigFlag = false
      this.groupImageUrl = this.iconGroup.whiteStroke.group;
    }
    if (this.routerLink == "/ConfigView") {
      // alert("1")
      this.settingFlag = false
      this.configFlag = true
      //  this.settingssubmenuChangeImage();
      this.settingsConfigImage();
      //  this. settingsConfigImage()


    }



    var userbooking = localStorage.getItem('adminBooking')

    if (this.bookingrouterLink == "/bookingsView" && userbooking == "userbooking") {
      this.UserImageUrl = this.iconGroup.whiteStroke.user;
      //this.ProviderChangeImage()
      this.userlinkactive = true

      // this.ridebookingImageUrl=this.iconGroup.whiteStroke.ridebooing;
      // this.bookingImageUrl=this.iconGroup.default.booking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = false

      //  this.adminbookingImageUrl=this.iconGroup.default.adminbooking;

    }

    if (ss == this.routerLink) {
      // alert("success")
      this.bookingChangeImage()
    }
    if (adminrouter == this.routerLink) {
      // alert("success")
      this.adminbookingChangeImage()
    }
    if (adminmap == this.routerLink) {
      // alert("success")
      this.adminbookingChangeImage()
    }

    if (ss56 == this.routerLink) {
      // alert("success")
      this.manualbookingChangeImage()
    }
    // BookingFlag
    this.routercheckActive = this.routerLink.slice(0, 20)
    this.userrouterLink = this.routerLink.slice(0, 15)
    this.bookingrouterLink = this.routerLink.slice(0, 13)
    this.grouprouterLink = this.routerLink.slice(0, 10)
    this.orguserrouterLink = this.routerLink
    this.supportlistrouterlink = this.routerLink.slice(0, 14)
    if (this.routercheckActive == "/providerVehicleView") {
      this.providerImageUrl = this.iconGroup.whiteStroke.provider;
    }
    if (this.userrouterLink == "/userDetailView") {
      this.UserImageUrl = this.iconGroup.whiteStroke.user;

    }
    //  if(this.referalrouterLink=="/userDetailView"){
    //   this.UserImageUrl = this.iconGroup.whiteStroke.user;

    //  }
    //  var group=localStorage.getItem('groupList')
    //  alert("navi1")
    if (this.orguserrouterLink == "/addAdmin") {
      this.adminImageUrl = this.iconGroup.whiteStroke.admin;
      //this.ProviderChangeImage()

      //this.ProviderChangeImage()
    }
    if (this.supportlistrouterlink == "/ticketdetails") {
      this.supportImageUrl = this.iconGroup.whiteStroke.support;
      //this.ProviderChangeImage()

      //this.ProviderChangeImage()
    }
    if (this.grouprouterLink == "/viewgroup") {
      // alert("navi2")
      this.groupImageUrl = this.iconGroup.whiteStroke.group;
      // this.rideLaterGroupFlag=true



    }
    //  if(this.Appconfigrouterlink=="/appConfigView"){


    //   this.AppConfigFlag=true



    //  }


    //  var group=localStorage.getItem('groupList')
    //  alert("navi1")
    //  if(this.grouprouterLink=="/viewgroup" &&  group=="groupList"){
    //   alert("navi2")
    //   this.groupImageUrl=this.iconGroup.whiteStroke.group;
    //   this.rideLaterGroupFlag=true



    //  }

    var ss1 = localStorage.getItem('adminBooking')
    if (this.bookingrouterLink == "/bookingsView" && ss1 == "booking") {
      this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
      this.bookingImageUrl = this.iconGroup.whiteStroke.booking;
      this.adminbookingImageUrl = this.iconGroup.default.adminbooking;
      //this.ProviderChangeImage()
      this.BookingFlag = true
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = false

      //this.ProviderChangeImage()
    }
    var config = localStorage.getItem('config')
    if (this.bookingrouterLink == "/ConfigView" && config == "config") {
      //  alert("2")
      //this.ProviderChangeImage()
      this.settingFlag = false
      this.configFlag = true

      //this.ProviderChangeImage()
    }

    //   if(this.orguserrouterLink=="/appConfigView")
    //   {
    //  this.isAppConfigActive=true
    //  this.settingssubmenuChangeImage()
    //    // this.VehicleImage()
    //   }
    var ss = localStorage.getItem('adminBooking')

    if (this.bookingrouterLink == "/bookingsView" && ss == "ridelaterbooking") {
      this.ridebookingImageUrl = this.iconGroup.whiteStroke.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = true
      this.adminBookingFlag = false

      this.adminbookingImageUrl = this.iconGroup.default.adminbooking;

    }
    var ss2 = localStorage.getItem('adminBooking')
    if (this.bookingrouterLink == "/bookingsView" && ss2 == "admin") {
      this.ridebookingImageUrl = this.iconGroup.default.ridebooking;
      this.bookingImageUrl = this.iconGroup.default.booking;
      this.adminbookingImageUrl = this.iconGroup.whiteStroke.adminbooking;
      this.BookingFlag = false
      this.rideLaterBookingFlag = false
      this.adminBookingFlag = true



    }





    //     if(this.routercheckActive=="//userDetailView"){
    //       this.providerImageUrl = this.iconGroup.whiteStroke.provider;
    // }

    //     // /providerVehicleView/1
    //     if(this.routerLink=="/providerVehicleView/1"){
    // // // //       if(this.routerLink="1"){
    // // // this.x=2
    // // // this.ngOnInit()
    // alert("success")
    //     }

    //     // alert(this.routerLink)
    this.getnavigation()
    // alert("dddddd")
    // }, 9000);
  }, 2000);
  // window.location.reload();
  //   if(this.roleName=="admin"){

  //   }
  //   else{


  //  this.getnavigation();
  //   }
  // }, 9000);



}
