// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'grdFilter'
// })
// export class GrdFilterPipe implements PipeTransform {
//   transform(items: any, filter: any, defaultFilter: boolean): any {
//     ////debugger
//     if (!filter){
      
//       console.log("test item.11111111111...",items)
//       return items;
   
//     }

//     if (!Array.isArray(items)){
//       console.log("test item22222....",items)
//       return items;

//     }

//     if (filter && Array.isArray(items)) {
//       let filterKeys = Object.keys(filter);

//       if (defaultFilter) {
//         return items.filter(item =>
//             filterKeys.reduce((x, keyName) =>
//                 (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
//       }
//       else {
//         ////debugger
//         // items=1;
//         // return items;
//         return items.filter(item => {
//           return filterKeys.some((keyName) => {
//             return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
//           });
//         });
//       }
//     }
//   }
// }




import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class GrdFilterPipe implements PipeTransform {
// export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    return items.filter(item => {
      // Apply your filtering logic here
      // For example, check if the item's name contains the search text
      return item.name.toLowerCase().includes(searchText);
    });
  }
}