
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { EncryService } from 'src/app/encry.service';
import { Time } from "@angular/common";
import { from } from 'rxjs';
//import { environment } from '../../../../environments/environment';
//import { environment } from '../../../../environments/environment';
import { environment } from '../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { start } from 'repl';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { OrgPlanModel } from './org.model';
import { OrgPlanService } from './org.component.service';
import { orgPlanViewModel } from './orglist.model';
import { OrgPeakModel } from './orgpeak.model';
import { OrgChargesModel } from './orgcharges.model';
import { OrgIncentiveModel } from './orgincentive.model';
import { FaqsModule } from '../faqs/faqs.module';

@Component({
  selector: 'app-org',
  templateUrl: './org.component.html',
  styleUrls: ['./org.component.css']
})
export class OrgComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  // userrole
  userRoleListModel: orgPlanViewModel;
  orgIncentiveView: orgPlanViewModel;
  IncentiveEditModel:OrgIncentiveModel;
  resultUser : any;
  pageUser : number = 1;

  r: any;
  doctypeEditForm: any;
  orgplanaddmodel: OrgPlanModel;
  orgPlanEditModel: OrgPlanModel;
  peakZoneADDModel: OrgPeakModel;
  peakZoneEditModel: OrgPeakModel;
  chargesEditModel: OrgChargesModel;
  ogrPlanForm: any;
  ogrPlanEditForm: any;
  peakZoneADDFrom: any;
  peakZoneEditFrom: any;
  chargesAddForm: any;
  EmailAddForm:any;


  incentiveADDFrom: any;
  IncentiveEditFrom:any;
  //addincentivevalue
  taskName: any;
  incentiveId:number;
  TargetLimit: any;
  TargetType: any;
  Description: any;
  Date1: any;
  StartTime: any;
  EndTime: any;
  IncentiveType: any;
  cashvalue: any;
  //addincentivevalue

  zoneType: any;
  startTime: any;
  endTime: any;
  dayRange: any;
  latitude: any;
  longitude: any;
  docresult: any[] = [];
  paramsid: number;
  commissionPercentage: number;
  commissionPercentage1: number;
  VStatus: String;
  VColor: String;
  VVehicleModelName: String;
  VVehicleBrandName: String;
  VVehicleNumber: number;
  RideVehicleTypeName: String;
  VVehicleImage: String;
  VId: number;
  Vi: number;
  Vr: number;
  Addplans: Boolean = false;
  editplans: Boolean = false;
  MenuFlag: Boolean = true;
  // ListPlans: Boolean =false;
  commissionPercentageFlag: Boolean =true;
  ListPlans: Boolean =true;
  listCharges: Boolean = false;
  listIncentive: Boolean = false;
  listPeakZone: Boolean = false;
  addPeakZone: Boolean = false;
  addIncentive: Boolean = false;
  editIncentive: Boolean = false;
  addUserRole:Boolean=false;
  editUserRole:Boolean=false;
  listUserRole:Boolean=false;
  listEmail:Boolean=false;
  orgPlanView: orgPlanViewModel;
  peakZoneListModel: orgPlanViewModel;
  incentiveListModel: orgPlanViewModel;
  chargesListModel: orgPlanViewModel;
  incentivePages :number=1;
  incentiveResult:any;
  page: number = 1;
  peakPage: number = 1;
  pages: any;
  peakPages: any;
  incentivePages1:any;
  incentivePage: number = 1;
  incentiveResult1:any;
  result: any;
  peakResult: any;
  peakId: number;
  orgPlanId: number;
  emailTemplate: any;
  emailTemplatecash:any;
  ///Charges field
  Cancel_Charges_Within: any;
  Cancel_Charges_After: any;
  Cancellation_Charges: any;
  Next_Ride_Charges: any;
  Cancellation_Charges_Pickup_Point: any;
  Low_Traffic_Cost: any;
  High_Traffic_Cost: any;
commissionpercentageNumber:any;
  ///EmailTemplate  fields
Welcome_Template:any;
Invoice_Template:any;
Cash_Template:any;
aa1:any;
  Date: any = new Date();
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  // public dateValue: Object = new Date(new Date().setDate(14));
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentDay);
  public maxDate: Object = new Date(this.currentYear, 12, 31);


  //
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public start: Date = new Date(this.fullYear, this.month - 1, 7);
  public end: Date = new Date(this.fullYear, this.month, 25);

//UserRole Add

LocalStoreageData : any
  permissionArray = []
  roleName : any ;
  Navigation : any ;
  
  id:any;
  LocalStoreageData1 : any
  permissionArray1 = []
  roleName1 : any ;
  Navigation1 : any ;
Alloption1:any;
AllOptions : any = [{"name":"dashboard","displayName" : "Dashboard","status" : false},
{"name":"users","displayName" : "Users","status" : false},                     
{"name":"providers","displayName" : "Providers","status" : false},  
 {"name":"documents","displayName" : "Document","status" : false},

 {"name":"vehicle","displayName" : "Vehicle","status" : false},
 
 {"name":"servicetype","displayName" : "Service Type","status" : false},
 {"name":"surgepayzones","displayName" : "Surge Pay Zones","status" : false},
 {"name":"adminbooking","displayName" : "Admin Booking","status" : false},
 {"name":"manualbooking","displayName" : "Manual Booking","status" : false},
 {"name":"ridelaterbooking","displayName" : "Ride Later Booking","status" : false},
{"name":"bookings","displayName" : "Bookings","status" : false},
 {"name":"emailtemplate","displayName" : "Email Template","status" : false},
 {"name":"cancelpolicy","displayName" : "Cancel Policy","status" : false},
 {"name":"pushnotification","displayName" : "Push Notification","status" : false},
 {"name":"promocode","displayName" : "Promo Code","status" : false},
{"name":"satelite","displayName" : "Satelite","status" : false},
{"name":"staticpages","displayName" : "Static Pages","status" : false},
{"name":"report","displayName" : "Report","status" : false},
{"name":"orgusers","displayName" : "Org Users","status" : false},
{"name":"settings","displayName" : "Settings","status" : false},
{"name":"withdrawlrequest","displayName" : "Withdrawl Request","status" : false},
{"name":"localize","displayName" : "Localize","status" : false},
{"name":"reviewmanagement","displayName" : "Review Management","status" : false},
{"name":"group","displayName" : "Group","status" : false},
{"name":"supportticket","displayName" : "Support Ticket","status" : false},
{"name":"referral","displayName" : "Referral","status" : false},
// {"name":"supportlist","displayName" : "Support List","status" : false},
//  {"name":"servicesservicelist","displayName" : "Services - Service List","status" : false},

//  {"name":"godseye","displayName" : "Gods Eye","status" : false},


//  {"name":"appsettingsappconfig","displayName" : "App Settings - App Config","status" : false},
//  {"name":"appsettingsappslider","displayName" : "App Settings - App Slider","status" : false},
//  {"name":"banneradvertisement","displayName" : "Banner Advertisement","status" : false},
 
//  {"name":"defaultcurrency","displayName" : "Default Currency","status" : false},
 
//  {"name":"admin","displayName" : "Admin","status" : false},
//  {"name":"rolses","displayName" : "Roles","status" : false}
];

  // AllOptions : any = [{"name":"dashboard","displayName" : "Dashboard","status" : false},
  //                     {"name":"users","displayName" : "Users","status" : false},                     
  //                     //  {"name":"servicecategory","displayName" : "Service Category","status" : false},
  //                     //  {"name":"servicecategorytaxi","displayName" : "Service Category - Taxi","status" : false},
  //                     //  {"name":"servicecategorydelivery","displayName" : "Service Category - Delivery","status" : false},
  //                     //  {"name":"servicecategoryservices","displayName" : "Service Category - Services","status" : false},
  //                     //  {"name":"taxconfig","displayName" : "Tax Config","status" : false},
  //                      {"name":"document","displayName" : "Document","status" : false},
                      
  //                      {"name":"taxivehiclebrand","displayName" : "Taxi - Vehicle Brand","status" : false},
  //                      {"name":"taxivehiclemodel","displayName" : "Taxi - Vehicle Model","status" : false},
  //                      {"name":"taxiservicetype","displayName" : "Taxi - Service Type","status" : false},
  //                      {"name":"taxicancellationpolicy","displayName" : "Taxi - Cancellation Policy","status" : false},
  //                      {"name":"taxiproviders","displayName" : "Taxi - Providers","status" : false},
  //                      {"name":"taxiridenowbookings","displayName" : "Taxi - Ride Now Bookings","status" : false},
  //                      {"name":"taxiridelaterbookings","displayName" : "Taxi - Ride Later Bookings","status" : false},
  //                     //  {"name":"taxicouponcode","displayName" : "Taxi - CouponCode","status" : false},
  //                     //  {"name":"deliveryoutlets","displayName" : "Delivery - Outlets","status" : false},
  //                     //   {"name":"addoutlet","displayName" : "Delivery - Add Outlet","status" : false},
                    
  //                     //  {"name":"packingcharge","displayName" : "Delivery - Packing Charge","status" : false},

  //                     //  {"name":"deliveryproviders","displayName" : "Delivery - Providers","status" : false},
  //                     //  {"name":"deliverydeliverybookings","displayName" : "Delivery - Delivery Bookings","status" : false},
  //                     //  {"name":"deliverycoupons","displayName" : "Delivery - Coupons","status" : false},
  //                     //  {"name":"deliveryadmincoupons","displayName" : "Delivery -Admin Coupons","status" : false},
  //                     //  {"name":"deliveryoutletbanners","displayName" : "Delivery - Outlet Banners","status" : false},
  //                     //  {"name":"percentagebanner","displayName" : "Delivery - Percentage Banners","status" : false},
  //                     //  {"name":"servicesservicesubcategory","displayName" : "Services - Service Subcategory","status" : false},
  //                      {"name":"servicesservicelist","displayName" : "Services - Service List","status" : false},
  //                     //  {"name":"serviceproviders","displayName" : "Services - Providers","status" : false},
  //                     //  {"name":"servicesservicebookings","displayName" : "Services - Service Bookings","status" : false},
  //                     //  {"name":"payoutproviderpayout","displayName" : "payout - Provider Payout","status" : false},
  //                     //  {"name":"payoutoutletpayout","displayName" : "payout - Outlet Payout","status" : false},
  //                      {"name":"godseye","displayName" : "Gods Eye","status" : false},
  //                     //  {"name":"deliveryboyzones","displayName" : "Delivery boy Zones","status" : false},
  //                     //  {"name":"surgebayzones","displayName" : "Surge Bay Zones","status" : false},
  //                      {"name":"emailtemplate","displayName" : "Email Template","status" : false},
  //                      {"name":"appsettingsappconfig","displayName" : "App Settings - App Config","status" : false},
  //                      {"name":"appsettingsappslider","displayName" : "App Settings - App Slider","status" : false},
  //                      {"name":"banneradvertisement","displayName" : "Banner Advertisement","status" : false},
  //                      {"name":"staticpages","displayName" : "Static Pages","status" : false},
  //                      {"name":"defaultcurrency","displayName" : "Default Currency","status" : false},
  //                      {"name":"notification","displayName" : "Notification","status" : false},
  //                      {"name":"admin","displayName" : "Admin","status" : false},
  //                      {"name":"rolses","displayName" : "Roles","status" : false}
  //                     ];
//UserRole Add




  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private encry: EncryService,
    private service: OrgPlanService,
    private appspinner: AppComponent) { }

  ngOnInit() {
    this.MenuFlag=true
    ////debugger
    // this.getorgplanlist(1);
    // this.ListPlans=true;
    this.appspinner.spinnerAlert("show");
    this.service.orgPlanList(1).subscribe(
      (res) => {
        console.log("checking data", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.orgPlanView = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.orgPlanView["data"][0].data.length > 0
          ) {
            this.pages = Math.ceil(this.orgPlanView["data"][0].Count/2);
            this.result = this.orgPlanView["data"][0].data;
            console.log("adsfsdfsdgsd", this.result);
            console.log("pages...", this.pages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.getincentivelist(1);
    // this.listPeakZone=true;
    // this.listPeakZoneFlag();
   // this.listChargesflag()
    // this.ListPlans=false;
    // this.ListPlans=true;
    // this.Addplans = true;
    this.getorgplanlist(1);
    this.chargesList();
    // this.ListPlans=true;
    // this.listUserRole=true;
    this.service.getRolesListView().subscribe((res) => {
      console.log("data result",res.body['data'])
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      this.userRoleListModel = new orgPlanViewModel(res.body['error'], res.body['msg'], res.body['data']);
      console.log("result ",this.userRoleListModel['data'])
      if (res.body['data'].length > 0 ) {
      //  this.pages = this.adminviewmodel['data'][0].Count;
      
        this.resultUser = this.userRoleListModel['data'] ;
        console.log("klkllkkkkkkkk",this.resultUser)
      
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
         ///   localStorage.removeItem('Token');
          //  this.router.navigate(['login']);
        }
      });
    this.appspinner.spinnerAlert("show");
    this.service.orgPlanList(1).subscribe(
      (res) => {
        console.log("checking data", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.orgPlanView = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.orgPlanView["data"][0].data.length > 0
          ) {
            this.pages = Math.ceil(this.orgPlanView["data"][0].Count/2);
            this.result = this.orgPlanView["data"][0].data;
            console.log("adsfsdfsdgsd", this.result);
            console.log("pages...", this.pages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
   

    const startTime = document.getElementById("startTime");
    const valueSpan = document.getElementById("value");
    ////debugger
    this.chargesList();


    // startTime.addEventListener("input", () => {
    //   valueSpan.innerText = startTime.value;
    // }, false);
  // this. LocalStoreageData  = JSON.parse(localStorage.getItem('roleseditdata') )
  //   this.Navigation  = this.LocalStoreageData.Navigation
  //  console.log("local staage in eit",this.LocalStoreageData)

  
  //  this.permissionArray = this.Navigation.concat(this.permissionArray);

  //  console.log("permission arry in edit first value loaded ",this.permissionArray)
  //  this.Alloption1 = [{"name":"dashboard","displayName" : "Dashboard","status" : this.Navigation.includes("dashboard")},
  // {"name":"users","displayName" : "Users","status" : this.Navigation.includes("users")},                     
  //  {"name":"servicecategory","displayName" : "Service Category","status" : this.Navigation.includes("servicecategory")},
  //  {"name":"servicecategorytaxi","displayName" : "Service Category - Taxi","status" : this.Navigation.includes("servicecategorytaxi")},
  //  {"name":"servicecategorydelivery","displayName" : "Service Category - Delivery","status" : this.Navigation.includes("servicecategorydelivery")},
  //  {"name":"servicecategoryservices","displayName" : "Service Category - Services","status" : this.Navigation.includes("servicecategoryservices")},
  //  {"name":"taxconfig","displayName" : "Tax Config","status" : this.Navigation.includes("taxconfig")},
  //  {"name":"document","displayName" : "Document","status" : this.Navigation.includes("document")},
  
  //  {"name":"taxivehiclebrand","displayName" : "Taxi - Vehicle Brand","status" : this.Navigation.includes("taxivehiclebrand")},
  //  {"name":"taxivehiclemodel","displayName" : "Taxi - Vehicle Model","status" : this.Navigation.includes("taxivehiclemodel")},
  //  {"name":"taxiservicetype","displayName" : "Taxi - Service Type","status" : this.Navigation.includes("taxiservicetype")},
  //  {"name":"taxicancellationpolicy","displayName" : "Taxi - Cancellation Policy","status" : this.Navigation.includes("taxicancellationpolicy")},
  //  {"name":"taxiproviders","displayName" : "Taxi - Providers","status" : this.Navigation.includes("taxiproviders")},
  //  {"name":"taxiridenowbookings","displayName" : "Taxi - Ride Now Bookings","status" : this.Navigation.includes("taxiridenowbookings")},
  //  {"name":"taxiridelaterbookings","displayName" : "Taxi - Ride Later Bookings","status" : this.Navigation.includes("taxiridelaterbookings")},
  //  {"name":"taxicouponcode","displayName" : "Taxi - CouponCode","status" : this.Navigation.includes("taxicouponcode")},
  //  {"name":"deliveryoutlets","displayName" : "Delivery - Outlets","status" : this.Navigation.includes("deliveryoutlets")},
  //  {"name":"addoutlet","displayName" : "Delivery -Add Outlet","status" : this.Navigation.includes("addoutlet")},
  //  {"name":"packingcharge","displayName" : "Delivery - Packing Charge","status" : this.Navigation.includes("packingcharge")},
  //  {"name":"deliveryproviders","displayName" : "Delivery - Providers","status" : this.Navigation.includes("deliveryproviders")},
  //  {"name":"deliverydeliverybookings","displayName" : "Delivery - Delivery Bookings","status" : this.Navigation.includes("deliverydeliverybookings")},
  //  {"name":"deliverycoupons","displayName" : "Delivery - Coupons","status" : this.Navigation.includes("deliverycoupons")},
  //  {"name":"deliveryadmincoupons","displayName" : "Delivery -Admin Coupons","status" : this.Navigation.includes("deliveryadmincoupons")},
  //  {"name":"deliveryoutletbanners","displayName" : "Delivery - Outlet Banners","status" : this.Navigation.includes("deliveryoutletbanners")},
  //  {"name":"percentagebanner","displayName" : "Delivery -Percentage Banners","status" : this.Navigation.includes("percentagebanner")},
  //  {"name":"servicesservicesubcategory","displayName" : "Services - Service Subcategory","status" : this.Navigation.includes("servicesservicesubcategory")},
  //  {"name":"servicesservicelist","displayName" : "Services - Service List","status" : this.Navigation.includes("servicesservicelist")},
  //  {"name":"serviceproviders","displayName" : "Services - Providers","status" : this.Navigation.includes("serviceproviders")},
  //  {"name":"servicesservicebookings","displayName" : "Services - Service Bookings","status" : this.Navigation.includes("servicesservicebookings")},
  //  {"name":"payoutproviderpayout","displayName" : "payout - Provider Payout","status" : this.Navigation.includes("payoutproviderpayout")},
  //  {"name":"payoutoutletpayout","displayName" : "payout - Outlet Payout","status" : this.Navigation.includes("payoutoutletpayout")},
  //  {"name":"godseye","displayName" : "Gods Eye","status" : this.Navigation.includes("godseye")},

  //  {"name":"deliveryboyzones","displayName" : "Delivery boy Zones","status" : this.Navigation.includes("deliveryboyzones")},
  //  {"name":"surgebayzones","displayName" : "Surge Bay Zones","status" : this.Navigation.includes("surgebayzones")},
 
  //  {"name":"emailtemplate","displayName" : "Email Template","status" : this.Navigation.includes("emailtemplate")},
  //  {"name":"appsettingsappconfig","displayName" : "App Settings - App Config","status" : this.Navigation.includes("appsettingsappconfig")},
  //  {"name":"appsettingsappslider","displayName" : "App Settings - App Slider","status" : this.Navigation.includes("appsettingsappslider")},
  //  {"name":"banneradvertisement","displayName" : "Banner Advertisement","status" : this.Navigation.includes("banneradvertisement")},
  //  {"name":"staticpages","displayName" : "Static Pages","status" : this.Navigation.includes("staticpages")},
  //  {"name":"defaultcurrency","displayName" : "Default Currency","status" : this.Navigation.includes("defaultcurrency")},
  //  {"name":"notification","displayName" : "Notification","status" : this.Navigation.includes("notification")},
  //  {"name":"admin","displayName" : "Admin","status" : this.Navigation.includes("admin")},
  //  {"name":"rolses","displayName" : "Roles","status" : this.Navigation.includes("rolses")} ,

  
   

  // ];

    ////debugger
    this. getemailtypeView('user',1)
    this. getemailtypeView('provider',1)
    this.getorgplanlist(1);
    this.Addplans = false;
    this.editplans = false;
    // this.ListPlans = true;
    this.ListPlans =false;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
    this.listPeakZone = false;
    this.addPeakZone = false;
    this.addUserRole=false;
    this.editUserRole=false;
    this.listUserRole=false;
    this.getorgplanlist(1);
    this.ogrPlanForm = this.formBuilder.group({

      // 'DefaultPlanName': ['', [Validators.required, Validators.minLength(1)]],
      'PlanName': ['', [Validators.required, Validators.minLength(1)]],
      'CommissionPercentage': ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      //'CommissionPercentage': ['', [Validators.required, Validators.minLength(1)]],
     // 'CommissionPercentage': ['', [Validators.required, Validators.min(0), Validators.max(100), this.validateNumberRange]]
    
    });
    




    
    

    this.ogrPlanEditForm = this.formBuilder.group({
      
      // DefaultPlanName1: ["", [Validators.required, Validators.minLength(1)]],
      'PlanName1': ["", [Validators.required, Validators.minLength(1)]],
      'CommissionPercentage1': ["", [Validators.required, Validators.minLength(1)]],

    });

    this.incentiveADDFrom = this.formBuilder.group({
      'taskName': ['', [Validators.required, Validators.minLength(1)]],
      //'TaskName': [''],
      'TargetType': ['', [Validators.required, Validators.minLength(1)]],
      'TargetLimit': ['', [Validators.required, Validators.minLength(1)]],
      'Description': ['', [Validators.required, Validators.minLength(1)]],
      'Date1': ['', [Validators.required, Validators.minLength(1)]],
      'StartTime': ['', [Validators.required, Validators.minLength(1)]],
      'EndTime': ['', [Validators.required, Validators.minLength(1)]],
      // 'IncentiveType': ['', [Validators.required, Validators.minLength(1)]],
      'cashvalue': ['', [Validators.required, Validators.minLength(1)]]
    });
    this.IncentiveEditFrom = this.formBuilder.group({
      'EndTime': ['', [Validators.required, Validators.minLength(1)]],
      'Value': ['', [Validators.required, Validators.minLength(1)]],
      'TaskName': ['', [Validators.required, Validators.minLength(1)]],
      'StartTime': ['', [Validators.required, Validators.minLength(1)]],
      // 'IncentiveType': ['', [Validators.required, Validators.minLength(1)]],
      'Date': ['', [Validators.required, Validators.minLength(1)]],
      'TargetType': ['', [Validators.required, Validators.minLength(1)]],
      'TargetLimit': ['', [Validators.required, Validators.minLength(1)]],
      'Description': ['', [Validators.required, Validators.minLength(1)]],
      // 'Id': ['', [Validators.required, Validators.minLength(1)]],




    });
    this.chargesAddForm = this.formBuilder.group({
      //  'sample11':['', [Validators.required, Validators.minLength(1)]],
      'Cancel_Charges_Within': [this.Cancel_Charges_Within],
      'Cancel_Charges_After': [this.Cancel_Charges_After, [Validators.required, Validators.minLength(1)]],
      
      'Cancellation_Charges': [this.Cancellation_Charges],
      'Next_Ride_Charges': [this.Next_Ride_Charges, [Validators.required, Validators.minLength(1)]],
      
      // 'latitude':[''],
      // 'longitude':['']
      'Cancellation_Charges_Pickup_Point': [this.Cancellation_Charges_Pickup_Point, [Validators.required, Validators.minLength(1)]],
      
      'Low_Traffic_Cost': [this.Low_Traffic_Cost],
      'High_Traffic_Cost': [this.High_Traffic_Cost],
      // 'Welcome_Template': [this.Welcome_Template],
      // 'Invoice_Template': [this.Invoice_Template],
      // 'Cash_Template': [this.Cash_Template]


    });
    // this.chargesAddForm = this.formBuilder.group({
    //   //  'sample11':['', [Validators.required, Validators.minLength(1)]],
    //   'Cancel_Charges_Within': ['', [Validators.required, Validators.minLength(1)]],
    //   'Cancel_Charges_After': ['', [Validators.required, Validators.minLength(1)]],
    //   'Cancellation_Charges': ['', [Validators.required, Validators.minLength(1)]],
    //   'Next_Ride_Charges': ['', [Validators.required, Validators.minLength(1)]],
    //   // 'latitude':[''],
    //   // 'longitude':['']
    //   'Cancellation_Charges_Pickup_Point': ['', [Validators.required, Validators.minLength(1)]],
    //   'Low_Traffic_Cost': ['', [Validators.required, Validators.minLength(1)]],
    //   'High_Traffic_Cost': ['', [Validators.required, Validators.minLength(1)]],
    //   'Welcome_Template': ['', [Validators.required, Validators.minLength(1)]],
    //   'Invoice_Template': ['', [Validators.required, Validators.minLength(1)]],
    //   'Cash_Template': ['', [Validators.required, Validators.minLength(1)]]


    // });

    this.EmailAddForm = this.formBuilder.group({
      // 'Welcome_Template': ['', [Validators.required, Validators.minLength(1)]],
      // 'Invoice_Template': ['', [Validators.required, Validators.minLength(1)]],
      // 'Cash_Template': ['', [Validators.required, Validators.minLength(1)]]
      'Welcome_Template': [this.Welcome_Template,[Validators.required, Validators.minLength(1)]],
      'Invoice_Template': [this.Invoice_Template,[Validators.required, Validators.minLength(1)]],
      'Cash_Template': [this.Cash_Template,[Validators.required, Validators.minLength(1)]]
    });
    this.peakZoneADDFrom = this.formBuilder.group({
      //  'sample11':['', [Validators.required, Validators.minLength(1)]],
      'zoneType': ['', [Validators.required, Validators.minLength(1)]],
      'startTime': ['', [Validators.required, Validators.minLength(1)]],
      'endTime': ['', [Validators.required, Validators.minLength(1)]],
      'dayRange': ['', [Validators.required, Validators.minLength(1)]],
      // 'latitude':[''],
      // 'longitude':['']
      'latitude': ['', [Validators.required, Validators.minLength(1)]],
      'longitude': ['', [Validators.required, Validators.minLength(1)]]

    });
    this.peakZoneEditFrom = this.formBuilder.group({

      zoneType: ["", [Validators.required, Validators.minLength(1)]],
      startTime: ["", [Validators.required, Validators.minLength(1)]],
      endTime: ["", [Validators.required, Validators.minLength(1)]],
      dayRange: ["", [Validators.required, Validators.minLength(1)]],
      // 'latitude':[''],
      // 'longitude':['']
      latitude: ["", [Validators.required, Validators.minLength(1)]],
      longitude: ["", [Validators.required, Validators.minLength(1)]]

    });
    //ChargesList
  


    this.getorgplanlist(1);
    this.getPeakZonelist(1);
    this.getincentivelist(1);


    //user role List

    this.service.getRolesListView().subscribe((res) => {
      console.log("data result",res.body['data'])
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      this.userRoleListModel = new orgPlanViewModel(res.body['error'], res.body['msg'], res.body['data']);
      console.log("result ",this.userRoleListModel['data'])
      if (res.body['data'].length > 0 ) {
      //  this.pages = this.adminviewmodel['data'][0].Count;
      
        this.resultUser = this.userRoleListModel['data'] ;
        console.log("klkllkkkkkkkk",this.resultUser)
      
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
         ///   localStorage.removeItem('Token');
          //  this.router.navigate(['login']);
        }
      });

  }

  getorglist(page){
    this.service.orgPlanList(page).subscribe(
      (res) => {
        console.log("checking data", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.orgPlanView = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.orgPlanView["data"][0].data.length > 0
          ) {
            this.pages = Math.ceil(this.orgPlanView["data"][0].Count/2);
            this.result = this.orgPlanView["data"][0].data;
            console.log("adsfsdfsdgsd", this.result);
            console.log("pages...", this.pages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getRolesList(){
    this.appspinner.spinnerAlert('show');
    this.appspinner.spinnerAlert('hide');
    this.listUserRoleFlag();
   
    this.service.getRolesListView().subscribe((res) => {
      console.log("data result",res.body['data'])
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        window.onload = function() {
          if(!window.location.hash) {
            // window.location = window.location + '#loaded';
            window.location.reload();
          }
        }
       
      this.userRoleListModel = new orgPlanViewModel(res.body['error'], res.body['msg'], res.body['data']);
      console.log("result ",this.userRoleListModel['data'])
      if (res.body['data'].length > 0 ) {
      //  this.pages = this.adminviewmodel['data'][0].Count;
    
        this.resultUser = this.userRoleListModel['data'] ;
        console.log("klkllkkkkkkkk",this.resultUser)
      
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
         ///   localStorage.removeItem('Token');
          //  this.router.navigate(['login']);
        }
      });

  }
  orgPlanAdd() {



    this.appspinner.spinnerAlert('show');
    ////debugger
    if (this.ogrPlanForm.valid) {



      var sF = this.ogrPlanForm.value;


      this.commissionPercentage = +sF.CommissionPercentage
      var data = {

        // DefaultPlanName: sF.DefaultPlanName,
        PlanName: sF.PlanName,
        CommissionPercentage: this.commissionPercentage
      }
      // this.orgplanaddmodel = new OrgPlanModel(
      //   sF.DefaultPlanName,
      //   sF.PlanName,
      //   this.commissionPercentage);




      console.log("FormData:::", this.orgplanaddmodel);

      this.service.orgPlanAdd(data).subscribe(res => {
        if (res.body['error']) {
         
          environment.swalalert('error', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              //...after list
              this.getorgplanlist(1);
              this.MenuFlag=true;
              // this.listIncentive=true;
              // this.listIncentive=true;
              // //  this.ListPlans = true;
              // this.ListPlans=false;
              // this.Addplans = false;
              // this.editplans = false;
              // this.listCharges = false;
              // this.listEmail=false;
              // this.listIncentive = false;
              // this.listPeakZone = false;
              //.................
              if (value) {
                this.ogrPlanForm.reset();
                this.MenuFlag=true
                //this.router.navigate(['promoCodes']);
                this.router.navigate(['org']);

              }
            });
          } 
          else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
    else{
      if(this.commissionPercentageFlag===true){
        environment.swalalert('warning', 'All Fields Required,Commission Percentage should be 1 to 100');
      }
      else{
      environment.swalalert('warning', 'All Fields Required,Commission Percentage should be 1 to 100');
      }
    }
  }
  cancelorgPlanAdd()
  {
    this.getorgplanlist(1);
              this.ListPlans = true;
              this.Addplans = false;
              this.editplans = false;
              this.listCharges = false;
              this.listEmail=false;
              this.listIncentive = false;
              this.listPeakZone = false;
  }
  cancelorgPlanEdit(){
    this.MenuFlag=true
    this.getorgplanlist(1);
              this.ListPlans = true;
              this.Addplans = false;
              this.editplans = false;
              this.listCharges = false;
              this.listEmail=false;
              this.listIncentive = false;
              this.listPeakZone = false;
  }
  cancelorgPlanAdd1(){
    this.MenuFlag=true
    this.getorgplanlist(1);
              this.ListPlans = true;
              this.Addplans = false;
              this.editplans = false;
              this.listCharges = false;
              this.listEmail=false;
              this.listIncentive = false;
              this.listPeakZone = false;
  }
  canceladdingRoles(){
    this.listUserRoleFlag();
          this.listUserRole=true;
          this.getRolesList();
    // this.getorgplanlist(1);
    //           this.ListPlans = true;
    //           this.Addplans = false;
    //           this.editplans = false;
    //           this.listCharges = false;
    //           this.listEmail=false;
    //           this.listIncentive = false;
    //           this.listPeakZone = false;
  }
  orgPlanViewId(id) {
    this.addOrgplanFlag();
    this.editOrgplanFlag();
    this.orgPlanId = id;
    // alert("sdsdfs"+id)
    ////debugger
    this.service.getOrgPlanView(id).subscribe((res) => {
      if (res["error"]) {
        ////debugger
        environment.swalalert("nodata", res["msg"]);
        this.appspinner.spinnerAlert("hide");
      } else {
        var rs = res.body["data"];
        console.log("peak edit data", rs)

        ////debugger
        this.orgPlanEditModel = new OrgPlanModel(
          // rs.DefaultPlanName,
          rs.PlanName,
          rs.CommissionPercentage,
          rs.Id


        );
        this.ogrPlanEditForm = this.formBuilder.group({
          // DefaultPlanName1: [
          //   this.orgPlanEditModel.DefaultPlanName,
          //   [Validators.required, Validators.minLength(1)],
          // ],
          PlanName1: [
            this.orgPlanEditModel.PlanName,
            [Validators.required, Validators.minLength(1)],
          ],
          CommissionPercentage1: [
            this.orgPlanEditModel.CommissionPercentage,
            [Validators.required, Validators.minLength(1)],
          ]
        });


      }
    });
  }



  orgPlanEdit() {



    this.appspinner.spinnerAlert('show');
    ////debugger
    if (this.ogrPlanEditForm.valid) {



      var sF = this.ogrPlanEditForm.value;


      this.commissionPercentage1 = +sF.CommissionPercentage1

      this.orgPlanEditModel = new OrgPlanModel(
        // sF.DefaultPlanName1,
        sF.PlanName1,
        this.commissionPercentage1,
        this.orgPlanId
      );




      console.log("FormData:::", this.orgPlanEditModel);

      this.service.OrgPlanEdit(this.orgPlanEditModel).subscribe(res => {
        if (res.body['error']) {
          environment.swalalert('error', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              //...after list
              this.getorgplanlist(1);
              this.MenuFlag=true
              this.ListPlans = true;
              this.Addplans = false;
              this.editplans = false;
              this.listCharges = false;
              this.listEmail=false;
              this.listIncentive = false;
              this.listPeakZone = false;
              
              //.................
              if (value) {
                this.ogrPlanForm.reset();
                //this.router.navigate(['promoCodes']);
                this.router.navigate(['org']);

              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
    else{
      environment.swalalert('warning', 'All Fields Required');
    }
  }
  getorgplanlist(page) {
    this.listUserRole=false
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = true;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
    this.listPeakZone = false;
    ////debugger
    this.appspinner.spinnerAlert("show");
    this.service.orgPlanList(page).subscribe(
      (res) => {
        console.log("checking data", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.orgPlanView = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.orgPlanView["data"][0].data.length > 0
          ) {
            this.pages = Math.ceil(this.orgPlanView["data"][0].Count/2);
            this.result = this.orgPlanView["data"][0].data;
             this.listUserRole=false
    this.Addplans = false;
    this.editplans = false;
  //   this.ListPlans = true;
    this.ListPlans=false;
    this.listCharges = false;
    this.listEmail=false;
   //  this.listIncentive = false;
    this.listIncentive = true;
    this.listPeakZone = false;
            console.log("adsfsdfsdgsd", this.result);
            console.log("pages...", this.pages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getorgplanlist1(page) {
    this.listUserRole=false
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = true;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
    this.listPeakZone = false;
    ////debugger
    this.appspinner.spinnerAlert("show");
    this.service.orgPlanList(page).subscribe(
      (res) => {
        console.log("checking data", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.orgPlanView = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.orgPlanView["data"][0].data.length > 0
          ) {
            this.pages = Math.ceil(this.orgPlanView["data"][0].Count/2);
            this.result = this.orgPlanView["data"][0].data;
             this.listUserRole=false
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = true;
   // this.ListPlans=false;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
   // this.listIncentive = true;
    this.listPeakZone = false;
            console.log("adsfsdfsdgsd", this.result);
            console.log("pages...", this.pages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getincentivelist(page) {
    this.Addplans = false;
    this.editplans = false;
    // this.ListPlans = false;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
    this.listPeakZone = false;
    this.listIncentive=true;
    this.ListPlans = false;
    ////debugger
    this.appspinner.spinnerAlert("show");
    this.appspinner.spinnerAlert("hide");
    this.service.incentiveView(page).subscribe(
      (res) => {
        console.log("checking data Peak Zone", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.incentiveListModel = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.incentiveListModel["data"][0].data.length > 0
          ) {
             this.incentivePages1 =Math.ceil( this.incentiveListModel["data"][0].Count/2);
            this.incentiveResult1 = this.incentiveListModel["data"][0].data;
           
            console.log("peak zoneadsfsdfsdgsd", this.incentiveResult1[0].StartTime.getUTCHours());
            console.log("peak zone pages...", this.incentivePages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getlistincentive(page){
    this.service.incentiveView(page).subscribe(
      (res) => {
        console.log("checking data Peak Zone", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.incentiveListModel = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.incentiveListModel["data"][0].data.length > 0
          ) {
             this.incentivePages1 = Math.ceil(this.incentiveListModel["data"][0].Count/2);
            this.incentiveResult1 = this.incentiveListModel["data"][0].data;
           
            console.log("peak zoneadsfsdfsdgsd", this.incentiveResult1[0].StartTime.getUTCHours());
            console.log("peak zone pages...", this.incentivePages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getPeakZonelist(page) {
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
    this.listPeakZone = true;
    ////debugger
    this.appspinner.spinnerAlert("show");
    this.appspinner.spinnerAlert('hide')
    this.service.peakZoneList(page).subscribe(
      (res) => {
        console.log("checking data Peak Zone", res)
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.peakZoneListModel = new orgPlanViewModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          if (
            res.body["data"].length > 0 &&
            this.peakZoneListModel["data"][0].data.length > 0
          ) {
            this.peakPages = Math.ceil(this.peakZoneListModel["data"][0].Count/2);
            this.peakResult = this.peakZoneListModel["data"][0].data;
            const d = new Date('1970-01-01T09:30:00.000Z') // Parses a ISO 8601 Date
            console.log("date.......", d.getHours()); // gets the hours in the timezone of the browser.
            console.log(d.getUTCHours()); // gets the hours in UTC timezone.
            console.log(d.getMinutes());
            console.log("peak zoneadsfsdfsdgsd", this.peakResult[0].StartTime.getUTCHours());
            console.log("peak zone pages...", this.peakPages)
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  orgplanUpdate(id, status, r, i) {
    ////debugger
     var data = { Id: { Id: id }, updata: { Status: status } };
    //var data = { Id:id,updata:status  };
    if (status === 'inactive') {
      ////debugger
      if (data) {
        this.service.orgPlanStatusUpdate(data).subscribe((res) => {
          if (res['error']) {
            environment.swalalert('nodata', res['msg']);
            this.appspinner.spinnerAlert('hide');

          } else {
            if (res.body['error'] === false) {
              ////debugger
              //this. getUsersSerchPage(this.page);
              this.getorgplanlist(1);
            } else {
              environment.swalalert('error', res.body['msg']);
            }
          }
        },
          (err) => {
            console.log(err);
          });
        //this. getUsersSerchPage(this.page);
      }
      this.getorgplanlist(1);
      environment.swalalert('Disabled', 'Disabled Successfully').then(value => {

        // if (value) {
        //   this.service.orgPlanStatusUpdate(data).subscribe((res) => {
        //     if (res['error']) {
        //       environment.swalalert('nodata', res['msg']);
        //       this.appspinner.spinnerAlert('hide');

        //     } else {
        //     if(res.body['error'] === false ) {
        //       ////debugger
        //       //this. getUsersSerchPage(this.page);
        //       this.getorgplanlist(1);
        //     } else {
        //       environment.swalalert('error',res.body['msg']);
        //     }
        //   }
        //   },
        //     (err) => {
        //       console.log(err);
        //     });
        //     //this. getUsersSerchPage(this.page);
        // }
      });

    } else {
      this.service.orgPlanStatusUpdate(data).subscribe((res) => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');

        } else {
          if (res.body['error'] === false) {
            this.getorgplanlist(1);
            environment.swalalert('success', status == 'active' ? 'Enabled Successfully' : 'Disabled Succesfully').then(value => {
              if (value) {
                ////debugger
                this.appspinner.spinnerAlert('show');
                //this. getUsersSerchPage(this.page);
                //this.getUsers(1)
                //this. getUsersSerchPage(this.page);
                this.getorgplanlist(1);
                this.appspinner.spinnerAlert('hide')
              }
              ////debugger
              this.appspinner.spinnerAlert('show');
              this.getorgplanlist(1);
              this.appspinner.spinnerAlert('hide')

            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      },

        (err) => {
          console.log(err);
        });
    }
  }
  orgplanUpdate1(id, status, r, i) {
    ////debugger
  // var data = { Id: { Id: id }, updata: { Status: status } };
    var data = { Id:id,Status:status  };
    if (status === 'inactive') {
      this.service.orgPlanStatusUpdate(data).subscribe((res) => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');

        } else {
          if (res.body['error'] === false) {
            this.getorgplanlist(1);
            environment.swalalert('success', status == 'active' ? 'Enabled Successfully' : 'Disabled Succesfully').then(value => {
              if (value) {
                ////debugger
                this.appspinner.spinnerAlert('show');
                //this. getUsersSerchPage(this.page);
                //this.getUsers(1)
                //this. getUsersSerchPage(this.page);
                this.getorgplanlist(1);
                this.appspinner.spinnerAlert('hide')
              }
              ////debugger
              this.appspinner.spinnerAlert('show');
              this.getorgplanlist(1);
              this.appspinner.spinnerAlert('hide')

            });
          }
           
        }
      },

        (err) => {
          console.log(err);
        });

    } 
    else if(status === 'active'){
      this.service.orgPlanStatusUpdate(data).subscribe((res) => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');

        } else {
          if (res.body['error'] === false) {
            this.getorgplanlist(1);
            environment.swalalert('success', status == 'active' ? 'Enabled Successfully' : 'Disabled Succesfully').then(value => {
              if (value) {
                ////debugger
                this.appspinner.spinnerAlert('show');
                //this. getUsersSerchPage(this.page);
                //this.getUsers(1)
                //this. getUsersSerchPage(this.page);
                this.getorgplanlist(1);
                this.appspinner.spinnerAlert('hide')
              }
              ////debugger
              this.appspinner.spinnerAlert('show');
              this.getorgplanlist(1);
              this.appspinner.spinnerAlert('hide')

            });
          }
           
        }
      },

        (err) => {
          console.log(err);
        });
    }
    
  }





  peakZoneAdd() {
    ////debugger
    if (this.peakZoneADDFrom.valid) {
      var peakValue = this.peakZoneADDFrom.value;
      var data = {

        ZoneName: peakValue.zoneType,
        Day: peakValue.dayRange,
        StartTime: peakValue.startTime,
        EndTime: peakValue.endTime,
        latitude: parseFloat(peakValue.latitude),
        longitude: parseFloat(peakValue.longitude)
      }

      console.log("FormData:::", this.peakZoneADDFrom);

      this.service.peakZoneAdd(data).subscribe(res => {
        if (res.body['error']) {
          environment.swalalert('nodata', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              //...after list
              this.addPeakZone=false;
              this.closebutton.nativeElement.click();
              this.peakZoneADDFrom.reset();
              this.listPeakZone = true;
              this.getPeakZonelist(1);
              
              
              this.cancel();
              //.................
              if (value) {
                this.peakZoneADDFrom.reset();

                this.router.navigate(['org']);

              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
  }

  // --------------Incentive List---------------

  getorgincentiveView(page) {
             ////debugger
             this.Addplans = false;
             this.editplans = false;
             this.ListPlans = false;
             this.listCharges = false;
             this.listEmail=false;
             this.listIncentive = false;
             this.listPeakZone = false;
             this.listIncentive=true;
             ////debugger
             this.appspinner.spinnerAlert("show");
             this.service.incentiveView(page).subscribe(
               (res) => {
                 console.log("checking data",res)
                 if (res.body["error"]) {
                   environment.swalalert("nodata", res.body["msg"]);
                   this.appspinner.spinnerAlert("hide");
                 } else {
                   this.orgIncentiveView = new orgPlanViewModel(
                     res.body["error"],
                     res.body["msg"],
                     res.body["data"]
                   );
                   if (
                     res.body["data"].length > 0 &&
                     this.orgIncentiveView["data"][0].data.length > 0
                   ) {
                     this.incentivePages = Math.ceil(this.orgIncentiveView["data"][0].Count/2);
                     this.incentiveResult = this.orgIncentiveView["data"][0].data;
                     console.log("incentive adsfsdfsdgsd",this.incentiveResult);
                     console.log("incentive page...",this.incentivePages)
                     this.appspinner.spinnerAlert("hide");
                   } else {
                     environment.swalalert("nodata", "No Data Available ");
                     this.appspinner.spinnerAlert("hide");
                   }
                 }
               },
               (err) => {
                 console.log(err);
               }
             );
           }
        
  showAddIncentiveTargetLimit(value='cash'){
    return this.incentiveADDFrom.get('TargetType').value === value
  }

  showEditIncentiveTargetLimit(value='cash'){
    return this.IncentiveEditFrom.get('TargetType').value === value
  }

  IncentiveAdd() {
    ////debugger;
    var incentiveValue = this.incentiveADDFrom.value;
    var ggg = "hgjhgjhg"
    var type="cash"
    var data = {

      TaskName: incentiveValue.taskName,
      // TargetLimit: incentiveValue.taskName,
      
      TargetType: incentiveValue.TargetType,
      TargetLimit: Number(incentiveValue.TargetLimit),
      Description: incentiveValue.Description,
      Date: this.formatDate(incentiveValue.Date1),

      StartTime: incentiveValue.StartTime,
      EndTime: incentiveValue.EndTime,
      // IncentiveType: incentiveValue.IncentiveType,
      IncentiveType: String(type),
      Value: incentiveValue.cashvalue

    }



    //  this.appspinner.spinnerAlert('show');
    ////debugger
    if (this.incentiveADDFrom.valid) {


      // var cccc="4242542.4433"
      // var vvvv=parseFloat(cccc)
      // alert(vvvv)

      var incentiveValue = this.incentiveADDFrom.value;
      //       // lo:any;

      //       parseFloat(peakValue.longitude)
      //       var wwww1=parseFloat(peakValue.sample11);
      // alert(wwww1)

      //      parseFloat(peakValue.longitude)
      //       var wwww=parseFloat(peakValue.longitude);
      // alert(wwww)

      // var data;
      //  data={
      //   peakValue.value,
      //  }
      //                 var data = {

      //                   TaskName:incentiveValue.TaskName,
      //                   TargetLimit:incentiveValue.TargetLimit,
      //                   Description:incentiveValue.Description,
      //                   Date:this.formatDate(incentiveValue.Date),

      //                   StartTime:incentiveValue.StartTime,
      //           EndTime:incentiveValue.endTime,
      //           IncentiveType:incentiveValue.IncentiveType,
      //           Value:incentiveValue.Value

      // }


      // this.latitude = parseFloat(this.bookingslist.Latitude);
      // this.longitude = parseFloat(this.bookingslist.Longitude);

      // providerId: id.Id, service: this.count, amount: this.amt}

      // this.peakZoneADDModel = new OrgPeakModel(
      //  peakValue.zoneType,
      //   peakValue.startTime,
      //   peakValue.endTime,
      //   peakValue.dayRange,
      //   peakValue.Latitude,
      //   peakValue.Longitude


      //  );




      console.log("FormData:::", this.incentiveADDFrom);

      this.service.incentiveAdd(data).subscribe(res => {
        if (res.body['error']) {
          environment.swalalert('nodata', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
                //...after list
                // this.addPeakZone=false;
                //...after list
              // this.addPeakZone=false;
              // this.closebutton.nativeElement.click();
              // this.peakZoneADDFrom.reset();
              // this.listPeakZone = true;
              // this.getPeakZonelist(1);

this.getlistincentive(1)
this.addIncentive=false;
                this.closebutton1.nativeElement.click();
                this.incentiveADDFrom.reset();
                // this.listIncentive = true;
                // this.getincentivelist(1);
                this.ListPlans=true;
                this.getorgplanlist1(1);
                
                this.cancel();
                //.................
              //...after list
              // this.listIncentiveFlag();
              // this.getincentivelist(1);
              // this.incentiveADDFrom.reset();
              // .......................
              //  this. listPeakZoneFlag();
              //   this.getPeakZonelist(1);
              // this.listIncentive = true;
            
              // this.listPeakZone = false;
              // this.ListPlans = false;
              // this.Addplans = false;
              // this.editplans = false;
              // this.listCharges = false;
              // this.listEmail=false;
              
             
              //  this.cancel();
              //.................
              if (value) {
                this.incentiveADDFrom.reset();
                //this.router.navigate(['promoCodes']);
                this.router.navigate(['org']);

              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
    else{

      environment.swalalert("warning", "All Fields Required");
    }
  }


  incentiveViewList(pageincentive,id) {   
    this.editIncentive=true;   
    // this.getincentivelist(1);
    // this.getincentivelist(pageincentive);
    // this.listIncentive = true;
    this.getorgplanlist1(1);
    this.ListPlans=true;
    
    // this.ListPlans=false;
    this.addIncentive=false;
    this.listCharges = false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
  
    this.listPeakZone = false;
    this.addPeakZone = false;
    this.addUserRole=false;
    this.editUserRole=false;
    this.listUserRole=false;                                                                               
    this.incentiveId = id;                                                     
    ////debugger
    this.service.IncentiveViewList(id).subscribe((res) => {
      if (res["error"]) {
        ////debugger
        environment.swalalert("nodata", res["msg"]);
        this.appspinner.spinnerAlert("hide");
      } else {
        var rd = res.body["data"];
        
        console.log("incentive edit data", rd)
        console.log("incentive edit data", rd.Date)
        var aa = this.formatDate(rd.Date)
        console.log("date format",aa)
        
        this.IncentiveEditModel = new OrgIncentiveModel (
          /*rd.Id,
          rd.TaskName,
          rd.Description,
          rd.TargetLimit,
          aa,                    
          rd.IncentiveType,                  
          rd.StartTime,
          rd.EndTime,
          rd.Value,*/
          rd.EndTime,
          rd.Value,
          rd.TaskName,
           rd.StartTime,
          rd.IncentiveType, 
           aa,
          rd.TargetLimit,
          rd.TargetType,
           rd.Description,
            rd.Id,

        
        );
        this.IncentiveEditFrom = this.formBuilder.group({
          EndTime: [
            this.IncentiveEditModel.EndTime,
            [Validators.required, Validators.minLength(1)],
          ],

          Value: [
            this.IncentiveEditModel.Value,
            [Validators.required, Validators.minLength(1)],
          ],

          TaskName: [
            this.IncentiveEditModel.TaskName,
            [Validators.required, Validators.minLength(1)],
          ],
          StartTime: [
            this.IncentiveEditModel.StartTime,
            [Validators.required, Validators.minLength(1)],
          ],
          // IncentiveType: [
          //   this.IncentiveEditModel.IncentiveType,
          //   [Validators.required, Validators.minLength(1)],
          // ],
          Date: [
            this.IncentiveEditModel.Date,
           // this.datepipe.transform(
            //this.IncentiveEditModel,
              //"dd-MM-yyyy"
           //),
            [Validators.required, Validators.minLength(1)],
          ],
          TargetLimit: [
            this.IncentiveEditModel.TargetLimit,
          
           [Validators.required, Validators.minLength(1)],
          ],
          TargetType: [
            this.IncentiveEditModel.TargetType,
          
           [Validators.required, Validators.minLength(1)],
          ],
          Description: [
            this.IncentiveEditModel.Description,
            [Validators.required, Validators.minLength(1)],
          ],

         
          
          
        });


      }
    });
  }
  
 IncentiveEdit() {
  ////debugger
  // var Id = this.route.snapshot.params.id;
  // if (this.IncentiveEditFrom.valid) {
    if (this.IncentiveEditFrom.valid) {
    var rd = this.IncentiveEditFrom.value;
    var type="cash";
    var bb = this.formatDate(rd.Date)
    

    this.IncentiveEditModel = new OrgIncentiveModel(
      //rd.TaskName,
      //rd.TargetLimit,
      //rd.Description,
      //bb,
      //rs.Day,
      //rd.StartTime,
      //rd.EndTime,
      //rd.IncentiveType,
      //rd.Value,
     //this.incentiveId
     rd.EndTime,
        rd.Value,
        rd.TaskName,
         rd.StartTime,
         String(type),
        // rd.IncentiveType, 
         bb,
        //  rd.TargetLimit,
       Number(rd.TargetLimit),
       rd.TargetType,
         rd.Description,
         this.incentiveId,
      /*rd.StartTime,
      rd.Description,
      rd.Value,
      rd.TaskName,
      rd.EndTime,
      rd.IncentiveType,
      bb,
      rd.TargetLimit,
      rd.Id*/

    );


    
    // this.service.IncentiveEdit(this.IncentiveEditModel).subscribe((res) => {
    //   if (res["error"]) {
    //     environment.swalalert("nodata", res["msg"]);
    //     this.appspinner.spinnerAlert("hide");
    //   } else {
    //     if (res.body["error"] === false) {
    //       environment
    //         .swalalert("success", res.body["msg"])
    //         .then((value) => {
    //           this.editIncentive=false;
    //           this.closebutton.nativeElement.click();
    //           this.IncentiveEditFrom.reset();
    //           this.listIncentive = true;
    //           this.getincentivelist(1);
              
              
    //           this.cancel();
              
    //           if (value) {
    //              this.IncentiveEditFrom.reset();
                
    //             this.router.navigate(['org']);
    //           }
    //         });
    //     } else {
    //       environment.swalalert("error", res.body["msg"]);
    //     }
    //   }
    // });




    this.service.IncentiveEdit(this.IncentiveEditModel).subscribe((res) => {
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        if (res.body['error'] === false) {
          environment.swalalert('success', res.body['msg']).then(value => {
            


            this.editIncentive=false;
                      this.closebutton2.nativeElement.click();
                      this.IncentiveEditFrom.reset();
                      // this.listIncentive = true;
                      // this.getincentivelist(1);
                      this.getlistincentive(1)
                      this.getorgplanlist1(1);
                      this.ListPlans=true;
                      
                      
                      this.cancel();
           
            if (value) {
              this.IncentiveEditFrom.reset();
              //this.router.navigate(['promoCodes']);
              this.router.navigate(['org']);

            }
          });
        } else {
          environment.swalalert('error', res.body['msg']);
        }
      }
    });





  } 
  
  else {
    environment.swalalert("warning", "All Fields Required");
  }
}
    


  emailEdit() {
    this.appspinner.spinnerAlert('show');
    ////debugger
    var test=1;
    if (this.EmailAddForm.valid) {
      // if (test) {
      // var sF = this.chargesAddForm.value;
      var sF = this.EmailAddForm.value;
      var sF1 = this.chargesAddForm.value;
      // this.chargesEditModel = new OrgChargesModel(
      //   sF.Cancel_Charges_After,
      //   sF.Cancellation_Charges_Pickup_Point,
      //   sF.Next_Ride_Charges,
      //   sF.Cancellation_Charges_Pickup_Point,
      //   // sF.Cancel_Charges_After,
      //  sF.Cancel_Charges_Within,
      //   // ..................................
      //   // sF1.Cancel_Charges_Within,
      //   // sF1.Cancel_Charges_After,
      //   // sF1.Cancellation_Charges,
      //   // sF1.Next_Ride_Charges,
      //   // sF1.Cancellation_Charges_Pickup_Point,
      //   sF1.Low_Traffic_Cost,
      //   sF1.High_Traffic_Cost,
      //   sF.Welcome_Template,
      //   sF.Invoice_Template,
      //   sF.Cash_Template

      // );
      this.chargesEditModel = new OrgChargesModel(
        sF.Cancel_Charges_After,
        sF.Next_Ride_Charges,
        sF.Cancellation_Charges_Pickup_Point,
        
       
      //   sF.Cancellation_Charges_Pickup_Point,
      //   // sF.Cancel_Charges_After,
      //  sF.Cancel_Charges_Within,
        // ..................................
        // sF1.Cancel_Charges_Within,
        // sF1.Cancel_Charges_After,
        // sF1.Cancellation_Charges,
        // sF1.Next_Ride_Charges,
        // sF1.Cancellation_Charges_Pickup_Point,
        // sF1.Low_Traffic_Cost,
        // sF1.High_Traffic_Cost,
        sF.Welcome_Template,
        sF.Invoice_Template,
        sF.Cash_Template

      );




      console.log("FormData:::", this.chargesEditModel);

      this.service.chargesEdit(this.chargesEditModel).subscribe(res => {
        if (res.body['error']) {
          environment.swalalert('nodata', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              //...after list
              //this.getorgplanlist(1);
              this.ListPlans = false;
              this.Addplans = false;
              this.editplans = false;
              this.listCharges = false;
              this.listEmail=true;
              this.listIncentive = false;
              this.listPeakZone = false;
              //.................
              if (value) {
                this.ogrPlanForm.reset();
                //this.router.navigate(['promoCodes']);
                this.router.navigate(['org']);

              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
    else {
    environment.swalalert("warning", "All Fields Required");
  }
  }
  chargesEdit() {
    this.appspinner.spinnerAlert('show');
    ////debugger
    var test=1;
    if (this.chargesAddForm.valid) {
      // if (test) {
      var sF = this.chargesAddForm.value;
     console.log("ggggggggg",sF)

//      U_Cancel_Charges_After
// : 
// "300"
// U_Cancel_Charges_Within
// : 
// "100"
// U_Cancellation_Charges
// : 
// "200"
// U_Cancellation_Charges_Pickup_Point
// : 
// null
// U_High_Traffic_Cost
// : 
// null
// U_Low_Traffic_Cost
// : 
// null
// U_Next_Ride_Charges
// : 
// "100"
      // sF.Cancel_Charges_After,
      // sF.Cancellation_Charges_Pickup_Point,
      // sF.Next_Ride_Charges,
      // sF.Cancel_Charges_After,
      //   sF.Cancellation_Charges_Pickup_Point,
      //   sF.Next_Ride_Charges,
      //   sF.Cancellation_Charges_Pickup_Point,
      this.chargesEditModel = new OrgChargesModel(
        sF.Cancel_Charges_After,
        sF.Next_Ride_Charges,
        sF.Cancellation_Charges_Pickup_Point,
      
        // sF.Cancel_Charges_Within,
        // sF.Cancel_Charges_After,
        // sF.Cancellation_Charges,
        
        
        // sF.Low_Traffic_Cost,
        // sF.High_Traffic_Cost,
        sF.Welcome_Template,
        sF.Invoice_Template,
        sF.Cash_Template

      );

// this.chargesEditModel = new OrgChargesModel(
//         sF.Cancel_Charges_After,
//         sF.Cancellation_Charges_Pickup_Point,
//         sF.Next_Ride_Charges,
//         // sF.Cancel_Charges_Within,
//         sF.Cancel_Charges_After,
//         sF.Cancellation_Charges,
        
        
//         sF.Low_Traffic_Cost,
//         sF.High_Traffic_Cost,
//         sF.Welcome_Template,
//         sF.Invoice_Template,
//         sF.Cash_Template

//       );
   

      console.log("FormData:::", this.chargesEditModel);

      this.service.chargesEdit(this.chargesEditModel).subscribe(res => {
        if (res.body['error']) {
          environment.swalalert('nodata', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              //...after list
              //this.getorgplanlist(1);
              this.ListPlans = false;
              this.Addplans = false;
              this.editplans = false;
              this.listCharges = true;
              this.listEmail=false;
              this.listIncentive = false;
              this.listPeakZone = false;
              //.................
              if (value) {
                this.ogrPlanForm.reset();
                //this.router.navigate(['promoCodes']);
                this.router.navigate(['org']);

              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
    else{
      environment.swalalert('warning', 'All Fields Required');
    }
  }


  getemailtypeView(type, page) {
    var type2 = 'user'
    var type3='provider'
    ////debugger;
    this.appspinner.spinnerAlert('show');

    this.service.emailtypeListView(type2, page).subscribe((res) => {
      if (res.body['error']) {
        console.log("Error*****");
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {

        console.log("resfffffffffffffff", res)
        console.log("length", res.body['data'].length)

        this.emailTemplate = res.body['data']
        console.log("jjjjjjjjjjjj", this.emailTemplate);

        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }

    },
      (err) => {
        console.log(err);
      })
      this.service.emailTemplateProviders(1,'provider').subscribe((res) => {
        if (res.body['error']) {
          console.log("Error*****");
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
  
          console.log("res", res)
          console.log("length", res.body['data'].length)
  
          this.emailTemplatecash = res.body['data'];
          console.log("emailTemplatecash", this.emailTemplatecash);
  
          environment.swalalert('nodata', 'No Data Available ');
          this.appspinner.spinnerAlert('hide');
        }
  
      },
        (err) => {
          console.log(err);
        })
  }

  emailTemplateAdd() {
    ////debugger;
    var emailValue = this.EmailAddForm.value;
    var ggg = "hgjhgjhg"
    var data = {

      Welcome_Template: emailValue.Welcome_Template,
      Invoice_Template: emailValue.Invoice_Template,
      Cash_Template: emailValue.Cash_Template,
      

    }

    ////debugger
    if (this.EmailAddForm.valid) {

      // var incentiveValue = this.EmailAddForm.value;
     console.log("FormData:::", this.EmailAddForm);

      this.service.emailTemplateAdd(data).subscribe(res => {
        if (res.body['error']) {
          environment.swalalert('nodata', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              
              this.ListPlans = false;
              this.Addplans = false;
              this.editplans = false;
              this.listCharges = false;
              this.listEmail=false;
              this.listIncentive = true;
              this.listPeakZone = false;
              this.EmailAddForm.reset();
             
              if (value) {
                this.EmailAddForm.reset();
                this.router.navigate(['org']);

              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  cancel() {
    this.peakZoneADDFrom.reset();
  }


  addOrgplanFlag() {
this.MenuFlag=false
    this.Addplans = true;
    this.editplans = false;
    this.ListPlans = false;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
    this.listPeakZone = false;
  }

  editOrgplanFlag() {

    this.Addplans = false;
    this.editplans = true;
    this.ListPlans = false;
    this.listCharges = false;
    this.listEmail=false;
    this.listIncentive = false;
    this.listPeakZone = false;
  }
  // listOrgplanFlag() {

  //   this.Addplans = false;
  //   this.editplans = false;
  //   this.ListPlans = true;
  //   this.listCharges = false;
  //   this.listEmail=false;
  //   this.listIncentive = false;
  //   this.listPeakZone = false;
  // }
  sample1() {

    this.Addplans = false;
    this.editplans = false;
    // this.ListPlans = true;
    this.ListPlans = false;
  }
  listChargesflag() {
    ////debugger
    this.chargesList();
    this.listCharges = true;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = false;
    this.listPeakZone = false;
    this.listUserRole=false;
    this.addUserRole=false;
  }
  listEmailFlag() {
    ////debugger
    this.chargesList();
    this.listCharges = false;
    this.listEmail=true;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = false;
    this.listPeakZone = false;
    this.listUserRole=false;
    this.addUserRole=false;
    this.editUserRole=false;


  }
  listIncentiveFlag() {
    this.getincentivelist(1);
   // this.getorgincentiveView(1)
    // this.listCharges = false;
    // this.listEmail=false;
    // this.Addplans = false;
    // this.editplans = false;
    // this.ListPlans = false;
    // this.listIncentive = true;
    // this.listPeakZone = false;
    this.listCharges = false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = true;
    this.addIncentive=false;
    this.editIncentive=false;
    this.listPeakZone = false;
    this.listUserRole=false;
    this.addUserRole=false;
    this.editUserRole=false;
  }
  listPeakZoneFlag() {
    this.getPeakZonelist(1);
    this.listCharges = false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = false;
    this.listPeakZone = true;
    this.addPeakZone = false;
    this.addUserRole=false;
    this.editUserRole=false;
    this.listUserRole=false;
  }
  listOrgPlanFlag() {
    this.getorgplanlist1(1);
    this.ListPlans=true;
    this.listPeakZone = false;
    
    this.listCharges = false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    // this.ListPlans = false;
    this.listIncentive = false;
    this.listPeakZone = false;
    this.addPeakZone = false;
    this.addUserRole=false;
    this.editUserRole=false;
    this.listUserRole=false;
  }
  addPeakZoneFlag() {
    this.getPeakZonelist(1);
    this.listCharges = false;
    this.ListPlans=false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = false;
    this.listPeakZone = true;
    this.addPeakZone = true;
    this.addUserRole=false;
    this.editUserRole=false;
    this.listUserRole=false;
    // aria-hidden="true"

  }
  addIncentiveFlag() {
    // this.getincentivelist(1);
    // this.listIncentive = true;
    // this.ListPlans=false;
    // this.listIncentive=false;
     this.ListPlans=true;
     this.getorgplanlist1(1)
    this.addIncentive=true;
    this.listCharges = false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
  
    this.listPeakZone = false;
    this.addPeakZone = false;
    this.addUserRole=false;
    this.editUserRole=false;
    this.listUserRole=false;
    // aria-hidden="true"

  }
  listUserRoleFlag() {
    // window.location.reload();
   //this. getRolesList();
   window.onload = function() {
    if(!window.location.hash) {
      // window.location = window.location + '#loaded';
      window.location.reload();
    }
  }
   this.listUserRole=true;
    this.listCharges = false;
    this.ListPlans=false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = false;
    
    this.listPeakZone = false;
    this.addPeakZone = false;
    this.addUserRole=false;
    this.editUserRole=false;
    
    // aria-hidden="true"

  }
  addUserRoleFlag() {
    
    this.listCharges = false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = false;
    //Change this one
    // this.getPeakZonelist(1);
    // this.listPeakZone = true;
    this.listPeakZone = false;
    this.addPeakZone = false;
    this.addUserRole=true;
    this.editUserRole=false;
    this.listUserRole=false;
    // aria-hidden="true"

  }
  editUserRoleFlag() {
    
    this.listCharges = false;
    this.listEmail=false;
    this.Addplans = false;
    this.editplans = false;
    this.ListPlans = false;
    this.listIncentive = false;
    //Change this one
    // this.getPeakZonelist(1);
    // this.listPeakZone = true;
    this.listPeakZone = false;
    this.addPeakZone = false;
    this.addUserRole=false;
    this.editUserRole=true;
    this.listUserRole=false;
    // aria-hidden="true"

  }
  peakZoneViewId(id) {
    this.peakId = id;
    ////debugger
    this.service.getPeakZoneView(id).subscribe((res) => {
      if (res["error"]) {
        ////debugger
        environment.swalalert("nodata", res["msg"]);
        this.appspinner.spinnerAlert("hide");
      } else {
        var rs = res.body["data"];
        console.log("peak edit data", rs)


        this.peakZoneEditModel = new OrgPeakModel(

          rs.ZoneName,
          //rs.Day,
          rs.StartTime,
          rs.EndTime,
          rs.Day,
          rs.latitude,
          rs.longitude,
          rs.Id


        );
        this.peakZoneEditFrom = this.formBuilder.group({
          zoneType: [
            this.peakZoneEditModel.ZoneName,
            [Validators.required, Validators.minLength(1)],
          ],
          startTime: [
            this.peakZoneEditModel.StartTime,
            [Validators.required, Validators.minLength(1)],
          ],
          endTime: [
            this.peakZoneEditModel.EndTime,
            [Validators.required, Validators.minLength(1)],
          ],
          dayRange: [
            this.peakZoneEditModel.Day,
            [Validators.required, Validators.minLength(1)],
          ],
          latitude: [
            this.peakZoneEditModel.latitude,
            [Validators.required, Validators.minLength(1)],
          ],
          longitude: [
            this.peakZoneEditModel.longitude,
            [Validators.required, Validators.minLength(1)],
          ],
          // maxvalue: [
          //   this.peakZoneADDModel.MaxValueToRedeem,
          //   [Validators.required, Validators.minLength(1)],
          // ],
          // validfrom: [
          //   this.datepipe.transform(
          //     this.peakZoneADDModel.ValidFrom,
          //     "dd-MM-yyyy"
          //   ),
          //   [Validators.required, Validators.minLength(1)],
          // ],
          // validto: [
          //   this.datepipe.transform(this.peakZoneADDModel.ValidTo, "dd-MM-yyyy"),
          //   [Validators.required, Validators.minLength(1)],
          // ],
          // redeemableperuser: [
          //   this.peakZoneADDModel.RedeemableType,
          //   [Validators.required, Validators.minLength(1)],
          // ],
          // description: [
          //   this.peakZoneADDModel.Description,
          //   [Validators.required, Validators.minLength(1)],
          // ],
          // IsActive: [this.peakZoneADDModel.Status],
        });


      }
    });
  }
chargesList(){
  ////debugger
  this.service.chargesList().subscribe(
    (res) => {
      if (res.body["error"]) {
        environment.swalalert("nodata", res.body["msg"]);
        this.appspinner.spinnerAlert("hide");
      } else {
        this.chargesListModel = new orgPlanViewModel(
          res.body["error"],
          res.body["msg"],
          res.body["data"]
        );
        ////debugger
        var fnl = this.chargesListModel["data"][0].data;
        console.log("sasdasd////debuggerchargesList();",fnl[33].Id)
        
        //UserU_Cancel_Charges_After

        this.Cancel_Charges_Within = fnl[25].Value;
        this.Cancel_Charges_After = fnl[23].Value;
        this.Cancellation_Charges = fnl[27].Value;
        
        this.Next_Ride_Charges = fnl[25].Value;
        // alert(this.Next_Ride_Charges)
       
        this.Cancellation_Charges_Pickup_Point = fnl[26].Value;
        // alert(this.Next_Ride_Charges )
        this.Low_Traffic_Cost = fnl[30].Value;

        this.High_Traffic_Cost = fnl[31].Value;
        // alert(fnl[32].Value)
        this.Welcome_Template=fnl[29].Value;
        this.Invoice_Template=fnl[30].Value;
        this.Cash_Template=fnl[31].Value;
        this. aa1=this.Invoice_Template;
    // alert(this.Invoice_Template)
      //  if(this.Invoice_Template=="wewetwettwvvrw235235")
      //  if(this.Cash_Template=="knkn")
      //  {
      //   this.Cash_Template="wewetwettwvvrw235235";
      //  }


        if (
          res.body["data"].length > 0 &&
          this.chargesListModel["data"][0].data.length > 0
        ) {

          this.appspinner.spinnerAlert("hide");
        } else {
          environment.swalalert("nodata", "No Data Available ");
          this.appspinner.spinnerAlert("hide");
        }
      }
    },
    (err) => {
      console.log(err);
      if (err["status"] === 401) {
        environment.swalalert("nodata", err["msg"]);
        // this.cookie.delete('Token');
        localStorage.removeItem("Token");
        this.router.navigate(["login"]);
      }
    }
  );
}
  peakZoneEdit() {
    ////debugger
    // var Id = this.route.snapshot.params.id;
    if (this.peakZoneEditFrom.valid) {
      var rs = this.peakZoneEditFrom.value;


      this.peakZoneEditModel = new OrgPeakModel(
        rs.zoneType,
        //rs.Day,
        rs.startTime,
        rs.endTime,
        rs.dayRange,
        rs.latitude,
        rs.longitude,
        this.peakId
        // rs.ZoneName,
        // //rs.Day,
        // rs.StartTime,
        // rs.EndTime,
        // rs.Day,
        // rs.latitude,
        // rs.longitude,
        // this.peakId



      );
      // console.log(this.promocodetype);
      this.service.peakzoneEdit(this.peakZoneEditModel).subscribe((res) => {
        if (res["error"]) {
          environment.swalalert("nodata", res["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          if (res.body["error"] === false) {
            environment
              .swalalert("success", res.body["msg"])
              .then((value) => {


                this.addPeakZone=false;
                this.closebutton.nativeElement.click();
                this.peakZoneADDFrom.reset();
                this.listPeakZone = true;
                this.getPeakZonelist(1);
                
                
                 this.cancel();
                // this.listPeakZone = true;
                // this.getPeakZonelist(1);
                // this.closebutton.nativeElement.click();
                // this.peakZoneEditFrom.reset();
                
                
                
                this.cancel();
                if (value) {
                  this.peakZoneEditFrom.reset();

                this.router.navigate(['org']);
                }
              });
          } else {
            environment.swalalert("error", res.body["msg"]);
          }
        }
      });

    } else {
      environment.swalalert("warning", "Validation Required");
    }
  }
  sample() {

    var samplyyyy;
    this.appspinner.spinnerAlert('show');




    var sam = this.peakZoneADDFrom.value;
    samplyyyy = sam.startTime
    // alert("time" + samplyyyy)
    this.appspinner.spinnerAlert('hide')

    //   const startTime = document.getElementById("startTime");
    // const valueSpan = document.getElementById("value");

    //alert(startTime)
    // startTime.addEventListener("input", () => {
    //   valueSpan.innerText = startTime.value;
    // }, false);
  }


  addingRoles()
  {
    ////debugger
      var data = {
        RoleName : this.roleName,
        Navigation : JSON.stringify(this.permissionArray)
      }
      // alert(this.permissionArray)
      if(this.permissionArray.length<=0)
      {
        environment.swalalert('error', "please Select Categorys");
      }
      // else if(this.roleName.length>21){
      //   environment.swalalert('error', "Role Name Lessthen 20");
      // }
      else{

      
      this.service.addroles(data).subscribe(res => {
        if (res['error']) {
          environment.swalalert('error', res['msg']);
          // environment.swalalert('error', "String Limit Should be 20 characters");
          this.appspinner.spinnerAlert('hide');
        // this.roleName=" "
        // this.permissionArray=[]
        } else {
        // if(res.body['error'] === false ) {
        //   this.getorgplanlist(1);
        //   this.addUserRole=false;
        //   this.listUserRole=true;
        //   this.addUserRole=false;
        if(res.body['error'] === false ) {
          ////debugger
          this.listUserRoleFlag();
          this.listUserRole=true;
          this.getRolesList();
          window.onload = function() {
            if(!window.location.hash) {
              // window.location = window.location + '#loaded';
              window.location.reload();
            }
          }
          
    //       this.getorgplanlist(1)
    //  this.listOrgplanFlag();
    //      this.addUserRole=false;
    //      this.listUserRole=false;
       
    //       this.getorgplanlist(1)
      
    //     this.listOrgplanFlag();
    //      this.addUserRole=false;
    //      this.listUserRole=false;
     
          environment.swalalert('success',res.body['msg']).then(value => {
            
            this.roleName=" "
            this.permissionArray=[  "    "]
           
            this.listUserRoleFlag();
            this.listUserRole=true;
            this.getRolesList();
            window.onload = function() {
              if(!window.location.hash) {
                // window.location = window.location + '#loaded';
                window.location.reload();
              }
            }

          


            // //window.location.reload();
            // this.listCharges = false;
            // this.listEmail=false;
            // this.Addplans = false;
            // this.editplans = false;
            // this.ListPlans = false;
            // this.listIncentive = false;
            // //Change this one
            // // this.getPeakZonelist(1);
            // // this.listPeakZone = true;
            // this.listPeakZone = false;
            // this.addPeakZone = false;
            // this.addUserRole=false;
            // this.editUserRole=false;
          
            if(value) {
        //       this.getorgplanlist(1)
        //  //this.listIncentive=true;
        // this.listOrgplanFlag();
        //  this.addUserRole=false;
        //  this.listUserRole=false;
              // this.service.getRolesListView().subscribe((res) => {
              //   console.log("data result",res.body['data'])
              //   if (res.body['error']) {
              //     environment.swalalert('nodata', res.body['msg']);
              //     this.appspinner.spinnerAlert('hide');
              //   } else {
              //   this.userRoleListModel = new orgPlanViewModel(res.body['error'], res.body['msg'], res.body['data']);
              //   console.log("result ",this.userRoleListModel['data'])
              //   if (res.body['data'].length > 0 ) {
              //   //  this.pages = this.adminviewmodel['data'][0].Count;
                
              //     this.resultUser = this.userRoleListModel['data'] ;
              //     console.log("klkllkkkkkkkk",this.resultUser)
                
              //     this.appspinner.spinnerAlert('hide');
              //   } else {
              //     environment.swalalert('nodata', 'No Data Available ');
              //     this.appspinner.spinnerAlert('hide');
              //   }
              // }
              // },
              //   (err) => {
              //     console.log(err);
              //     if (err['status'] === 401) {
              //       environment.swalalert('nodata', err['msg']);
              //      ///   localStorage.removeItem('Token');
              //       //  this.router.navigate(['login']);
              //     }
              //   });
              //window.location.reload();
          //    this.peakchargeedit.reset();
              // this.router.navigate(['/rolesview']);
              // this. getRolesList();
              // this.listUserRoleFlag();
             
              // this.listUserRoleFlag();
          
            }
            });
        } 
        else 
        {
        //   this.roleName=" "
        // this.permissionArray=[]
          environment.swalalert('error', "String Limit Should be less than or equal to 20 characters");
          // environment.swalalert('error',res.body['msg']);
        }
      }
      });

    }


  }

  addeditvaluetolocalstoreage(data)
  { 

    ////debugger
    this.editUserRoleFlag();
    console.log("in view localdata set ",JSON.stringify(data))
    localStorage.setItem("roleseditdata", JSON.stringify(data));
    this.getNavigationData();
  //   this. LocalStoreageData  = JSON.parse(localStorage.getItem('roleseditdata') )
  //   this.Navigation  = this.LocalStoreageData.Navigation
  //  console.log("local staage in eit",this.LocalStoreageData)
  //  this.permissionArray = this.Navigation.concat(this.permissionArray);

  }
  getNavigationData()
  {
    ////debugger
    this. LocalStoreageData1  = JSON.parse(localStorage.getItem('roleseditdata') )
    this.Navigation1  = this.LocalStoreageData1.Navigation
   console.log("local staage in eit",this.LocalStoreageData1)
   this.permissionArray1 = this.Navigation1;
  //  this.permissionArray1 = this.Navigation1.concat(this.permissionArray1);
   this.id= this.LocalStoreageData1.Id
    this.roleName1 = this.LocalStoreageData1.RoleName
    this.Navigation1 = this.LocalStoreageData1.Navigation
  //    this.Alloption1 = [{"name":"dashboard","displayName" : "Dashboard","status" : this.Navigation1.includes("dashboard")},
  // {"name":"users","displayName" : "Users","status" : this.Navigation1.includes("users")},                     
  // //  {"name":"servicecategory","displayName" : "Service Category","status" : this.Navigation1.includes("servicecategory")},
  // //  {"name":"servicecategorytaxi","displayName" : "Service Category - Taxi","status" : this.Navigation1.includes("servicecategorytaxi")},
  // //  {"name":"servicecategorydelivery","displayName" : "Service Category - Delivery","status" : this.Navigation1.includes("servicecategorydelivery")},
  // //  {"name":"servicecategoryservices","displayName" : "Service Category - Services","status" : this.Navigation1.includes("servicecategoryservices")},
  // //  {"name":"taxconfig","displayName" : "Tax Config","status" : this.Navigation1.includes("taxconfig")},
  //  {"name":"document","displayName" : "Document","status" : this.Navigation1.includes("document")},
  
  //  {"name":"taxivehiclebrand","displayName" : "Taxi - Vehicle Brand","status" : this.Navigation1.includes("taxivehiclebrand")},
  //  {"name":"taxivehiclemodel","displayName" : "Taxi - Vehicle Model","status" : this.Navigation1.includes("taxivehiclemodel")},
  //  {"name":"taxiservicetype","displayName" : "Taxi - Service Type","status" : this.Navigation1.includes("taxiservicetype")},
  //  {"name":"taxicancellationpolicy","displayName" : "Taxi - Cancellation Policy","status" : this.Navigation1.includes("taxicancellationpolicy")},
  //  {"name":"taxiproviders","displayName" : "Taxi - Providers","status" : this.Navigation1.includes("taxiproviders")},
  //  {"name":"taxiridenowbookings","displayName" : "Taxi - Ride Now Bookings","status" : this.Navigation1.includes("taxiridenowbookings")},
  //  {"name":"taxiridelaterbookings","displayName" : "Taxi - Ride Later Bookings","status" : this.Navigation1.includes("taxiridelaterbookings")},
  // //  {"name":"taxicouponcode","displayName" : "Taxi - CouponCode","status" : this.Navigation1.includes("taxicouponcode")},
  // //  {"name":"deliveryoutlets","displayName" : "Delivery - Outlets","status" : this.Navigation1.includes("deliveryoutlets")},
  // //  {"name":"addoutlet","displayName" : "Delivery -Add Outlet","status" : this.Navigation1.includes("addoutlet")},
  // //  {"name":"packingcharge","displayName" : "Delivery - Packing Charge","status" : this.Navigation1.includes("packingcharge")},
  // //  {"name":"deliveryproviders","displayName" : "Delivery - Providers","status" : this.Navigation1.includes("deliveryproviders")},
  // //  {"name":"deliverydeliverybookings","displayName" : "Delivery - Delivery Bookings","status" : this.Navigation1.includes("deliverydeliverybookings")},
  // //  {"name":"deliverycoupons","displayName" : "Delivery - Coupons","status" : this.Navigation1.includes("deliverycoupons")},
  // //  {"name":"deliveryadmincoupons","displayName" : "Delivery -Admin Coupons","status" : this.Navigation1.includes("deliveryadmincoupons")},
  // //  {"name":"deliveryoutletbanners","displayName" : "Delivery - Outlet Banners","status" : this.Navigation1.includes("deliveryoutletbanners")},
  // //  {"name":"percentagebanner","displayName" : "Delivery -Percentage Banners","status" : this.Navigation1.includes("percentagebanner")},
  // //  {"name":"servicesservicesubcategory","displayName" : "Services - Service Subcategory","status" : this.Navigation1.includes("servicesservicesubcategory")},
  //  {"name":"servicesservicelist","displayName" : "Services - Service List","status" : this.Navigation1.includes("servicesservicelist")},
  // //  {"name":"serviceproviders","displayName" : "Services - Providers","status" : this.Navigation1.includes("serviceproviders")},
  // //  {"name":"servicesservicebookings","displayName" : "Services - Service Bookings","status" : this.Navigation1.includes("servicesservicebookings")},
  // //  {"name":"payoutproviderpayout","displayName" : "payout - Provider Payout","status" : this.Navigation1.includes("payoutproviderpayout")},
  // //  {"name":"payoutoutletpayout","displayName" : "payout - Outlet Payout","status" : this.Navigation1.includes("payoutoutletpayout")},
  //  {"name":"godseye","displayName" : "Gods Eye","status" : this.Navigation1.includes("godseye")},

  // //  {"name":"deliveryboyzones","displayName" : "Delivery boy Zones","status" : this.Navigation1.includes("deliveryboyzones")},
  // //  {"name":"surgebayzones","displayName" : "Surge Bay Zones","status" : this.Navigation1.includes("surgebayzones")},
 
  //  {"name":"emailtemplate","displayName" : "Email Template","status" : this.Navigation1.includes("emailtemplate")},
  //  {"name":"appsettingsappconfig","displayName" : "App Settings - App Config","status" : this.Navigation1.includes("appsettingsappconfig")},
  //  {"name":"appsettingsappslider","displayName" : "App Settings - App Slider","status" : this.Navigation1.includes("appsettingsappslider")},
  //  {"name":"banneradvertisement","displayName" : "Banner Advertisement","status" : this.Navigation1.includes("banneradvertisement")},
  //  {"name":"staticpages","displayName" : "Static Pages","status" : this.Navigation1.includes("staticpages")},
  //  {"name":"defaultcurrency","displayName" : "Default Currency","status" : this.Navigation1.includes("defaultcurrency")},
  //  {"name":"notification","displayName" : "Notification","status" : this.Navigation1.includes("notification")},
  //  {"name":"admin","displayName" : "Admin","status" : this.Navigation1.includes("admin")},
  //  {"name":"rolses","displayName" : "Roles","status" : this.Navigation1.includes("rolses")} ,

  
   

  // ];

  this.Alloption1 = [{"name":"dashboard","displayName" : "Dashboard","status" : this.Navigation1.includes("dashboard")},
  {"name":"users","displayName" : "Users","status" : this.Navigation1.includes("users")},   
  
                    
  {"name":"providers","displayName" : "Providers","status" : this.Navigation1.includes("providers")},  
   {"name":"documents","displayName" : "Document","status" : this.Navigation1.includes("documents")},
  
   {"name":"vehicle","displayName" : "Vehicle","status" : this.Navigation1.includes("vehicle")},
   
   {"name":"servicetype","displayName" : "Service Type","status" : this.Navigation1.includes("servicetype")},
   {"name":"surgepayzones","displayName" : "Surge Pay Zones","status" : this.Navigation1.includes("surgepayzones")},
   {"name":"adminbooking","displayName" : "Admin Booking","status" : this.Navigation1.includes("adminbooking")},
   {"name":"manualbooking","displayName" : "Manual Booking","status" : this.Navigation1.includes("manualbooking")},
   {"name":"ridelaterbooking","displayName" : "Ride Later Booking","status" : this.Navigation1.includes("ridelaterbooking")},
   {"name":"bookings","displayName" : "Bookings","status" : this.Navigation1.includes("bookings")},
   {"name":"emailtemplate","displayName" : "Email Template","status" : this.Navigation1.includes("emailtemplate")},
   {"name":"cancelpolicy","displayName" : "Cancel Policy","status" : this.Navigation1.includes("cancelpolicy")},
   {"name":"pushnotification","displayName" : "Push Notification","status" : this.Navigation1.includes("pushnotification")},
   {"name":"promocode","displayName" : "Promo Code","status" : this.Navigation1.includes("promocode")},
  {"name":"satelite","displayName" : "Satelite","status" : this.Navigation1.includes("satelite")},
  {"name":"staticpages","displayName" : "Static Pages","status" : this.Navigation1.includes("staticpages")},
  {"name":"report","displayName" : "Report","status" : this.Navigation1.includes("report")},
  {"name":"orgusers","displayName" : "Org Users","status" : this.Navigation1.includes("orgusers")},
  {"name":"settings","displayName" : "Settings","status" : this.Navigation1.includes("settings")},
  {"name":"withdrawlrequest","displayName" : "Withdrawl Request","status" : this.Navigation1.includes("withdrawlrequest")},
  {"name":"localize","displayName" : "Localize","status" : this.Navigation1.includes("localize")},
  {"name":"reviewmanagement","displayName" : "Review Management","status" : this.Navigation1.includes("reviewmanagement")},
  {"name":"group","displayName" : "Group","status" : this.Navigation1.includes("group")},

  {"name":"supportticket","displayName" : "Support Ticket" ,"status": this.Navigation1.includes("supportticket")},
  {"name":"referral","displayName" : "Referral" ,"status": this.Navigation1.includes("referral")},
// {"name":"supportlist","displayName" : "Support List","status" : this.Navigation1.includes("supportlist")},
  
   

  ];



  }
  updateRoleData(name,event)
  {
    ////debugger
   
    console.log("name,event",name,event.target.checked)
       
    if (event.target.checked) {
			this.permissionArray.push(name)
		} else {
			const index = this.permissionArray.indexOf(name);
			if (index > -1) {
				this.permissionArray.splice(index, 1);
			}
		}
    console.log(this.permissionArray)
  }

  updateRoleData1(name,event)
  {
    ////debugger
   
    console.log("name,event",name,event.target.checked)
       
    if (event.target.checked) {
			this.permissionArray1.push(name)
		} else {
			const index = this.permissionArray1.indexOf(name);
			if (index > -1) {
				this.permissionArray1.splice(index, 1);
			}
		}
    console.log(this.permissionArray1)
  }
  updateeditedroles()
  {
    ////debugger
    var data = {
      id : this.id,
      RoleName : this.roleName1,
      Navigation : JSON.stringify(this.permissionArray1)
    }
    this.service.editroles(data).subscribe(res => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      if(res.body['error'] === false ) {
        this.listUserRoleFlag();
          this.listUserRole=true;
          this.getRolesList();
          window.onload = function() {
            if(!window.location.hash) {
              // window.location = window.location + '#loaded';
              window.location.reload();
            }
          }
        // this.getorgplanlist(1)
        // this.editUserRole=false;
        environment.swalalert('success',res.body['msg']).then(value => {
          this.editUserRole=false;
          // this.getRolesList()
          // this.listCharges = false;
          // this.listEmail=false;
          // this.Addplans = false;
          // this.editplans = false;
          // this.ListPlans = false;
          // this.listIncentive = false;
          // //Change this one
          // // this.getPeakZonelist(1);
          // // this.listPeakZone = true;
          // this.listPeakZone = false;
          // this.addPeakZone = false;
          // this.addUserRole=false;
          // this.editUserRole=false;
          // this.listUserRole=true;
          if(value) {
            this.editUserRole=false;
          // this.peakchargeedit.reset();
          //  this.router.navigate(['/rolesview']);
          //  this.getRolesList();
          //  this.listUserRole=true;
          //   this.listUserRoleFlag();
          
          }
          });
      } else {
        environment.swalalert('error',res.body['msg']);
      }
    }
    });
  }
  cancelupdateeditedroles()
  {
    this.listUserRoleFlag();
    this.listUserRole=true;
    this.getRolesList();

  }
  changeres(event){

  }
 
  //number 1 to 100
//   validateNumberRange(control) {
//     const value = control.value;
//     if (value !== null && (isNaN(value) || value < 0 || value > 100)) {
//       return { numberRange: true };
//     }
//     return null;
//   }
//   numericOnly(event) {
//     let patt = /^([0-9])$/;
//     let pat=/^[0-9]{10}$/;
//     let result = patt.test(event.key);
//     return result;
//   }
//   mobileCheckTimeInterval()
//   {
//     setTimeout(()=>{
//       this.mobilecheck=true;
//           // window.location.reload();
//         }, 5000);
//     this.mobilecheck = false
//     // clearTimeout(setTimeout());
    
//   }

  mobileCheck(event)

{
  // alert(event.key)
  
let patt = /^([0-9])$/;
let patt2=/^[0-9]{12}$/;
let pat=/^[0-9]{10}$/;
var patt3=/^\d{10}$/;
// let result = patt.test(event.key);
let result = patt.test(event.key);
let vvv=event.key;

if(result===true)
{
  
  this.commissionPercentageFlag=false;
  if(this.commissionpercentageNumber.length>3 || Number(this.commissionpercentageNumber)>100)
  {
   
     this.commissionPercentageFlag=true;
    
 }
//  else if(Number(this.commissionpercentageNumber)>100){
  
//   this.commissionPercentageFlag=true;
//  }
 else {
  
  this.commissionPercentageFlag=false;
 }
  }
  // alert()
return result;
var value1="0123456789"
const str="^4[0-9]{12}(?:[0-9]{3})?$}"
const str1="^[0-9]{10}$"
}
//..................................................




}


