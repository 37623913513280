export class CreategroupModel {
    public GroupName: String;
    public Users: any;
    
    constructor( GroupName: String,
                    Users: any,           
                               
                    
                ) {
                    this.GroupName = GroupName;
                   this.Users=Users;
                    
                    
    }
}