

export class OrgIncentiveModel {
    
    public EndTime:String; 
    public Value:String;
    public TaskName: String;
    public StartTime:String;
    public IncentiveType: String; 
    public Date:String;
    public TargetLimit:Number;
    public TargetType:String;
    public Description:String;
    public Id:number;
    
    // public startTime:String;
    // public endTime:String;
    // public dayRange: String;
    // public latitude:String;
    // public longitude:String;
    // public Id:number;
    constructor( 
        
        EndTime:String,
        Value:String,
        TaskName: String,
        StartTime:String,
        IncentiveType: String, 
        Date: String,
        TargetLimit : Number,
        TargetType : String,
        Description:String,
        Id:number,
        
        
        
                ) {
                   
                    this.EndTime=EndTime;
                    this.Value=Value;
                    this.TaskName=TaskName;
                    this.StartTime=StartTime;
                    this.IncentiveType=IncentiveType;
                    this.Date=Date;
                   this.TargetLimit=TargetLimit;
                   this.TargetType=TargetType;
                   this.Description=Description;
                   this.Id=Id;
                   
                   
                   
                  
                   
                    
    }
}