export class OrgChargesModel {
    // public U_Cancel_Charges_Within:Number;
    public U_Cancel_Charges_After:Number;
    // public U_Cancellation_Charges:Number;
    public U_Next_Ride_Charges:Number;
    public U_Cancellation_Charges_Pickup_Point:Number;
    // public U_Low_Traffic_Cost:Number;
    // public U_High_Traffic_Cost:Number;
    public U_Welcome_Template:String;
    public U_Invoice_Template:String;
    public P_Cash_Template:String;

constructor( 
    // U_Cancel_Charges_Within:Number,
    U_Cancel_Charges_After:Number,
    // U_Cancellation_Charges:Number,
    U_Next_Ride_Charges:Number,
    U_Cancellation_Charges_Pickup_Point:Number,
    // U_Low_Traffic_Cost:Number,
    // U_High_Traffic_Cost:Number,
    U_Welcome_Template:String,
    U_Invoice_Template:String,
    P_Cash_Template:String,


) {
                   
                //    this.U_Cancel_Charges_Within=U_Cancel_Charges_Within;
                    this.U_Cancel_Charges_After=U_Cancel_Charges_After;
                    // this.U_Cancellation_Charges=U_Cancellation_Charges;
                    this.U_Next_Ride_Charges=U_Next_Ride_Charges;
                    this.U_Cancellation_Charges_Pickup_Point=U_Cancellation_Charges_Pickup_Point;
                    // this.U_Low_Traffic_Cost=U_Low_Traffic_Cost;
                    // this.U_High_Traffic_Cost=U_High_Traffic_Cost;
                    this.U_Welcome_Template=U_Welcome_Template;
this.U_Invoice_Template=U_Invoice_Template;
this.P_Cash_Template=P_Cash_Template;
    }
}