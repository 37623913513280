import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HourlyPackageService } from './hourly-package-service/hourly-package.service';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { RidetypevehicleviewService } from '../ride-type-vehicle/ridetypevehicleview/ridetypevehicleview.service';
@Component({
  selector: 'app-rentail-package-list',
  templateUrl: './rentail-package-list.component.html',
  styleUrls: ['./rentail-package-list.component.css']
})
export class RentailPackageListComponent implements OnInit {

  packageList:any;
  packageState:any;
  isAdd = true;
  rental_id = '';
  servicetype:any;
  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  constructor(private packageService: HourlyPackageService,private fb: FormBuilder,private serviceTypeService: RidetypevehicleviewService) { }

  ngOnInit() {
    this.packageInit();
    this.HourlyPackageList();
    this.ServiceTypeList();
  }


  HourlyPackageList(){
    try {
      let params = {
        page : 1,
        limit : 10
      }
      this.packageService.PackageList(params).subscribe((res:any) => {
        if(res['body'].error == false){
          this.packageList = res['body'].data.rentalList;
        }
      })
    } catch (error) {
      console.log('Error',error)
    }
  }

  ServiceTypeList(){
    try {
      this.serviceTypeService.serviceTypeListView().subscribe((res:any) => {
        if(res['body'].error == false){
          this.servicetype = res['body'].data.data;
          console.log('=====>',this.servicetype)
        }
      })
    } catch (error) {
      
    }
  }
  packageInit(){
    this.packageState = this.fb.group({
      "name": [""],
      "hours": [""],
      "kilometers": [""],
      "price": [""],
      "description": [""],
      "seat_count": "4",
      "vechicle_type": [""],
      "icon_path": "https://betmobility.s3.ap-south-1.amazonaws.com/vendors/documents/f98da244-19ba-4275-b021-72963d3401c8-download.jpg%22",
      "isActive": ['']
    })
  }

  Createpackage(){
    try {
      this.packageState.removeControl("isActive");
      this.packageService.AddPackage(this.packageState.value).subscribe((res:any) => {        
        if(res['body'].error == false){
          this.HourlyPackageList();
          environment.swalalert('success','Package Details Updated');
          this.rental_id = ''
          this.packageInit();
          this.isAdd = true;
          this.closeEditModal();
        }else{
          
        }
      })
    } catch (error) {
      
    }
  }

  FindPackage(value){
    this.isAdd = false;
    this.rental_id = value.rental_id;
    try {
      this.packageService.ViewPackage(this.rental_id).subscribe((res:any) => {
        if(res['body'].error == false){
          let PackageDet = res['body'].data;
          this.packageState.patchValue({
            "name": PackageDet.name,
            "hours": PackageDet.hours,
            "kilometers": PackageDet.kilometers,
            "price": PackageDet.price,
            "description": PackageDet.description,
            "seat_count": PackageDet.seat_count,
            "vechicle_type": PackageDet.vechicle_type,
            "icon_path": PackageDet.icon_path,
            "isActive": PackageDet.isActive,
            
          })
        }
      })
    } catch (error) {
      
    }
  }

  UpdatePackage(){
    try {
      let params = {
        ...this.packageState.value,
        "rental_id": this.rental_id
      }
      this.packageService.EditPackage(params).subscribe((res:any) => {
        if(res['body'].error == false){
          environment.swalalert('success','Package Details Updated');
          this.rental_id = ''
          this.packageInit();
          this.isAdd = true;
          this.closeEditModal();
          this.HourlyPackageList();
        }else{

        }
      })
    } catch (error) {
      
    }
  }

  closeEditModal(){
    this.packageInit();
    this.isAdd = true;
    this.closeAddModal.nativeElement.click();
  }

  
}
