import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { CookieService } from 'ngx-cookie-service';
//import { PushNotificationViewList } from './pushnotificationview.model';


@Injectable({
  providedIn: 'root'
})
export class TripService {

  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  url = environment.Url;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,
    private cookie: CookieService) { }



generateReportView(data) {
  ////debugger
    return this.http.post(`${this.url}/getReportView`, data,  {
      headers: this.httpheaders,
      observe: 'response'
    });
  } 

  reportSearchView(data) {
    const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
////debugger
  return this.http.post(`${this.url}/reportSearchView`, data,  {
    headers: httpheaders,
    observe: 'response'
  });
} 
}