import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.css']
})
export class UserdetailsComponent implements OnInit {
  doctypeEditForm:any;
  constructor() { }

  ngOnInit() {
  }
  cancel()
  {

  }
  doctypeedit()
  {

  }

}
