import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { EncryService } from 'src/app/encry.service';
import { environment } from '../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { CurrencyConfigEditModel } from './currencyconfigedit.model';
import { AppslidereditService } from './currencyconfigedit.service';

@Component({
  selector: 'app-currencyconfigedit',
  templateUrl: './currencyconfigedit.component.html',
  styleUrls: ['./currencyconfigedit.component.css']
})
export class CurrencyconfigeditComponent implements OnInit {
  @ViewChild('img') img: ElementRef;
  paramsid: any;
  currencyConfigEditForm: any;
  editcurrencyconfig: CurrencyConfigEditModel;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private encry: EncryService,
    private service: AppslidereditService,
    private appspinner: AppComponent) {
    this.currencyConfigEditForm = this.formBuilder.group({
      'Country': ['', [Validators.required, Validators.minLength(1)]],
      'CurrencySymbol': ['', [Validators.required, Validators.minLength(1)]],

    });
  }

  ngOnInit() {
    this.appspinner.spinnerAlert('show');
    this.route.params.subscribe(params => {
      this.paramsid = params.id;
      this.service.getcurrencyConfig(this.paramsid).subscribe((res) => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          //var rs = res.body['data'];
          var rs = res.body['data'][0].data[0];
          this.editcurrencyconfig = new CurrencyConfigEditModel(
            rs.Id,
            rs.Country,
            rs.CurrencySymbol,
          );
          console.log(res);

          console.log(res.body['data'][0].data[0]);

          this.currencyConfigEditForm = this.formBuilder.group({
            'Country': [this.editcurrencyconfig.Country, [Validators.required, Validators.minLength(1)]],
            'CurrencySymbol': [this.editcurrencyconfig.CurrencySymbol, [Validators.required, Validators.minLength(1)]],

          });
          this.appspinner.spinnerAlert('hide');
        }
      })
    });
  }
  currencyconfigedit() {


    var Id = this.route.snapshot.params.id;

    this.editcurrencyconfig = new CurrencyConfigEditModel(
      Id,
      this.currencyConfigEditForm.value.Country,
      this.currencyConfigEditForm.value.CurrencySymbol
    );
    this.service.currencyConfigEdit(this.editcurrencyconfig).subscribe(res => {
      if (res.body['error'] === false) {
        environment.swalalert('success', res.body['msg']).then(value => {
          if (value) {
            this.currencyConfigEditForm.reset();
            this.router.navigate(['currencyConfigView']);
          }
        });
      } else {
        environment.swalalert('error', res.body['msg']);
      }
    });



  }


}


