export class APPConfigEmailData {
   public servername:String;
   public portnumber:Number;
 public   secureid:Number;
  public  username:String;
   public password:String;
    constructor( 
        servername:String,
    portnumber:Number,
      secureid:Number,
         username:String,
         password:String
                ) {
                  
                   this.servername=servername;
                   this.portnumber=portnumber;
                   this.secureid=secureid;
                   this.username=username;
                   this.password=password;
    }
}