import { Component, OnInit, ViewChild } from '@angular/core';
import * as $ from "../../../assets/js/jquery.3.2.1.min.js";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
// import { GrdFilterPipe } from 'src/app/grd-filter.pipe';
import * as FileSaver from 'file-saver';

import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { from } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ScrollEvent } from 'ngx-scroll-event';
import { TripModel } from './tripcancellation.model';
import { TripCancellationDataModel } from './tripcancellationdata.model.js';
import{TripService} from'./tripcancellation.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


declare var $;
@Component({
  selector: 'app-tripcancellation',
  templateUrl: './tripcancellation.component.html',
  styleUrls: ['./tripcancellation.component.css']
})
export class TripcancellationComponent implements OnInit {
    public searchText : string;
  @ViewChild('dataTable') table;
  tripCompleted :Boolean=true;
  dataTable: any;
  dtOptions: any;
  searchdata:any;
  reportType:any;
  typeview:TripModel;
  data:TripCancellationDataModel;
  result: any;
  page: number = 1;
  pages: any;
  typeview1:TripModel;
  noDataFlag:Boolean=false;
  constructor(  private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: TripService,
    private appspinner: AppComponent,
    private cookie: CookieService) { 


      
      var ReportType=localStorage.getItem('ReportType') 
      
     
      if(ReportType=="Generate trip cancelled reports")
      {
        this.reportType="Trip Cancelled Reports"
      }
      else
      {
        this.reportType="Trip Completed Reports"
        this.tripCompleted=false;
      }
      var sam2=Date.parse(localStorage.getItem('fromdate') )
      var sam3=localStorage.getItem('todate') 
     var RangeFrom= this.formatDate(sam2);
     var RangeTo= this.formatDate(sam3);
       this.data = new TripCancellationDataModel(
      ReportType,
      RangeFrom,
      RangeTo,
    
    );
    // alert(this.data.ReportType)
    console.log("data....",this.data)
    this. tripCancelReport(this.data);
    }

  ngOnInit() {
  //   var ReportType=localStorage.getItem('ReportType') 
  //   var sam2=Date.parse(localStorage.getItem('fromdate') )
  //   var sam3=localStorage.getItem('todate') 
  //  var RangeFrom= this.formatDate(sam2);
  //  var RangeTo= this.formatDate(sam3);
   

    // this.data = new TripCancellationDataModel(
    //   ReportType,
    //   RangeFrom,
    //   RangeTo,
    
    // );
  //   console.log("data....",this.data)
  // this. tripCancelReport(this.data);
    this.dtOptions = {
      // "ajax": {
      //   url: 'http://localhost/country/country.php',
      //   type: 'POST',
      //     data: {'id': 1, 'name': 'sathish'}
      // },
      // columns: [
      //     {
      //       title: 'Short Name',
      //         data: 'short_name'
      //     },
      //     {
      //         title: 'Long Name',
      //         data: 'long_name'
      //     },
      //     {
      //         title: 'ISO 3',
      //         data: 'iso3'
      //     },
      //     {
      //         title: 'ISO 2',
      //         data: 'iso2'
      //     }
      // ],
       dom: 'Bfrtip',
      // buttons: [
      //     'copy', 'csv', 'excel', 'pdf', 'print'
      // ]
  };


  this.dataTable = $(this.table.nativeElement);
  //this.dataTable.DataTable();
  this.dataTable.DataTable(this.dtOptions);
  //   this.dtOptions = {
  //      dom: 'Bfrtip',
  // };
  // this.dataTable = $(this.table.nativeElement);
  // this.dataTable.DataTable(this.dtOptions);

   
   

  //   this.dtOptions = {
    
      
      
  //      dom: 'Bfrtip',
     
  // };





  
  // this.dataTable = $(this.table.nativeElement);
  
  
  // this.dataTable.DataTable(this.dtOptions);
 

  }


  tripCancelReport(data) {
    ////debugger
    //this.commontypename = type;
    // this.cookie.set('emailtemplatepageslist', page);
    // localStorage.setItem('emailtemplatepageslist', page);
    this.service.generateReportView(data).subscribe((res: any) => {
      if (res['error']) {
        alert("true")
        
        // environment.swalalert('nodata', res['msg']);
      } else {

        this.typeview = new TripModel(res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.typeview['data'].length > 0) {
          console.log("trip.......",res.body['data'])
          console.log("tripvvvvvv.......",res)
          console.log("trip change....",this.typeview)
          // this.pages = this.typeview['data'][1].Count;
          // this.result = this.typeview['data'][0].data;
          this.result=res.body['data']
          console.log("trip change 3",this.result[0].Id)
          console.log("Report Generation",this.result)
       
        } else {
          environment.swalalert('nodata', 'No Data Available ');
            this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }





  //....................................................................



  exportAsXLSX2(): void {
    ////debugger
    
//  this.data3   =this.filt.transform
// this.data4=  this.filt.transform(this.result,this.searchText,true);
// this.data5=  this.filt.transform(this.result,this.searchText,false);
        if (this.result.length === 0) {
          // if (r.length === 0) {
          Swal.fire({
            title: 'Error',
            text: 'Orders Data has empty',
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.exportAsExcelFile(this.result, 'reports');
          // this.exportAsExcelFile(r, 'reports');
    
        }
      }
  exportAsXLSX(): void {
    ////debugger
        if (this.result.length === 0) {
          // if (r.length === 0) {
          Swal.fire({
            title: 'Error',
            text: 'Orders Data has empty',
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.exportAsExcelFile(this.result, 'reports');
          // this.exportAsExcelFile(r, 'reports');
    
        }
      }
      exportAsXLSX11(r): void {
        ////debugger
            // if (this.result.length === 0) {
              // for(var i=0;i<=r.length; i++)
              // {
            //   if (r.length === 0) {
            //   Swal.fire({
            //     title: 'Error',
            //     text: 'Orders Data has empty',
            //     type: 'error',
            //     confirmButtonText: 'Ok',
            //   });
            // } else {
            //   ////debugger
        //       const obj = {5.0: 10, 28.0: 14, 3.0: 6};
        
        // const mapped = Object.keys(obj).map(key => ({type: key, value: obj[key]}));
        
        console.log(r);
        // for(var i=0;i<=r.length; i++)
        //       {
        const mapped = Object.keys(r).map(key => ({type: key, value: r[key]}));
              // this.exportAsExcelFile(this.result, 'reports');
              // this.exportAsExcelFile(r, 'reports');
              this.exportAsExcelFile(mapped, 'reports');
             // this.exportAsExcelFile(r, 'reports');
        
            // }
          // }
              // }
          }
          // applyFilter1(event: Event) {
          //   ////debugger
          //   const filterValue = (event.target as HTMLInputElement).value;
          //   this.dataSource.filter = filterValue.trim().toLowerCase();
          //   console.log("fffff",this.dataSource)
          // }
          // applyFilter(event: Event) {
          //   ////debugger
          //   const filterValue = (event.target as HTMLInputElement).value;
          //   this.dataSource.filter = filterValue.trim().toLowerCase();
          //   console.log("fffff",this.dataSource)
          //   // if (this.dataSource.paginator) {
          //   //   this.dataSource.paginator.firstPage();
          //   // }
          // }
   //Export as Excel
   exportAsXLSX1(r): void {
////debugger
    // if (this.result.length === 0) {
      if (r.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'Orders Data has empty',
        type: 'error',
        confirmButtonText: 'Ok',
      });
    } else {
      ////debugger
//       const obj = {5.0: 10, 28.0: 14, 3.0: 6};

// const mapped = Object.keys(obj).map(key => ({type: key, value: obj[key]}));

// console.log(mapped);
const mapped = Object.keys(r).map(key => ({type: key, value: r[key]}));
      // this.exportAsExcelFile(this.result, 'reports');
      // this.exportAsExcelFile(r, 'reports');
      this.exportAsExcelFile(mapped, 'reports');
      

    }
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
////debugger
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  findname(search) {
  ////debugger
    console.log("findname",search)
    var searchData = search
    if(!searchData)
    {
this.tripCancelReport(this.data)
    }
   else if(searchData.split("/").length = 3){
      var splitteddata = searchData.split("/")

      var ReportType=localStorage.getItem('ReportType') 
      var sam2=Date.parse(localStorage.getItem('fromdate') )
      var sam3=localStorage.getItem('todate') 
     var RangeFrom= this.formatDate(sam2);
     var RangeTo= this.formatDate(sam3);
      // var date = splitteddata[2]+"%2F"+splitteddata[1]+"%2F"+splitteddata[0]
      // searchData = date
    }
    console.log("jjjjjjjj......",searchData);
    console.log("1111111111111111111.......")
    // var data = {search: search, typename: 'bookings', page: this.page ,status:'later'}
    var data = {ReportType:ReportType , RangeFrom: RangeFrom, RangeTo: RangeTo ,search:search}
    // this.service.bookingsearchdataView(data).subscribe((res:any) => {
      this.service.reportSearchView(data).subscribe((res:any) => {  
        console.log("2222222222222222222222222.......")
        // alert("2222")
      console.log("ride result",res)
      // alert("2222")
      if (res.body['error']) {
        this.noDataFlag=true;
        this.pages = 0
        this.result=[]
        // environment.swalalert('nodata', res['msg']);
        // this.appspinner.spinnerAlert('hide');
        // console.log("error")
        // alert("3333")
      } 
      else {

        this.noDataFlag=false
        // alert("4444444444")

        this.typeview1 = new TripModel(res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.typeview1['data'].length > 0) {
          console.log("trip.......",res.body['data'])
          console.log("tripvvvvvv.......",res)
          console.log("trip change....",this.typeview)
          // this.pages = this.typeview['data'][1].Count;
          // this.result = this.typeview['data'][0].data;
          this.result=res.body['data']
// this.dataSource=res.body['data']
          console.log("trip change 3",this.result[0].Id)
          console.log("Report Generation",this.result)
        console.log("success")
        // this.result = new TripModel(res['error'], res['msg'], res['data'])
       
        // // console.log(this.result['data'][0].data);
        // if (res['data'][0].data.length > 0 && this.result['data'][0].data.length > 0) {
        //   console.log(res);
          
        //   this.pages = this.result['data'][0].Count;
        //    this.result= this.result['data'][0].data;
        //   console.log("test data from ride later",this.result)
        //   this.appspinner.spinnerAlert('hide');
        //   console.log(this.result);

        // } else {
          // environment.swalalert('nodata', 'No Data Available ');
          // this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }
}
