import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class OrgPlanService {
  url = environment.Url;
  iconimgurl = environment.IconUrl;
  constructor(private http: HttpClient,
    private cookie: CookieService) { }

 



  orgPlanAdd(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });

////debugger
    return this.http.post(`${this.url}/orgPlanAdd`,data,  {
      headers: httpheaders,
      observe: 'response'
    });
  }



  peakZoneAdd(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });

////debugger
    return this.http.post(`${this.url}/peakZoneAdd `,data,  {
      headers: httpheaders,
      observe: 'response'
    });
  }


  incentiveAdd(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });

////debugger
    return this.http.post(`${this.url}/incentivesAdd`,data,  {
      headers: httpheaders,
      observe: 'response'
    });
  }

  // ------------------Incentive List --------------------------------
  incentiveView(page) {
    ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/incentivesListView/${page}`,  {
      headers: httpheaders,
      observe: 'response'
    });
  } 



// ------------------Incentive Edit--------------------------------
IncentiveEdit(data) {
  ////debugger
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  return this.http.post(`${this.url}/incentivesEdit`, data, {
    headers: httpheaders,
    observe: 'response'
  });
}

// ------------------Incentive View--------------------------------
IncentiveViewList(id) {
  ////debugger
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  return this.http.get(`${this.url}/incentivesView/${id}`, {
    headers: httpheaders,
    observe: 'response'
  });
}
  
  orgPlanList(page) {
    ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/orgPlanListView/${page}`,  {
      headers: httpheaders,
      observe: 'response'
    });
  } 

  orgPlanStatusUpdate(data){
    ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/orgPlanStatusUpdate`,data,{
      headers:httpheaders,
      observe: 'response'
    });
  } 
  emailTemplateProviders(page,type){
    ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/emailTemplateListView`,{type:type},  {
    // return this.http.post(`${this.url}/emailTemplatePagesListView`,data,{
      headers:httpheaders,
      observe: 'response'
    });
  }

  peakZoneList(page) {
    ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/peakZoneListView/${page}`,  {
      headers: httpheaders,
      observe: 'response'
    });
  } 
   // peakzone edit
   peakzoneEdit(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/peakZoneEdit`, data, {
      headers: httpheaders,
      observe: 'response'
    });
  }

  // get peakzone edit View
  getPeakZoneView(id) {
    ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/peakZoneView/${id}`, {
      headers: httpheaders,
      observe: 'response'
    });
  }



    // OrgPlan edit
    OrgPlanEdit(data) {
      const httpheaders = new HttpHeaders({
        'Content-Type': [],
        'Accept': 'application/json',
        // 'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token')
      });
      return this.http.post(`${this.url}/orgPlanEdit`, data, {
        headers: httpheaders,
        observe: 'response'
      });
    }
   
    // get orgplan edit View
    getOrgPlanView(id) {
      const httpheaders = new HttpHeaders({
        'Content-Type': [],
        'Accept': 'application/json',
        // 'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token')
      });
      return this.http.get(`${this.url}/orgPlanView/${id}`, {
        headers: httpheaders,
        observe: 'response'
      });
    }
//chargeslist
    chargesList() {
      ////debugger
      const httpheaders = new HttpHeaders({
        'Content-Type': [],
        'Accept': 'application/json',
        // 'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token')
      });
      return this.http.get(`${this.url}/appConfigListView`,  {
        headers: httpheaders,
        observe: 'response'
      });
    } 
//chargesedit
chargesEdit(data) {
  ////debugger
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  return this.http.post(`${this.url}/appConfigEdit`, data, {
    headers: httpheaders,
    observe: 'response'
  });
}

//Get type  user ..Email Template
emailtypeListView(type,page) {
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  return this.http.post(`${this.url}/emailTemplateListView`,{type:type},  {
    headers:httpheaders,
    observe: 'response'
  });
}


emailTemplateAdd(data) {
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });

////debugger
  return this.http.post(`${this.url}/incentivesAdd`,data,  {
    headers: httpheaders,
    observe: 'response'
  });
}

 //List the admin 
 getRolesListView(){
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
   //  'Token': this.cookie.get('Token')
   'Token': localStorage.getItem('Token')
  });
  return this.http.get(`${this.url}/viewRole`,{
    headers: httpheaders,
    observe: 'response'
  });

  // http://3.110.252.18:3000/api/admin/getRolesListView/1 


}
 //Viewroles based on id 
 getRolesViewId(id){
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
   //  'Token': this.cookie.get('Token')
   'Token': localStorage.getItem('Token')
  });
  return this.http.get(`${this.url}/getRolesListView/${id}`,{
    headers: httpheaders,
    observe: 'response'
  });

  // http://3.110.252.18:3000/api/admin/getRolesListView/1 


}


 //add roles by NK
 editroles(data){
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
   //  'Token': this.cookie.get('Token')
   'Token': localStorage.getItem('Token')
  });
  return this.http.post(`${this.url}/editRole`,data,{
    headers: httpheaders,
    observe: 'response'
  });
}


//add roles by NK
addroles(data){
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
   //  'Token': this.cookie.get('Token')
   'Token': localStorage.getItem('Token')
  });
  return this.http.post(`${this.url}/addingRole`,data,{
    headers: httpheaders,
    observe: 'response'
  });
}


}


