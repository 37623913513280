import { Component, OnInit, HostListener, DoCheck, OnChanges, AfterViewInit, ViewChild, Input } from '@angular/core';
import * as $ from "../../../assets/js/jquery.3.2.1.min.js";
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { from } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ScrollEvent } from 'ngx-scroll-event';
import { addMapZoneService } from './addmapzone.service.js';
import { MapViewModel } from './addmapzone.model';
import { AgmMap, AgmPolygon, PolyMouseEvent ,MouseEvent} from '@agm/core';
import { AgmSnazzyInfoWindow } from "@agm/snazzy-info-window";
import { FormGroup, FormControl } from '@angular/forms';
declare var google: any;
declare let $:any;
@Component({
  selector: 'app-addmapzone',
  templateUrl: './addmapzone.component.html',
  styleUrls: ['./addmapzone.component.css']
})
export class AddmapzoneComponent implements OnInit{
  noDataFlag:Boolean=false;
  today: Date = new Date();
   map: any;
  drawingManager: any;
  lat: number = 13.0827;
  lng: number = 80.2707;
  lat1: number = 11.7429;
  lng1: number = 78.0473;
  ZoneName:any;
  Searchkey:any;
  ZoneForm:FormGroup;
  center:any;
  zoneid:any;
  editZoneName:String;
editDistance:Number;
  editPrice:Number;
  editfromTime:any;
edittoTime:any;
EditFrom:any;
date1:any;
// date1: any = new Date();
  editLat: Number = 13.0827;
  editLng: Number = 80.2707;
  editCenter:Number=8;
viewFlag:Boolean=false;
mapView: MapViewModel;
mapDistance:String;
mapPrice:String;
mapId:String;
mapLat:any;
maplong:String;
mapcenter:any;
mapZoneName:String;
zoneList: any= [

  
//   {'zonename':'A','zone': [  
//    {lat: this.lat, lng: this.lng},
//   {lat: this.lat, lng: this.lng+0.1},
//   {lat: this.lat+0.1, lng: this.lng+0.1},
//  {lat: this.lat+0.1, lng: this.lng},
// ]
// },
// {'zonename':'B','zone': [  
//   {lat: this.lat1, lng: this.lng1},
//  {lat: this.lat1, lng: this.lng1+0.1},
//  {lat: this.lat1+0.1, lng: this.lng1+0.1},
// {lat: this.lat1+0.1, lng: this.lng1},
// ]
// },{
//   'zonename':'C','zone':[

   
//     {lat:11.86546197291289,lng:78.25107822036743},
//     {lat:11.80094484927078,lng:78.34446200942993},
//     {lat:11.791534831757506,lng:78.28815707778931},
    
//   ]
// }
];
isdrawn:boolean=false;
isEdit:boolean=false;
markers:any;
zoom:number=10;
drawnlatlong:any=[];
ismanualzoneclicked:boolean=false;
manualzonename:any;
manualzonedistance:any;
manualzonelatlonarray=[];
distance:any;
fromTime:any;
toTime:any;
price:any;
// date:any;
minDate: string;
date: any = new Date();
  constructor(    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: addMapZoneService,
    private appspinner: AppComponent,
    private cookie: CookieService) {
      const currentDate = new Date().toISOString().split('T')[0];
    this.minDate = currentDate;
     }

  ngOnInit() {
  //   window.location.reload();
  //  window.location.reload();
  //   window.location.reload();
    this.EditFrom = this.formBuilder.group({
      'editZoneName': ['', [Validators.required, Validators.minLength(1)]],
      'editDistance': ['', [Validators.required, Validators.minLength(1)]],
      'editPrice': ['', [Validators.required, Validators.minLength(1)]],
      'date1': ['', [Validators.required, Validators.minLength(1)]],
      
      'editfromTime': ['', [Validators.required, Validators.minLength(1)]],
      'edittoTime': ['', [Validators.required, Validators.minLength(1)]],
      
   




    });
    
    this.ZoneForm = new FormGroup({
      latlon: this.formBuilder.array([this.createLatlonFormGroup()])
      
    });
    
    // const data={ZoneType:'surge zone'}
// this.listZone(data)
// this.listZone(data)
// this.listZone(data)
    
  }
  listZone(data)
  {
    this.service.listOfZones(data).subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('', res.body['msg']);
      } else {    
        console.log(res.body['data'].zoneList);
          
     this.zoneList=res.body['data'].zoneList
     
     
    this.zoneList.map(res=>{
      console.log(res.Latlongs);


      
     if(res.Latlongs!=null){

  

      var bound = new google.maps.LatLngBounds();

      for (let i = 0; i < res.Latlongs.length; i++) {
        bound.extend( new google.maps.LatLng(res.Latlongs[i].lat, res.Latlongs[i].lng) );
      
        // OTHER CODE
      }
      this.center={lat: bound.getCenter().lat().toFixed(4),lng:bound.getCenter().lng().toFixed(4)}
      console.log(this.center);
      
 res.center={lat: bound.getCenter().lat().toFixed(4),lng:bound.getCenter().lng().toFixed(4)}
      res.Latlongs.map(res=>{
console.log(parseFloat(res.lat));
res.lat=parseFloat(res.lat);
res.lng=parseFloat(res.lng);
      })
    }
     // return res.Latlongs
    })
     //  environment.swalalert('success', res.body['msg']);
     console.log(this.zoneList);  
      }
    },
      (err) => {
        console.log(err);
      });
      // window.location.reload();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  
   
   createLatlonFormGroup(): FormGroup {
    return new FormGroup({
      
      lat: new FormControl(''),
      lng: new FormControl(''),
    })
  }
  public addlatlonFormGroup() {
    const latlon = this.ZoneForm.get('latlon') as FormArray
    latlon.push(this.createLatlonFormGroup())
    console.log(latlon);
    
  }

  public removeOrClearLatlon(i: number) {
    const latlon = this.ZoneForm.get('latlon') as FormArray
    if (latlon.length > 1) {
      latlon.removeAt(i)
    } else {
      latlon.reset()
    }
  }
  mapClicked($event: MouseEvent) {
    console.log($event);
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
    });
  }
  onPolyClick(index, polygon: AgmPolygon, infoWindow: AgmSnazzyInfoWindow) {
   // console.log(polygon,$event);
  // var mapinfowindow = new google.maps.InfoWindow();
  //  google.maps.infoWindow.isOpen = Object.assign({}, google.maps.infoWindow.isOpen);
    //google.maps.selectedPoint = getPolygonCenter(polygon.points);
    
  }

  onMapReady(map) {
    console.log(map);
    const data={ZoneType:'surge zone'}
    this.listZone(data)
    this.initDrawingManager(map);
    
    
  }

  cancel(){
    this.isdrawn=false;
    this.zoneid=undefined;

  }
  cancelEdit(){
    this.isEdit=false;
    this.zoneid=undefined;

  }
//   editzone(data){
// console.log(data);
// this.zoneid=data.Id;
// this.isdrawn=true;
// this.ZoneName=data.ZoneName;
// this.distance=data.Distance;
// this.drawnlatlong=data.Latlongs;
// this.center=data.Center;

//   }
  listZones(){
    const data={ZoneType:'surge zone'}

    this.service.listOfZones(data).subscribe((res) => {

      if (res.body['error']) {
        this.noDataFlag=true;
        environment.swalalert('', res.body['msg']);
      } else {   
        this.noDataFlag=false; 
        console.log(res.body['data'].zoneList);
          
     this.zoneList=res.body['data'].zoneList
     
     
    this.zoneList.map(res=>{
      console.log(res.Latlongs);

    //  var bound = new google.maps.LatLngBounds();

    //  for (let i = 0; i < this.drawnlatlong.length; i++) {
    //    bound.extend( new google.maps.LatLng(this.drawnlatlong[i].lat, this.drawnlatlong[i].lng) );
     
    //    // OTHER CODE
    //  }
    //  this.center={lat: bound.getCenter().lat().toFixed(4),lng:bound.getCenter().lng().toFixed(4)}


     if(res.Latlongs!=null){




      var bound = new google.maps.LatLngBounds();

      for (let i = 0; i < res.Latlongs.length; i++) {
        bound.extend( new google.maps.LatLng(res.Latlongs[i].lat, res.Latlongs[i].lng) );
      
        // OTHER CODE
      }
      this.center={lat: bound.getCenter().lat().toFixed(4),lng:bound.getCenter().lng().toFixed(4)}
      console.log(this.center);
      
 res.center={lat: bound.getCenter().lat().toFixed(4),lng:bound.getCenter().lng().toFixed(4)}
      res.Latlongs.map(res=>{
console.log(parseFloat(res.lat));
res.lat=parseFloat(res.lat);
res.lng=parseFloat(res.lng);
      })
    }
     // return res.Latlongs
    })
     //  environment.swalalert('success', res.body['msg']);
     console.log(this.zoneList);  
      }
    },
      (err) => {
        console.log(err);
      });
  }

  mapFlag()
  {
   this.viewFlag=false
  }
 payDetails(id)
 {
  this.viewFlag=true
  
  this.service.getPayZoneDetails(id).subscribe((res) => {
    
    if (res['error']) {
      environment.swalalert('nodata', res['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
      this.mapView = new MapViewModel(res['error'], res['msg'], res['body']['data']);
      if (Object.keys(this.mapView.data).length > 0) {
       
       console.log(this.mapView['data'])
     this.mapDistance= this.mapView['data'][0].Distance
   this.mapPrice= this.mapView['data'][0].price
   this.mapZoneName= this.mapView['data'][0].ZoneName
   this.editfromTime=this.mapView['data'][0].fromTime
  this.edittoTime=this.mapView['data'][0].toTime
  // alert("normal"+this.mapView['data'][0].date)
 var aa= this.formatDate(this.mapView['data'][0].date)
//  alert("dddd"+this.mapView['data'][0].date)
// this.date1=aa
   this.date1=this.mapView['data'][0].date
   this.mapId= this.mapView['data'][0].Id
  this.mapcenter=this.mapView['data'][0].Center
this.mapLat=this.mapView['data'][0].Latlongs
  //  console.log("jjjttttttt",this.mapcenter)
       
      } else {
        environment.swalalert('nodata', 'No Data Available ');
      }
      this.appspinner.spinnerAlert('hide');
    }
  })
 }
 
  deletezone(data){

  const apidata={zoneId:data}

    console.log('deleted');
    environment.swalalert('delete', 'Zone has been deleted successfully').then(value => {
      if (value) {
    this.service.deleteZone(apidata).subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('', res.body['msg']);
      } else {      
        this.viewFlag=false
        this.listZones()
      //  environment.swalalert('success', res.body['msg']);
        
      }
    },
      (err) => {
        console.log(err);
      });
    }
    else
    {

    }
    });
    
  }
  manualsavezone(){

console.log(this.ZoneForm.value.latlon);

    
    const apidata={ZoneName:this.manualzonename,distance:this.manualzonedistance,Latlongs:this.ZoneForm.value.latlon}

this.service.addZone(apidata).subscribe((res) => {

  if (res.body['error']) {
    environment.swalalert('', res.body['msg']);
  } else {      
    this.ismanualzoneclicked=false;
    this.manualzonename='';
    this.manualzonedistance=''
    this.ZoneForm.reset()
    this.listZones()
   environment.swalalert('success', res.body['msg']);
    
  }
},
  (err) => {
    console.log(err);
  });
  }
  addmanualzone(){
    this.ismanualzoneclicked=true;
  }



  closeAddZone()
  {
    this.isdrawn=false
  }
  closeEditZone()
  {
    this.isEdit=false
  }
  savezone(){
    console.log("date",this.date)
    console.log(this.ZoneName)
    console.log("fromtime",this.fromTime)
    if(this.date){
      
   var  formatteddate=`${this.date.day}/${this.date.month}/${this.date.year}`
   var  formatteddate=this.formatDate(this.date) 
   console.log("formatdate",formatteddate)
  } 
   const apidata={ZoneName:this.ZoneName,center:this.center,distance:this.distance,Latlongs:this.drawnlatlong,date:formatteddate,fromTime:this.fromTime,toTime:this.toTime,price:this.price, ZoneType:'surge zone'  }
  // const apidata={ZoneName:this.ZoneName,center:this.center,distance:this.distance,Latlongs:this.drawnlatlong,price:this.price, ZoneType:'surge zone'  }  
  console.log(apidata)

// alert(formatteddate)
    
    if(this.ZoneName && this.center && this.distance && this.price  ){
  // console.log('yes');
  
}else{
  // console.log('no');
  environment.swalalert('', 'All Fields Required');
  return
}


//console.log(apidata);
//this.service.addZone(apidata)


 
this.service.addZone(apidata).subscribe((res) => {

  if (res.body['error']) {
    environment.swalalert('', res.body['msg']);
  } else {      
    $(function () {
      $('#basicExampleModal').modal('toggle');
   });
    this.isdrawn=false;
    this.ZoneName=''
    this.distance=undefined;
this.drawnlatlong=undefined;
this.center=undefined;
this.fromTime=undefined;
this.toTime=undefined;
this.price=undefined;
this.date=undefined;
    this.listZones()
   environment.swalalert('success', res.body['msg']);
    
  }
},
  (err) => {
    console.log(err);
  });

  }
  editZoneFlag(Id)
  {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
this.payDetails(Id)
this.viewFlag=false
this.service.getPeakZone(Id).subscribe((res) => {
  if (res['error']) {
    environment.swalalert('nodata', res['msg']);
    this.appspinner.spinnerAlert('hide');
  } else {
  var rs = res.body['data'];
  this.editDistance=Number(this.mapDistance);
  this.editPrice=Number(this.mapPrice);
  this.editZoneName=this.mapZoneName;
  this.editfromTime=this.editfromTime
  this.edittoTime=this.edittoTime
  // this.date1=this.date1
  this.EditFrom = this.formBuilder.group({
    editDistance: [
      this.editDistance,
      [Validators.required, Validators.minLength(1)],
    ],

    editPrice: [
      this.editPrice,
      [Validators.required, Validators.minLength(1)],
    ],

    editZoneName: [
      this.editZoneName,
      [Validators.required, Validators.minLength(1)],
    ],
    
    date1: [

      this.date1,
      [Validators.required, Validators.minLength(1)],
    ],
    // IncentiveType: [
    //   this.IncentiveEditModel.IncentiveType,
    //   [Validators.required, Validators.minLength(1)],
    // ],
    editfromTime: [
      this.editfromTime,
     // this.datepipe.transform(
      //this.IncentiveEditModel,
        //"dd-MM-yyyy"
     //),
      [Validators.required, Validators.minLength(1)],
    ],
    edittoTime: [
      this.edittoTime,
    
     [Validators.required, Validators.minLength(1)],
    ],
   

   
    
    
  });
  // alert(res.body['data'])
// this.editcancel = new CancellationPolicyEditModel(
//     rs.Id,
//     rs.UserType,
//     rs.Description
//   );
//   this.cancelEditForm = this.formBuilder.group({
//     'UserType' : [this.editcancel.UserType,[Validators.required,Validators.minLength(1)]],
//     'Description' : [this.editcancel.Description,[Validators.required,Validators.minLength(1)]],
//   });
this.appspinner.spinnerAlert('hide');
}
})

if(this.isdrawn==false)
{
this.isEdit=true;
}
else
{
  this.isEdit=false
}
// this.isEdit=true;

  }
  editzone(){
    
    var sf=this.EditFrom.value
    console.log(sf)
    if(this.date){
   //var  formatteddate=`${this.date.day}/${this.date.month}/${this.date.year}`
   var  formatteddate=this.formatDate(sf.date1) 
  } 
    //const apidata={ZoneName:this.ZoneName,center:this.center,distance:this.distance,Latlongs:this.drawnlatlong,date:formatteddate,fromTime:this.fromTime,toTime:this.toTime,price:this.price, ZoneType:'surge zone'  }
  const apidata={Id:this.mapId,ZoneName:this.editZoneName,Latlongs:this.mapLat,distance:this.editDistance,center:this.mapcenter,price:this.editPrice ,date:formatteddate,fromTime:sf.editfromTime,toTime:sf.edittoTime}  
  console.log("",apidata)
  // http://3.110.252.18:3000/api/chat/recentSupportTicketListView
  // post method
  // param:-{Id,ZoneName,Latlongs,distance,center,price}
// alert(formatteddate)
    
//     if(this.editZoneName && this.editDistance && this.editPrice){
//   // console.log('yes');
  
// }else{
//   // console.log('no');
//   environment.swalalert('', 'All Fields Required');
//   return
// }


//console.log(apidata);
//this.service.addZone(apidata)


 
this.service.peakZoneEdit(apidata).subscribe((res) => {

  if (res.body['error']) {
    environment.swalalert('', res.body['msg']);
  } else {      
    $(function () {
      $('#basicExampleModal').modal('toggle');
   });
    this.isEdit=false;
    this.editCenter=0
    this.editDistance=0
    this.editPrice=0
    this.editLat=0
    this.editLng=0
    this.editZoneName=""
    this.listZones()
   environment.swalalert('success', res.body['msg']);
   window.location.reload();
   window.location.reload();
    window.location.reload();

  }
},
  (err) => {
    console.log(err);
  });

  }
  initDrawingManager(map: any) {
    console.log('drawing manager',map);
    
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    
    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
    //var infowindow = new google.maps.InfoWindow();
   // this.zoneList.data.forEach((poly, i) => {
      // google.maps.event.addListener(google.maps.drawing.OverlayType.POLYGON, 'click', function (event) {
      //   infowindow.setContent('Ajdkshgggggggg');
      //   infowindow.setPosition(event.latLng);
      //   infowindow.open(map);
      // });
    //})
    var infowindow = new google.maps.InfoWindow();

    
    infowindow.setContent('Ajdkshgggggggg');

    
    
    console.log(drawingManager);
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      // Polygon drawn
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        //this is the coordinate, you can assign it to a variable or pass into another function.
      //  alert(event.overlay.getPath().getArray());
        this.drawnlatlong=event.overlay.getPath().getArray().map(coord => {
          return {
            lat: coord.lat().toFixed(4),
            lng: coord.lng().toFixed(4)
          }
        });;
        if(this.isEdit==false)
        {
        this.isdrawn=true;
        }
        else
        {
          this.isdrawn=false
        }
        $(document).ready(function(){
          $("#basicExampleModal").modal();
          });
        // this.ZoneName='';
        // this.distance='';
        console.log(this.drawnlatlong);
        var bound = new google.maps.LatLngBounds();

for (let i = 0; i < this.drawnlatlong.length; i++) {
  bound.extend( new google.maps.LatLng(this.drawnlatlong[i].lat, this.drawnlatlong[i].lng) );

  // OTHER CODE
}
this.center={lat: bound.getCenter().lat().toFixed(4),lng:bound.getCenter().lng().toFixed(4)}
console.log( bound.getCenter().lat().toFixed(4) ,bound.getCenter().lng().toFixed(4));
        
      }
    });
  }
}
