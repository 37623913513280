import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment';
import { AdminModelList } from './admin-list.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class adminviewservies {
  url = environment.Url;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
}
  constructor(private http:HttpClient) { }

  //viewing admin view by NK
  getAdminListView(page,status){
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
     //  'Token': this.cookie.get('Token')
     'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/orgUserListView/${page}/${status}`,{
     
      headers: httpheaders,
      observe: 'response'
    });
  }
  searchdataView(name): Observable<AdminModelList[]> {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
     //  'Token': this.cookie.get('Token')
     'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
      headers:httpheaders
    })
      .map((res: Response) => {
        return <AdminModelList[]><unknown>res;
      })
      .catch(this.handleError);
  } 
  
  usersListView1(page,status) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
     //  'Token': this.cookie.get('Token')
     'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/userListView/${page}/${status}`,  {
      headers: httpheaders,
      observe: 'response'
    });
  } 
//delete admin by NK
deleteAdmin(data){
  ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
     //  'Token': this.cookie.get('Token')
     'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/deleteAdmin`,data,{
      headers: httpheaders,
      observe: 'response'
    });
  }

  adminStatusUpdate(data){
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
     //  'Token': this.cookie.get('Token')
     'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/orgUserStatusUpdate`,data,{
      headers: httpheaders,
      observe: 'response'
    });
  } 
  userFilterListView(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
     //  'Token': this.cookie.get('Token')
     'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/orgUserFilterListView`,data,{
      headers: httpheaders,
      observe: 'response'
    });
 }
}