import { Component, OnInit, HostListener, DoCheck, OnChanges, AfterViewInit, ViewChild, Input } from '@angular/core';
import * as $ from "../../../assets/js/jquery.3.2.1.min.js";
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { from } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ScrollEvent } from 'ngx-scroll-event';
import { deliveryBoyZonesService } from './deliveryboyzones.service';
import { AgmMap, AgmPolygon, PolyMouseEvent, MouseEvent } from '@agm/core';
import { AgmSnazzyInfoWindow } from "@agm/snazzy-info-window";
import { FormGroup, FormControl } from '@angular/forms';
declare var google: any;
@Component({
  selector: 'app-deliveryboyzones',
  templateUrl: './deliveryboyzones.component.html',
  styleUrls: ['./deliveryboyzones.component.css']
})
export class DeliveryboyzonesComponent implements OnInit {


  map: any;
  drawingManager: any;
  lat: number = 13.0827;
  lng: number = 80.2707;
  lat1: number = 11.7429;
  lng1: number = 78.0473;
  ZoneName: any;
  Searchkey: any;
  ZoneForm: FormGroup;
  center: any;
  zoneList: any = [
    //   {'zonename':'A','zone': [  
    //    {lat: this.lat, lng: this.lng},
    //   {lat: this.lat, lng: this.lng+0.1},
    //   {lat: this.lat+0.1, lng: this.lng+0.1},
    //  {lat: this.lat+0.1, lng: this.lng},
    // ]
    // },
    // {'zonename':'B','zone': [  
    //   {lat: this.lat1, lng: this.lng1},
    //  {lat: this.lat1, lng: this.lng1+0.1},
    //  {lat: this.lat1+0.1, lng: this.lng1+0.1},
    // {lat: this.lat1+0.1, lng: this.lng1},
    // ]
    // },{
    //   'zonename':'C','zone':[


    //     {lat:11.86546197291289,lng:78.25107822036743},
    //     {lat:11.80094484927078,lng:78.34446200942993},
    //     {lat:11.791534831757506,lng:78.28815707778931},

    //   ]
    // }
  ];
  isdrawn: boolean = false;
  markers: any;
  zoom: number = 10;
  drawnlatlong: any = [];
  ismanualzoneclicked: boolean = false;
  manualzonename: any;
  manualzonedistance: any;
  manualzonelatlonarray = [];
  distance: any;
  zoneStatus: any;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: deliveryBoyZonesService,
    private appspinner: AppComponent,
    private cookie: CookieService) { }

  ngOnInit() {
    const data = { ZoneType: 'provider zone' }

    this.ZoneForm = new FormGroup({
      latlon: this.formBuilder.array([this.createLatlonFormGroup()])

    });
    this.service.listOfZones(data).subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('', res.body['msg']);
      } else {
        console.log(res.body['data'].zoneList);

        this.zoneList = res.body['data'].zoneList
        if (res.body['data'].zoneStatus == 'enable') {
          this.zoneStatus = true;
        } else {
          this.zoneStatus = false;
        }

        this.zoneList.map(res => {
          console.log(res.Latlongs);



          if (res.Latlongs != null) {



            var bound = new google.maps.LatLngBounds();

            for (let i = 0; i < res.Latlongs.length; i++) {
              bound.extend(new google.maps.LatLng(res.Latlongs[i].lat, res.Latlongs[i].lng));

              // OTHER CODE
            }
            this.center = { lat: bound.getCenter().lat().toFixed(4), lng: bound.getCenter().lng().toFixed(4) }
            console.log(this.center);

            res.center = { lat: bound.getCenter().lat().toFixed(4), lng: bound.getCenter().lng().toFixed(4) }
            res.Latlongs.map(res => {
              console.log(parseFloat(res.lat));
              res.lat = parseFloat(res.lat);
              res.lng = parseFloat(res.lng);
            })
          }
          // return res.Latlongs
        })
        //  environment.swalalert('success', res.body['msg']);
        console.log(this.zoneList);
      }
    },
      (err) => {
        console.log(err);
      });


  }

  createLatlonFormGroup(): FormGroup {
    return new FormGroup({

      lat: new FormControl(''),
      lng: new FormControl(''),
    })
  }
  public addlatlonFormGroup() {
    const latlon = this.ZoneForm.get('latlon') as FormArray
    latlon.push(this.createLatlonFormGroup())
    console.log(latlon);

  }
  changeStatus(stats) {
    console.log(stats);

    if (stats == true) {
      var data = { status: 'enable' };
    } else {
      var data = { status: 'disable' };
    }

    this.service.deliveryboyzoneStatusUpdate(data).subscribe(res => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        if (res.body['error'] === false) {

        } else {
          environment.swalalert('error', res.body['msg']);
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }
  public removeOrClearLatlon(i: number) {
    const latlon = this.ZoneForm.get('latlon') as FormArray
    if (latlon.length > 1) {
      latlon.removeAt(i)
    } else {
      latlon.reset()
    }
  }
  mapClicked($event: MouseEvent) {
    console.log($event);
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
    });
  }
  onPolyClick(index, polygon: AgmPolygon, infoWindow: AgmSnazzyInfoWindow) {
    // console.log(polygon,$event);
    // var mapinfowindow = new google.maps.InfoWindow();
    //  google.maps.infoWindow.isOpen = Object.assign({}, google.maps.infoWindow.isOpen);
    //google.maps.selectedPoint = getPolygonCenter(polygon.points);

  }

  onMapReady(map) {
    console.log(map);
    this.initDrawingManager(map);


  }
  listZones() {
    const data = { ZoneType: 'provider zone' }
    this.service.listOfZones(data).subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('', res.body['msg']);
      } else {
        console.log(res.body['data'].zoneList);

        this.zoneList = res.body['data'].zoneList
        if (res.body['data'].zoneStatus == 'enable') {
          this.zoneStatus = true;
        } else {
          this.zoneStatus = false;
        }

        this.zoneList.map(res => {
          console.log(res.Latlongs);

          //  var bound = new google.maps.LatLngBounds();

          //  for (let i = 0; i < this.drawnlatlong.length; i++) {
          //    bound.extend( new google.maps.LatLng(this.drawnlatlong[i].lat, this.drawnlatlong[i].lng) );

          //    // OTHER CODE
          //  }
          //  this.center={lat: bound.getCenter().lat().toFixed(4),lng:bound.getCenter().lng().toFixed(4)}


          if (res.Latlongs != null) {




            var bound = new google.maps.LatLngBounds();

            for (let i = 0; i < res.Latlongs.length; i++) {
              bound.extend(new google.maps.LatLng(res.Latlongs[i].lat, res.Latlongs[i].lng));

              // OTHER CODE
            }
            this.center = { lat: bound.getCenter().lat().toFixed(4), lng: bound.getCenter().lng().toFixed(4) }
            console.log(this.center);

            res.center = { lat: bound.getCenter().lat().toFixed(4), lng: bound.getCenter().lng().toFixed(4) }
            res.Latlongs.map(res => {
              console.log(parseFloat(res.lat));
              res.lat = parseFloat(res.lat);
              res.lng = parseFloat(res.lng);
            })
          }
          // return res.Latlongs
        })
        //  environment.swalalert('success', res.body['msg']);
        console.log(this.zoneList);
      }
    },
      (err) => {
        console.log(err);
      });
  }


  deletezone(data) {

    const apidata = { zoneId: data }

    console.log('deleted');
    this.service.deleteZone(apidata).subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('', res.body['msg']);
      } else {

        this.listZones()
        environment.swalalert('success', res.body['msg']);

      }
    },
      (err) => {
        console.log(err);
      });

  }
  manualsavezone() {

    console.log(this.ZoneForm.value.latlon);


    const apidata = { ZoneName: this.manualzonename, distance: this.manualzonedistance, Latlongs: this.ZoneForm.value.latlon }

    this.service.addZone(apidata).subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('', res.body['msg']);
      } else {
        this.ismanualzoneclicked = false;
        this.manualzonename = '';
        this.manualzonedistance = ''
        this.ZoneForm.reset()
        this.listZones()
        environment.swalalert('success', res.body['msg']);

      }
    },
      (err) => {
        console.log(err);
      });
  }
  addmanualzone() {
    this.ismanualzoneclicked = true;
  }
  savezone() {
    const apidata = { ZoneName: this.ZoneName, center: this.center, distance: this.distance, Latlongs: this.drawnlatlong, ZoneType: "provider zone" }
    if (this.ZoneName) {
      console.log('yes');

    } else {
      console.log('no');
      environment.swalalert('', 'Please Enter Zone Name');
      return
    }


    console.log(apidata);
    //this.service.addZone(apidata)



    this.service.addZone(apidata).subscribe((res) => {

      if (res.body['error']) {
        environment.swalalert('', res.body['msg']);
      } else {
        this.isdrawn = false;
        this.ZoneName = ''
        this.listZones()
        environment.swalalert('success', res.body['msg']);

      }
    },
      (err) => {
        console.log(err);
      });

  }
  initDrawingManager(map: any) {
    console.log('drawing manager', map);

    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: true,
        editable: true,

      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };


    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
    //var infowindow = new google.maps.InfoWindow();
    // this.zoneList.data.forEach((poly, i) => {
    // google.maps.event.addListener(google.maps.drawing.OverlayType.POLYGON, 'click', function (event) {
    //   infowindow.setContent('Ajdkshgggggggg');
    //   infowindow.setPosition(event.latLng);
    //   infowindow.open(map);
    // });
    //})
    var infowindow = new google.maps.InfoWindow();


    infowindow.setContent('Ajdkshgggggggg');



    console.log(drawingManager);
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      // Polygon drawn
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        //this is the coordinate, you can assign it to a variable or pass into another function.
        //  alert(event.overlay.getPath().getArray());
        this.drawnlatlong = event.overlay.getPath().getArray().map(coord => {
          return {
            lat: coord.lat().toFixed(4),
            lng: coord.lng().toFixed(4)
          }
        });;

        this.isdrawn = true;
        console.log(this.drawnlatlong);
        var bound = new google.maps.LatLngBounds();

        for (let i = 0; i < this.drawnlatlong.length; i++) {
          bound.extend(new google.maps.LatLng(this.drawnlatlong[i].lat, this.drawnlatlong[i].lng));

          // OTHER CODE
        }
        this.center = { lat: bound.getCenter().lat().toFixed(4), lng: bound.getCenter().lng().toFixed(4) }
        console.log(bound.getCenter().lat().toFixed(4), bound.getCenter().lng().toFixed(4));

      }
    });
  }

}
