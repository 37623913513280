
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { EncryService } from 'src/app/encry.service';
import { from } from 'rxjs';
//import { environment } from '../../../../environments/environment';
//import { environment } from '../../../../environments/environment';
import { environment } from '../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { start } from 'repl';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { ReportService } from './report.service';
import { ReportModel } from './report.model';
import * as $ from "../../../assets/js/jquery.3.2.1.min.js";
import { TripModel } from './trip.model';
// import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
// import { GrdFilterPipe } from 'src/app/grd-filter.pipe';
import * as FileSaver from 'file-saver';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//import {DataTablesModule} from 'angular-datatables';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare var $;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit  ,AfterViewInit {
  registerForm: FormGroup;
  //.................................
  title = 'dataTableDemo';
 
 // dtOptions23: DataTables.Settings = {};
  posts: any;
  //.............................................
  public searchText : string;
  @ViewChild('dataTable') table;
  @ViewChild('dataTable1') table1;
  dataTable: any;
  dataTable1: any;
  dtOptions: any;
  dtOptions1: any;
  listTrip: Boolean = false;
  listTrip1: Boolean = true;
  typeid: any;
  returnUrl: any;
  promocodesAddForm1: any;
  promovtype1:ReportModel;
  data1:any;
  typeview:ReportModel;
  typeview1:TripModel;
  result: any;
  dataSource:any;
  page: number = 1;
  sno: Number = 1;
  pages: any;
  data3:any;
  data4:any;
  data5:any;
  reportPage:Boolean=true;
  Trippage:Boolean=false;
  validfrom: any = new Date();
  validfrom1: any = new Date();
  validto: any = new Date();
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  // public dateValue: Object = new Date(new Date().setDate(14));
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentDay);
  public maxDate: Object = new Date(this.currentYear, 12, 31);


  


  //
  public month: number = new Date().getMonth();
public fullYear: number = new Date().getFullYear();
public start: Date = new Date(this.fullYear, this.month - 1 , 7);
public end: Date = new Date(this.fullYear, this.month, 25);
// public start: Date ;
// public end: Date ;
// start: any = new Date();
//   end: any = new Date();
dataSource1: MatTableDataSource<TripModel>;

// @ViewChild(MatPaginator) paginator: MatPaginator;
// @ViewChild(MatSort) sort: MatSort;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private encry: EncryService,
   private service: ReportService,
    private appspinner: AppComponent,
    // private filt:GrdFilterPipe

    
  ) 
  
  { 
    //alert(this.validfrom)
    
    // this.promocodesAddForm1 = this.formBuilder.group({
      
    //   'ReportType': ['', [Validators.required, Validators.minLength(1)]],
    //   'validfrom': [this.validfrom, [Validators.required, Validators.minLength(1)]],
    //   'validto': [this.validto, [Validators.required, Validators.minLength(1)]],
      
    // });
    this.createForm();
    // this.dataSource = new MatTableDataSource(this.result);
}


createForm(): void {
  this.registerForm = this.formBuilder.group({
    ReportType: ['', [Validators.required, Validators.minLength(1)]],
    // createdOn: ['1/1/2019', Validators.required],
    // date: ['1/1/2018']
    validfrom: [this.validfrom, [Validators.required, Validators.minLength(1)]],
    validto: [this.validto, [Validators.required, Validators.minLength(1)]],
  });
}

ngAfterViewInit() {
  ////debugger;
  
  // this.registerForm.setValue({
  //   ReportType: ['', [Validators.required, Validators.minLength(1)]],
  //   createdOn: [new Date()],
  //   date: [new Date()]
  // });
  
}
// ngAfterViewInit() {
//   this.promocodesAddForm1.setValue({
      
//     'ReportType': ['', [Validators.required, Validators.minLength(1)]],
//     'validfrom': [this.validfrom, [Validators.required, Validators.minLength(1)]],
//     'validto': [this.validto, [Validators.required, Validators.minLength(1)]],
    
//   });
// }
  ngOnInit() {

    this.validfrom=this.formatDate(this.validfrom)
    this.validto=this.formatDate(this.validto)
    this.appspinner.spinnerAlert('show');

    this.appspinner.spinnerAlert('hide');
    this.listTrip1=true;
    





   





    this.reportPage=true;


  
 


    
  }
 
  sample3()
  {

    var sF1 = this.registerForm.value;
    alert("fff"+sF1.ReportType)
  }
  reportClear()
  {
  //    this.registerForm.setValue({
  //   ReportType: [''],
  //   createdOn: [''],
  //   date: ['']
  // });
  this.registerForm.reset();

  }
  promocodesAdd1() {
   
  //   this.reportPage=false;
  //   this.Trippage=true;



  //      this.dtOptions = {
  //     dom: 'Bfrtip', 
  //   };
  // this.dataTable = $(this.table.nativeElement);
  // this.dataTable.DataTable(this.dtOptions);
  ///..........................................................
    this.appspinner.spinnerAlert('show');
////debugger
    // if (this.promocodesAddForm1.valid) {
      if (this.registerForm.valid) {


      // var sF = this.promocodesAddForm1.value;
      var sF = this.registerForm.value;
      console.log(Date.parse(sF.validfrom));
      console.log(Date.parse(sF.validto));
      localStorage.setItem("ReportType", sF.ReportType);
      localStorage.setItem("fromdate", sF.validfrom);
      localStorage.setItem("todate", sF.validto);
      if (Date.parse(sF.validfrom) > Date.parse(sF.validto)) {
        environment.swalalert('error', 'Please enter a valid expiry date')
      } else {
      this.promovtype1 = new ReportModel(
        sF.ReportType,
       this.formatDate(sF.validfrom),
      this.formatDate(sF.validto),
      
      );

           console.log("FormData:::",this.promovtype1);
      
      this.service.reportcodesAdd(this.promovtype1).subscribe(res => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            var ReportType=localStorage.getItem('ReportType') 
            var sam2=Date.parse(localStorage.getItem('fromdate') )
            var sam3=localStorage.getItem('todate') 
           var RangeFrom= this.formatDate(sam2);
           var RangeTo= this.formatDate(sam3);
           
        
            this. data1 = {
              ReportType,
              RangeFrom,
              RangeTo,
            
          }
          this.tripCancelReport(this.data1)
            environment.swalalert('success', res.body['msg']).then(value => {
////debugger
             
              this.listTrip1=false;
              
              // this.listTrip=true;
              

              if (value) {
                //this.promocodesAddForm1.reset();
                //this.router.navigate(['promoCodes']);
                this.router.navigate(['tripcancellation']);
                //...................................................
                // this.tripCancelReport(this.data1)
                // this.listTrip1=false;
                // this.listTrip=true;
                
              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
    }
    else {
      environment.swalalert('warning', 'Validation Required');
    }
   }


   cancelpromocodesAdd1(){
    this.promocodesAddForm1.reset();
   }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }


  tripCancelReport(data) {
    ////debugger
    //this.commontypename = type;
    // this.cookie.set('emailtemplatepageslist', page);
    // localStorage.setItem('emailtemplatepageslist', page);
    this.service.generateReportView(data).subscribe((res: any) => {
      if (res.body['error']) {
        environment.swalalert('nodata', res['msg']);
      } else {

        this.typeview1 = new TripModel(res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.typeview1['data'].length > 0) {
          console.log("trip.......",res.body['data'])
          console.log("tripvvvvvv.......",res)
          console.log("trip change....",this.typeview)
          // this.pages = this.typeview['data'][1].Count;
          // this.result = this.typeview['data'][0].data;
          this.result=res.body['data']
this.dataSource=res.body['data']
          console.log("trip change 3",this.result[0].Id)
          console.log("Report Generation",this.result)
       
        } else {
          environment.swalalert('nodata', 'No Data Available ');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }

  sample23(item){
    ////debugger
    var ss=item;
    var newArray = this.result.filter(function(item)
 {
  return newArray
 });
  }
  sample()
  {

  }
  
  exportAsXLSX2(): void {
    ////debugger
    
//  this.data3   =this.filt.transform
// this.data4=  this.filt.transform(this.result,this.searchText,true);
// this.data5=  this.filt.transform(this.result,this.searchText,false);
        if (this.result.length === 0) {
          // if (r.length === 0) {
          Swal.fire({
            title: 'Error',
            text: 'Orders Data has empty',
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.exportAsExcelFile(this.result, 'reports');
          // this.exportAsExcelFile(r, 'reports');
    
        }
      }
  exportAsXLSX(): void {
    ////debugger
        if (this.result.length === 0) {
          // if (r.length === 0) {
          Swal.fire({
            title: 'Error',
            text: 'Orders Data has empty',
            type: 'error',
            confirmButtonText: 'Ok',
          });
        } else {
          this.exportAsExcelFile(this.result, 'reports');
          // this.exportAsExcelFile(r, 'reports');
    
        }
      }
      exportAsXLSX11(r): void {
        ////debugger
            // if (this.result.length === 0) {
              // for(var i=0;i<=r.length; i++)
              // {
            //   if (r.length === 0) {
            //   Swal.fire({
            //     title: 'Error',
            //     text: 'Orders Data has empty',
            //     type: 'error',
            //     confirmButtonText: 'Ok',
            //   });
            // } else {
            //   ////debugger
        //       const obj = {5.0: 10, 28.0: 14, 3.0: 6};
        
        // const mapped = Object.keys(obj).map(key => ({type: key, value: obj[key]}));
        
        console.log(r);
        // for(var i=0;i<=r.length; i++)
        //       {
        const mapped = Object.keys(r).map(key => ({type: key, value: r[key]}));
              // this.exportAsExcelFile(this.result, 'reports');
              // this.exportAsExcelFile(r, 'reports');
              this.exportAsExcelFile(mapped, 'reports');
             // this.exportAsExcelFile(r, 'reports');
        
            // }
          // }
              // }
          }
          applyFilter1(event: Event) {
            ////debugger
            const filterValue = (event.target as HTMLInputElement).value;
            this.dataSource.filter = filterValue.trim().toLowerCase();
            console.log("fffff",this.dataSource)
          }
          applyFilter(event: Event) {
            ////debugger
            const filterValue = (event.target as HTMLInputElement).value;
            this.dataSource.filter = filterValue.trim().toLowerCase();
            console.log("fffff",this.dataSource)
            // if (this.dataSource.paginator) {
            //   this.dataSource.paginator.firstPage();
            // }
          }
   //Export as Excel
   exportAsXLSX1(r): void {
////debugger
    // if (this.result.length === 0) {
      if (r.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'Orders Data has empty',
        type: 'error',
        confirmButtonText: 'Ok',
      });
    } else {
      ////debugger
//       const obj = {5.0: 10, 28.0: 14, 3.0: 6};

// const mapped = Object.keys(obj).map(key => ({type: key, value: obj[key]}));

// console.log(mapped);
const mapped = Object.keys(r).map(key => ({type: key, value: r[key]}));
      // this.exportAsExcelFile(this.result, 'reports');
      // this.exportAsExcelFile(r, 'reports');
      this.exportAsExcelFile(mapped, 'reports');
      

    }
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
////debugger
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  findname(search) {
  ////debugger
    console.log("findname",search)
    var searchData = search
    if (searchData)
    {
      alert("empty")
this.promocodesAdd1();
    }
    else{
    if(searchData.split("/").length = 3){
      var splitteddata = searchData.split("/")

      var ReportType=localStorage.getItem('ReportType') 
      var sam2=Date.parse(localStorage.getItem('fromdate') )
      var sam3=localStorage.getItem('todate') 
     var RangeFrom= this.formatDate(sam2);
     var RangeTo= this.formatDate(sam3);
      // var date = splitteddata[2]+"%2F"+splitteddata[1]+"%2F"+splitteddata[0]
      // searchData = date
    }
    console.log("jjjjjjjj......",searchData);

   
    // var data = {search: search, typename: 'bookings', page: this.page ,status:'later'}
    var data = {ReportType:ReportType , RangeFrom: RangeFrom, RangeTo: RangeTo ,search:search}
    // this.service.bookingsearchdataView(data).subscribe((res:any) => {
      this.service.reportSearchView(data).subscribe((res:any) => {  
      console.log("ride result",res)
      if (res['error']) {
        // environment.swalalert('nodata', res['msg']);
        // this.appspinner.spinnerAlert('hide');
        console.log("error")
      } 
      else {



        this.typeview1 = new TripModel(res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.typeview1['data'].length > 0) {
          console.log("trip.......",res.body['data'])
          console.log("tripvvvvvv.......",res)
          console.log("trip change....",this.typeview)
          // this.pages = this.typeview['data'][1].Count;
          // this.result = this.typeview['data'][0].data;
          this.result=res.body['data']
this.dataSource=res.body['data']
          console.log("trip change 3",this.result[0].Id)
          console.log("Report Generation",this.result)
        console.log("success")
        // this.result = new TripModel(res['error'], res['msg'], res['data'])
       
        // // console.log(this.result['data'][0].data);
        // if (res['data'][0].data.length > 0 && this.result['data'][0].data.length > 0) {
        //   console.log(res);
          
        //   this.pages = this.result['data'][0].Count;
        //    this.result= this.result['data'][0].data;
        //   console.log("test data from ride later",this.result)
        //   this.appspinner.spinnerAlert('hide');
        //   console.log(this.result);

        // } else {
          // environment.swalalert('nodata', 'No Data Available ');
          // this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      });
    }
  }
  listreportFlag()
  {
    this.listTrip=false;
    this.listTrip1=true;
  }
}

