export class OrgPlanModel {
    // public DefaultPlanName: String;
    public PlanName:String;
    public CommissionPercentage: number;
    public Id:number;
    constructor( 
        // DefaultPlanName: String,
PlanName:String,
    CommissionPercentage: number  ,
    Id:number      
                    
                ) {
                //    this.DefaultPlanName=DefaultPlanName;
                   this.PlanName=PlanName;
                   this.CommissionPercentage=CommissionPercentage;
                   this.Id=Id;
                    
    }
}