import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { CookieService } from 'ngx-cookie-service';
import { BookingsList } from './adminbookingslist.model';


@Injectable({
  providedIn: 'root'
})
export class AdminBookingsService {

  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  url = environment.Url;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,
    private cookie: CookieService) { }

    adminProviderListView(page) {
      return this.http.get(`${this.url}/adminProviderListView/${page}`,  {
        headers: this.httpheaders,
        observe: 'response'
      });
    } 
cancelBooking(data){
  
    return this.http.post(`${this.url}/cancelBooking`,data,  {
        headers: this.httpheaders,
        observe: 'response'
      });
}


bookingsearchdataView(name): Observable<BookingsList[]> {
  ////debugger
  return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
    headers: this.httpheaders
  })
    .map((res: Response) => {
      return <BookingsList[]><unknown>res;
    })
    .catch(this.handleError);
} 

bookingFilterListView(data) {
  return this.http.post(`${this.url}/bookingFilterListView`,data,{
    headers: this.httpheaders,
    observe: 'response'
  });
}
}

