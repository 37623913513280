import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VendorServiceService } from '../vendorService/vendor-service.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
@Component({
  selector: 'app-vendor-details-view',
  templateUrl: './vendor-details-view.component.html',
  styleUrls: ['./vendor-details-view.component.css']
})
export class VendorDetailsViewComponent implements OnInit {

  VendorId:any;
  limit = 20 ;
  driverList:any;
  currentTab:String='active';
  VendorDet: any;
  vendorState: any;
  @ViewChild('closeEditModals') closeEditModals: ElementRef;
  constructor(private vendorService:VendorServiceService,private activatedRoute:ActivatedRoute,
    private fb:FormBuilder,private _location: Location
  ) { 
    this.activatedRoute.params.subscribe(params => {
      this.VendorId = params['id'];
    });
  }

  ngOnInit() {
    this.VendorInit();
    this.GetVendorDetails();
    this.DriverList(1,'active');
  }

  VendorInit(){
    this.vendorState = this.fb.group({
      'VendorName': '',
      'Email': '',
      'Mobile': '',
      'CompanyAddress': '',
      'CompanyName': '',
      'IsAdmin': '',
      'Status': ''
    })
  }

  // Vendor Based Driver List
  DriverList(page:any,status:any){
    try {
      let params = {
        vendorId : this.VendorId,
        page : page,
        limit : this.limit,
        status : status
      }
      this.vendorService.VendorDriverList(params).subscribe((res:any) => {
        if(!res.error){
          this.currentTab = 'active';
          this.driverList = res.data.drivers;
        }else{
          console.log('==========>eror',res.error)
        }
      })
    } catch (error) {
      console.log('Catch Error',error.error.message)
    }
  }
  backClicked() {
    this._location.back();
  }

  GetVendorDetails(){
    try {
      this.vendorService.GetVendorDet({'vendorId':this.VendorId}).subscribe((res:any) => {
        if(!res.error){
          this.VendorDet = res.data;                    
        }else{
          console.log('==========>eror',res.error)
        }
      })
    } catch (error) {
      console.log('Catch Error',error.error.message)
    }
  }

  VendorValuePatch(){
    this.vendorState.patchValue({
      'VendorName': this.VendorDet.VendorName,
      'Email': this.VendorDet.Email,
      'Mobile': this.VendorDet.Mobile,
      'CompanyAddress': this.VendorDet.CompanyAddress,
      'CompanyName': this.VendorDet.CompanyName,
      'IsAdmin': this.VendorDet.IsAdmin,
      'Status': this.VendorDet.Status
    })
  }

  VendorUpdate(){
    try {
      let params = {
        'VendorName': this.vendorState.value.VendorName,
        'Email': this.vendorState.value.Email,
        'Mobile': this.vendorState.value.Mobile,
        'CompanyAddress': this.vendorState.value.CompanyAddress,
        'CompanyName': this.vendorState.value.CompanyName,
        'IsAdmin': this.vendorState.value.IsAdmin == "1" ? true : false,
        'Status': this.vendorState.value.Status
      }
      
      this.vendorService.UpdateVendor(this.VendorId,params).subscribe( (res:any) => {
        if(!res.error){
          environment.swalalert('success','Vendor Details updated');
          this.closeEditModal();
          this.GetVendorDetails();
        }else{
          environment.swalalert('error',res.error);
          
        }
      })
    } catch (error) {
      console.log('Catch Error',error.error.message)
    }
  }
  
  closeEditModal(){
    this.closeEditModals.nativeElement.click();
  }
}
