
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { EncryService } from 'src/app/encry.service';
import { from } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { CreategroupService } from './creategroup.service';
import { CreategroupModel } from './creategroup.model';
import { CreateGroupDataModel } from './creategroupdata.model';
import { GroupService } from '../group.service';
@Component({
  selector: 'app-creategroup',
  templateUrl: './creategroup.component.html',
  styleUrls: ['./creategroup.component.css']
})
export class CreategroupComponent implements OnInit {
  selectAll: boolean = false;
  searchdata:String;
  creategroup: any;
  typeid: any;
  returnUrl: any;
  areAllSelected = false;
  masterSelected: boolean;
  //checkedList = [];
  Name: String;
  // Name: Number;
  Type: String;
  
  groupModel:CreategroupModel;
 searchlist:CreateGroupDataModel;
 
 selectedOption: string = 'option1'; 
selectedOptionbooking: string = 'option1'; 
selectedOptionsupport: string = 'user'; 
selectedOption1: string; 
  validfrom: any = new Date();
   validto: any = new Date();
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
   public dateValue: Object = new Date(new Date().setDate(14));
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentDay);
  public maxDate: Object = new Date(this.currentYear, 12, 31);
filter
  typeview: CreateGroupDataModel;
  result: any;
  page: Number = 1;
  pages: any;
  // pagechange:Number=1;
numObject: Number = new Number(10);
pagechange: number = this.numObject.valueOf();
  commontypename: String = 'user';
  checkedList = [];
  selectedType: string = 'provider';
  selectedType1: string;
  noDataFlag:Boolean=false;
  selectedItems: any[];
  paginationflag:Boolean=false
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private encry: EncryService,
    private servicegroup: GroupService,
    private servicecreate:CreategroupService,
    private appspinner: AppComponent) { 
      this.masterSelected = false;



      // this.creategroup= this.formBuilder.group({
      // //   'name': ['', [Validators.required, Validators.minLength(1)]],
      // //   'coupon': ['', [Validators.required, Validators.minLength(1)]],
      // //   'discount': ['', [Validators.required, Validators.minLength(1)]],
      // //   'discounttype': ['', [Validators.required, Validators.minLength(1)]],
      // //   // 'threshold': ['', [Validators.required, Validators.minLength(1)]],
      //   // 'minvalue': ['', [Validators.required, Validators.minLength(1)]],
      //   // 'maxvalue': ['', [Validators.required, Validators.minLength(1)]],
      //   // 'validfrom': [this.validfrom, [Validators.required, Validators.minLength(1)]],
      //   // 'validto': [this.validto, [Validators.required, Validators.minLength(1)]],
      // //   // 'redeemableperuser': ['', [Validators.required, Validators.minLength(1)]],
      //   'GroupName': ['', [Validators.required, Validators.minLength(1)]],
      // //   'IsActive': ['']
      // });
    }

    toggleSelectAll1() {
      // alert("hhghfhgfgh")
      for (let item of this.result) {
        item.selected = this.selectAll;
        // alert("sss"+item.selected)
      }
    }

    toggleSelectAll() {
      this.selectAll = !this.selectAll; // Toggle the selectAll state
    
      if (this.selectAll) {
        // Select all items
        this.selectedItems = [this.result];
      } else {
        // Unselect all items
        this.selectedItems = [];
      }

     
    }

  ngOnInit() {

    this.appspinner.spinnerAlert('show');
this.getActiveProvider(1)
// this.getActiveUsers(1)
    // this.appspinner.spinnerAlert('hide');
   // this.pushprovidersView(this.page, 'provider')
    // this.appspinner.spinnerAlert('show');
    // this.servicecreate.pushproviderviewListView(this.page).subscribe((res: any) => {
    //   if (res.body['error']) {
    //     environment.swalalert('nodata', res.body['msg']);
    //     this.appspinner.spinnerAlert('hide');
    //   } else {
    //     this.typeview = new CreateGroupDataModel(res.body['error'], res.body['msg'], res.body['data']);
    //     if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
    //       ////debugger
    //       // alert(this.typeview['data'][0].data.length)
    //       this.pages = this.typeview['data'][1].Count;
    //       // alert(this.typeview['data'][1].Count/10)
    //       // alert(this.pages)
    //       // alert(this.page)
    //       // this.pagination(this.page)
    //       this.result = this.typeview['data'][0].data;
        
    //       this.result.map(x => {
    //         x['isSelected'] = false;
    //       });
    //       //this.getCheckedItemList();
    //       this.appspinner.spinnerAlert('hide');
    //     } else {
    //       environment.swalalert('nodata', 'No Data Available ');
    //       this.appspinner.spinnerAlert('hide');
    //     }
    //   }
    // },
    //   (err) => {
    //     console.log(err);
    //     if (err['status'] === 401) {
    //       environment.swalalert('nodata', err['msg']);
    //       // this.cookie.delete('Token');
    //       localStorage.removeItem('Token');
    //       this.router.navigate(['login']);
    //     }
    //   });
    // this.pushprovidersView(this.page, 'provider')
   // this.pushusersView(this.page, 'user')
  }



  isAllSelected() {
    // this.result.map(x => {
    //   x['isSelected'] = true;
    // });
    this.pushprovidersView1()
    this.areAllSelected = !this.areAllSelected;
    // this.result = this.result.map(item => ({ ...item, isSelected: this.areAllSelected }));

    // this.masterSelected = this.result.every(function (item: any) {
    //   return item.isSelected == true;
    // })
    this.getCheckedItemList();
  }

  //event handler for the select element's change event
  selectChangeHandler (event: any) {
    //update the ui
     this.selectedType = event.target.value;
     this.Type= this.selectedType;
    // alert("success"+this.selectedDay)
    this.page = 1
    this.checkedList=[];
    if (this.selectedType === 'provider') {
      this.getActiveProvider(this.page)
      // this.pushprovidersView(this.page, 'provider')
      this.selectedType1='provider';
      
    } else {
      this.getActiveUsers(this.page)
      // this.pushusersView(this.page, 'user')
      this.selectedType1='user';
    }
  }

  reset() {
   this.Name = '';
    // this.Name = 0;
    this.Type = '';  
    this.areAllSelected=false;
    this.result = this.result.map(item => ({ ...item, isSelected: false }));
    this.getCheckedItemList();

  }


  getCheckedItemList() {
    this.checkedList = [];
    this.pages = this.typeview['data'][1].Count;
    // for (var i = 0; i < this.result.length; i++) {
    //   if (this.result[i].isSelected)
    //     this.checkedList.push(this.result[i]);
    // }
    // alert(this.pages)
    // alert(this.result[0][2])
    for (var i = 0; i < this.pages; i++) {
      if (this.result[i].isSelected)
        this.checkedList.push(this.result[i]);
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }
  pushnotificationAdd() {
    ////debugger
    var Ids = [];
  //  var Name;
    this.checkedList.map(x => {
      Ids.push(x.Id);
    });
    var data = { Name: this.Name, Type: this.Type,Ids:Ids};
    
   
  
    // var data1:any = {
    //   data: data
    //   // msg: msgdata
    // };
    console.log("Data::",data);

    if (Ids.length > 0) {
      
        this.servicecreate.userPushNotificationSend(data).subscribe(res => {
          if (res['error']) {
            environment.swalalert('nodata', res['msg']);
            this.appspinner.spinnerAlert('hide');
          } 
          else {
            if (res.body['error'] === false) {
              environment.swalalert('success', res.body['msg']).then(value => {
                if (value) {

                  this.Name = '';
                  // this.Name = 0;
                  this.Type = '';
                 
                }
              });
            } else {
              environment.swalalert('error', res.body['msg']);
            }
          }
        });
    } 
    
     else {
      environment.swalalert('error', 'Select anyone of the user or provider');
      this.router.navigate(['creategroup']);
    }

  }
  cancelpushnotificationAdd(){
    ////debugger;

    // this.creategroup.reset();
                    
    this.router.navigate(['group']);
    
  }
  findname(search) {
    ////debugger
    this.selectedType1
    this.selectedType
    //alert(this.selectedType)


// if(this.selectedType== 'user'){
 if(this.Type== 'user'){
  var data = {search: search, typename: 'users', page: this.page}
    this.servicecreate.usersearchdataView(data).subscribe((res) => {
          if (res['error']) {
            // environment.swalalert('nodata', res['msg']);
            // this.appspinner.spinnerAlert('hide');
          } else {
            this.searchlist = new CreateGroupDataModel(res['error'], res['msg'], res['data']);
            if (res['data'].length > 0 && this.searchlist['data'][0].data.length > 0) {
              this.pages = this.searchlist['data'][1].Count;
              this.result = this.searchlist['data'][0].data;
              this.appspinner.spinnerAlert('hide');
            } else {
              // environment.swalalert('nodata', 'No Data Available ');
              // this.appspinner.spinnerAlert('hide');
            }
          }
        },
          (err) => {
            console.log(err);
          });
}
    
else{
    // this.currentTab='active'/pending;
    // var data = {search: search, typename: 'users', page: this.page}
    // var data = { search: search, typename: this.selectedType, page: this.page,status:'active' }
    var data1 = { search: search, typename: 'providers', page: this.page,status:'active' }
    var pen = [];
    var reslt = [];
    this.servicecreate.providersearchdataView(data1).subscribe((res) => {
      if (res['error']) {
        // environment.swalalert('nodata', res['msg']);
        // this.appspinner.spinnerAlert('hide');
      } else {
        this.searchlist = new CreateGroupDataModel(res['error'], res['msg'], res['data']);
        if (res['data'].length > 0 && this.searchlist['data'][0].data.length > 0) {
          this.pages = this.searchlist['data'][1].Count;
          this.result =  this.searchlist['data'][0].data;
          console.log("search data ex...",this.result)
          this.appspinner.spinnerAlert('show');
          this.appspinner.spinnerAlert('hide');
        } else {
          // environment.swalalert('nodata', 'No Data Available ');
          // this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      });
    }


  }
  groupAdd() {
    ////debugger
        if (this.creategroup.valid) {
    
    
    
          var sF = this.creategroup.value;
    
          // console.log(Date.parse(sF.validfrom));
          // console.log(Date.parse(sF.validto));
          //if (Date.parse(sF.validfrom) > Date.parse(sF.validto)) {
            if(this.creategroup.value== 0){
            environment.swalalert('error', 'Please enter a valid expiry date')
          } else {
          this.groupModel = new CreategroupModel(
            sF.GroupName,
           sF.Users
         
          
          );
               console.log("FormData:::",this.groupModel);
          
          this.servicecreate.GroupAdd(this.groupModel).subscribe(res => {
            if (res['error']) {
              environment.swalalert('nodata', res['msg']);
              this.appspinner.spinnerAlert('hide');
            } else {
              if (res.body['error'] === false) {
                environment.swalalert('success', res.body['msg']).then(value => {
                  if (value) {
                    this.creategroup.reset();
                    
                    this.router.navigate(['group']);
                    
                  }
                });
              } else {
                environment.swalalert('error', res.body['msg']);
              }
            }
          });
        }
        }
        else {
          environment.swalalert('warning', 'Validation Required');
        }
      }
      formatDate(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
      }




      pushprovidersView(page, type) {
        ////debugger
        this.commontypename = type;
       // alert(page)
        // this.cookie.set('emailtemplatepageslist', page);
        localStorage.setItem('emailtemplatepageslist', page);
        this.servicecreate.pushproviderviewListView(page).subscribe((res: any) => {
          ////debugger
          if (res['error']) {
            environment.swalalert('nodata', res['msg']);
          } else {
    
            this.typeview = new CreateGroupDataModel(res.body['error'], res.body['msg'], res.body['data']);
            if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
              this.pages = this.typeview['data'][1].Count;
              this.result = this.typeview['data'][0].data;
              console.log("page",this.page);
              console.log("pages",this.pages);
              console.log("Create provider1111",this.result)
              this.result.map(x => {
                x['isSelected'] = false;
              });
            } else {
              environment.swalalert('nodata', 'No Data Available ');
            }
          }
        },
          (err) => {
            console.log(err);
          });
      }

      // pushprovidersView1(page, type) {
        pushprovidersView1() {
        ////debugger
        // this.commontypename = type;
       // alert(page)
        // this.cookie.set('emailtemplatepageslist', page);
        // localStorage.setItem('emailtemplatepageslist', page);
        this.servicecreate.pushproviderviewListView(1).subscribe((res: any) => {
          ////debugger
          if (res['error']) {
            environment.swalalert('nodata', res['msg']);
          } else {
    
            this.typeview = new CreateGroupDataModel(res.body['error'], res.body['msg'], res.body['data']);
            if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
              this.pagechange = Number(this.typeview['data'][1].Count/10);
              this.pagechange = Math.round(this.typeview['data'][1].Count / 10);
              alert(this.pagechange)
              for(var i=1;i< this.pagechange;i++)
              {
              this.pushprovidersView2(i,this.Type)
              }
              // this.result = this.typeview['data'][0].data;
              // console.log("page",this.page);
              // console.log("pages",this.pages);
              // console.log("Create provider1111",this.result)
              // this.result.map(x => {
              //   x['isSelected'] = false;
              // });
            } else {
              environment.swalalert('nodata', 'No Data Available ');
            }
          }
        },
          (err) => {
            console.log(err);
          });
      }
      pushprovidersView2(page, type) {
        ////debugger
        this.commontypename = type;
       // alert(page)
        // this.cookie.set('emailtemplatepageslist', page);
        localStorage.setItem('emailtemplatepageslist', page);
        this.servicecreate.pushproviderviewListView(page).subscribe((res: any) => {
          ////debugger
          if (res['error']) {
            environment.swalalert('nodata', res['msg']);
          } else {
    
            this.typeview = new CreateGroupDataModel(res.body['error'], res.body['msg'], res.body['data']);
            if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
              // this.pagechange = Number(this.typeview['data'][1].Count/10);
              // alert("ddddd"+this.pagechange)
              // for(var i=1;i<=this.pagechange;i++)
              // {
              // this.pushprovidersView2(i,type)
              // }
              // alert("success"+page)
               this.result = this.typeview['data'][0].data;
          //      item['isSelected']=true;
          // this.checkedList.push(item);
              for (let item of this.result) {
                item['isSelected']=true;
          this.checkedList.push(item);
                item.selected = this.selectAll;
                // alert("sss"+item.selected)
              }
              console.log("page",this.page);
              console.log("pages",this.pages);
              console.log("Create provider1111",this.result)
              this.result.map(x => {
                x['isSelected'] = true;
              });
            } else {
              environment.swalalert('nodata', 'No Data Available ');
            }
          }
        },
          (err) => {
            console.log(err);
          });
      }

      ChangeTab(type) {
        this.page = 1
        this.checkedList=[];
        if (type === 'provider') {
          this.getActiveProvider(this.page)
          // this.pushprovidersView(this.page, 'provider')
        } else {
          // this.pushusersView(this.page, 'user')
          this.getActiveUsers(this.page)
        }
      }


       pushusersView(page, type) {

    this.commontypename = type;
    // this.cookie.set('emailtemplatepageslist', page);
    localStorage.setItem('emailtemplatepageslist', page);
    this.servicecreate.pushviewListView(page).subscribe((res: any) => {

      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
      } else {
        this.typeview = new  CreateGroupDataModel (res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {

          this.pages = this.typeview['data'][1].Count;
          this.result = this.typeview['data'][0].data;
          console.log("Create  users1111",this.result)
          this.result.map(x => {
            x['isSelected'] = false;
          });
        } else {
          environment.swalalert('nodata', 'No Data Available ');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }
    
  userCall(){
    // alert("success")
  }




      pagination(page) {
        ////debugger
        
        // if (this.commontypename === 'provider') {
          if (this.selectedType === 'provider') {
          
          // this.pushprovidersView(page, 'provider')
          this.getActiveProvider(page)
        } else {
          // this.pushusersView(page, 'user')
          this.getActiveUsers(page)
        }
      }
      paginationFilter(page) {
        ////debugger
        // if (this.selectedType === 'provider') {
        //   this.pushprovidersView(page, 'provider')
        // } else {
        //   this.pushusersView(page, 'user')
        // }
        this.getallproviders(page,this.selectedOption1)
      }

      checkCheckBoxvalue(e,item){
        // this.checkedList=[];
        //  alert("ssss")
        ////debugger
        if(e.target.checked){
          item['isSelected']=true;
          this.checkedList.push(item);
        }
        if(!e.target.checked){
          var del=this.checkedList.indexOf(item);
          this.checkedList.splice(del,1);
        }
      }




      findname1(searchdata)
{

  this. getallproviders(1,searchdata)
}
reloadWindow() {
  window.location.reload();
}

getActiveProvider(page)
{
  
  this.servicecreate.providerListView(page,'active').subscribe((res) => {
    console.log(res);

    if (res.body['error']) {
      environment.swalalert('nodata', res.body['msg']);
      this.appspinner.spinnerAlert('show');
      this.appspinner.spinnerAlert('hide');
    } 
    
    else {

      
      
    
     

      
      // this.providermodel = new ProviderListModel(res.body['error'],res.body['msg'], res.body['data']);
      
    if (res.body['data'].length > 0 ) {
      //this.page = page;
      this.pages = res.body['data'][1].Count;
      this.result = res.body['data'][0].data;
     
      this.appspinner.spinnerAlert('show');
      this.appspinner.spinnerAlert('hide');
      console.log("result list in provider list check......",res.body['data'][0].data)
    } 
    else 
    {
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('show');
      this.appspinner.spinnerAlert('hide');
    }
  
}
},
  (err) => {
    console.log(err);
    if (err['status'] === 401) {
      environment.swalalert('nodata', err['msg']);
      // this.cookie.delete('Token');
      localStorage.removeItem('Token');
      this.router.navigate(['login']);
    }
  })
}
 onChangeSearchkeytype(data){
  // this.searchdata=''
  // this.filterFlag=true;
  this.paginationflag=true;
  this.selectedOption1=data;
  if(this.selectedOption1=="DOJ This Month")
  {
    // this.filterFlag=false
  this.selectedOption1="doj"
  this. getallproviders(1,"month")
  this.selectedOption1=data;
  }
  if(this.selectedOption1=="DOJ This Week"){
    // this.filterFlag=false
  this.selectedOption1="doj"
  this. getallproviders(1,"week")
  this.selectedOption1=data;
  }
  if(this.selectedOption1=="newlyjoined"){
    // this.filterFlag=false
  this.selectedOption1="newlyjoined"
  this. getallproviders(1,"newlyjoined")
  this.selectedOption1=data;
  }
  if(this.selectedOption1=="ride"){
    // this.filterFlag=false
  this.selectedOption1="ride"
  this. getallproviders(1,"ride")
  this.selectedOption1=data;
  }
  
  

  
}
getallproviders(page,search) {
  
  this.appspinner.spinnerAlert('show');
 // sessionStorage.setItem('doctypelist',page);
 this.noDataFlag=false;
//  if(this.selectedOption1="DOJ This Month"){
//   // alert("sucess")
//   const data={
//     page:page,
//     type:this.selectedOption1,
//     // searchkey:this.searchdata
//     searchkey:search
//   }
// }
// else{
//   const data={
//     page:page,
//     type:this.selectedOption1,
//     // searchkey:this.searchdata
//     searchkey:search
//   }
// }
// "page": 1, "type": "newlyjoined", "status":"verified"}
const data1={
  page:page,
  type:this.selectedOption1,
  
  status:"verified"
}
const data={
  page:page,
  type:this.selectedOption1,
  
  status:"active"
}
  if(!search)
  {
    // this.searchflag=false;
    // this.noDataFlag=false;
    if (this.selectedType === 'provider') {
      this.getActiveProvider(this.page)
      // this.pushprovidersView(this.page, 'provider')
      this.selectedType1='provider';
      
    } else {
      this.getActiveUsers(this.page)
      // this.pushusersView(this.page, 'user')
      this.selectedType1='user';
    }
    // this.getProviders(this.currentpage,this.searchStatus);
    //this.getprovidersSearch(this.currentpage);

  }
  else{
  // alert(data)

  if (this.selectedType === 'provider') {
  this.servicecreate.providerFilterListView(data1).subscribe((res) => {
    if (res.body['error']) {
     
      this.noDataFlag=true;
      this.pages = 0
      this.result = []
      environment.swalalert('nodata', res['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
  var a=0
      if (res.body['data'][0].data.length > 0 ) {
        this.noDataFlag=false;
        
        this.pages = Math.ceil(res.body['data'][1].Count/2);
        this.result = res.body['data'][0].data;
        console.log("filtercheck",res.body['data'][0].data)
        this.appspinner.spinnerAlert('hide'); 
      }
      else {
       
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
    },
    (err) => {
      console.log(err);
  })
}
else {
  this.servicecreate.userFilterListView(data).subscribe((res) => {
    if (res.body['error']) {
     
      this.noDataFlag=true;
      this.pages = 0
      this.result = []
      environment.swalalert('nodata', res['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
  var a=0
      if (res.body['data'][0].data.length > 0 ) {
        this.noDataFlag=false;
        
        this.pages = Math.ceil(res.body['data'][1].Count/2);
        this.result = res.body['data'][0].data;
        console.log("filtercheck",res.body['data'][0].data)
        this.appspinner.spinnerAlert('hide'); 
      }
      else {
       
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
    },
    (err) => {
      console.log(err);
  })
}
}
}




getActiveUsers(page)
{
this.servicecreate.usersListView(page,'active').subscribe((res) => {
 

  if (res.body['error']) {
    this.pages = 0
    this.result = []
    this.appspinner.spinnerAlert('hide');
   
  } else {
    
    // this.usermodel = new UserListModel(res.body['error'],res.body['msg'], res.body['data']);
  if (res.body['data'].length > 0 ) {
    //this.page = page;
    ////debugger
    this.pages = Math.ceil(res.body['data'][1].Count/2);
    this.result = res.body['data'][0].data;
    this.appspinner.spinnerAlert('hide');
    console.log("ffffff......",res.body['data'][0].data)
  } else {
    environment.swalalert('nodata', 'No Data Available ');
    this.appspinner.spinnerAlert('hide');
  }
}
},
(err) => {
  console.log(err);
  if (err['status'] === 401) {
    environment.swalalert('nodata', err['msg']);
    // this.cookie.delete('Token');
    // localStorage.removeItem('Token');
    // this.router.navigate(['login']);
  }
})
}

}



