// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { EncryService } from 'src/app/encry.service';
import { from } from 'rxjs';
import { environment } from '../../../environments/environment';
//  import { environment } from '/../../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
// import { PromoCodeAddModel } from './promocodeadd.model';
import { TicketDetailsService } from './ticketdetails.service';
import { TicketDetailsModel } from './ticketdetail.model';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Renderer2 } from '@angular/core';
// import { Clipboard } from 'clipboard';
// import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-ticketdetails',
  templateUrl: './ticketdetails.component.html',
  styleUrls: ['./ticketdetails.component.css']
})
export class TicketdetailsComponent implements OnInit {
  
  reloadWindow() {
    window.location.reload();
  }
  @ViewChild('activeimg3') activeimg3: ElementRef;
  @ViewChild('copyInput') copyInput: ElementRef;
  uFirstName:any;
  uMobile:any;
  pMobile:any;
 waitingTime:any;
 uEmail:any;
uLastName:any;
pLastName:any
bookingId:any;
FromLocation:any;
ToLocation:any;
Distance:any;
TotalAmount:any;
RideName:any;
VehicleName:any;
VehicleBrandName:any
VehicleModelName:any
Ridename:any;
VehicleColor:any
date1:any;
date2:any;
date4:any;
date3:any;
date5:any;
vehicleid:any;
sendStatus:any;
  paramsid: any;
  chatresult:any;
  promocodesAddForm: any;
  bookingdetail:any;
  bookingdetailslist:TicketDetailsModel;
  ticketlist:TicketDetailsModel;
  result:any;
  Localstoreagedata:any;
  providertype:any;
  usertype:any;
  driverid:any
  tax:[];
  
  total:any;
  crn:any;
  pay:any;
  round:any;
  round1:any;
  Tripfield1:String;
  Tripvalue1:String;
  Tripfield2:String;
  Tripvalue2:String;
  Tripfield3:String;
  Tripvalue3:String;
  Tripfield4:String;
  Tripvalue4:String;
  Tripfield5:String;
  Tripvalue5:String;
  Tripfield6:String;
  Tripvalue6:String;
  Tripfield7:String;
  Tripvalue7:String;
  closeFlag:Boolean=false;
  copycrnFlag:Boolean=false;
  attachmentFlag:Boolean=false;
  cancellationCharge:String;
  activeimagefile3: File[];
  ret: String;
  IconActive3: String;
  imageUrl3: any ;
  imageUrl4: any ;
  imageUrl5: any ;
  imageUrl6: any ;
  editFile: boolean = true;
  removeUpload: boolean = false;
  imagechatFlag:boolean=true;
  mmm:number; receiptLength:any;
  fourflag:Boolean=false;
  fiveflag:Boolean=false;
  sixflag:Boolean=false;
  sevenflag:Boolean=false;
  imagespacelength:Number;
  index:any;
  spaceFlag:Boolean=false
  @ViewChild('fileInput') fileInput: ElementRef;

  onFileInputChange(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      // File selected, handle it here
      const file = files[0];
      console.log('File selected:', file);
    } else {
      // File selection dialog canceled
      console.log('File selection canceled');
      // You can handle the cancel event here
      // For example, show an alert or perform any other action
      window.removeEventListener('focus', this.onFileDialogCancel);
      window.addEventListener('focus', this.onFileDialogCancel);
    }

    // Reset the file input to clear the selected file (if any)
    this.resetFileInput();
  }
  resetFileInput() {
    // Reset the file input to clear the selected file (if any)
    this.fileInput.nativeElement.value = '';
  }

  onFileDialogCancel() {
    console.log('No file selected');
  }
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private encry: EncryService,
    private service: TicketDetailsService,
    private appspinner: AppComponent,private renderer: Renderer2,private cd: ChangeDetectorRef) {

      this.promocodesAddForm = this.formBuilder.group({
        'name': ['', [Validators.required, Validators.minLength(1)]],
      
      });
     }


     name:string;
     copyTextpp(val: string) {
      const selBox = this.renderer.createElement('textarea');
      this.renderer.setStyle(selBox, 'position', 'fixed');
      this.renderer.setStyle(selBox, 'left', '0');
      this.renderer.setStyle(selBox, 'top', '0');
      this.renderer.setStyle(selBox, 'opacity', '0');
      const textNode = this.renderer.createText(val);
      this.renderer.appendChild(selBox, textNode);
      this.renderer.appendChild(document.body, selBox);
      selBox.select();
      document.execCommand('copy');
      this.renderer.removeChild(document.body, selBox);
    }
     /* To copy Text from Textbox */
       copyInputMessage1(inputElement){
         inputElement.select();
         document.execCommand('copy');
         inputElement.setSelectionRange(0, 0);
       }
       copyInputMessage2(){
        this.crn.select();
        document.execCommand('copy');
        this.crn.setSelectionRange(0, 0);
      }
     
     /* To copy any Text */
     copyText1(val: string){
       let selBox = document.createElement('textarea');
         selBox.style.position = 'fixed';
         selBox.style.left = '0';
         selBox.style.top = '0';
         selBox.style.opacity = '0';
         selBox.value = val;
         document.body.appendChild(selBox);
         selBox.focus();
         selBox.select();
         document.execCommand('copy');
         document.body.removeChild(selBox);
       }
     copyContent() {
      // const textToCopy = 'Your text to copy'; // Replace with the actual text you want to copy
      
      // const textarea = this.renderer.createElement('textarea');
      // this.renderer.setProperty(textarea, 'value', textToCopy);
      // this.renderer.appendChild(document.body, textarea);
      
      // textarea.select();
      // document.execCommand('copy');
      
      // this.renderer.removeChild(document.body, textarea);
      const textToCopy = 'Your text to copy'; // Replace with the actual text you want to copy

  this.copyInput.nativeElement.value = textToCopy;
  this.copyInput.nativeElement.select();
  document.execCommand('copy');
    }
    /* To copy Text from Textbox */
  copyInputMessage(inputElement){
    var temp = inputElement;
    temp.execCommand("copy");
    alert(inputElement)
    var text="ffffff"
    inputElement=text
    alert(inputElement)
    inputElement.select();
    // document.execCommand('copy');
    document.execCommand("1",true,"gfhgfghfghd")
    
    //  document.execCommand("copy", false, $temp.val());
    // (method) Document.execCommand(commandId: string, showUI?: boolean, value?: string): boolean
    inputElement.setSelectionRange(0, 0);
  }
  copyText(text: string) {
    const copyInput = document.getElementById('copyInput') as HTMLInputElement;
    copyInput.value = text;
    copyInput.select();
    window.getSelection()
    //  document.execCommand('copy');
    // document.execCommand("copy", false, copyInput.val());
  }
  ngOnInit() 
  {
    this.mmm=1
    this.imageUrl6= "http://3.110.252.18:3000/public/uploads/support/aa9008f1-6082-468a-99dc-327abc102c00.jpg"
   this.imageUrl5= "http://3.110.252.18:3000/public/uploads/support/aa9008f1-6082-468a-99dc-327abc102c00.jpg"
    //  this.imageUrl4="http://3.110.252.18:3000/public/uploads/rideAmenities/7dd70560-af04-4305-b29c-318d300dffc5.png"
    this.imagechatFlag=false;
     this.route.params.subscribe(params => {
      this.paramsid = params.id;
      this.getBookingDetails(this.paramsid)
      this.getchatlist(this.paramsid);
      // alert("hhhh")
      // alert("id...."+this.paramsid)
      // console.log("id view...",this.paramsid)
  //     this.getProfileView(this.paramsid); 
  //    this. getprovidervehicle(this.paramsid) 
  //   this. getprovidervehicledocuments(this.paramsid)
  //   this.appspinner.spinnerAlert('show');
  //   this.appspinner.spinnerAlert('hide');
  //   // this.supportTicketList('provider',this.paramsid);
  //   this.supportTicketList('provider',21);
  //  // this.getproviderridehistory(this.paramsid,this.page); 
  //  //this.getRideHistory(this.paramsid,this.page);
    });
    this. Localstoreagedata  = JSON.parse(localStorage.getItem('drivername') )
    this. providertype = JSON.parse(localStorage.getItem('providertype') )
    this.usertype = JSON.parse(localStorage.getItem('usertype') )


   
    // alert(this.Localstoreagedata)
    // alert("tickeykkkk"+this.providertype)
//     if(this.providertype== '1' && this.providertype== 'provider' )
//     {
// alert("check provider")
//     }
    
//     else{
//       alert("check user")
//     }
    this.appspinner.spinnerAlert('show');

    this.appspinner.spinnerAlert('hide');
    this.getchatlist(this.paramsid);
  }
  closeImagePreview(event) {
    // alert("ggggggg")
    // this.uploadFile4(event)
    this.imageUrl3 = null; 
    // Reset the imageUrl3 variable to remove the background image
    this.attachmentFlag=false
  }
  attachment()
  {

    
    this.attachmentFlag = true;
    setTimeout(() => {
      this.mmm+=1
      this.activeimg3.nativeElement.click();
    }, 0);
    // this.attachmentFlag=true
    // // alert("first")
    // // this.activeimg3.click()
    // this.activeimg3.nativeElement.click();

    // this.activeimg3.nativeElement.click();
    // this.attachmentFlag=true
    
    // alert("second")
  }
  
  ngOnDestroy(){
    clearInterval(this.setintervalid)
  }
  public setintervalid = setInterval(() => {
this.mmm=2
   this.getchatlist(this.paramsid);
  }, 5000);
  getchatlist(id)
  {
    
      this.appspinner.spinnerAlert('show');
      this.appspinner.spinnerAlert('hide');
      
      var data = {
        TicketId:id,
        
      }
      this.service.getChatList(data).subscribe((res) => {
        console.log("data result",res.body['data'])
        if (res.body['error']) {
          environment.swalalert('nodata', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          window.onload = function() {
            if(!window.location.hash) {
              // window.location = window.location + '#loaded';
              window.location.reload();
            }
          }
         
        this.ticketlist= new TicketDetailsModel(res.body['error'], res.body['msg'], res.body['data']);
        console.log("result ",this.ticketlist['data'])
        if (res.body['data'].length > 0 ) {
          var ss=res.body['data'].length
          
         this.imagespacelength=Number(ss)-1;
         
        //  this.pages = this.adminviewmodel['data'][0].Count;
      // alert("jjjjjjjjjjjj"+ss)
this.spaceFlag=false
          this.chatresult= this.ticketlist['data'] ;
          console.log(ss)
          console.log("sssssssssss",this.ticketlist['data'][10].Chat)
          this.index=this.imagespacelength
          if(this.ticketlist['data'][this.index].isUrl=="true")
          {
            this.spaceFlag=true
            // alert("success"+this.index)
          }
          // this.spaceFlag=false
          //       {
          // if()
          // for(var i=1;i< Number(ss);i++)
          //     {
          //       console.log("for",i)
          //       if(this.ticketlist['data'][i].isUrl=="true")
          //       {
          //         // console.log("true")
          //         // this.imageUrl4=this.ticketlist['data'][i].Chat
          //         // this.imageUrl4=String(this.ticketlist['data'][i].Chat)
          //         // this.imageToBase64(this.imageUrl4).then((base64String) => {
          //         //   this.imageUrl4 = base64String; 
          //         //   console.log("image",this.imageUrl4)// Assign the base64-encoded string to imageUrl4
          //         // });
          //         // this.imageUrl4= "http://3.110.252.18:3000/public/uploads/support/aa9008f1-6082-468a-99dc-327abc102c00.jpg"
           
          //       }
          //       this.imageUrl4=" "
          //   // alert(this.imageUrl4)
          //   // console.log("dfsfdsfdsfd",this.imageUrl4)
          //     }

        
      //  alert(this.ticketlist['data'][10].isUrl)  
          console.log("klkllkkkkkkkk",this.chatresult[0].isfrom)
        
          this.appspinner.spinnerAlert('hide');
        } else {
          environment.swalalert('nodata', 'No Data Available ');
          this.appspinner.spinnerAlert('hide');
        }
      }
      },
        (err) => {
          console.log(err);
          if (err['status'] === 401) {
            environment.swalalert('nodata', err['msg']);
           ///   localStorage.removeItem('Token');
            //  this.router.navigate(['login']);
          }
        });
  
    
  }
  uploadImage(){
    alert("sss")
  }

 
  
  getBookingDetails(id)
  {
    ////debugger
      this.appspinner.spinnerAlert('show');
      this.appspinner.spinnerAlert('hide');
      
      var data = {
        TicketId:id,
        
      }
      this.service.getBookingDetailsTicket(data).subscribe((res) => {
        console.log("data result",res.body['data'])
        if (res.body['error']) {
          environment.swalalert('nodata', res.body['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          window.onload = function() {
            if(!window.location.hash) {
              // window.location = window.location + '#loaded';
              window.location.reload();
            }
          }
         
        this.bookingdetailslist= new TicketDetailsModel(res.body['error'], res.body['msg'], res.body['data']);
        console.log("result1111 ",this.bookingdetailslist['data'].uFirstName)
       this.receiptLength=this.bookingdetailslist['data'].receipt.length
        if(this.receiptLength==4)
        {
          
this.fourflag=true
            this.Tripfield1=this.bookingdetailslist['data'].receipt[0].fieldName
        this.Tripvalue1=this.bookingdetailslist['data'].receipt[0].value
        this.Tripfield2=this.bookingdetailslist['data'].receipt[1].fieldName
        this.Tripvalue2= this.bookingdetailslist['data'].receipt[1].value
        this.Tripfield3=this.bookingdetailslist['data'].receipt[2].fieldName
        this.Tripvalue3= this.bookingdetailslist['data'].receipt[2].value
       this.Tripfield4= this.bookingdetailslist['data'].receipt[3].fieldName
       this.Tripvalue4= this.bookingdetailslist['data'].receipt[3].value
     
     
       
        }
        if(this.receiptLength==5)
        {
          
this.fiveflag=true
            this.Tripfield1=this.bookingdetailslist['data'].receipt[0].fieldName
        this.Tripvalue1=this.bookingdetailslist['data'].receipt[0].value
        this.Tripfield2=this.bookingdetailslist['data'].receipt[1].fieldName
        this.Tripvalue2= this.bookingdetailslist['data'].receipt[1].value
        this.Tripfield3=this.bookingdetailslist['data'].receipt[2].fieldName
        this.Tripvalue3= this.bookingdetailslist['data'].receipt[2].value
       this.Tripfield4= this.bookingdetailslist['data'].receipt[3].fieldName
       this.Tripvalue4= this.bookingdetailslist['data'].receipt[3].value
      this.Tripfield5= this.bookingdetailslist['data'].receipt[4].fieldName
       this.Tripvalue5=this.bookingdetailslist['data'].receipt[4].value
      //  this.Tripfield6= this.bookingsview['data'].receipt[5].fieldName
      //  this.Tripvalue6=this.bookingsview['data'].receipt[5].value
        }
        if(this.receiptLength==6)
        {
          
          this.sixflag=true
            this.Tripfield1=this.bookingdetailslist['data'].receipt[0].fieldName
        this.Tripvalue1=this.bookingdetailslist['data'].receipt[0].value
        this.Tripfield2=this.bookingdetailslist['data'].receipt[1].fieldName
        this.Tripvalue2= this.bookingdetailslist['data'].receipt[1].value
        this.Tripfield3=this.bookingdetailslist['data'].receipt[2].fieldName
        this.Tripvalue3= this.bookingdetailslist['data'].receipt[2].value
       this.Tripfield4= this.bookingdetailslist['data'].receipt[3].fieldName
       this.Tripvalue4= this.bookingdetailslist['data'].receipt[3].value
      this.Tripfield5= this.bookingdetailslist['data'].receipt[4].fieldName
       this.Tripvalue5=this.bookingdetailslist['data'].receipt[4].value
       this.Tripfield6= this.bookingdetailslist['data'].receipt[5].fieldName
       this.Tripvalue6=this.bookingdetailslist['data'].receipt[5].value
        }
        if(this.receiptLength==7)
        {
          
          this.sevenflag=true  
          this.Tripfield1=this.bookingdetailslist['data'].receipt[0].fieldName
        this.Tripvalue1=this.bookingdetailslist['data'].receipt[0].value
        this.Tripfield2=this.bookingdetailslist['data'].receipt[1].fieldName
        this.Tripvalue2= this.bookingdetailslist['data'].receipt[1].value
        this.Tripfield3=this.bookingdetailslist['data'].receipt[2].fieldName
        this.Tripvalue3= this.bookingdetailslist['data'].receipt[2].value
       this.Tripfield4= this.bookingdetailslist['data'].receipt[3].fieldName
       this.Tripvalue4= this.bookingdetailslist['data'].receipt[3].value
      this.Tripfield5= this.bookingdetailslist['data'].receipt[4].fieldName
       this.Tripvalue5=this.bookingdetailslist['data'].receipt[4].value
       this.Tripfield6= this.bookingdetailslist['data'].receipt[5].fieldName
       this.Tripvalue6=this.bookingdetailslist['data'].receipt[5].value
         this.Tripfield7= this.bookingdetailslist['data'].receipt[6].fieldName
        this.Tripvalue7=this.bookingdetailslist['data'].receipt[6].value
        }
      
        // this.Tripfield1=this.bookingdetailslist['data'].receipt[0].fieldName
        //   this.Tripvalue1=this.bookingdetailslist['data'].receipt[0].value

        //     this.Tripvalue4= this.bookingdetailslist['data'].receipt[3].value
        //     this.Tripfield4= this.bookingdetailslist['data'].receipt[3].fieldName
          
        //    this.Tripfield2=this.bookingdetailslist['data'].receipt[1].fieldName
        //    this.Tripvalue2= this.bookingdetailslist['data'].receipt[1].value

        //    this.Tripfield3=this.bookingdetailslist['data'].receipt[2].fieldName
        //    this.Tripvalue3= this.bookingdetailslist['data'].receipt[2].value
           

        //    this.Tripfield5= this.bookingdetailslist['data'].receipt[4].fieldName
        //    this.Tripvalue5=this.bookingdetailslist['data'].receipt[4].value

this.uFirstName=this.bookingdetailslist['data'].pFirstName;

this.pLastName=this.bookingdetailslist['data'].pLastName;
this.pMobile=this.bookingdetailslist['data'].pMobile;
this.uMobile=this.bookingdetailslist['data'].uMobile;
this.waitingTime=this.bookingdetailslist['data'].waitingTime;
this.uEmail=this.bookingdetailslist['data'].uEmail;
this.uLastName=this.bookingdetailslist['data'].uLastName;
this.bookingId=this.bookingdetailslist['data'].Id;
this.FromLocation=this.bookingdetailslist['data'].FromLocation;
this.ToLocation=this.bookingdetailslist['data'].ToLocation;
this.Distance=this.bookingdetailslist['data'].Distance;
this.TotalAmount=this.bookingdetailslist['data'].TotalAmount;
this.RideName=this.bookingdetailslist['data'].RideName;
this.VehicleName=this.bookingdetailslist['data'].VehicleName;
this.vehicleid=this.bookingdetailslist['data'].vehicleNumber;
this.sendStatus=this.bookingdetailslist['data'].tStatus;
// alert("status"+this.sendStatus)
// "Color": "Blue",
//         "VehicleBrandName": "Audi",
//         "VehicleModelName": "RS Q8"
// alert(this.bookingdetailslist['data'].Color)
this.VehicleColor=this.bookingdetailslist['data'].Color;
this.VehicleBrandName=this.bookingdetailslist['data'].VehicleBrandName;
this.VehicleModelName=this.bookingdetailslist['data'].VehicleModelName;
this.Ridename=this.bookingdetailslist['data'].RideName;
this.driverid=this.bookingdetailslist['data'].Id;

this.cancellationCharge=this.bookingdetailslist['data'].cancellationCharge;
this.pay=this.bookingdetailslist['data'].receipt[0].value
this.total=this.bookingdetailslist['data'].receipt[1].value
this.round=this.bookingdetailslist['data'].receipt[2].value
this.crn=this.bookingdetailslist['data'].orderReferenceId
// alert(this.bookingdetailslist['data'].orderReferenceId)
this.round1=this.bookingdetailslist['data'].receipt[5].value
console.log("ffffffff",this.pay)
// this.date1=this.bookingdetailslist['data'].date;
this.date1=this.bookingdetailslist['data'].CreateAt;
var datePipe = new DatePipe("en-US");
// this.date2 = datePipe.transform(this.date1, 'h:mm a');
this.date3=this.bookingdetailslist['data'].UpdateAt
this.date4=datePipe.transform(this.date1, ' h:mm a');
this.date5=datePipe.transform(this.date3, ' h:mm a');

this.date2 = datePipe.transform(this.date1, 'EEE , MMMM d, h:mm a');
//  this.date2 = datePipe.transform(this.date1, 'y,MMMMEEEEd, h:mm a');


this.bookingdetail= this.bookingdetailslist['data'] ;
      console.log("length",  this.bookingdetail)
        // console.log("testing1111",this.bookingdetailslist.uFirstName)
        // console.log("testing1111",this.bookingdetail)
        // if (res.body['data'].length > 0 ) {
          if ( this.bookingdetailslist['data'].length > 0 ) {  
            
        //  this.pages = this.adminviewmodel['data'][0].Count;
      // alert("jjjjjjjjjjjj")
          // this.bookingdetail= this.bookingdetailslist['data'] ;
          console.log("testing1111",this.bookingdetail)
          console.log("testing data of booking ticket1111",this.bookingdetail.uFirstName)
        
          this.appspinner.spinnerAlert('hide');
        } else {
          environment.swalalert('nodata', 'No Data Available ');
          this.appspinner.spinnerAlert('hide');
        }
      }
      },
        (err) => {
          console.log(err);
          if (err['status'] === 401) {
            environment.swalalert('nodata', err['msg']);
           ///   localStorage.removeItem('Token');
            //  this.router.navigate(['login']);
          }
        });
  
    
  }
  // promocodesAdd() {

  //   if (this.promocodesAddForm.valid) {



  //     var sF = this.promocodesAddForm.value;

  //     console.log(Date.parse(sF.validfrom));
  //     console.log(Date.parse(sF.validto));
  //     if (Date.parse(sF.validfrom) > Date.parse(sF.validto)) {
  //       environment.swalalert('error', 'Please enter a valid expiry date')
  //     } else {

  //     var status = '';
  //     if (sF.IsActive === true) {
  //       status += 'Active';
  //     } else {
  //       status += 'InActive';
  //     }
  //     // console.log(sF.validfrom.getFullYear() + "-" + sF.validfrom.getMonth() + 1 + "-" +sF.validfrom.getDate());
  //     this.promovtype = new PromoCodeAddModel(
  //       sF.name,
  //       sF.coupon,
  //       sF.discount,
  //       sF.discounttype,
  //       100,
  //       sF.minvalue,
  //       sF.maxvalue,
  //       this.formatDate(sF.validfrom),
  //       this.formatDate(sF.validto),
  //       'multiple',
  //       status,
  //       sF.description
  //     );
  //          console.log("FormData:::",this.promovtype);
      
  //     this.service.promocodesAdd(this.promovtype).subscribe(res => {
  //       if (res['error']) {
  //         environment.swalalert('nodata', res['msg']);
  //         this.appspinner.spinnerAlert('hide');
  //       } else {
  //         if (res.body['error'] === false) {
  //           environment.swalalert('success', res.body['msg']).then(value => {
  //             if (value) {
  //               this.promocodesAddForm.reset();
  //               this.router.navigate(['promoCodes']);
  //             }
  //           });
  //         } else {
  //           environment.swalalert('error', res.body['msg']);
  //         }
  //       }
  //     });
  //   }
  //   }
  //   else {
  //     environment.swalalert('warning', 'Validation Required');
  //   }
  // }
  // cancelpromocodesAdd()
  // {
  //   this.promocodesAddForm.reset();
  //   this.router.navigate(['promoCodes']);
  // }
  // addingRoles()
  // {
  //   ////debugger
  //     var data = {
  //       RoleName : this.roleName,
  //       Navigation : JSON.stringify(this.permissionArray)
  //     }
  //     this.service.addroles(data).subscribe(res => {
  //       if (res['error']) {
  //         environment.swalalert('nodata', res['msg']);
  //         this.appspinner.spinnerAlert('hide');
  //       } else {
       
  //       if(res.body['error'] === false ) {
  //         ////debugger
         
          
  //         window.onload = function() {
  //           if(!window.location.hash) {
             
  //             window.location.reload();
  //           }
  //         }
    
     
  //         environment.swalalert('success',res.body['msg']).then(value => {

           

          


           
          
  //           if(value) {
       
          
  //           }
  //           });
  //       } else {
  //         environment.swalalert('error',res.body['msg']);
  //       }
  //     }
  //     });


  // }
  imagefileupload24(data : any) {
    ////debugger
    // alert("kkkkkkk")
      return new Promise<any>((resolve, reject) => {
        this.service.fileupload(data.file, data.type).subscribe((res) => {
          if (res['error']) {
            environment.swalalert('nodata', res['msg']);
            this.appspinner.spinnerAlert('hide');
          } else {
          this.ret = res.body['data'];
          resolve(res.body['data']);
        }
        },
          (err) => {
            console.log(err);
            reject(err);
          })
      })
    }
    async chatSend()
    {

      this.activeimagefile3 = this.activeimg3.nativeElement.files;
      // var sF = this.promocodesAddForm.value;
      // sF
      // var ss=sF.name
      // // alert( "fhfhgfhgfg"+sF.name)
      // // alert("hello"+ss)
      // ////debugger
      // var text=sF.name.trim();
  //     if(!text)
  //     {
  //       // alert(text)
  // environment.swalalert('warning','Please Enter The Message')
  //     }
      // else{
  // alert(text)
      
        // var data = {
        //   TicketId:1,
        //   chat:ss
        // }
        this.IconActive3 = await this.imagefileupload24({file: this.activeimagefile3, type: 'support' });
        // alert(this.IconActive3)
        var data = {
          TicketId:this.paramsid,
           chat:this.IconActive3,
           isUrl:"true"
        }
        this.service.addchat(data).subscribe(res => {
          if (res['error']) {
            environment.swalalert('nodata', res['msg']);
            this.appspinner.spinnerAlert('hide');
          } else {
         
          if(res.body['error'] === false ) {
            ////debugger
            this.getchatlist(this.paramsid);
            this.imageUrl3=" "
         this.promocodesAddForm.reset()
            
            // window.onload = function() {
            //   if(!window.location.hash) {
            //   //  this.getchatlist();
            //     window.location.reload();
            //   }
            // }
      
       
        //     environment.swalalert('success',res.body['msg']).then(value => {
        //       // this.getchatlist();
  
             
  
            
  
  
             
            
        //       if(value) {
                
        //  this.getchatlist(this.paramsid);
        //  this.promocodesAddForm.reset()
            
        //       }
        //       });
          } else {
            environment.swalalert('error',res.body['msg']);
          }
        }
        });
  
      // }
    }
     chatSend1()
  {
////debugger
    // this.activeimagefile3 = this.activeimg3.nativeElement.files;
    var sF = this.promocodesAddForm.value;
    
    var ss=sF.name
    // alert( "fhfhgfhgfg"+sF.name)
    // alert("hello"+ss)
    
    
    
    if(this.attachmentFlag==true){
     
      this.chatSend()
      this.attachmentFlag=false
    }
   else{
     var text=sF.name.trim();
    if(!text)
    {
      // alert(text)
environment.swalalert('warning','Please Enter The Message')
    }
    else{
// alert(text)

      // var data = {
      //   TicketId:1,
      //   chat:ss
      // }
      // this.IconActive3 = await this.imagefileupload24({file: this.activeimagefile3, type: 'rideAmenities' });
      var data = {
        TicketId:this.paramsid,
        chat:text,
        isUrl:"false"
      }
      
      this.service.addchat(data).subscribe(res => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
       
        if(res.body['error'] === false ) {
          ////debugger
          this.getchatlist(this.paramsid);
       this.promocodesAddForm.reset()
          
          // window.onload = function() {
          //   if(!window.location.hash) {
          //   //  this.getchatlist();
          //     window.location.reload();
          //   }
          // }
    
     
      //     environment.swalalert('success',res.body['msg']).then(value => {
      //       // this.getchatlist();

           

          


           
          
      //       if(value) {
              
      //  this.getchatlist(this.paramsid);
      //  this.promocodesAddForm.reset()
          
      //       }
      //       });
        } else {
          environment.swalalert('error',res.body['msg']);
        }
      }
      });

    }
  }



  }
 
  ridevehicletypeEdit()
  {

  }
  sendInvoice()
  {

    var sF = this.promocodesAddForm.value;
    sF
    // var ss=sF.name
    var id=this.paramsid;
    // var ss=id
    var ss=this.bookingId;
    // alert( "fhfhgfhgfg"+sF.name)
    // alert("hello"+ss)
    ////debugger
      var data = {
       Id:ss
      }
      this.service.sendInvoice(data).subscribe(res => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
       
        if(res.body['error'] === false ) {
          ////debugger
         
          
          // window.onload = function() {
          //   if(!window.location.hash) {
          //   //  this.getchatlist();
          //     window.location.reload();
          //   }
          // }
    
    //  this.getchatlist(this.paramsid);
          environment.swalalert('success',res.body['msg']).then(value => {

           

          


           
          
            if(value) {
      //  this.getchatlist(this.paramsid);
          
            }
            });
        } else {
          environment.swalalert('error',res.body['msg']);
        }
      }
      });


  }
  uploadFile3(event) {

    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      // File selected, handle it here
      const file = files[0];
      console.log('File selected:', file);
    //       let reader = new FileReader(); // HTML5 FileReader API
    // let file1 = event.target.files[0];

this.sample43568(file)

      //  reader.readAsDataURL(file);

      // // When file uploads set it to file formcontrol
      // reader.onload = () => {
      //   this.imageUrl3 = reader.result;
      //   // this.registrationForm.patchValue({
      //   //   file: reader.result
      //   // });
      //   this.editFile = false;
      //   this.removeUpload = true;
      // }
      // // ChangeDetectorRef since file is loading outside the zone
      // this.cd.markForCheck();        
    // }
  // }
    // console.log("fgffdgfdfg")
    // console.log(event.target.files[0])
    } 
    else {
alert("fffffff")

    this.imageUrl3 = null; 
      // Reset the imageUrl3 variable to remove the background image
      this.attachmentFlag=false
    //  console.log("full")
    // this.attachmentFlag=false
      // The imageUrl3 is not empty
      // Add your logic here for when the image URL is not empty




      this.attachmentFlag=false
      // File selection dialog canceled
      console.log('File selection canceled');
      // You can handle the cancel event here
      // For example, show an alert or perform any other action
      // window.removeEventListener('focus', this.onFileDialogCancel);
      // window.addEventListener('focus', this.onFileDialogCancel);

    }






  //   // if (event.target.files && event.target.files[0]) {
  //   let reader = new FileReader(); // HTML5 FileReader API
  //   let file = event.target.files[0];
  //   // console.log("fgffdgfdfg")
  //   // console.log(event.target.files[0])
   
  //  // if (file) {
  //    if (event.target.files && event.target.files[0]) {
    
  //     reader.readAsDataURL(file);

  //     // When file uploads set it to file formcontrol
  //     reader.onload = () => {
  //       this.imageUrl3 = reader.result;
  //       // this.registrationForm.patchValue({
  //       //   file: reader.result
  //       // });
  //       this.editFile = false;
  //       this.removeUpload = true;
  //     }
  //     // ChangeDetectorRef since file is loading outside the zone
  //     this.cd.markForCheck();        
  //   }
  // // }
  //   else if(!event.target.files[0]) {
     
  //     this.imageUrl3 = null; 
  //     // Reset the imageUrl3 variable to remove the background image
  //     this.attachmentFlag=false
  //   //  console.log("full")
  //   // this.attachmentFlag=false
  //     // The imageUrl3 is not empty
  //     // Add your logic here for when the image URL is not empty
  //   }
  }
  sample43568(file:File)
  { 
    let reader = new FileReader();
    reader.readAsDataURL(file);

    // When file uploads set it to file formcontrol
    reader.onload = () => {
      this.imageUrl3 = reader.result;
      // this.registrationForm.patchValue({
      //   file: reader.result
      // });
      this.editFile = false;
      this.removeUpload = true;
    }
    // ChangeDetectorRef since file is loading outside the zone
    this.cd.markForCheck();

  }
  uploadFile4(event) {
    // let reader = new FileReader(); // HTML5 FileReader API
    // let file = event.target.files[0];
    // if (event.target.files && event.target.files[0]) {
    //   reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      // reader.onload = () => {
        this.imageUrl3 = " "
        // this.registrationForm.patchValue({
        //   file: reader.result
        // });
    //     this.editFile = false;
    //     this.removeUpload = true;
    //   }
    //   // ChangeDetectorRef since file is loading outside the zone
    //   this.cd.markForCheck();        
    // }
  }
  closeChat()
  {


    var sF = this.promocodesAddForm.value;
    sF
    // var ss=sF.name
    // alert( "fhfhgfhgfg"+sF.name)
    // alert("hello"+ss)
    ////debugger
    var ss="user"
      var data = {
        TicketId:this.paramsid,
        type:this.providertype
      }
      this.service.closeChat(data).subscribe(res => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
       
        if(res.body['error'] === false ) {
          ////debugger
         
          
          // window.onload = function() {
          //   if(!window.location.hash) {
          //   //  this.getchatlist();
          //     window.location.reload();
          //   }
          // }
    
    //  this.getchatlist();
          environment.swalalert('success',res.body['msg']).then(value => {

           

          


           
          
            if(value) {
      //  this.getchatlist();
          
            }
            });
            this.closeFlag=true;
        } else {
          environment.swalalert('error',res.body['msg']);
        }
      }
      });


    
  }
  closeInvoice()
  {
    ////debugger
    this.usertype = JSON.parse(localStorage.getItem('usertype') )
    if(this.usertype==="user")
    {
    this.router.navigate(['Users']);
    }
    else if(this.usertype==="provider")
    {
      this.router.navigate(['Providers']);
   
    }
    else
    {
      this.router.navigate(['supportlist']);
    }


    
  }



  // Function to convert image to base64 string
 imageToBase64(imageUrl) {
  return fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}



openImageInNewWindow(imageUrl: string) {
  window.open(imageUrl, '_blank');
}




}
