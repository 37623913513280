import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  url = environment.Url;
  iconimgurl = environment.IconUrl;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,
    private cookie: CookieService) { }

 //report codes add
  reportcodesAdd(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/reportAdd`, data, {
      headers: httpheaders,
      observe: 'response'
    });
  }



  // generateReportView(data) {
  //   const httpheaders = new HttpHeaders({
  //     'Content-Type': [],
  //     'Accept': 'application/json',
  //     // 'Token': this.cookie.get('Token')
  //     'Token': localStorage.getItem('Token')
  //   });


  //   return this.http.post(`${this.url}/getReportView/${data}`,  {
  //     headers: httpheaders,
  //     observe: 'response'
  //   });
  // }
  generateReportView(data) {
      const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
  ////debugger
    return this.http.post(`${this.url}/getReportView`, data,  {
      headers: httpheaders,
      observe: 'response'
    });
  } 

  bookingsearchdataView(name) {
    ////debugger
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
      headers: httpheaders
    })
      .map((res: Response) => {
        return res;
      })
      .catch(this.handleError);
  } 
  // reportSearchView(name) {
  //   ////debugger
  //   const httpheaders = new HttpHeaders({
  //     'Content-Type': [],
  //     'Accept': 'application/json',
  //     // 'Token': this.cookie.get('Token')
  //     'Token': localStorage.getItem('Token')
  //   });
  //   // return this.http.get(`${this.url}/reportSearchView /${JSON.stringify(name)}`, {
  //   //   return this.http.get(`${this.url}/reportSearchView`, name, {
        
      
  //   //   headers: httpheaders
  //   // })
  //   return this.http.post(`${this.url}/getReportView`, name,  {
  //     headers: httpheaders,
  //     observe: 'response'
  //   });
  //     .map((res: Response) => {
  //       return res;
  //     })
  //     .catch(this.handleError);
  // }
  reportSearchView(data) {
    const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
////debugger
  return this.http.post(`${this.url}/reportSearchView`, data,  {
    headers: httpheaders,
    observe: 'response'
  });
} 
    
}


