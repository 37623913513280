import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pushnotifications',
  templateUrl: './pushnotifications.component.html',
  styleUrls: ['./pushnotifications.component.css']
})
export class PushnotificationsComponent implements OnInit {
  promocodesEditForm:any;

  constructor() { }

  ngOnInit() {
  }
  promocodesEdit()
  {

  }
  getDoctypeView(status,page){

  }
}
