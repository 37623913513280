import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class addadminservies {
  url = environment.Url;
  constructor(private http:HttpClient) { }
 //viewing admin view by NK
 getAdminListView(page,status){
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
   //  'Token': this.cookie.get('Token')
   'Token': localStorage.getItem('Token')
  });
  return this.http.get(`${this.url}/orgUserListView/${page}/${status}`,{
   
    headers: httpheaders,
    observe: 'response'
  });
}
  addadmin(data){
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
     //  'Token': this.cookie.get('Token')
     'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/addingAdmin`,data,{
      headers: httpheaders,
      observe: 'response'
    });
  }

 //viewing admin view by NK
 getRolesListView(){
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
   //  'Token': this.cookie.get('Token')
   'Token': localStorage.getItem('Token')
  });
  return this.http.get(`${this.url}/viewRole`,{
    headers: httpheaders,
    observe: 'response'
  });
}

}