import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class deliveryBoyZonesService {

  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  url = environment.Url;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,
    private cookie: CookieService) { }
    deliveryboyzoneStatusUpdate(data){
      return this.http.post(`${this.url}/updateZoneStatus`, data, {
        headers: this.httpheaders,
        observe: 'response'
      });
    }
    addZone(data) {
     
      return this.http.post(`${this.url}/addNewZone`, data, {
        headers: this.httpheaders,
        observe: 'response'
      });
    }
    editZone(data){
      return this.http.post(`${this.url}/editZone`, data, {
        headers: this.httpheaders,
        observe: 'response'
      });
    }
    listOfZones(data){
      return this.http.post(`${this.url}/listOfZones`, data, {
        headers: this.httpheaders,
        observe: 'response'
      });
    }
    
    deleteZone(data){
      return this.http.post(`${this.url}/deleteZone`, data, {
        headers: this.httpheaders,
        observe: 'response'
      });
    }
}


