import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute,RouterLinkActive } from '@angular/router';
import { GuardGuard } from '../guard.guard';

import { from } from 'rxjs';
import { EncryService } from '../encry.service';

import { environment } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../login/login.service';
import { ValidationService } from '../login/validation.service';
@Component({
  selector: 'app-emailforotp',
  templateUrl: './emailforotp.component.html',
  styleUrls: ['./emailforotp.component.css']
})
export class EmailforotpComponent implements OnInit {

  returnUrl:string;
  emailForm:any;
  constructor(
    private formBuilder:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private guard:GuardGuard,
    private encry:EncryService,
    private service:LoginService,
    private location: Location,
    private appspinner: AppComponent,
    private cookie: CookieService) {   
      this.emailForm = this.formBuilder.group({
        // 'email' : ['',[Validators.required,ValidationService.emailValidator]]
        'email' : ['']
      });
    }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['return'] || 'dashboard'; 
    
    console.log("Token::") ;

    console.log("Token::",this.cookie.get('Token')) ;
    if (localStorage.getItem('Token')) {
      this.router.navigateByUrl(this.returnUrl);
    }           
  }
  // sendotp(){


  //   // if(this.emailForm.get('email').invalid)
  //   // {
  //     alert("fffffff")
  //   // }
  // }

  // sendotp(){
  //   if(this.emailForm.dirty && this.emailForm.valid) {
  //     var data = {Email:this.emailForm.value.email};  
  //   alert(data )   
  //     this.service.forgotPwdOtp(data).subscribe(async res => {
  //       if(res.body['error'] === false ) {
  //         console.log(res)
       
  //           environment.swalalert('success',res.body['msg']).then(value => {
  //           if(value) {
  //             sessionStorage.setItem('otpemail',this.emailForm.value.email)
  //             this.router.navigateByUrl('otp');
  //           }
  //           });
  //       } 
  //       // else if (this.emailForm.pristine) {
         
  //       //     environment.swalalert('warning','Enter The  Email Id And Password');
  //       //   } 
  //       else {
  //       environment.swalalert('error',res.body['msg']);
  //       }
  //     });
  //   }     
  //    else {
  //     environment.swalalert('warning','Validation');
  //     }
  // }
  // sendotp() {
  //   // if (this.emailForm.dirty && this.emailForm.valid) {
  //     var aa=1
  //     if(aa==1)
  //     {
  //     alert(this.emailForm.value.email.trim())
  //     // Trim and check if the email field is empty or contains only white spaces
      
  //     if (this.emailForm.value.email.trim() === '') 
  //     {
  //       environment.swalalert('warning', 'Email field is empty');
  //     }
      
  //     else {
  //       var data = { Email: this.emailForm.value.email };
        
  //       this.service.forgotPwdOtp(data).subscribe(async (res) => {
  //         if (res.body['error'] === false) {
  //           console.log(res);
  
  //           environment.swalalert('success', res.body['msg']).then((value) => {
  //             if (value) {
  //               sessionStorage.setItem('otpemail', this.emailForm.value.email);
  //               this.router.navigateByUrl('otp');
  //             }
  //           });
  //         } else {
  //           environment.swalalert('error', res.body['msg']);
  //         }
  //       });
  //     }
  //   } 
  //   else {
  //     environment.swalalert('warning', 'Validation');
  //   }
  // }
  sendotp() {
    // if (this.emailForm.dirty && this.emailForm.valid) {
    var aa = 1;
    if (aa == 1) {
      console.log("Email Value:", this.emailForm.value.email); // Debug line
  
      // Trim and check if the email field is empty or contains only white spaces
      const trimmedEmail = this.emailForm.value.email.trim();
      console.log("Trimmed Email Value:", trimmedEmail); // Debug line
  
      if (trimmedEmail === '') {
        environment.swalalert('warning', 'Email field is empty');
      } else {
        var data = { Email: trimmedEmail };
  
        this.service.forgotPwdOtp(data).subscribe(async (res) => {
          if (res.body['error'] === false) {
            console.log(res);
  
            environment.swalalert('success', res.body['msg']).then((value) => {
              if (value) {
                sessionStorage.setItem('otpemail', trimmedEmail);
                this.router.navigateByUrl('otp');
              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        });
      }
    } else {
      environment.swalalert('warning', 'Validation');
    }
  }
  

}
