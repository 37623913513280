import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticketview',
  templateUrl: './ticketview.component.html',
  styleUrls: ['./ticketview.component.css']
})
export class TicketviewComponent implements OnInit {

  constructor() 
  { 

  }

  ngOnInit() {
  }
  ridevehicletypeEdit()
  {
    
  }
}
