import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ViewGroupModel } from './viewgroup.model';
@Injectable({
  providedIn: 'root'
})
export class ViewgroupService {
  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  url = environment.Url;
  iconimgurl = environment.IconUrl;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
}
  constructor(private http: HttpClient,
    private cookie: CookieService) { }

 //report codes add
  GroupAdd(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/reportAdd`, data, {
      headers: httpheaders,
      observe: 'response'
    });
  }
  pushviewListView(page,id) {
   // return this.http.get(`${this.url}/usersPushNotificationView/${page}/${id}`,  {
   
    return this.http.get(`${this.url}/usersPushNotificationViewByGroupId/${page}/${id}`,  {
    
      headers: this.httpheaders,
      observe: 'response'
    });
  } 

  pushproviderviewListView(page,id) {
    ////debugger
    // providerPushNotificationViewByGroupId/1/67
    // return this.http.get(`${this.url}/providersPushNotificationView/${page}/${id}`,  {
      return this.http.get(`${this.url}/providerPushNotificationViewByGroupId/${page}/${id}`,  { 
    headers: this.httpheaders,
      observe: 'response'
    });
  } 


  userPushNotificationSend(data) {
    return this.http.post(`${this.url}/createNotificationGroup`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
 // push notification send provider
 groupView(id) {
  return this.http.get(`${this.url}/getNotificationGroupView/${id}`, {
    headers: this.httpheaders,
    observe: 'response'
  });
}
// Group Edit
groupEdit(data){
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
   return this.http.post(`${this.url}/groupStatusUpdate `,data,{
     headers: httpheaders,
     observe: 'response'
   });
 }
 

 pushproviderviewList(type,page) {
   
  ////debugger
  return this.http.get(`${this.url}/getNotificationListView/${type}/${page}`,  {
    headers: this.httpheaders,
    observe: 'response'
  });
 // return this.http.post(`${this.url}/getNotificationListView/${page}`,{type:type},{
    // return this.http.post(`${this.url}/getNotificationListView/${type}`,{page},{
  //     return this.http.get(`${this.url}/getNotificationListView/${type}/${page}`,{
  //   headers: httpheaders,
  //   observe: 'response'
  // })

}
usersearchdataView(name): Observable<ViewGroupModel[]> {
  return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
    headers: this.httpheaders
  })
    .map((res: Response) => {
      return <ViewGroupModel[]><unknown>res;
    })
    .catch(this.handleError);
} 

 // provider search data List View
 providersearchdataView(name): Observable<ViewGroupModel[]> {
  return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
    headers: this.httpheaders
  })
    .map((res: Response) => {
      return <ViewGroupModel[]><unknown>res;
    })
    .catch(this.handleError);
}  



providerFilterListView(data) {
  return this.http.post(`${this.url}/providerFilterGroup`,data,{
    headers: this.httpheaders,
    observe: 'response'
  });
  

}


userFilterListView(data) {
  return this.http.post(`${this.url}/userFilterGroup`,data,{
    headers: this.httpheaders,
    observe: 'response'
  });

}


providerListView(page,status) {
  return this.http.get(`${this.url}/providerListView/${page}/${status}`,  {
    headers: this.httpheaders,
    observe: 'response'
  });
} 




usersListView(page,status) {
  return this.http.get(`${this.url}/userListView/${page}/${status}`,  {
    headers: this.httpheaders,
    observe: 'response'
  });
} 



}


