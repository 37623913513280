import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CreateGroupDataModel } from './creategroupdata.model';
@Injectable({
  providedIn: 'root'
})
export class CreategroupService {
  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  url = environment.Url;
  iconimgurl = environment.IconUrl;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
}
  constructor(private http: HttpClient,
    private cookie: CookieService) { }

 //report codes add
  GroupAdd(data) {
    const httpheaders = new HttpHeaders({
      'Content-Type': [],
      'Accept': 'application/json',
      // 'Token': this.cookie.get('Token')
      'Token': localStorage.getItem('Token')
    });
    return this.http.post(`${this.url}/reportAdd`, data, {
      headers: httpheaders,
      observe: 'response'
    });
  }
  pushviewListView(page) {
    return this.http.get(`${this.url}/usersPushNotificationView/${page}`,  {
      headers: this.httpheaders,
      observe: 'response'
    });
  } 

  pushproviderviewListView(page) {
    ////debugger
    return this.http.get(`${this.url}/providersPushNotificationView/${page}`,  {
      headers: this.httpheaders,
      observe: 'response'
    });
  } 


  userPushNotificationSend(data) {
    return this.http.post(`${this.url}/createNotificationGroup`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
 // push notification send provider
 providerPushNotificationSend(data) {
  return this.http.post(`${this.url}/providerPushNotificationSend`, data, {
    headers: this.httpheaders,
    observe: 'response'
  });
}
usersearchdataView(name): Observable<CreateGroupDataModel[]> {
  return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
    headers: this.httpheaders
  })
    .map((res: Response) => {
      return <CreateGroupDataModel[]><unknown>res;
    })
    .catch(this.handleError);
} 

 // provider search data List View
 providersearchdataView(name): Observable<CreateGroupDataModel[]> {
  return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
    headers: this.httpheaders
  })
    .map((res: Response) => {
      return <CreateGroupDataModel[]><unknown>res;
    })
    .catch(this.handleError);
}     

providerFilterListView(data) {
  return this.http.post(`${this.url}/providerFilterGroup`,data,{
    headers: this.httpheaders,
    observe: 'response'
  });

}


userFilterListView(data) {
  return this.http.post(`${this.url}/userFilterGroup`,data,{
    headers: this.httpheaders,
    observe: 'response'
  });

}


providerListView(page,status) {
  return this.http.get(`${this.url}/providerListView/${page}/${status}`,  {
    headers: this.httpheaders,
    observe: 'response'
  });
} 




usersListView(page,status) {
  return this.http.get(`${this.url}/userListView/${page}/${status}`,  {
    headers: this.httpheaders,
    observe: 'response'
  });
} 

}


