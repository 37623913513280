export class OrgPeakModel {
    
    public ZoneName: String;
    public StartTime:String;
    public EndTime:String;
    public Day: String;
    public latitude:String;
    public longitude:String;
    public Id:number;
    // public zoneType: String;
    // public startTime:String;
    // public endTime:String;
    // public dayRange: String;
    // public latitude:String;
    // public longitude:String;
    // public Id:number;
    constructor( 
        
        ZoneName: String,
        StartTime:String,
        EndTime:String,
        Day: String,            
        latitude:String,
        longitude:String,
        Id:number,
                ) {
                   
                   this.ZoneName=ZoneName;
                   this.StartTime=StartTime;
                   this.EndTime=EndTime;
                   this.Day=Day;
                   this.latitude=latitude;
                   this.longitude=longitude;
                   this.Id=Id;
                    
    }
}