import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { CookieService } from 'ngx-cookie-service';
import { GroupList } from './supportlistlist.model';
import { GroupModel } from './supportlist.model';
//import { PushNotificationViewList } from './pushnotificationview.model';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  url = environment.Url;
  chatUrl=environment.chatUrl;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,
    private cookie: CookieService) { }



// pushproviderviewList(type,page) {
//     return this.http.get(`${this.url}/getNotificationListView/${page}`,  {
//       headers: this.httpheaders,
//       observe: 'response'
//     });
//   } 
supportSearch(data) {
  ////debugger
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  // return this.http.post(`${this.url}/deleteAdmin`,data,{
  // return this.http.post(`${this.url}/getSupportTicketListView`,'provider', {
    return this.http.post(`${this.url}/commonSearchViewPage`,data, {
    headers: httpheaders,
    observe: 'response'
  });
}
// Email search data List View
// groupsearchdataView(search,type,page): Observable<GroupList[]> {
  // groupsearchdataView(name): Observable<GroupList[]> {
    groupsearchdataView(name): Observable<GroupModel[]> {
  // usersearchdataView(name): Observable<UserModelList[]> {
  //return this.http.get(`${this.url}/searchNotificationGroup/${search}/${type}/${page}`, {
    // return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
      return this.http.get(`${this.url}/commonSearchViewPage/${JSON.stringify(name)}`, {
      
    headers: this.httpheaders
  })
    .map((res: Response) => {
      // return <GroupList[]><unknown>res;
      return <GroupModel[]><unknown>res;
    })
    .catch(this.handleError);

}  

supportTicketListType(data) {
  ////debugger
  const httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  // return this.http.post(`${this.url}/deleteAdmin`,data,{
  // return this.http.post(`${this.url}/getSupportTicketListView`,'provider', {
    return this.http.post(`${this.chatUrl}/getSupportTicketListView`,data, {
    headers: httpheaders,
    observe: 'response'
  });
}
// supportTicketListType(type){
//   ////debugger
//   return this.http.post(`${this.url}/getSupportTicketListView`,type,{
//     headers: this.httpheaders,
//     observe: 'response'
//   });
// } 

//   pushproviderviewList(type,page) {
   
//     ////debugger
//     return this.http.get(`${this.url}/getNotificationListView/${type}/${page}`,  {
//       headers: this.httpheaders,
//       observe: 'response'
//     });



// //     http://3.110.252.18:3000/api/chat/getSuppportTicketListView/:type
// // get method
// // param:-type user or provider


//    // return this.http.post(`${this.url}/getNotificationListView/${page}`,{type:type},{
//       // return this.http.post(`${this.url}/getNotificationListView/${type}`,{page},{
//     //     return this.http.get(`${this.url}/getNotificationListView/${type}/${page}`,{
//     //   headers: httpheaders,
//     //   observe: 'response'
//     // })

//   }


supportFilterListView(data) {
  
  return this.http.post(`${this.chatUrl}/supportFilterListView`,data,{
    headers: this.httpheaders,
    observe: 'response'
  });
}
  pushproviderviewList(type) {
   
    ////debugger
    return this.http.get(`${this.url}/getSuppportTicketListView/${type}`,  {
      headers: this.httpheaders,
      observe: 'response'
    });



//     http://3.110.252.18:3000/api/chat/getSuppportTicketListView/:type
// get method
// param:-type user or provider


   // return this.http.post(`${this.url}/getNotificationListView/${page}`,{type:type},{
      // return this.http.post(`${this.url}/getNotificationListView/${type}`,{page},{
    //     return this.http.get(`${this.url}/getNotificationListView/${type}/${page}`,{
    //   headers: httpheaders,
    //   observe: 'response'
    // })

  }
}
// pushviewListView(page) {
//   return this.http.get(`${this.url}/usersPushNotificationView/${page}`,  {
//     headers: this.httpheaders,
//     observe: 'response'
//   });
// }