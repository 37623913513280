import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import * as $ from "../../../assets/js/jquery.3.2.1.min.js";
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { from } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ScrollEvent } from 'ngx-scroll-event';
import { GroupModel } from './supportlist.model';
import{GroupService} from'./supportlist.service';
import { sample } from 'rxjs-compat/operator/sample.js';

declare var $;
@Component({
  selector: 'app-supportlist',
  templateUrl: './supportlist.component.html',
  styleUrls: ['./supportlist.component.css']
})
export class SupportListComponent implements OnInit {
  typeview: GroupModel;
  typeview1:GroupModel;
  result: any;
  type1:any;
  page: number = 1;
  pages: any;
  search:any;
  typeglobal:any;
  searchtypeglobal:String;
  noDataFlag:Boolean=false;
  currentTab:String='user';
  @ViewChild('dataTable') table;
  dataTable: any;
  dtOptions: any;
  typeGlobalPage:Number;
  searchFlag:Boolean=false;
  searchdata:String;
  selectedOption1: string=' '; 
  filterFlag:Boolean=false;
  searchflag:Boolean=true;
  
  noDataFlag1:Boolean=false;




   ////////////////////////
   searchStatus:String;
   statusGlobal:String;
   startdate:String;
   enddate:String;
   fulldate:String;
   filterkeyFlag:Boolean=false;
   isMenuOpen = false;
   dateFlag:Boolean=false;
   TimeintervalFlag:Boolean=false;
  // selectedOption1: string; 
  // searchflag:Boolean=true;
  // noDataFlag1:Boolean=false;
   @ViewChild('menuElementRef') menuElementRef: ElementRef;

 selectedValue1: string;
 showDropdown: boolean = false;
//  dropdownOptions: string[] = ['Today', 'Week', 'Month','Customrange','Name','Id'];
 dropdownOptions: string[] = ['Today', 'Week', 'Month','Customrange','Id'];
 dataFlag:Boolean=true;
 maxDate: Date;
 balanceamount:Number
 amount:Number
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: GroupService,
    private appspinner: AppComponent,
    private cookie: CookieService
  ) {


    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate()); 
   }

  ngOnInit() {
    this.filterFlag=true;
    this.selectedOption1 = ' '; 
    this.supportTicketListPage('user',1);
    sessionStorage.setItem('supportlist','0')
    this.appspinner.spinnerAlert('show');
    this.appspinner.spinnerAlert('hide');
  
    // this.supportTicketList('user');
    
    this.typeglobal="user"
   }

   ngOnDestroy(){
    clearInterval(this.setintervalid)
  }
  public setintervalid = setInterval(() => {
    
    // if(this.searchFlag===false && this.filterFlag===false && this.filterkeyFlag===false)
    // {
if(this.TimeintervalFlag===false){
    // alert("false")
  //  this.supportTicketList(this.typeglobal);
  // alert("success")
  this.supportTicketListPage(this.typeglobal,this.typeGlobalPage);
    }
  }, 5000);





  supportTicketList(type) {
    ////debugger
    // this.typeglobal=""
    this.typeglobal=type;
// alert(this.typeglobal)
    // localStorage.setItem("providertype", type);
    // alert("test"+type)

    // this.sample1(type);
    this.sample()
      ////debugger
      var data  = {
        type : type,
        page:1
      }
      this.service.supportTicketListType(data).subscribe(res => {
    
      if (res['error']) {
        this.noDataFlag=true
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        ////debugger
      this.typeview1 = new GroupModel(res.body['error'],res.body['msg'], res.body['data']);
    
      if (res.body['data'] && res.body['data'].length > 0 && this.typeview1.data.length > 0) {
      
        if(type=='provider'){
          this.noDataFlag=false
          // this.typeview=this.typeview1.data
          this.result=this.typeview1.data[0].data

         console.log("ggg",this.result)
          this.pages=this.typeview1.data[0].Count
          this.appspinner.spinnerAlert('hide');
        }
        else
        {
          this.noDataFlag=false
          this.result=this.typeview1.data[0].data

          console.log("ggg",this.result)
           this.pages=this.typeview1.data[0].Count
          // this.typeview=this.typeview1.data
        // this.result=this.typeview1.data
          this.appspinner.spinnerAlert('hide');
        }
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
   
      //................................................
      // if (res.body['error']) {
      //   environment.swalalert('nodata', res['msg']);
      // } else {

      //   this.typeview = new GroupModel(res.body['error'], res.body['msg'], res.body['data']);
      //   if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
      //   this.pages = this.typeview['data'][1].Count;
      //    this.result = this.typeview['data'][0].data;
      //    // this.result = res.body['data'];
          
      //     console.log("Create group users",this.result)
      //     this.result.map(x => {
      //       x['isSelected'] = false;
      //     });
      //   } else {
      //     environment.swalalert('nodata', 'No Data Available ');
      //   }
      // }
    },
      (err) => {
        console.log(err);
      });
  }
   supportTicketListPage(type,pagenumber) {
    ////debugger
    this.searchdata=''
    this.search=''
    this.selectedOption1=' '
    this.filterFlag=true
    // this.typeglobal=""
    this.typeglobal=type;
    this.typeGlobalPage=pagenumber;
// alert(this.typeglobal)
    // localStorage.setItem("providertype", type);
    // alert("test"+type)

    // this.sample1(type);
    this.sample()
      ////debugger
      
      var data  = {
        
        type : type,
        page:pagenumber
      }
      this.service.supportTicketListType(data).subscribe(res => {
    
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        ////debugger
      this.typeview1 = new GroupModel(res.body['error'],res.body['msg'], res.body['data']);
    
      if (res.body['data'] && res.body['data'].length > 0 && this.typeview1.data.length > 0) {
      
        if(type=='provider'){
          this.noDataFlag=false;
          // this.typeview=this.typeview1.data
          this.result=this.typeview1.data[0].data

         console.log("gggprovider",this.result)
          this.pages=this.typeview1.data[0].Count
          this.appspinner.spinnerAlert('hide');
          
        }
        else if(type=='user')
        {
          this.noDataFlag=false;
          // this.typeview=this.typeview1.data
          this.result=this.typeview1.data[0].data

          console.log("ggg111user",this.result)
           this.pages=this.typeview1.data[0].Count
          this.appspinner.spinnerAlert('hide');
        }
        this.appspinner.spinnerAlert('hide');
      } else {
        this.noDataFlag=true;
        this.pages = 0
        this.result=[]
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
   
      //................................................
      // if (res.body['error']) {
      //   environment.swalalert('nodata', res['msg']);
      // } else {

      //   this.typeview = new GroupModel(res.body['error'], res.body['msg'], res.body['data']);
      //   if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
      //   this.pages = this.typeview['data'][1].Count;
      //    this.result = this.typeview['data'][0].data;
      //    // this.result = res.body['data'];
          
      //     console.log("Create group users",this.result)
      //     this.result.map(x => {
      //       x['isSelected'] = false;
      //     });
      //   } else {
      //     environment.swalalert('nodata', 'No Data Available ');
      //   }
      // }
    },
      (err) => {
        console.log(err);
      });
  }

  navigateTo(route: string, id: number) {
    this.router.navigate([route,id]);
    this.checkrouteractive(28)
  }
  checkrouteractive(page){
    // alert("gffffddd")
    sessionStorage.setItem('supportlist',page)
   }
  sample()
  { var dd="3";
    // alert("arg sample"+this.typeglobal)
    // var dd=this.typeglobal
    // localStorage.setItem("providertype",dd);
    var type4="support"
    localStorage.setItem("providertype", JSON.stringify(this.typeglobal));
    // localStorage.setItem("usertype",type4)
    localStorage.setItem("usertype", JSON.stringify(type4));
    var dd="provider";
  var ff="provider"
  // localStorage.setItem("providertype", dd);
  localStorage.setItem("providertype", JSON.stringify(dd));
  localStorage.setItem("sss1", JSON.stringify(ff));
  }
  sample1(type)
  {
    alert("function view")
  //   // var dd="3";
  //   var dd1=type;
  //   // alert(type)
  // localStorage.setItem("providertype", dd1);
  }

  PayoutModal(data){

  }
  providerUpdate(Id,status,r,i){

  }

  searchGroup(search) {
    ////debugger
    var searchType;
  //   alert(search)
  //  console.log("dsadafaf",this.type1)
this.searchFlag=true
    if(!search)
    {
      
      this.searchFlag=false
      // alert("hello")
    
      if(this.searchtypeglobal=="provider"){
       
      this.supportTicketList('provider');
// this.pushprovidersView('user',1);
      }
      else{
      
        this.supportTicketList('user');
       // this.pushprovidersView('provider',1);
      }
    }
    else{
     

    // if(this.type1=="user")
    // {


      // this.service.groupsearchdataView(search,'user',1)
     // this.service.groupsearchdataView(search,this.type1,this.page).subscribe((res) => {
      // this.service.groupsearchdataView(search,'user',1).subscribe((res) => {
        // alert(this.typeglobal)
        // alert("hello3333")
        
      this.searchtypeglobal=this.typeglobal;
     
       var data = {search: search, typename: 'supportticket',status:this.typeglobal,page: 1 }
        // this.service.groupsearchdataView(data).subscribe((res) => {
          this.service.supportSearch(data).subscribe((res) => {
        if (res.body['error']) {
          
          this.noDataFlag=true;
          this.pages = 0
          this.result=[]
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
       
        this.typeview = new GroupModel(res['error'],res['msg'], res['data']);
        if (res.body['data'][0].data.length > 0 ) {
         
            this.pages = res.body['data'][1].Count;
           
            this.result=res.body['data'][0].data;
          
            this.appspinner.spinnerAlert('hide');
          } else {
          //   this.noDataFlag=true;
          // this.pages = 0
          // this.result=[]
            environment.swalalert('nodata', 'No Data Available ');
            this.appspinner.spinnerAlert('hide');
          }

       
      }
    },
      (err) => {
        console.log(err);
      });
  
    //  }

    // else if(this.type1=="provider")
    // {
    //   alert("provider")
    // }
  }
  }



//...............................................

  findname1(searchdata)
{

  this. getallsupport(1,searchdata)
}
 onChangeSearchkeytype(data){




   //  this.searchdata=''
   this.dateFlag=false;
   this.filterFlag=false;
   this.selectedOption1=data;
   if(this.selectedOption1=="Month"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
     this.filterFlag=false
     
     this.dateFlag=false;
     this.searchflag=false
   this.selectedOption1="doj"
   this.searchdata="month"
   this. getallsupport(1,"month")
   
   this.selectedOption1=data;
   }
   if(this.selectedOption1=="Week"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
     this.filterFlag=false
     this.dateFlag=false;
     this.searchflag=false
   this.selectedOption1="doj"
   this.searchdata="week"
   this. getallsupport(1,"week")
   this.selectedOption1=data;
   }
   if(this.selectedOption1=="Today"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
     this.filterFlag=false
     this.dateFlag=false;
     this.searchflag=false
   this.selectedOption1="doj"
   this.searchdata="today"
   this. getallsupport(1,"today")
   this.selectedOption1=data;
   }
   if(this.selectedOption1=="zeroride"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
     this.filterFlag=false
     this.dateFlag=false;
     this.searchflag=false
   this.selectedOption1="ride"
   this.searchdata="0"
   this.getallsupport(1,"0")
   this.selectedOption1=data;



   }
   if(this.selectedOption1=="Customrange"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
    this.filterFlag=false
    this.searchflag=false
  this.selectedOption1="customrange"
  
  this.dateFlag=true;
  this.searchdata=this.fulldate
  this.selectedOption1="customrange";
  //   this.isMenuOpen=false
   
  //   this.dataFlag=false;
  //    this.toggleMenu()
  //    this.filterFlag=false
  //    this.searchflag=false
  //  this.selectedOption1="customrange"
   
  //  this.dateFlag=true;
  //  this.searchdata=this.fulldate
  //  this.selectedOption1="customrange";
  this.getallsupport(1,this.fulldate)
   }
   if(this.selectedOption1=="Name"){
  //   this.isMenuOpen=false
   
  //   this.dataFlag=true;
  //    this.toggleMenu()
  //    this.dateFlag=false;
  //    this.filterFlag=true
     
  //    // this.searchflag=false
  //    this.filterkeyFlag=true
     
  //  this.searchflag=false
  //    this.selectedOption1="name"
  //    this.getallsupport(1,this.searchdata)
  //    // this.selectedOption1=data;
  //    // this.getallproviders(1,"name")
  //  // this.selectedOption1="customrange";





  this.dataFlag=false;
     this.toggleMenu()
     this.filterFlag=false
     this.dateFlag=false;
     this.searchflag=false
     
   this.selectedOption1="name"
this.dataFlag=true
      this.filterFlag=true
     this.filterkeyFlag=true
  //  this.searchdata="0"
   this.getallsupport(1,this.searchdata)
   this.selectedOption1=data;
   }
   if(this.selectedOption1=="Id"){
  //   this.isMenuOpen=false
  //  alert("success")
  //   this.dataFlag=true;
  //    this.toggleMenu()
  //    this.dateFlag=false;
  //    this.filterFlag=true
  //    this.filterkeyFlag=true
  //  this.searchflag=false
     
  //    this.selectedOption1="id"
  //    this.getallsupport(1,this.searchdata)
  //    // this.selectedOption1=data;
  //  // this.selectedOption1="customrange";




  
  this.dataFlag=false;
     this.toggleMenu()
     this.filterFlag=false
     this.dateFlag=false;
     this.searchflag=false
     
   this.selectedOption1="id"
   this.getallsupport(1,this.searchdata)
this.dataFlag=true
      this.filterFlag=true
     this.filterkeyFlag=true
  //  this.searchdata="0"
  //  this.getallsupport(1,this.searchdata)
  //  this.selectedOption1=data;
  this.selectedOption1="id"
   }
   //   searchkey:"2023-06-07,"2023-06-014
 // type: "customrange"
   
   
 // this.filterFlag=true;
   
//   // this.searchdata=''
//   this.filterFlag=true;
//   this.selectedOption1=data;
//   if(this.selectedOption1=="DOJ This Month"){
//     this.filterFlag=false
//   this.selectedOption1="doj"
//   this. getallsupport(1,"month")
//   this.selectedOption1=data;
//   }
//   if(this.selectedOption1=="DOJ This Week"){
//     this.filterFlag=false
//   this.selectedOption1="doj"
//   this. getallsupport(1,"week")
//   this.selectedOption1=data;
//   }
//   if(this.selectedOption1=="DOJ Today"){
//     this.filterFlag=false
//   this.selectedOption1="doj"
//   this. getallsupport(1,"today")
//   this.selectedOption1=data;
//   }
  
  
// this.searchFlag==true
// // this.filterFlag=true
  
}
getallsupport(page,search) {
  this.appspinner.spinnerAlert('show');
 // sessionStorage.setItem('doctypelist',page);
//  this.filterFlag=true
 this.noDataFlag=false;
//  alert(this.typeglobal)
// alert(this.selectedOption1)
//   const data={
//     page:page,
//     type:this.selectedOption1,
//     // searchkey:this.searchdata
//     searchkey:search,
//     userType:this.typeglobal
//   }
  
 
  if(!search)
  {
    // this.searchflag=false;
    this.noDataFlag=false;
    this.supportTicketListPage(this.typeglobal,this.typeGlobalPage);
    // this.getProviders(this.currentpage,this.searchStatus);
    //this.getprovidersSearch(this.currentpage);

  }
  else{
  
  const data={
    page:page,
    type:this.selectedOption1,
    // searchkey:this.searchdata
    searchkey:search,
    userType:this.typeglobal
  }
  this.service.supportFilterListView(data).subscribe((res) => {
    if (res.body['error']) {
     
      this.noDataFlag=true;
      this.pages = 0
      this.result = []
      environment.swalalert('nodata', res['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
  var a=0
      if (res.body['data'][0].data.length > 0 ) {
        this.noDataFlag=false;
        
        this.pages = res.body['data'][1].Count;
        this.result = res.body['data'][0].data;
        console.log("filtercheck",res.body['data'][0].data)
        this.appspinner.spinnerAlert('hide'); 
      }
      else {
       
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
    },
    (err) => {
      console.log(err);
  })
}




}


reloadWindow() {
  this.dateFlag=false
  this.filterFlag=true
  this.filterkeyFlag=false
  this.searchflag=true
  window.location.reload();
}


  //....................................................




        //////////////////////////////////

getallproviderssearch(page,search) {
  ////debugger
  this.appspinner.spinnerAlert('show');

 this.noDataFlag=false;

// const data={
//   page:page,
//   type:this.selectedOption1,
//   // searchkey:this.searchdata
//   searchkey:search,
//   status:this.searchStatus
// }

const data={
  page:page,
  type:this.selectedOption1,
  
  searchkey:search,
  userType:this.typeglobal
}

  if(!search)
  {
    // this.searchflag=false;
    this.noDataFlag=false;
    this.getallsupport(1,this.searchStatus);
    //this.getprovidersSearch(this.currentpage);

  }
  else{
  
  this.service.supportFilterListView(data).subscribe((res) => {
    if (res.body['error']) {
      
      this.noDataFlag=true;
      this.pages = 0
      this.result = []
      environment.swalalert('nodata', res['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
  var a=0
 
      if (res.body['data'][0].data.length > 0 ) {
        this.noDataFlag=false;
        
        this.pages = res.body['data'][1].Count;
        this.result = res.body['data'][0].data;
        console.log("filtercheck",res.body['data'][0].data)
        this.appspinner.spinnerAlert('hide'); 
      }
      else {
       
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
    },
    (err) => {
      console.log(err);
  })
}
}


 

checksearch(searchdata){
  this.TimeintervalFlag=true
  
  if(this.filterkeyFlag===true){

this.getallproviderssearch(1,this.searchdata)
// this.searchdata="Name"
// alert("success")
  }
  else if(this.searchflag===true){
// this.getallsupport(1,this.searchdata)
this.searchGroup(this.searchdata)
  }
}

          toggleDropdown() {
            this.showDropdown = !this.showDropdown;
          }
          
          selectOption(option: string) {
            // alert(this.isMenuOpen)
            this.selectedValue1 = option;
            this.isMenuOpen=false
            if(option=="Name")
            {
              
              // this.searchdata="name"
            }
            this.onChangeSearchkeytype(option)
            // this.toggleDropdown();
          }
          
          
          
          
          toggleMenu() {
            // alert("hi")
            this.isMenuOpen = !this.isMenuOpen;
            this.TimeintervalFlag=true
            
          }
          
          menuItemClicked(item: string) {
            console.log('Clicked:', item);
            // Perform desired actions with the clicked item
          }
          
          
          
          onDateRangeChange(event: any) {
            const startDate = this.formatDate(event.startDate);
            const endDate =this.formatDate( event.endDate);
            if(startDate=="1970-01-01"){
              this.fulldate=" "
              this.searchdata=this.fulldate
                }
                else{

                
            this.startdate=startDate
            this.enddate=endDate
            this.fulldate=this.startdate+","+this.enddate
            this.searchdata=this.fulldate
            this.selectedOption1="customrange"
            this.getallsupport(1,this.fulldate)
            // this.getallusers(1,this.fulldate)
            this.dateFlag=true
            // alert(this.fulldate)
          
            // Use the start and end dates as needed
                }
          }
          formatDate(date) {
            var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
          
            return [year, month, day].join('-');
          }
          
          
          
          
          // Open the menu when the button is clicked
          openMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            
            // this.isMenuOpen = true;
            document.addEventListener('click', this.closeMenuOutsideClick.bind(this));
          }
          
          // Close the menu when clicking outside of the menu area
          closeMenuOutsideClick(event: MouseEvent) {
            
          
            if (!this.menuElementRef.nativeElement.contains(event.target)) {
              
          //  this.isMenuOpen = false;
              document.removeEventListener('click', this.closeMenuOutsideClick.bind(this));
            }
          }

          getallusers(page,search) {
            this.appspinner.spinnerAlert('show');
           // sessionStorage.setItem('doctypelist',page);
        
           this.noDataFlag=false;
            const data={
              page:page,
              type:this.selectedOption1,
              // searchkey:this.searchdata
              // searchkey:search,
              searchkey:this.searchdata,
              status:this.statusGlobal
            }
            
            
            if(!search)
            {
              // this.searchflag=false;
              this.noDataFlag=false;
              this.supportTicketListPage(this.typeglobal,1);
              // this.getProviders1(1,this.statusGlobal) 
              // this.getProviders1(1,'active')
              //this.getprovidersSearch(this.currentpage);
          
            }
            else{
            
            this.service.supportFilterListView(data).subscribe((res) => {
              if (res.body['error']) {
               
                this.noDataFlag=true;
                this.pages = 0
                this.result = []
                environment.swalalert('nodata', res['msg']);
                this.appspinner.spinnerAlert('hide');
              } else {
            var a=0
                if (res.body['data'][0].data.length > 0 ) {
                  this.noDataFlag=false;
                  
                  this.pages = res.body['data'][1].Count;
                  this.result = res.body['data'][0].data;
                  console.log("filtercheck",res.body['data'][0].data)
                  this.appspinner.spinnerAlert('hide'); 
                }
                else {
                 
                environment.swalalert('nodata', 'No Data Available ');
                this.appspinner.spinnerAlert('hide');
              }
            }
              },
              (err) => {
                console.log(err);
            })
          }
          }

          hideModel()
          {
            
          }
          payout(){

          }

         






}


