import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { CookieService } from 'ngx-cookie-service';
import { GroupList } from './grouplist.model';
//import { PushNotificationViewList } from './pushnotificationview.model';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });
  url = environment.Url;
  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }
  constructor(private http: HttpClient,
    private cookie: CookieService) { }



// pushproviderviewList(type,page) {
//     return this.http.get(`${this.url}/getNotificationListView/${page}`,  {
//       headers: this.httpheaders,
//       observe: 'response'
//     });
//   } 
// Email search data List View
groupsearchdataView(search,type,page): Observable<GroupList[]> {
  return this.http.get(`${this.url}/searchNotificationGroup/${search}/${type}/${page}`, {
    headers: this.httpheaders
  })
    .map((res: Response) => {
      return <GroupList[]><unknown>res;
    })
    .catch(this.handleError);
}  
  pushproviderviewList(type,page) {
   
    ////debugger
    return this.http.get(`${this.url}/getNotificationListView/${type}/${page}`,  {
      headers: this.httpheaders,
      observe: 'response'
    });
   // return this.http.post(`${this.url}/getNotificationListView/${page}`,{type:type},{
      // return this.http.post(`${this.url}/getNotificationListView/${type}`,{page},{
    //     return this.http.get(`${this.url}/getNotificationListView/${type}/${page}`,{
    //   headers: httpheaders,
    //   observe: 'response'
    // })

  }
}
// pushviewListView(page) {
//   return this.http.get(`${this.url}/usersPushNotificationView/${page}`,  {
//     headers: this.httpheaders,
//     observe: 'response'
//   });
// }