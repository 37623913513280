export class AppConfigModelList {
public Id : Number ; 
public Value : String ; 

constructor( Id: Number,
            Value: String
        ) {
    this.Id = Id;
    this.Value = Value;
    }

}