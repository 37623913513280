import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule, NgbAlertModule, NgbDatepicker, NgbDatepickerModule, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ScrollEventModule } from 'ngx-scroll-event';
import { CKEditorModule } from 'ngx-ckeditor';
// import { Daterangepicker } from 'ngx-daterangepicker';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from "agm-overlays"
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDirectionModule } from 'agm-direction'
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ControlMessagesComponent } from './login/control-messages.component'
import { CurrencyconfigviewComponent } from './layout/currencyconfig/currencyconfigview.component';
import { CurrencyconfigeditComponent } from './layout/currencyconfigedit/currencyconfigedit.component';
import { DeliveryboyzonesComponent } from './layout/deliveryboyzones/deliveryboyzones.component';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from "./layout/layout.component";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { SidebarComponent } from "./layout/sidebar/sidebar/sidebar.component";
import { EncryService } from './encry.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { AdminbookingsComponent } from './layout/adminbookings/adminbookings.component';
import { OtpscreenComponent } from './otpscreen/otpscreen.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { EmailforotpComponent } from './emailforotp/emailforotp.component';
import { UserdetailsComponent } from './layout/userdetails/userdetails.component';
import { AdminComponent } from './layout/admin/admin.component';
import{ModalComponent} from './modal/modal.component'
import { AdminAddComponent } from './layout/admin-add/admin-add.component';
import { SupportticketComponent } from './layout/supportticket/supportticket.component';
import { TicketviewComponent } from './layout/ticketview/ticketview.component';
import { TicketdetailsComponent } from './layout/ticketdetails/ticketdetails.component';
import { PushnotificationsComponent } from './layout/pushnotifications/pushnotifications.component';
import { ReportComponent } from './layout/report/report.component';
import { TripcancellationComponent } from './layout/tripcancellation/tripcancellation.component';
import { OrgComponent } from './layout/org/org.component';
import { configviewComponent } from './layout/config/config.component';
import { AppconfigviewComponent } from "./layout/appconfigview/appconfigview.component";
import { SupportListComponent } from './layout/supportlist/supportlist.component';
import { GroupComponent } from './layout/group/group.component';
import { CreategroupComponent } from './layout/group/creategroup/creategroup.component';
import { ViewgroupComponent } from './layout/group/viewgroup/viewgroup.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import {DataTablesModule} from 'angular-datatables';
// search module
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GrdFilterPipe } from './grd-filter.pipe';
import { Time24to12Format } from './time24to12.pipe';
import { abs } from './abs.pipe';
import { AddmapzoneComponent } from './layout/addmapzone/addmapzone.component';
import { VehicleMapComponent } from './layout/vehicle-map/vehicle-map.component';
// import { ProviderAddComponent } from './layout/provider-add/provider-add.component';
// import { ProviderEditComponent } from './layout/provider-edit/provider-edit.component';
@NgModule({
    declarations: [
        
        ModalComponent,
        AppComponent,
        RegisterComponent,
        NotFoundComponent,
        LoginComponent,
        ControlMessagesComponent,
        LayoutComponent,
        NavbarComponent,
        SidebarComponent,
        DeliveryboyzonesComponent,
        AddmapzoneComponent,
        CurrencyconfigviewComponent,
        CurrencyconfigeditComponent,
        AdminbookingsComponent,
        OtpscreenComponent,
        ResetpasswordComponent,
        EmailforotpComponent,
        UserdetailsComponent,
        AdminComponent,
        AdminAddComponent,
        AdminAddComponent,
        // DataTablesModule,
        // Ng2SearchPipeModule,
        SupportticketComponent,
        TicketviewComponent,
        TicketdetailsComponent,
        PushnotificationsComponent,
        ReportComponent,
        TripcancellationComponent,
        configviewComponent,
        OrgComponent,
        AppconfigviewComponent,
        SupportListComponent,
        GroupComponent,
        CreategroupComponent,
        ViewgroupComponent,
        GrdFilterPipe,
        Time24to12Format,
        abs,
        VehicleMapComponent,
        // ProviderAddComponent,
        // ProviderEditComponent
    ],
    imports: [
        // Ng2SearchPipeModule,
        NgbPaginationModule,
        NgbModule,
        NgbAlertModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        NgProgressModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        
        ScrollEventModule,
        CKEditorModule,
        DatePickerModule,
        DateRangePickerModule,
        
        AgmOverlays,
        NgxMaterialTimepickerModule,
        NgxMaterialTimepickerModule.forRoot(),
        AgmJsMarkerClustererModule,
        AgmCoreModule.forRoot({
            apiKey: environment.mapApiKey,
            language: "en",
            libraries: ["geometry", "places", "drawing"],
        }),
        AgmDirectionModule,
        NgProgressModule.forRoot(),
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        ChartsModule,
        AgmSnazzyInfoWindowModule,
        BrowserAnimationsModule
    ],
    providers: [
        EncryService,
        CookieService,
        ThemeService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
