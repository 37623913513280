export class ReportModel {
    public ReportType: String;
    public ValidFrom: any;
    public ValidTo: any;
    constructor( ReportType: String,
                    ValidFrom: any,           
                    ValidTo: any,              
                    
                ) {
                    this.ReportType = ReportType;
                   this.ValidFrom = ValidFrom;
                    this.ValidTo = ValidTo;
                    
    }
}