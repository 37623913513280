export class APPConfigData {
    public U_AUTH_TYPE: String;
    public U_OTP_TIMER: Number;
    public U_MAP_API_KEY: String;
    public U_WAITING_TIME: Number;
    public U_MAX_RANGE: Number;
    public U_TWILIO_AUTH_KEY: Number;
    public  U_REFERRAL_ENABLED: String;
    public U_REFERRAL_AMOUNT: String;
    public U_REFERRAL_DESCRIPTION: String;
    public U_REFERRAL_THRESHOLD: String;
    public U_ADMIN_USER_ID: String;
    public P_AUTH_TYPE: String;
    public P_OTP_TIMER: Number;
    public  P_MAP_API_KEY: String;
    public P_WAITING_TIME: Number;
    public P_PICKUP_DISTANCE: Number;
    public P_Due_Amount_Threshold: Number;
    public P_TWILIO_AUTH_KEY: Number;
   public P_PROVIDER_APPROVAL_STATUS:String;

//    public A_SMTP_SERVER:String;
//    public A_SMTP_PORT:Number;
//  public   A_SMTP_SECURE:Number;
//   public  A_SMTP_USER:String;
//    public A_SMTP_PASSWORD:String;

        constructor( 
          U_AUTH_TYPE: String,
         U_OTP_TIMER: Number,
         U_MAP_API_KEY: String,
         U_WAITING_TIME: Number,
         U_MAX_RANGE: Number,
         U_TWILIO_AUTH_KEY: Number,
          U_REFERRAL_ENABLED: String,
         U_REFERRAL_AMOUNT: String,
         U_REFERRAL_DESCRIPTION: String,
         U_REFERRAL_THRESHOLD: String,
         U_ADMIN_USER_ID: String,
            P_AUTH_TYPE: String,
            P_OTP_TIMER: Number,
            P_MAP_API_KEY: String,
            P_WAITING_TIME: Number,
            P_PICKUP_DISTANCE: Number,
            P_Due_Amount_Threshold:Number,
            P_TWILIO_AUTH_KEY: Number,
            P_PROVIDER_APPROVAL_STATUS:String,
            // A_SMTP_SERVER:String,
            // A_SMTP_PORT:Number,
            // A_SMTP_SECURE:Number,
            // A_SMTP_USER:String,
            // A_SMTP_PASSWORD:String
                ) {
                  this.U_AUTH_TYPE=  U_AUTH_TYPE;
                    this. U_OTP_TIMER= U_OTP_TIMER;
                    this.U_MAP_API_KEY=U_MAP_API_KEY;
                    this.U_WAITING_TIME=U_WAITING_TIME;
                    this.U_MAX_RANGE=U_MAX_RANGE;
                    this.U_TWILIO_AUTH_KEY=U_TWILIO_AUTH_KEY;
                    this.U_REFERRAL_ENABLED=U_REFERRAL_ENABLED;
                    this.U_REFERRAL_AMOUNT=U_REFERRAL_AMOUNT;
                    this.U_REFERRAL_DESCRIPTION=U_REFERRAL_DESCRIPTION;
                    this.U_REFERRAL_THRESHOLD=U_REFERRAL_THRESHOLD;
                    this.U_ADMIN_USER_ID=U_ADMIN_USER_ID;
                    this.P_AUTH_TYPE=P_AUTH_TYPE;
                    this.P_OTP_TIMER=P_OTP_TIMER;
                    this.P_MAP_API_KEY=P_MAP_API_KEY;
                    this.P_WAITING_TIME=P_WAITING_TIME;
                    this.P_PICKUP_DISTANCE=P_PICKUP_DISTANCE;
                    this.P_Due_Amount_Threshold=P_Due_Amount_Threshold;
                    this.P_TWILIO_AUTH_KEY=P_TWILIO_AUTH_KEY;
                   this.P_PROVIDER_APPROVAL_STATUS=P_PROVIDER_APPROVAL_STATUS;
                  //  this.A_SMTP_SERVER=A_SMTP_SERVER;
                  //  this.A_SMTP_PORT=A_SMTP_PORT;
                  //  this.A_SMTP_SECURE=A_SMTP_SECURE;
                  //  this.A_SMTP_USER=A_SMTP_USER;
                  //  this.A_SMTP_PASSWORD=A_SMTP_PASSWORD;
                    
    }
}