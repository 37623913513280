
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { EncryService } from 'src/app/encry.service';
import { from } from 'rxjs';
import { environment } from '../../../../environments/environment';
 import { AppComponent } from 'src/app/app.component';
// import { CreategroupService } from '../creategroup/creategroup.service';
// import { CreategroupModel } from '../creategroup/creategroup.model';
// import { CreateGroupDataModel } from '../creategroup/creategroupdata.model';
import { ViewgroupService } from './viewgroup.service';
import { ViewGroupModel } from './viewgroup.model';
import { DashboardComponent } from '../../dashboard/dashboard.component';
@Component({
  selector: 'app-viewgroup',
  templateUrl: './viewgroup.component.html',
  styleUrls: ['./viewgroup.component.css']
})

export class ViewgroupComponent  implements OnInit {
    id:any;
    type:any;
    name:any;
    ids=[];
    num1=[];
    num2=[];
  paramsid: any;
  creategroup: any;
  typeid: any;
  returnUrl: any;
  areAllSelected = false;
  masterSelected: boolean;
  Name: String;
  Type: String;
length1:any;
  validfrom: any = new Date();
   validto: any = new Date();
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
   public dateValue: Object = new Date(new Date().setDate(14));
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentDay);
  public maxDate: Object = new Date(this.currentYear, 12, 31);
  result: any;
  result1:any;
  checkResult:any;
  page: Number = 1;
  pages: any;
  commontypename: String = 'user';
  checkedList = [];
  selectedType: string = '';
  viewmodel:ViewGroupModel;
  viewmodel1:ViewGroupModel;
  searchlist:ViewGroupModel;
  ischeck:Boolean;
  searchdata:String;
  selectedOption1: string; 
  paginationflag:Boolean=false
  noDataFlag:Boolean=false;
  selectedType1: string;
  constructor(private formBuilder: FormBuilder,
    private  router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private encry: EncryService,
    private service: ViewgroupService,
    private appspinner: AppComponent,
   
    ) { 
      this.masterSelected = false;
      // super(router);
      // // constructor(public override router:Router) {
      // //   super(router);
      // //   console.log("Inside ChildComponent constructor")
      // //  }
      // this.ngOnInit();
      //window.location.reload();
      
    }
    // ngDoCheck(){
    //   this.updateeditedroles();

    // }
    // ngAfterViewInit()
    // {
    //   this.updateeditedroles();
    // }
//   ngOnDestroy()
//   {
//     // alert("Ondestroy")

//  // this.updateeditedroles();
//   }
//   ngOnDestroy()
//   {
//     // this.checkedList = [0];
//     // this.checkedList;
//     // console.log("this.checkedList",this.checkedList)
//     // alert(this.checkedList);
//     // alert("Ondestroy")

//  // this.updateeditedroles();
//   }
  // ngDestroy()
  // {
  //   alert("destroy")

  //   //this.updateeditedroles();
  // }
  // ngOnDestroy()
  // {
  //   // this.route.params.subscribe(params => {
  //   //   this.paramsid = params.id;
  //   //   console.log("id view...",this.paramsid)
    
  //   // });
  //   // window.location.reload();
  //  this.getgroupView(this.paramsid);
  // }
ngOnDestroy()
{
  this.getgroupView(this.paramsid)
}
  ngOnInit() {
   // alert(this.checkedList);
    this.appspinner.spinnerAlert('show');
    this.appspinner.spinnerAlert('hide');
  //  window.location.reload();
    // window.onload = function() {
    //   if(!window.location.hash) {
    //   // window.location = window.location + #loaded;
    //     window.location.reload();
    //   }
    // }

    this.appspinner.spinnerAlert('show');

    this.route.params.subscribe(params => {
      this.paramsid = params.id;
      console.log("id view...",this.paramsid)
    
    });
    // window.location.reload();
   this.getgroupView(this.paramsid);
   this.Type
   this.type
   console.log("ggggg",this.Type)
   console.log("ssssssssssss",this.type)
   //............................................
  //  if (this.selectedType === 'provider') {
  //   ////debugger
  //   this.pushprovidersView(this.page, 'provider')
  // } else {
  //  this.pushusersView(this.page, 'user')
  // }
//...................................................
  this. selectChange(this.type)
 // alert(this.checkedList);
//  console.log(this.checkedList)
//  this.getgroupView(this.paramsid);
//  alert("ddd"+this.checkedList);
// console.log(this.checkedList)
this.appspinner.spinnerAlert('hide');
  }

 

  getgroupView(id) {
    this.appspinner.spinnerAlert('show');
    this.appspinner.spinnerAlert('hide');
   // window.location.reload();
    // ////debugger
   
    ////debugger
    // var num1:[];
    // var num2:[];
    this.appspinner.spinnerAlert('show');
   
    //.................................................
    //  if (this.type==='provider') {
    //   this.pushprovidersView(this.page, 'provider')
    // } else {
    //   this.pushusersView(this.page, 'user')
    // }
    //...................................................
   
      // this.service. usersListView(this.page,'active').subscribe((res: any) => {
    this.service.pushviewListView(this.page,this.paramsid).subscribe((res: any) => {

      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
      } else {
        this.viewmodel1 = new  ViewGroupModel (res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.viewmodel1['data'][0].data.length > 0) {
         //  this.length1=this.viewmodel1.data.Ids.length
         this.length1=this.viewmodel1['data'][0].data.length;
          this.pages = Math.ceil(this.viewmodel1['data'][1].Count/2);
          this.result1= this.viewmodel1['data'][0].data;

          console.log("Create  users1111",this.result1)
          console.log("Create provider",this.viewmodel1['data'][0].data[0].Id)
          for(var i=0;i<this.length1 ;i++){
           this.num2.push( this.viewmodel1['data'][0].data[i].Id)
           if(this.num2[i]==this.viewmodel1['data'][0].data[i].Id){
            //this.checkedList.push(this.num2[i]);
            console.log("check list sdfsdgsdg.....",this.checkedList)
            //alert("success")
           }
          }
          console.log("allchgdghdghdhgdhfdhfsfgsfd",this.num2)
          
          // this.result.map(x => {
          //   x['isSelected'] = false;
          // });
        } else {
          environment.swalalert('nodata', 'No Data Available ');
        }
      }
    },
      (err) => {
        console.log(err);
      });
    //............................................
    this.service.groupView(this.paramsid).subscribe((res) => {
      console.log("NgOnInitData:::",res);





      if (res.body['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        ////debugger
        
      this.viewmodel = new ViewGroupModel(res.body['error'], res.body['msg'], res.body['data']);
      var length=this.viewmodel.data.Ids.length
      console.log("fffffname",length)
     this.id=this.viewmodel.data.Ids;
     this.name=this.viewmodel.data.Name;
     ////debugger
     this.type=this.viewmodel.data.Type;
    // alert(this.type)
    var swe;

     if (this.type === 'provider') {
      ////debugger
     // alert("ffffffprovider")
     //window.location.reload();
      swe=1;
      this.pushprovidersView(this.page, 'provider')
    } else {
     // alert("fffffuser")
     //window.location.reload();
      swe=1;
     this.pushusersView(this.page, 'user')
    }
    // window.location.reload();
    // window.onload = function() {
    //   if(!window.location.hash) {
    //     // window.location = window.location + '#loaded';
    //     window.location.reload();
    //   }
    // }
  //   if(swe==1){
  //   setTimeout(()=>{
  //     window.location.reload();
  //   }, 10000);
  //   swe=0;
  // }
  //   //  var length=this.viewmodel.data.length;
     console.log("sdsfsdfdfsg",this.id)
// this.id.map(x => {
//           x['isSelected'] = true;
//           });


    //  for(var i=0;i<this.length1;i++){
    // //  this.ids.push(this.viewmodel1.data.Ids[i])
    // this. num2.push(this.viewmodel1.data.Ids[i])
    // console.log("all ...",this.num2)

    //  }
    console.log("user check result1.....",this.result1)
    console.log("user check result.....",this.result1)
    console.log("user checklist.....",this.checkedList)
    // var result1lemgth=this.result1.length
    // console.log("length....",result1lemgth)
    // for(var i=0;i<result1lemgth;i++){
   //this.checkResult.push(this.result1[i])
     //this. num2.push(this.result1[i])
     //console.log("fdsfsdfdsg",this.num1[i])
//this.num2.push(this.result1)

 
      // }
  
    console.log("usergfhgfjfg check.....",this.num1)
     for(var i=0;i<length;i++){
      this.ids.push(this.viewmodel.data.Ids[i])
     
this.checkedList.push(this.viewmodel.data.Ids[i])
// alert("ccccc"+this.checkedList)
console.log("ccccc",this.checkedList)
// this.checkedList;
////debugger
if(this.checkedList){
  this.checkedList['isSelected']=true;
 
 }
     console.log("selected ...",this.ids)
     console.log("ppppppppp...",this.ids)
 
      }
    
      console.log("aaaaaaaaaaaa",this.checkedList)
     console.log("mmmmmmm",this.result1)

     for(var j=0;j<length;j++){
     for(var i=0;i<this.pages;i++)
     {
     if(this.viewmodel.data.Ids[j]=== this.num2[i])
  

     {
      // alert(this.viewmodel.data.Ids[j])
      this.checkedList['isSelected']=true;
    
    
     }
     
      }
    }


   
      
     
       }
    },
      (err) => {
        console.log(err);
      })
 
      // alert("ddd"+this.checkedList);
      // this.checkedList;
    }

// alert("ddd"+this.checkedList);
findname(search) {
  ////debugger
  // this.selectedType1
  this.selectedType
  //alert(this.selectedType)


// if(this.selectedType== 'user'){
  if(this.type == 'user'){
    // alert("user")
var data = {search: search, typename: 'users', page: this.page}
  this.service.usersearchdataView(data).subscribe((res) => {
        if (res['error']) {
          // environment.swalalert('nodata', res['msg']);
          // this.appspinner.spinnerAlert('hide');
        } else {
          this.searchlist = new ViewGroupModel(res['error'], res['msg'], res['data']);
          if (res['data'].length > 0 && this.searchlist['data'][0].data.length > 0) {
            this.pages = this.searchlist['data'][1].Count;
            this.result = this.searchlist['data'][0].data;
            this.appspinner.spinnerAlert('hide');
          } else {
            // environment.swalalert('nodata', 'No Data Available ');
            // this.appspinner.spinnerAlert('hide');
          }
        }
      },
        (err) => {
          console.log(err);
        });
}
  
else{
  // this.currentTab='active'/pending;
  // var data = {search: search, typename: 'users', page: this.page}
  // var data = { search: search, typename: this.selectedType, page: this.page,status:'active' }
  var data1 = { search: search, typename: 'providers', page: this.page,status:'active' }
  var pen = [];
  var reslt = [];
  this.service.providersearchdataView(data1).subscribe((res) => {
    if (res['error']) {
      // environment.swalalert('nodata', res['msg']);
      // this.appspinner.spinnerAlert('hide');
    } else {
      this.searchlist = new ViewGroupModel(res['error'], res['msg'], res['data']);
      if (res['data'].length > 0 && this.searchlist['data'][0].data.length > 0) {
        this.pages = Math.ceil(this.searchlist['data'][1].Count/2);
        this.result =  this.searchlist['data'][0].data;
        console.log("search data ex...",this.result)
        this.appspinner.spinnerAlert('show');
        this.appspinner.spinnerAlert('hide');
      } else {
        // environment.swalalert('nodata', 'No Data Available ');
        // this.appspinner.spinnerAlert('hide');
      }
    }
  },
    (err) => {
      console.log(err);
    });
  }


}

  isAllSelected() {
////debugger
    this.areAllSelected = !this.areAllSelected;
    this.result = this.result.map(item => ({ ...item, isSelected: this.areAllSelected }));
var ss=1;

  //   this.masterSelected = this.result.every(function (item: any) {
  //     return item.isSelected == true;
  //   })
  //  this.getCheckedItemList();
  }


  checkCheckBoxvalue(e,item){
    // this.checkedList=[];
//     ////debugger
//     console.log(this.result1)
// console.log("hhhhhhh first",this.checkedList);
// var len=this.checkedList.length
// if(this.checkedList){
//  this.checkedList['isSelected']=true;
// //e.target.checked
// //this.result = this.result.map(item => ({ ...item, isSelected: this.areAllSelected }));
// }
    ////debugger
    if(e.target.checked){
      item['isSelected']=true;
      //test id otherwise add full data without id
      //pass this one in update 
      this.checkedList.push(item.Id);
    }
    if(!e.target.checked){
      var del=this.result.indexOf(item);
      // alert("result"+del)
    var ch1=  this.checkedList.indexOf(item.Id)
    // alert("item.id"+ch1)
      var ch=this.checkedList.indexOf(item);
      // alert("item"+ch)
       this.checkedList.splice(ch1,1);
    }
    console.log("hhhhhhh second",this.checkedList)
  }
  pushnotificationAdd(){
    
  }


  pagination(page) {
    ////debugger
    if (this.commontypename === 'provider') {
      this.pushprovidersView(page, 'provider')
    } else {
      //this.getActiveUsers(page)
       this.pushusersView(page, 'user')
    }
  }

  selectChangeHandler (event: any) {
    //update the ui
     this.selectedType = event.target.value;
     this.Type= this.selectedType;
    // alert("success"+this.selectedDay)
    this.page = 1
   // this.checkedList=[];
    // if (this.selectedType === 'provider') {
      if (this.Type === 'provider') {
      // this.pushprovidersView(this.page, 'provider')
      this.selectChangeHandler1('provider')

    } else {
     //this.pushusersView(this.page, 'user')
    this.selectChangeHandler2('user')
    }
  }
  selectChangeHandler2(type) {
    //update the ui
    //  this.selectedType = event.target.value;
     this.Type= type;
    // alert("success"+this.selectedDay)
    this.page = 1
   // this.checkedList=[];
    // if (this.selectedType === 'provider') {
      if (this.Type === 'provider') {
      // this.pushprovidersView(this.page, 'provider')

    } else {
     this.pushusersView(this.page, 'user')
    }
  }
  selectChangeHandler1 (type) {
    //update the ui
    //  this.selectedType = event.target.value;
     this.Type= type;
    // alert("success"+this.selectedDay)
    this.page = 1
   // this.checkedList=[];
    // if (this.selectedType === 'provider') {
      if (this.Type === 'provider') {
       this.pushprovidersView(this.page, 'provider')

    } else {
     this.pushusersView(this.page, 'user')
    }
  }
  selectChange (type) {
    //update the ui
   //  this.selectedType = event.target.value;
     this.type=type;
    // alert("success"+this.selectedDay)
    this.page = 1
    //this.checkedList=[];
    if (this.type === 'provider') {
      this.pushprovidersView(this.page, 'provider')
    } else {
     this.pushusersView(this.page, 'user')
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  

  pushprovidersView(page, type) {
    ////debugger
    this.commontypename = type;
   // alert(page)
    // this.cookie.set('emailtemplatepageslist', page);
    localStorage.setItem('emailtemplatepageslist', page);
    this.service.pushproviderviewListView(page,this.paramsid).subscribe((res: any) => {
      ////debugger
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
      } else {

        this.viewmodel1 = new ViewGroupModel(res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.viewmodel1['data'][0].data.length > 0) {
          this.pages = Math.ceil(this.viewmodel1['data'][1].Count/2);
          this.result = this.viewmodel1['data'][0].data;
          console.log("page",this.page);
          console.log("pages",this.pages);
          console.log("Create provider",this.viewmodel1['data'][0].data)
          // this.result.map(x => {
          //   x['isSelected'] = false;//false;
          // });
          this.result.map((perm, i) => {
            if (this.checkedList.indexOf(perm.Id) !== -1) {
            // this.permissionsArr.at(i).patchValue(true);
            // this.result.map(x => {
            perm['isSelected'] = true;//false;
            // });
            // this.result.at(i)['isSelected'] = true;
           // alert(i)
             }
           });
        } else {
          environment.swalalert('nodata', 'No Data Available ');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }

  ChangeTab(type) {
    this.page = 1
   // this.checkedList=[];
    if (type === 'provider') {
      this.pushprovidersView(this.page, 'provider')
    } else {
      this.pushusersView(this.page, 'user')
    }
  }
  getActiveUsers(page)
  {
  this.service.usersListView(page,'active').subscribe((res) => {
   
  
    if (res.body['error']) {
      this.pages = 0
      this.result = []
      this.appspinner.spinnerAlert('hide');
     
    } else {
      
      // this.usermodel = new UserListModel(res.body['error'],res.body['msg'], res.body['data']);
    if (res.body['data'].length > 0 ) {
      //this.page = page;
      ////debugger
      this.pages = Math.ceil(res.body['data'][1].Count/2);
      this.result = res.body['data'][0].data;
      this.appspinner.spinnerAlert('hide');
      console.log("ffffff......",res.body['data'][0].data)
    } else {
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
  },
  (err) => {
    console.log(err);
    if (err['status'] === 401) {
      environment.swalalert('nodata', err['msg']);
      // this.cookie.delete('Token');
      // localStorage.removeItem('Token');
      // this.router.navigate(['login']);
    }
  })
  }
  pushusersView(page, type) {

    this.commontypename = type;
    // this.cookie.set('emailtemplatepageslist', page);
    localStorage.setItem('emailtemplatepageslist', page);
    
    this.service.pushviewListView(page,this.paramsid).subscribe((res: any) => {

      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
      } else {
        this.viewmodel1 = new  ViewGroupModel (res.body['error'], res.body['msg'], res.body['data']);
        if (res.body['data'].length > 0 && this.viewmodel1['data'][0].data.length > 0) {

          this.pages = Math.ceil(this.viewmodel1['data'][1].Count/2);
          this.result = this.viewmodel1['data'][0].data;
          var ida=this.viewmodel1['data'][0].data[0].Id
          console.log("Create provider",this.viewmodel1['data'][0].data[0].Id)
          var X=6;
          ////debugger
          var sss=this.checkedList.length
          var eee=this.result.length
          // patchValue() {
          //   this.permissionList.map((perm, i) => {
          //     if (this.rolePermissionList.indexOf(perm.id) !== -1) {
          //       this.permissionsArr.at(i).patchValue(true);
          //     }
          //   });
          // }
          this.result.map((perm, i) => {
              if (this.checkedList.indexOf(perm.Id) !== -1) {
              // this.permissionsArr.at(i).patchValue(true);
              // this.result.map(x => {
              perm['isSelected'] = true;//false;
              // });
              // this.result.at(i)['isSelected'] = true;
             // alert(i)
               }
             });
          
const changedObjects = this.checkedList.filter(obj1 => {
  const obj2 = this.result.find(x => 1 === 1);
  return obj2;
});

const changedObjects1 = this.checkedList.filter(obj3 => {
 this.result.map(obj3=> {
            // obj3['isSelected'] = true;
          });
        });
        
        console.log("wwwwwwwwwwwwwwww",changedObjects1)
console.log("llllllllllllllllllll",changedObjects)
//           console.log(this.checkedList)
//           for(var j=0;j<eee;j++){
//           for(var i=0;i<=sss ;i++){
// console.log("ddddd",this.result[i].Id)

           
//             // if(this.checkedList[i]==this.result[j].Id){
//             //  //this.checkedList.push(this.num2[i]);
//             //  console.log("qqqqqq .....",this.checkedList)
//             //  //alert("success")
//             // }
//           }
//           }
        //  if(this.checkedList[i])

          // this.result = this.checkedList.map(item => ({ ...item, isSelected: true}));
          // this.result = this.result.map(item => ({ ...item, isSelected: this.areAllSelected }));
          // this.result.map(X => {
          //   X['isSelected'] = true;
          // });
        } else {
          environment.swalalert('nodata', 'No Data Available ');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }
  updateeditedroles()
  {
    ////debugger
    var data={
       Name:this.name,
      Type:this.type,
      // Ids:[5,6]
      Ids:this.checkedList
    }
    console.log("mmmmmm",this.checkedList)
  console.log("kkkk",data)
    // var data = {
    //   id : this.id,
    //   RoleName : this.roleName1,
    //   Navigation : JSON.stringify(this.permissionArray1)
    // }
    this.service.groupEdit(data).subscribe(res => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      if(res.body['error'] === false ) {
        this.appspinner.spinnerAlert('show');
        this.appspinner.spinnerAlert('hide');
   window.location.reload();
        //this.banneradsEditForm.reset();
        // this.router.navigate(["promoCodes"]);
        // this.router.navigate(['group']);
        environment.swalalert('success',res.body['msg']).then(value => {
          
          if(value) {
            this.router.navigate(["promoCodes"]);
            this.router.navigate(['group']);
          }
          });
      } else {
        environment.swalalert('error',res.body['msg']);
      }
    }
    });
  }

  updateeditedrolesor()
  {
    ////debugger
    var data={
       Name:this.name,
      Type:this.type,
      // Ids:[5,6]
      Ids:this.checkedList
    }
  
    // var data = {
    //   id : this.id,
    //   RoleName : this.roleName1,
    //   Navigation : JSON.stringify(this.permissionArray1)
    // }
    this.service.groupEdit(data).subscribe(res => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } 
      else {
      if(res.body['error'] === false ) {
        // this.appspinner.spinnerAlert('show');
        // this.appspinner.spinnerAlert('hide');
        //window.location.reload();
        //this.banneradsEditForm.reset();
        // this.router.navigate(['group']);
        environment
        .swalalert("success", res.body["msg"])
        .then((value) => {
          if (value) {
            this.router.navigate(['group']);
          }
        });
        // environment.swalalert('success',res.body['msg']).then(value => {

          
        //   if(value) {
           
        //     this.router.navigate(['group']);
        //   }
        //   });
      } else {
        environment.swalalert('error',res.body['msg']);
      }
    }
    });

    // window.onload = function() {
    //   if(!window.location.hash) {
    //   // window.location = window.location + #loaded;
    //     window.location.reload();
    //   }
    // }
  }
  updateeditedroles1() {
    ////debugger
    var data={
      Name:this.name,
     Type:this.type,
     // Ids:[5,6]
     Ids:this.checkedList
   }
       
        // console.log(this.promocodetype);
        this.service.groupEdit(data).subscribe(res => {
          if (res["error"]) {
            environment.swalalert("nodata", res["msg"]);
            this.appspinner.spinnerAlert("hide");
          } else {
            if (res.body["error"] === false) {
              environment
                .swalalert("success", res.body["msg"])
                .then((value) => {
                  if (value) {
                    this.router.navigate(['group']);
                  }
                });
            } else {
              environment.swalalert("error", res.body["msg"]);
            }
          }
        });
      
    
  }

  cancelupdateeditedroles()
  {
    this.router.navigate(['group']);
  }

  sample()
  {
    let numArr1 = [ 53, 8, 14, 12, 1, 5, 5, 7, 96, 23, 5, 23, 85, 8, 99, 13, 304]; 
let numArr2 = [ 3, 99, 45, 12, 5, 5, 78, 45, 36, 23, 97, 111, 5];

const filteredNumArray = numArr1.filter(value => numArr2.includes(value)).filter((value, index, self) => self.indexOf(value) === index);
console.log("array........",filteredNumArray); 
  }
  // pushprovidersView(type,page) {
  //   // this.appspinner.spinnerAlert('show');
  //   this.type1=type;
  //   ////debugger
  //    localStorage.setItem('emailtemplatepageslist', page);
  //   // this.type=type;
  //   this.service.pushproviderviewList(this.type1,page).subscribe((res: any) => {
  //     console.log()
  //     ////debugger
  //     if (res['error']) {
  //       environment.swalalert('nodata', res['msg']);
  //       this.appspinner.spinnerAlert('hide');
  //     } else {
  //     this.typeview = new GroupModel(res.body['error'],res.body['msg'], res.body['data']);
  //     if (res.body['data'] && res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
  //      // this.pages = this.typeview['data'][0].Count;
  //       if(this.type1=='provider'){
  //         this.type1=type;
  //          this.result = res.body['data'][0].data;
  //         console.log("provider",this.result)
  //         this.pages = this.typeview['data'][0].Count;
  //         this.appspinner.spinnerAlert('hide');
  //       }
  //       else
  //       {
  //         this.type1=type;
  //         this.result = res.body['data'][0].data;
  //         console.log("user",this.result)
  //         this.pages = this.typeview['data'][0].Count;
  //         this.appspinner.spinnerAlert('hide');
  //       }
  //       this.appspinner.spinnerAlert('hide');
  //     } else {
  //       environment.swalalert('nodata', 'No Data Available ');
  //       this.appspinner.spinnerAlert('hide');
  //     }
  //   }
  //     //................................................
  //     // if (res.body['error']) {
  //     //   environment.swalalert('nodata', res['msg']);
  //     // } else {

  //     //   this.typeview = new GroupModel(res.body['error'], res.body['msg'], res.body['data']);
  //     //   if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
  //     //   this.pages = this.typeview['data'][1].Count;
  //     //    this.result = this.typeview['data'][0].data;
  //     //    // this.result = res.body['data'];
          
  //     //     console.log("Create group users",this.result)
  //     //     this.result.map(x => {
  //     //       x['isSelected'] = false;
  //     //     });
  //     //   } else {
  //     //     environment.swalalert('nodata', 'No Data Available ');
  //     //   }
  //     // }
  //   },
  //     (err) => {
  //       console.log(err);
  //     });
  // }

  onChangeSearchkeytype(data){
    // this.searchdata=''
    // this.filterFlag=true;
    this.paginationflag=true;
    this.selectedOption1=data;
    if(this.selectedOption1=="DOJ This Month")
    {
      // this.filterFlag=false
    this.selectedOption1="doj"
    this. getallproviders(1,"month")
    this.selectedOption1=data;
    }
    if(this.selectedOption1=="DOJ This Week"){
      // this.filterFlag=false
    this.selectedOption1="doj"
    this. getallproviders(1,"week")
    this.selectedOption1=data;
    }
    if(this.selectedOption1=="newlyjoined"){
      // this.filterFlag=false
    this.selectedOption1="newlyjoined"
    this. getallproviders(1,"newlyjoined")
    this.selectedOption1=data;
    }
    if(this.selectedOption1=="ride"){
      // this.filterFlag=false
    this.selectedOption1="ride"
    this. getallproviders(1,"ride")
    this.selectedOption1=data;
    }
   
    
    
  
    
  }
  getallproviders(page,search) {
  
    this.appspinner.spinnerAlert('show');
   // sessionStorage.setItem('doctypelist',page);
   this.noDataFlag=false;
  //  if(this.selectedOption1="DOJ This Month"){
  //   // alert("sucess")
  //   const data={
  //     page:page,
  //     type:this.selectedOption1,
  //     // searchkey:this.searchdata
  //     searchkey:search
  //   }
  // }
  // else{
  //   const data={
  //     page:page,
  //     type:this.selectedOption1,
  //     // searchkey:this.searchdata
  //     searchkey:search
  //   }
  // }
  // "page": 1, "type": "newlyjoined", "status":"verified"}
  const data1={
    page:page,
    type:this.selectedOption1,
    
    status:"verified"
  }
  const data={
    page:page,
    type:this.selectedOption1,
    
    status:"active"
  }
    if(!search)
    {
      // this.searchflag=false;
      // this.noDataFlag=false;
      if (this.selectedType === 'provider') {
        this.pushprovidersView(this.page, 'provider')
        this.selectedType1='provider';
        
      } else {
        this.pushusersView(this.page, 'user')
        this.selectedType1='user';
      }
      // this.getProviders(this.currentpage,this.searchStatus);
      //this.getprovidersSearch(this.currentpage);
  
    }
    else{
    // alert(data)
  
    if (this.selectedType === 'provider') {
    this.service.providerFilterListView(data1).subscribe((res) => {
      if (res.body['error']) {
       
        this.noDataFlag=true;
        this.pages = 0
        this.result = []
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
    var a=0
        if (res.body['data'][0].data.length > 0 ) {
          this.noDataFlag=false;
          // alert("kkkkk")
          this.pages = Math.ceil(res.body['data'][1].Count/2);
          this.result = res.body['data'][0].data;
          console.log("filtercheck",res.body['data'][0].data)
          this.appspinner.spinnerAlert('hide'); 
        }
        else {
         
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
      },
      (err) => {
        console.log(err);
    })
  }
  else {
    this.service.userFilterListView(data).subscribe((res) => {
      if (res.body['error']) {
       
        this.noDataFlag=true;
        this.pages = 0
        this.result = []
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
    var a=0
        if (res.body['data'][0].data.length > 0 ) {
          this.noDataFlag=false;
          
          this.pages = Math.ceil(res.body['data'][1].Count/2);
          this.result = res.body['data'][0].data;
          console.log("filtercheck",res.body['data'][0].data)
          this.appspinner.spinnerAlert('hide'); 
        }
        else {
         
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
      },
      (err) => {
        console.log(err);
    })
  }
  }
  }
  reloadWindow() {
     window.location.reload();
    // this.type = '';
    // this.name = '';
    // this.selectedOption1 = '';
  }







  
}


