import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VendorServiceService } from '../vendorService/vendor-service.service';
import { environment } from 'src/environments/environment';
import { FormBuilder } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.css']
})
export class VendorListComponent implements OnInit {

  ActiveVendor: any;
  InactiveVendor: any;
  limit = 10;
  page = 1;
  pages:any;
  currentTab: String='active';
  InactiveLength: number = 0;
  vendorState : any;
  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  constructor(private vendorService:VendorServiceService,private fb:FormBuilder) { }

  ngOnInit() {
    sessionStorage.setItem('bookinglistpage11','0');
    this.VendorFormInit();
    this.PendingVendorList(1);
    this.ActiveVendorList();
    // this.PendingVendorList(1);
  }

  VendorFormInit(){
    this.vendorState = this.fb.group({
      "FirstName" : [""],
      "mobile" : [""],
      "email" : [""],
      "password" : [""]
    })
  }

  // Active vendor lisr
  ActiveVendorList(){
    try {
      let params = {
        page : this.page,
        limit : this.limit
      }
      this.vendorService.VendorActiveListService(params).subscribe((res:any) => {
        if(!res.error){
          this.currentTab = 'active';
          this.ActiveVendor = res.data.vendors;
          this.pages = res.data.pagination.total;
        }else{
          environment.swalalert('error',res.msg);
        }
      })
    } catch (error) {
      environment.swalalert('error',error.error.message);
    }
  }

  PendingVendorList(page:any){
    try {
      let params = {
        page : page,
        limit : this.limit
      }
      this.vendorService.vendorInActiveListService(params).subscribe((res:any) => {
        if(!res.error){
          this.currentTab = 'pending';
          this.InactiveVendor = res.data.vendors;
          this.InactiveLength = res.data.pagination.total;
          this.pages = res.data.pagination.total;
        }else{
          environment.swalalert('error',res.msg);
        }
      })
    } catch (error) {
      environment.swalalert('error',error.error.message);
    }
  }


  // Add New Vendor:
  AddVendor(){
    try {
      this.vendorService.CreateVendor(this.vendorState.value).subscribe((res:any) => {
        if(res.error == false){
          environment.swalalert('success',res.mes);  
          this.CloseAddModal();
          this.PendingVendorList(1);
        }else{
          environment.swalalert('error',res.msg);
        }
      })
    } catch (error) {
      console.log('===Catch Error',error.error.message)
    }
  }

  CloseAddModal(){
    this.closeAddModal.nativeElement.click();
  }

  GetSingleVendor(Vdet:any){
    try {
      console.log('===',Vdet)
    } catch (error) {
      console.log('===Catch Error',error.error.message);
    }
  }

  ApproveVendor(VendorId:any){
    try {
      this.vendorService.UpdateVendor(VendorId,{ Status:'active' }).subscribe((res:any) => {
        if(res.error == false){
          environment.swalalert('success',res.msg);
          this.PendingVendorList(1);
          this.ActiveVendorList();
        }else{
          environment.swalalert('error',res.msg);
        }
      })
    } catch (error) {
      console.log('===Catch Error',error.error.message);
    }
  }

  RejectVendor(VendorId:any){
    try {
      this.vendorService.UpdateVendor(VendorId,{ Status:'inactive' }).subscribe((res:any) => {
        if(res.error == false){
          environment.swalalert('success',res.msg);
          this.PendingVendorList(1);
        }else{
          environment.swalalert('error',res.msg);
        }
      })
    } catch (error) {
      console.log('===Catch Error',error.error.message);
    }
  }

  HandleVendor(event,type){
    this.page = event;
    if(type == 'active'){
      this.ActiveVendorList()
    }else{
      this.PendingVendorList(event);
    }
  }
}
