import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute,RouterLinkActive } from '@angular/router';
import { GuardGuard } from '../guard.guard';

import { from } from 'rxjs';
import { EncryService } from '../encry.service';

import { environment } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../login/login.service';
import { ValidationService } from '../login/validation.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  returnUrl:string;
  resetpasswordForm:any;
  forgotemail:any;
  constructor(
    private formBuilder:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private guard:GuardGuard,
    private encry:EncryService,
    private service:LoginService,
    private location: Location,
    private appspinner: AppComponent,
    private cookie: CookieService) {   
      this.resetpasswordForm = this.formBuilder.group({
        'password' : ['',[Validators.required,Validators.minLength(8)]],
        'confirmpassword' : ['',[Validators.required,Validators.minLength(8)]]
      
      });
    }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['return'] || 'dashboard'; 
    this.forgotemail=sessionStorage.getItem('otpemail');
    console.log("Token::") ;

    console.log("Token::",this.cookie.get('Token')) ;
    if (localStorage.getItem('Token')) {
      this.router.navigateByUrl(this.returnUrl);
    }           
  }
  resetpassword(){
    if(this.resetpasswordForm.value.password!==this.resetpasswordForm.value.confirmpassword){
      environment.swalalert('error',"Passwords not Match");
      return
    }
    if(this.resetpasswordForm.value.password.length<8||this.resetpasswordForm.value.confirmpassword<8){
      environment.swalalert('error',"Passwords must be 8 characters");
      return
    }
    if(this.resetpasswordForm.dirty && this.resetpasswordForm.valid) {
      var data = {Email:this.forgotemail,otp:sessionStorage.getItem('otp'),password:this.resetpasswordForm.value.password};     
      this.service.updatePwd(data).subscribe(async res => {
        if(res.body['error'] === false ) {
          console.log(res)
       
            environment.swalalert('success',res.body['msg']).then(value => {
            if(value) {
             // sessionStorage.setItem('otpemail',this.resetpasswordForm.value.email)
              this.router.navigateByUrl('login');
            }
            });
        } else {
        environment.swalalert('error',res.body['msg']);
        }
      });
    }     
     else {
      environment.swalalert('warning','Validation');
      }
  }

}
