import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { from } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BookingsViewModel } from './adminbookings.model';

import { AdminBookingsService } from './adminbookings.service';
@Component({
  selector: 'app-adminbookings',
  templateUrl: './adminbookings.component.html',
  styleUrls: ['./adminbookings.component.css']
})
export class AdminbookingsComponent implements OnInit {
  page: number = 1;
  pages: any;
  commonpage: number = 1;
  searchdata: String;
  bookingsview: any;
  // bookingsview: BookingsViewModel;
  bookingslist: any[];
  currentpage:number;
  noDataFlag:Boolean=false;

   //................................................
   dropdownOptions: string[] = ['CRN Number','Today','Week','Month','CustomRange']
   selectedValue1:String;
   isMenuOpen = false;
   option:String;
   filtersearch:String;
   dateFlag:Boolean=false
   startdate:String;
     enddate:String;
     fulldate:String;
     dataFlag:Boolean=true;
     maxDate: Date;
     searchflag:Boolean=true;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: AdminBookingsService,
    private appspinner: AppComponent,
    private cookie: CookieService 
  ) {


    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate()); 
  }
  ngOnInit() {

    localStorage.setItem('adminBooking','admin')
    localStorage.setItem('ridelaterbookings','')
localStorage.setItem('adminroutebookings','0')
localStorage.setItem('bookings','')
    if(sessionStorage.getItem('adminbookingslistpage')){
      console.log(this.page);
     
      this.currentpage=parseInt(sessionStorage.getItem('adminbookingslistpage'))
    
      
    }else{
     
      this.currentpage=1;
    }
    this.appspinner.spinnerAlert('show');
    this.service.adminProviderListView(this.currentpage).subscribe((res) => {
      console.log(res);
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        if(sessionStorage.getItem('adminbookingslistpage')){
       
          this.page=parseInt(sessionStorage.getItem('adminbookingslistpage'))
         
          
        }
        this.bookingsview = res;
        console.log(this.bookingsview);
        
        if (res.body['data'][0].data.length > 0 ) {
          this.pages = Math.ceil(this.bookingsview.body['data'][1].Count/2);
          this.bookingslist = this.bookingsview.body['data'][0].data;
          this.appspinner.spinnerAlert('hide');
        } else {
          environment.swalalert('nodata', 'No Data Available ');
          this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
          // this.cookie.delete('Token');
          localStorage.removeItem('Token');
          this.router.navigate(['login']);
        }
      })
  }

  navigateTo(route: string, id: number) {
    // alert(route)
    this.router.navigate([route,id]);
    this. checkrouteractive(id)
  }
  getBookingsView(page) {
    sessionStorage.setItem('adminbookingslistpage',page)
    this.page = page;
    this.appspinner.spinnerAlert('show');
    // this.cookie.set('bookingslist', page);
    localStorage.setItem('bookingslist', page);
    
    if (this.searchdata !== undefined && this.searchdata.length !== 0) {
      console.log(this.searchdata.length);
    } else {
      this.searchdata = '';
    }
    if (this.searchdata.length > 0) {
     this.findname(this.searchdata);
    } else {

    this.service.adminProviderListView(page).subscribe((res) => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else { 
        this.bookingsview = res;
       
        if (res.body['data'][0].data.length > 0 ) {
          this.pages = Math.ceil(this.bookingsview.body['data'][1].Count/2);
          this.bookingslist = this.bookingsview.body['data'][0].data;
          this.appspinner.spinnerAlert('hide');
        } else {
          environment.swalalert('nodata', 'No Data Available ');
          this.appspinner.spinnerAlert('hide');
        }
      }
      },
        (err) => {
          console.log(err);
        }
        );
    }
  }


  reset() {
    this.searchdata = '';
    this.getBookingsView(this.commonpage);
  }

  cancelBooking(data) {
    const apidata = { bookingNo: data.Id,reason:'Others',isFrom: 'admin'
  } ;
  console.log(apidata);
  
    
      this.service.cancelBooking(apidata).subscribe((res) => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');

        } else {
        if(res.body['error'] === false ) {
          environment.swalalert('success', res['msg']).then(value => {
            if(value) {
             
            }
                this.getBookingsView(this.page)

            });
        } else {
          environment.swalalert('error',res.body['msg']);
        }
      }
      },

      (err) => {
        console.log(err);
      });
      
    }
  findname(search) {
    var searchData = search
    if(searchData.split("/").length = 3){
      var splitteddata = searchData.split("/")
      // var date = splitteddata[2]+"%2F"+splitteddata[1]+"%2F"+splitteddata[0]
      // searchData = date
    }
    // if(searchData.split("/").length = 3){
    //   var splitteddata = searchData.split("/")
    //   var date = splitteddata[2]+"%2F"+splitteddata[1]+"%2F"+splitteddata[0]
    //   searchData = date
    // }
    var data = { search: searchData, typename: 'adminBooking', page: this.page ,status:'now'}
    this.service.bookingsearchdataView(data).subscribe((res:any) => {
      if (res['error']) {
        this.noDataFlag=true;
        this.pages=0
        this.bookingslist=[]
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } 
      else {
        this.noDataFlag=false
        console.log("adminbookingdddd",res['data'])
        // console.log("admin search",res.body['data'][0].data)
        // this.bookingsview = new BookingsViewModel(res['error'], res['msg'], res['data']);
        if (res['data'][0].data.length > 0 ) {
          this.pages = Math.ceil(res['data'][0].Count/2);
          this.bookingslist = res['data'][0].data;
          this.appspinner.spinnerAlert('hide');

        } else {
          // environment.swalalert('nodata', 'No Data Available ');
          // this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  }


  AutogetBookingsView() {
    this.service.adminProviderListView(this.page).subscribe((res:any) => {
      if (res.body['error']) {
        environment.swalalert('nodata', res['msg']);
      } else {
        this.bookingsview =res;
        if (res['data'] && res['data'].length > 0 && this.bookingsview['data'][0].data.length > 0) {
          this.pages = Math.ceil(this.bookingsview['data'][0].Count/2);
          this.bookingslist = this.bookingsview['data'][0].data;
        } else {
          environment.swalalert('nodata', 'No Data Available ');
        }
      }
    },
      (err) => {
        console.log(err);
      })
  }
  ngOnDestroy(){
    clearInterval(this.setintervalid)
  }
  public setintervalid = setInterval(() => {
    this.AutogetBookingsView();
  }, 5000);

 
  checkrouteractive(page){
    // alert("gffffddd")
    sessionStorage.setItem('adminroutebookings',page)
   }
   




   //................................................
   toggleMenu() {
    // alert("hi")
    this.isMenuOpen = !this.isMenuOpen;
    
  }

  selectOption(option: string) {
    // alert(this.isMenuOpen)
    this.selectedValue1 = option;
    this.isMenuOpen=false
  
    this.onChangeSearchkeytype(option)
   
  }

  onChangeSearchkeytype(data){
    this.searchflag=false
   this.option=data;
   
    if(data=="CRN Number"){
      this.filtersearch="crn"
      this.isMenuOpen=false
      this.toggleMenu()
      this.dateFlag=false
     this.dataFlag=true;
     
    // alert("success")
    // alert(this.searchdata)
     this.findname(this.searchdata)
      // this.selectedOption1=data;
      // this.getallproviders(1,"name")
    // this.selectedOption1="customrange";
    }
    if(data=="Today"){
this.filtersearch="doj"
this.isMenuOpen=false
this.toggleMenu()
this.dateFlag=false
this.dataFlag=false
this.searchdata=data
this.checksearch(data)
      // this.selectedOption1=data;
      // this.getallproviders(1,"name")
    // this.selectedOption1="customrange";
    }
    if(data=="Week"){
      this.filtersearch="doj"
            this.isMenuOpen=false
            this.toggleMenu()
            this.dateFlag=false
            this.dataFlag=false
            this.searchdata=data
            this.checksearch(data)
            // this.selectedOption1=data;
            // this.getallproviders(1,"name")
          // this.selectedOption1="customrange";
          }
          if(data=="Month"){
            this.filtersearch="doj"
            this.isMenuOpen=false
            this.toggleMenu()
            this.dateFlag=false
            this.dataFlag=false
            this.searchdata=data
            this.checksearch(data)
                  // this.selectedOption1=data;
                  // this.getallproviders(1,"name")
                // this.selectedOption1="customrange";
          }
      if(data=="CustomRange"){
        this.filtersearch="customrange"
                        this.isMenuOpen=false
                        this.toggleMenu()
                       this.dateFlag=true
                       this.dataFlag=false
                       this.checksearch(data)
                        // this.selectedOption1=data;
                        // this.getallproviders(1,"name")
                      // this.selectedOption1="customrange";
                      }
    
  }
  // reloadWindow() {
  //   if (!this.isWindowReloaded) {
  //     window.location.reload();
  //     this.isWindowReloaded = true;
  //   }
  // }
  reloadWindow() {
    // this.filterFlag=true
    // this.filterkeyFlag=false
    // this.searchflag=true
    this.searchflag=true
    this.searchdata=""
    window.location.reload();
  }
  checksearch(data){
    if(this.searchflag===true)
    {
     this. findname(data)
this.searchflag=true
    }
    else
    {
    if(this.option=="CRN Number"){
  this.findname1(this.searchdata)
    }
    if(this.option=="Today"){
      this.findname1("today")
        }
        if(this.option=="Month"){
          this.findname1("month")
            }
            if(this.option=="Week"){
              this.findname1("week")
                }
                // if(this.option=="CustomRange"){
                //   this.findname1()
                //     }
  }
  }
  findname1(search) {
    const data1={
      page:1,
      type:this.filtersearch,
      // searchkey:this.searchdata
      searchkey:search,
      bookingType:"admin"
    }
//     param:-page,searchkey,bookingType,type
//     type=crn
// searchkey=CRN 1687779518219
// example 2
// type=doj
// searchkey=today,week,month
    // alert("ffff")
    ////debugger
    var searchData = search
    if(searchData.split("/").length = 3){
      var splitteddata = searchData.split("/")
      // var date = splitteddata[2]+"%2F"+splitteddata[1]+"%2F"+splitteddata[0]
      // searchData = date
    }
    console.log("aaaa....",searchData);
    
    // var data = {search: search, typename: 'bookings', page: this.page ,status:'later'}
    
    this.service.bookingFilterListView(data1).subscribe((res:any) => {
      if (res.body['error']) {
       
        this.noDataFlag=true;
        this.pages=0
        this.bookingslist=[]
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } 
      else {
        this.noDataFlag=false
        // console.log("adminbookingdddd",res['data'])
        this.bookingsview = new BookingsViewModel(res['error'], res['msg'], res['data']);
        // if (res['data'].length > 0 && this.bookingsview['data'][0].data.length > 0) {
        //   this.pages = this.bookingsview['data'][0].Count;
        //   this.bookingslist = this.bookingsview['data'][0].data;

        // if (this.bookingsview['data'][0].data.length > 0) {
          if (res.body['data'][0].data.length > 0) {
          // console.log(res);
          this.pages=0
          // this.pages = this.bookingsview['data'][1].Count;
          // this.bookingslist = this.bookingsview['data'][0].data;
          this.bookingslist = res.body['data'][0].data
          this.appspinner.spinnerAlert('hide');

        } else {
          // environment.swalalert('nodata', 'No Data Available ');
          // this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      });




      

  
  }
  onDateRangeChange(event: any) {
    const startDate = this.formatDate(event.startDate);
    const endDate =this.formatDate( event.endDate);
    if(startDate=="1970-01-01"){
      this.fulldate=" "
      this.searchdata=this.fulldate
        }
        else{
    this.startdate=startDate
    this.enddate=endDate
    this.fulldate=this.startdate+","+this.enddate
    this.searchdata=this.fulldate
    this.findname1(this.fulldate)
    this.dateFlag=false
        }
    
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  }


}
