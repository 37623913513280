import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute,RouterLinkActive } from '@angular/router';
import { GuardGuard } from '../guard.guard';
import { ValidationService } from './validation.service';
import { from } from 'rxjs';
import { EncryService } from '../encry.service';
import { LoginService } from './login.service';
import { environment } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit ,OnDestroy{
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  submitted = false;
  returnUrl:string;
  loginForm:any;
  constructor(
    private formBuilder:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private guard:GuardGuard,
    private encry:EncryService,
    private service:LoginService,
    private location: Location,
    private appspinner: AppComponent,
    private cookie: CookieService) {   

      
      this.loginForm = this.formBuilder.group({
        // 'email' : ['',[Validators.required,ValidationService.emailValidator]],
        // 'password' : ['',[Validators.required,Validators.minLength(9)]]
          'email' : ['',[Validators.required,ValidationService.emailValidator]],
        'password' : ['',[Validators.required,Validators.minLength(9)]]
        // 'DocType' : ['',[Validators.required,Validators.minLength(1)]],
      });
    }

  // ngOnInit() {
  //   this.returnUrl = this.route.snapshot.queryParams['return'] || 'dashboard'; 
    
  //   console.log("Token::") ;

  //   console.log("Token::",this.cookie.get('Token')) ;
  //   if (localStorage.getItem('Token')) {
  //     this.router.navigateByUrl(this.returnUrl);
  //   }           
  // }
  // login(){
  //   if(this.loginForm.dirty && this.loginForm.valid) {
  //     var data = {Email:this.loginForm.value.email,Password:this.loginForm.value.password};     
  //     this.service.login_authentication(data).subscribe(async res => {
  //       if(res.body['error'] === false ) {
  //         console.log(res)
  //       localStorage.setItem('Token', res.body['data'].token );
  //         this.cookie.set('Token', res.body['data'].token );
  //       console.log(res.body['data'].token);
  //           environment.swalalert('success',res.body['msg']).then(value => {
  //           if(value) {
  //             this.router.navigateByUrl(this.returnUrl);
  //           }
  //           });
  //       } else {
  //       environment.swalalert('error',res.body['msg']);
  //       }
  //     });
  //   }     
  //    else {
  //     environment.swalalert('warning','Validation');
  //     }
  // }

  ngOnInit() {

    
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      // this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      // console.log('Online...');
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      // console.log('Offline...');
      // environment.swalalert('warning','Connection lost! You are not connected to internet');
    }));
  
    this.returnUrl = this.route.snapshot.queryParams['return'] || 'dashboard';
    if (localStorage.getItem('Token')) {
      this.router.navigateByUrl(this.returnUrl);
    }
    localStorage.getItem('Token');
  }

  get f() { return this.loginForm.controls; }
  login() {
    ////debugger
    // if(this.offlineEvent)
    if(this.connectionStatus=="offline")
    {
      // alert("online")
      // alert(this.connectionStatus)
      environment.swalalert('warning','Please check your internet connection!');
    }
    // else{
    //   // alert("offline")
    //   // environment.swalalert('warning','Connection lost! You are not connected to internet');
    // }
    // this.submitted = true;
    else if (this.loginForm.pristine) {
    //  else if (this.loginForm.get('password').value ==" " && this.loginForm.get('email').value==" ") 
    // else if (this.loginForm.get('password').value ==" " && this.loginForm.get('email').value==" ") 
    //  {
      environment.swalalert('warning','Enter The  Email Id And Password');
    } 
    // else {
      
    // }
    // stop here if form is invalid
    // if (this.loginForm.email.value.invalid) {
      // if (this.loginForm.value.email) {
    else  if(this.loginForm.get('password').invalid){
    // if (this.loginForm.invalid) {
      // if (this.loginForm.valid) {
        // environment.swalalert('warning','Invalid Email');
        environment.swalalert('warning','Your password must be at least 9 characters');
      // alert("success")
      // var aa=1;
      // if (this.loginForm.value.email) {
        // return;
        // environment.swalalert('error',res.body['msg']);
      // environment.swalalert('warning','Validation Required');
      // environment.swalalert('warning','Validation Required');
    }
    // else if(this.loginForm.value.password){
      else if(this.loginForm.get('email').invalid){
          environment.swalalert('warning','Invalid Email');
    //   environment.swalalert('warning','Your password must be at least 9 characters');
    }
    else{
    var data = { Email: this.loginForm.value.email, Password: this.loginForm.value.password };
    this.service.login_authentication(data).subscribe(async res => {
      if (res.body['error'] === false) {
        ////debugger
        var ss=JSON.stringify(res.body['data']) 
        // alert("ssss"+ss)
        // sessionStorage.setItem('Token', res.body['data'].token );
        
        localStorage.setItem('Token', res.body['data'].token);
        // var dd=res.body['data'].token
        
        // alert(dd)
        localStorage.setItem('UserRole', JSON.stringify(res.body['data'][0]) );
        // localStorage.setItem('AllData', JSON.stringify(res.body['data']) );
        // this.getnavigation()
        // console.log(localStorage.getItem('token'));
        environment.swalalert('success', res.body['msg']).then(value => {
          if (value) {
            // if (navigator.onLine) {

            console.log(res.body['data'].Email);

             if(res.body['data'].Email=='outletadd@gmail.com'){
              this.router.navigateByUrl('outletAdd');
             }else{
            this.router.navigateByUrl(this.returnUrl);
             }
            // window.location.reload();
          }
        });
      } else {
        environment.swalalert('error', res.body['msg']);
        // environment.swalalert('', res.body['msg']);
        // environment.swalalert('warning','Validation Required');
      }
    
    
    });
  }

    
  }
  // getnavigation(){
   
  //   ////debugger
  //      this.service.getRoles().subscribe((res) => {
         
  //      if (res.body['error']) {
  //        environment.swalalert('nodata', res['msg']);
  //        this.appspinner.spinnerAlert('hide');
  //      } else {
  //      var rs = res.body['data'];
  //     //  localStorage.setItem('UserRole', JSON.stringify(res.body['data'][0]) );
  //     // console.log("login111",rs[0].Navigation)
  // //     this. LocalStoreageData1  = JSON.parse(localStorage.getItem('UserRole') )
  // //  this.Navigation1=this.LocalStoreageData1.Navigation
  // //  console.log("navigaation111 ",this.Navigation1[0])
  //    this.appspinner.spinnerAlert('hide');
  //    }
  //    })
  //  }

  ngOnDestroy(): void {
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.offlineEvent = fromEvent(window, '');
    this.connectionStatus= " ";
  }
}
