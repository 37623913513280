import { Component, OnInit,ViewChild } from '@angular/core';
import * as $ from "../../../assets/js/jquery.3.2.1.min.js";
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { from } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ScrollEvent } from 'ngx-scroll-event';
import { GroupModel } from './group.model';
import{GroupService} from'./group.service';
 import { GrdFilterPipe } from 'src/app/grd-filter.pipe';
//import {DataTablesModule} from 'angular-datatables';
declare var $;
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  filteredItems: any[];
searchText: string;
  typeview: GroupModel;
  result: any;
  type1:any;
  page: number = 1;
  pages: any;
  search:any;
  searchTypeGlobal:String;
  searchFlag:Boolean=false;
  @ViewChild('dataTable') table;
  noDataFlag:Boolean=false;
  dataTable: any;
  dtOptions: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: GroupService,
    private appspinner: AppComponent,
    private cookie: CookieService
  ) { }
  //dtOptions: DataTables.Settings = {};
 
  //  ngDoCheck(){
  //     this.updateeditedroles();

  //   }
  // ngOnDestroy()
  // {
  //   // this.result="";
  //   // this.result
  // }
// ngOnDestroy()
// {
//   this.appspinner.spinnerAlert('show');
//     this.appspinner.spinnerAlert('hide');
//   this.pushprovidersView('user',1); 

// }
  ngOnInit() {
    // this.filterItems()
    this.pushprovidersView('user',1);
    sessionStorage.setItem('groupList11','0')
    localStorage.setItem('groupList','groupList')
    // alert(localStorage.getItem('groupList'))
    localStorage.setItem('groupParam','0')
    this.appspinner.spinnerAlert('show');
    this.appspinner.spinnerAlert('hide');
   // window.location.reload();
    // window.onload = function() {
    //   if(!window.location.hash) {
    //   // window.location = window.location + #loaded;
    //     window.location.reload();
    //   }
    // }
    // window.location.reload();
    // window.location.reload();
    this.pushprovidersView('user',1);
   }

  // pushprovidersView(page, type)
  // getCancellationPolicyView(type,page) {
  //   this.appspinner.spinnerAlert('show');
  //   // this.cookie.set('cancellationpolicylist',page);
  //   localStorage.setItem('cancellationpolicylist',page);
  //      this.type=type;
  //     this.service.cancellationpolicyListView(type,page).subscribe((res) => {
  //       if (res['error']) {
  //         environment.swalalert('nodata', res['msg']);
  //         this.appspinner.spinnerAlert('hide');
  //       } else {
  //       this.cancellationpolicyview = new CancellationpolicyViewModel(res.body['error'],res.body['msg'], res.body['data']);
  //       if (res.body['data'] && res.body['data'].length > 0 && this.cancellationpolicyview['data'][0].data.length > 0) {
  //         this.pages = this.cancellationpolicyview['data'][0].Count;
  //         if(type=='provider'){
  //           this.resultProvider = this.cancellationpolicyview['data'][0].data;
  //           this.pages = this.cancellationpolicyview['data'][0].Count;
  //         }
  //         else
  //         {
  //           this.resultUser = this.cancellationpolicyview['data'][0].data;
  //           this.pages = this.cancellationpolicyview['data'][0].Count;
  //         }
  //         this.appspinner.spinnerAlert('hide');
  //       } else {
  //         environment.swalalert('nodata', 'No Data Available ');
  //         this.appspinner.spinnerAlert('hide');
  //       }
  //     }
  //       },
  //       (err) => {
  //         console.log(err);
  //     })
  //   }
  pushprovidersView(type,page) {
    ////debugger
    
    if(this.searchFlag===false)
    {

    
    this.searchTypeGlobal=type;
    // this.appspinner.spinnerAlert('show');
    this.type1=type;
    
    ////debugger
     localStorage.setItem('emailtemplatepageslist', page);
    // this.type=type;
    this.service.pushproviderviewList(this.type1,page).subscribe((res: any) => {
      console.log()
      ////debugger
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      this.typeview = new GroupModel(res.body['error'],res.body['msg'], res.body['data']);
      if (res.body['data'] && res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
       
        if(this.type1=='provider'){
          this.type1=type;
           this.result = res.body['data'][0].data;
          console.log("provider",this.result)
          this.pages=  Math.ceil(this.typeview['data'][0].Count / 2);
          
          this.appspinner.spinnerAlert('hide');
        }
        else
        {
          
          this.type1=type;
          this.result = res.body['data'][0].data;
          console.log("user",this.result)
          this.pages=  Math.ceil(this.typeview['data'][0].Count / 2);
          this.appspinner.spinnerAlert('hide');
        }
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
      //................................................
      // if (res.body['error']) {
      //   environment.swalalert('nodata', res['msg']);
      // } else {

      //   this.typeview = new GroupModel(res.body['error'], res.body['msg'], res.body['data']);
      //   if (res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
      //   this.pages = this.typeview['data'][1].Count;
      //    this.result = this.typeview['data'][0].data;
      //    // this.result = res.body['data'];
          
      //     console.log("Create group users",this.result)
      //     this.result.map(x => {
      //       x['isSelected'] = false;
      //     });
      //   } else {
      //     environment.swalalert('nodata', 'No Data Available ');
      //   }
      // }
    },
      (err) => {
        console.log(err);
      });
    }
  }

  PayoutModal(data){

  }
  providerUpdate(Id,status,r,i){

  }

  searchGroup(search) {
    
    ////debugger
   
   console.log("dsadafaf",this.type1)
var x="0";
    if(!search)
    {
      // if(this.type1=="provider"){
        //  if(this.searchTypeGlobal==="user"){
        // if(x=="0"){
          if(this.type1=='provider'){
        
      this.pushprovidersView('user',1);
// this.pushprovidersView('user',1);
      }
      else if(this.type1=="user"){
        // else if(x=="1"){
       
        this.pushprovidersView('user',1);
       // this.pushprovidersView('provider',1);
      }
    }
    else{

    // if(this.type1=="user")
    // {


      // this.service.groupsearchdataView(search,'user',1)
     // this.service.groupsearchdataView(search,this.type1,this.page).subscribe((res) => {
      // this.service.groupsearchdataView(search,'user',1).subscribe((res) => {
        
        this.service.groupsearchdataView(search,this.searchTypeGlobal,1).subscribe((res) => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        this.typeview = new GroupModel(res['error'],res['msg'], res['data']);
      //  if (res['data'] && res['data'].length > 0 && this.typeview['data'][0].data.length > 0) {

        // this.emailtemplate = new EmailTemplateViewModel(res['error'], res['msg'], res['data']);
        if (res['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
          if(this.type1=='provider'){
            ////debugger
          console.log("testbbbbbbbbbbbbbbbbbbbbb",res['data'][1])
          //this.pages = this.providermodel['data'][1].Count;
          //this.rideHistory =  this.providermodel['data'][0].data;
          //this.pages=res['data'][1].Count;
           this.pages = res['data'][0].Count;
           this.result = res['data'][0].data;
          console.log("test bbbbbbbbbbb",this.result)
//          console.log("search ride history resultHistory",this.result)
          this.appspinner.spinnerAlert('hide');
          }
          else if(this.type1== 'user'){
            ////debugger
            this.pages = res['data'][0].Count;
           this.result = res['data'][0].data;
          console.log("test bbbbbbbbbbb",this.result)
          this.appspinner.spinnerAlert('hide');

          }
        } else {
          // environment.swalalert('nodata', 'No Data Available ');
          // this.appspinner.spinnerAlert('hide');
        }
      }
    },
      (err) => {
        console.log(err);
      });
  
    //  }

    // else if(this.type1=="provider")
    // {
    //   alert("provider")
    // }
  }
  }

  pushprovidersView1(type4,page) {
    ////debugger
    if(this.searchFlag===false){

    
    this.searchTypeGlobal=type4;
   
    ////debugger
     localStorage.setItem('emailtemplatepageslist', page);
    // this.type=type;
    this.service.pushproviderviewList(this.searchTypeGlobal,page).subscribe((res: any) => {
      console.log()
      ////debugger
      if (res['error']) {
        this.noDataFlag = true;
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      this.typeview = new GroupModel(res.body['error'],res.body['msg'], res.body['data']);
      if (res.body['data'] && res.body['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
      
           this.noDataFlag = false;
           this.result = res.body['data'][0].data;
         
           this.pages=  Math.ceil(this.typeview['data'][0].Count / 2);
          this.appspinner.spinnerAlert('hide');
       
         
        
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
      
    },
      (err) => {
        console.log(err);
      });
    }
  }


  searchGroup1(search) {
    ////debugger
    //alert(this.type1)
  this.searchFlag=true;

    if(!search)
    {
      // if(this.type1=="provider"){
        //  if(this.searchTypeGlobal==="user"){
        // if(x=="0"){
          this.searchFlag=false;
          if(this.searchTypeGlobal=='provider'){
        
      this.pushprovidersView1('provider',1);
// this.pushprovidersView('user',1);
      }
      else if(this.searchTypeGlobal=="user"){
        // else if(x=="1"){
        
        this.pushprovidersView1('user',1);
       // this.pushprovidersView('provider',1);
      }
    }
    else{

    // if(this.type1=="user")
    // {
      if(this.searchFlag===true){

      


     
        this.service.groupsearchdataView(search,this.searchTypeGlobal,1).subscribe((res) => {
      if (res['error']) {
        this.noDataFlag=true;
        this.pages=0
        this.result=[]
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
        this.noDataFlag=false
        this.typeview = new GroupModel(res['error'],res['msg'], res['data']);
      //  if (res['data'] && res['data'].length > 0 && this.typeview['data'][0].data.length > 0) {

        // this.emailtemplate = new EmailTemplateViewModel(res['error'], res['msg'], res['data']);
        if (res['data'].length > 0 && this.typeview['data'][0].data.length > 0) {
         
          console.log("testbbbbbbbbbbbbbbbbbbbbb",res['data'][1])
          
           this.pages = res['data'][0].Count;
           this.result = res['data'][0].data;
          console.log("test bbbbbbbbbbb",this.result)

          this.appspinner.spinnerAlert('hide');
         

          
        } 
      }
    },
      (err) => {
        console.log(err);
      });
  
    //  }

    // else if(this.type1=="provider")
    // {
    //   alert("provider")
    // }
      }
  }
  }

  navigateTo(route: string, id: number) {
    this.checkrouteractive(id)
    // alert(sessionStorage.getItem('ridelaterbookings'))
    this.router.navigate([route,id]);
  }
  checkrouteractive(page){
   
    // localStorage.setItem('groupList','groupList')
    // localStorage.setItem('groupParam','page')
    // alert(localStorage.getItem('groupList'))
    sessionStorage.setItem('groupList11',page)
    
    
   }
   filterItems() {
    ////debugger
    if (!this.searchText) {
      this.filteredItems = this.result;
      return;
    }
  
    this.filteredItems = this.result.filter(item => {
      // Apply your filtering logic here
      // For example, check if the item's name contains the search text
      return item.name.toLowerCase().includes(this.searchText.toLowerCase());
      
    });
    // console.log("filter check",this.filteredItems)
  }
}
