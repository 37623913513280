import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  MinLengthValidator,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { from } from "rxjs";
import { GuardGuard } from "src/app/guard.guard";
import { AppConfigModelList } from "./appconfigview.model";
import { AppConfigModel } from "./appconfigview-list.model";
import { AppconfigviewService } from "./appconfigview.service";
import { environment } from "../../../environments/environment";
import * as $ from "../../../assets/js/jquery.3.2.1.min.js";
import { AppComponent } from "src/app/app.component";
import { CookieService } from "ngx-cookie-service";
import { APPConfigData } from "./appconfigmodel.model";
import { APPConfigEmailData } from "./appconfigemail.model";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Component({
  selector: "app-appconfigview",
  templateUrl: "./appconfigview.component.html",
  styleUrls: ["./appconfigview.component.css"],
})
export class AppconfigviewComponent implements OnInit {
  AppConfigData: APPConfigData;
  AppConfigEmailData:APPConfigEmailData;
  AppconfigAddEmailForm:any;
  AppconfigAddForm: any;
  //promovtype: PromoCodeAddModel;
  doctypeEditForm:any;
  returnUrl: any;
  sessionpage: number;
  appconfigmodel: AppConfigModel;
  appconfiglist: AppConfigModelList;
  userresult: any = [];
  providerresult: any = [];
  emailresult: any = [];
  //fields user
  userAuthType:any;
  userOtpTimer:any;
  userMapApiKey:any;
  userWaitingTime:any;
  userMaxiRange:any;
  userTwilioAuthKey:any;
  //userReferralEnabled:any;
  userReferralEnabled:Boolean=false;
  userReferralAmount:any;
  userReferralDescription:any;
  userReferralThreshold:any;
  userAdminId:any;

refstatus:Boolean;
  //fields of provider
  providerAuthType:any;
  providerOtpTimer:any;
  providerMapApiKey:any;
  providerWaitingTime:any;
 providerpickuppointdistance:any;
 providerDueAmountThreshold:any;
  providerTwilioAuthKey:any;
providerDriverApproval:any;
//Fields Of Email

serverName:any;
portNumber:any;
secureId:any;
userName:any;
password:any;

  vvalue: FormControl;
  eyeview: Boolean = true;
  eyeviews: Boolean = false;
  exportData: any[];
  exportType: String = "gen";
  toggleFieldId:any;
  toggleValue:any;
  walletFlag:Boolean=false;
  thresholdFlag:Boolean=false;
  descriptionFlag:Boolean=false;
  duethresholdFlag:Boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private service: AppconfigviewService,
    private appspinner: AppComponent,
    private cookie: CookieService
  ) {
  this. AppconfigAddEmailForm=this.formBuilder.group({
    'a_servername': ['', [Validators.required, Validators.minLength(1)]],
    'a_portnumber': ['', [Validators.required, Validators.minLength(1)]],
    'a_secureid': ['', [Validators.required, Validators.minLength(1)]],
    'a_username': ['', [Validators.required, Validators.minLength(1)]],
    'a_password': ['', [Validators.required, Validators.minLength(1)]],

  
  });

    this.AppconfigAddForm = this.formBuilder.group({
      'U_AUTH_TYPE': ['', [Validators.required, Validators.minLength(1)]],
      'U_OTP_TIMER': ['', [Validators.required, Validators.minLength(1)]],
      'U_MAP_API_KEY': ['', [Validators.required, Validators.minLength(1)]],
      'U_WAITING_TIME': ['', [Validators.required, Validators.minLength(1)]],
      'U_MAX_RANGE': ['', [Validators.required, Validators.minLength(1)]],
      'U_TWILIO_AUTH_KEY': ['', [Validators.required, Validators.minLength(1)]],
      'U_REFERRAL_ENABLED': ['', [Validators.required, Validators.minLength(1)]],
      'U_REFERRAL_AMOUNT': ['', [Validators.required, Validators.minLength(1)]],
      'U_REFERRAL_DESCRIPTION': ['', [Validators.required, Validators.minLength(1)]],
      'U_REFERRAL_THRESHOLD': ['', [Validators.required, Validators.minLength(1)]],
      'U_ADMIN_ID': ['', [Validators.required, Validators.minLength(1)]],

      'P_AUTH_TYPE': ['', [Validators.required, Validators.minLength(1)]],
      'P_OTP_TIMER': ['', [Validators.required, Validators.minLength(1)]],
      'P_MAP_API_KEY': ['', [Validators.required, Validators.minLength(1)]],
      'P_WAITING_TIME': ['', [Validators.required, Validators.minLength(1)]],
      'P_PICKUP_DISTANCE': ['', [Validators.required, Validators.minLength(1)]],
      'P_Due_Amount_Threshold': ['', [Validators.required, Validators.minLength(1)]],
      'P_TWILIO_AUTH_KEY': ['', [Validators.required, Validators.minLength(1)]],
      // 'description': ['', [Validators.required, Validators.minLength(1)]],
      'P_PROVIDER_APPROVAL_STATUS': [''],
      // 'A_SMTP_SERVER': ['', [Validators.required, Validators.minLength(1)]],
      // 'A_SMTP_PORT': ['', [Validators.required, Validators.minLength(1)]],
      // 'A_SMTP_SECURE': ['', [Validators.required, Validators.minLength(1)]],
      // 'A_SMTP_USER': ['', [Validators.required, Validators.minLength(1)]],
      // 'A_SMTP_PASSWORD': ['', [Validators.required, Validators.minLength(1)]]
    });
  }
  ngOnInit() {
    
    this.appspinner.spinnerAlert("show");
    this.appspinner.spinnerAlert("hide");
    this.appspinner.spinnerAlert("show");
    this.service.appConfigListView().subscribe(
      (res) => {
        if (res.body["error"]) {
          environment.swalalert("nodata", res.body["msg"]);
          this.appspinner.spinnerAlert("hide");
        } else {
          this.appconfigmodel = new AppConfigModel(
            res.body["error"],
            res.body["msg"],
            res.body["data"]
          );
          var fnl = this.appconfigmodel["data"][0].data;
          console.log("hjjhjjhjhjhjh",fnl)
          console.log("user authtype",fnl[0].Value);
          //User
          this.userAuthType=fnl[0].Value;
          this.userOtpTimer=fnl[1].Value;
          this.userMapApiKey=fnl[2].Value;
          this.userWaitingTime=fnl[6].Value;
          // alert(fnl[6].Value)
          this.userMaxiRange=fnl[14].Value;
          this.userTwilioAuthKey=fnl[18].Value;
         
          var checkstatus;
           checkstatus=fnl[36].Value;
          if(checkstatus=="true"){
            this.userReferralEnabled=true
          }
          else{
            this.userReferralEnabled=false
          }
         
          //    alert("hhhh"+this.userReferralEnabled)
          //   this.userReferralEnabled=false
          //   alert("hhhhddd"+this.userReferralEnabled)
          // this.userReferralEnabled=1;
          // this.userReferralEnabled=true;
          //  this.userReferralEnabled="";
          this.userReferralAmount=fnl[21].Value;
          this.userReferralDescription=fnl[38].Value;
          this.userReferralThreshold=fnl[39].Value;
         //Provider
         this.userAdminId=fnl[20].Value;
         this.providerAuthType=fnl[3].Value;
         this.providerOtpTimer=fnl[4].Value;
         this.providerMapApiKey=fnl[5].Value;
         this.providerWaitingTime=fnl[7].Value;
         
         this.providerpickuppointdistance=fnl[8].Value;
         this.providerDueAmountThreshold=fnl[40].Value;
         this.providerTwilioAuthKey=fnl[19].Value;
        //  this.providerDriverApproval=fnl[17].Value;
        //  alert("jjjjjj"+this.providerTwilioAuthKey)
          var checkstatus1;
           checkstatus1=fnl[17].Value;
           if(checkstatus1=="InActive"){
             this.providerDriverApproval=false
           }
           else{
             this.providerDriverApproval=true
           }
           //  alert(this.providerDriverApproval)

         this.serverName=fnl[9].Value;
         this.portNumber=fnl[10].Value;
         this.secureId=fnl[11].Value;
         this.userName=fnl[12].Value;
         this.password=fnl[13].Value;
         var status;
        //  if (this.userReferralEnabled ==="true") {
        //    status = true;
        //  } else {
        //    status = false;
        //  }
        //  this.AppconfigAddForm = this.formBuilder.group({
        //   U_REFERRAL_ENABLED: "0",
        //  });alert("sfsfsdfs")
    this.AppconfigAddForm = this.formBuilder.group({
      U_AUTH_TYPE: [this.userAuthType, [Validators.required, Validators.minLength(1)]],
      U_OTP_TIMER: [this.userOtpTimer, [Validators.required, Validators.minLength(1)]],
      U_MAP_API_KEY: [this.userMapApiKey, [Validators.required, Validators.minLength(1)]],
      U_WAITING_TIME: [this.userWaitingTime, [Validators.required, Validators.minLength(1)]],
      U_MAX_RANGE: [this.userMaxiRange, [Validators.required, Validators.minLength(1)]],
      U_TWILIO_AUTH_KEY: [this.userTwilioAuthKey, [Validators.required, Validators.minLength(1)]],
      U_REFERRAL_ENABLED: [this.userReferralEnabled, [Validators.required, Validators.minLength(1)]],
      U_REFERRAL_AMOUNT: [this.userReferralAmount, [Validators.required, Validators.minLength(1)]],
      U_REFERRAL_DESCRIPTION: [this.userReferralDescription, [Validators.required, Validators.minLength(1)]],
      U_REFERRAL_THRESHOLD: [this.userReferralThreshold, [Validators.required, Validators.minLength(1)]],
      U_ADMIN_ID: [this.userAdminId, [Validators.required, Validators.minLength(1)]],

      P_AUTH_TYPE: [this.providerAuthType, [Validators.required, Validators.minLength(1)]],
      P_OTP_TIMER: [this.providerOtpTimer, [Validators.required, Validators.minLength(1)]],
      P_MAP_API_KEY: [this.providerMapApiKey, [Validators.required, Validators.minLength(1)]],
      P_WAITING_TIME: [this.providerWaitingTime, [Validators.required, Validators.minLength(1)]],
      P_PICKUP_DISTANCE: [this.providerpickuppointdistance, [Validators.required, Validators.minLength(1)]],
      P_Due_Amount_Threshold : [this.providerDueAmountThreshold, [Validators.required, Validators.minLength(1)]],
      P_TWILIO_AUTH_KEY: [this.providerTwilioAuthKey, [Validators.required, Validators.minLength(1)]],
      // 'description': ['', [Validators.required, Validators.minLength(1)]],
      P_PROVIDER_APPROVAL_STATUS: [this.providerDriverApproval],
      A_SMTP_SERVER: [this.serverName, [Validators.required, Validators.minLength(1)]],
      A_SMTP_PORT: [this.portNumber, [Validators.required, Validators.minLength(1)]],
      A_SMTP_SECURE: [this.secureId, [Validators.required, Validators.minLength(1)]],
      A_SMTP_USER: [this.userName, [Validators.required, Validators.minLength(1)]],
      A_SMTP_PASSWORD: [this.password, [Validators.required, Validators.minLength(1)]]
    });
          if (res.body["data"].length > 0 && fnl.length > 0) {
            fnl.filter((x) => {
              if (x["FieldName"] === "AUTH_TYPE") {
                x["authname"] = "Authentication Type";
              } else if (x["FieldName"] === "OTP_TIMER") {
                x["authname"] = "OTP Timer (in sec)";
              }
              else if (x["FieldName"] === "TWILIO_AUTH_KEY") {
                x["authname"] = "Auth Key (Twilio)";
              }
              else if (x["FieldName"] === "WAITING_TIME") {
                x["authname"] = "Waiting Time (in sec)";
              } else if (x["FieldName"] === "MAP_API_KEY") {
                x["authname"] = "Google Map Api Key";
              } else if (x["FieldName"] === "SMTP_SERVER") {
                x["authname"] = "Server Name";
              } else if (x["FieldName"] === "SMTP_PORT") {
                x["authname"] = "Port Number";
              } else if (x["FieldName"] === "SMTP_SECURE") {
                x["authname"] = "Secure Id";
              } else if (x["FieldName"] === "SMTP_USER") {
                x["authname"] = "User Name";
              } else if (x["FieldName"] === "SMTP_PASSWORD") {
                x["authname"] = "Password";
              } else if (x["FieldName"] === "PICKUP_DISTANCE") {
                x["authname"] = "Pickup Point Distance (in level range)";
              } else if (x["FieldName"] === "MAX_RANGE") {
                x["authname"] = "Maximum Trip Range (in km)";
              } else if (x["FieldName"] === "FB_APP_ID") {
                x["authname"] = "FACEBOOK APP ID";
              } else if (x["FieldName"] === "GOOGLE_ID") {
                x["authname"] = "GOOGLE APP ID";
              } else if (x["FieldName"] === "SOS_NUMBER") {
                x["authname"] = "SOS NUMBER";
              }else if(x["FieldName"]==="DRIVER_WAITING_TIME"){
                x["authname"] = "DRIVER WAITING TIME";
               }
              else if(x["FieldName"]==="REFERRAL_ENABLED"){
                x["authname"] = "REFERAL ENABLED";
              }else if(x["FieldName"]==="REFERRAL_AMOUNT"){
                x["authname"] = "REFERAL AMOUNT";
              }else if(x["FieldName"]==="REFERRAL_DESCRIPTION"){
                x["authname"] = "REFERAL DESCRIPTION";
              }
              else if(x["FieldName"]==="ADMIN_USER_ID"){
                x["authname"] = "ADMIN USER ID";
              }
              
            });
            fnl.filter((f) => {
              if (f.Type === "user") {
                if (f.FieldName === "MAX_RANGE") {
                  f.Value = parseFloat(f.Value) / 1000;
                }
                this.userresult.push(f);
                console.log("user",this.userresult)
                // console.log("user authtype",this.userresult.au)
//..................................

//..................................

              } else if (f.Type === "provider") {

                this.providerresult.push(f);
                console.log("provider",this.providerresult)
              } else {
                this.emailresult.push(f);
                console.log("email.",this.emailresult)
              }
              this.exportData = [
                this.userresult,
                this.providerresult,
              ].map((data, index) => {
                var temp = index + 1;
                var finaldata: any = {
                  SNo: temp,
                  FieldName: data.FieldName,
                  Value: data.Value,
                  Type: data.Type,
                  CreateAt: data.CreateAt,
                  UpdateAt: data.UpdateAt,
                  authname: data.authname,
                };
                return finaldata;
              });
            });
           var manualApproveData= this.providerresult.filter(data=>{
              return data.FieldName==="PROVIDER_APPROVAL_STATUS";
            });

            this.toggleValue=manualApproveData[0].Value==1 ?true :false;
            this.toggleFieldId=manualApproveData[0].Id;
            this.appspinner.spinnerAlert("hide");
          } else {
            environment.swalalert("nodata", "No Data Available ");
            this.appspinner.spinnerAlert("hide");
          }
        }
      },
      (err) => {
        console.log(err);
        if (err["status"] === 401) {
          environment.swalalert("nodata", err["msg"]);
          // this.cookie.delete('Token');
          localStorage.removeItem("Token");
          this.router.navigate(["login"]);
        }
      }
    );
    // $("nav-item nav-link").removeClass("active").addClass("active");





  }

  sample()
  {
    console.log("fsdfsdgs");
    var sam=this.AppconfigAddEmailForm.value;
  //  alert(sam.servername)
  }
  toggle(event){
    ////debugger
    this.toggleValue=event.target.checked;
    this.appconfiglist = new AppConfigModelList(this.toggleFieldId,this.toggleValue);
    console.log("hfhgdfgsdfsfgdgh",this.appconfiglist)
    this.service.appconfigEdit(this.appconfiglist).subscribe((res) => {
      if (res["error"]) {
        environment.swalalert("nodata", res["msg"]);
        this.appspinner.spinnerAlert("hide");
      } else {
        if (res.body["error"] === false) {
          environment.swalalert("success", res.body["msg"]).then((value) => {
            if (value) {
              this.router.navigate(["appConfigView"]);
            }
          });
        } else {
          environment.swalalert("error", res.body["msg"]);
        }
      }
    });

  }

  disabledchange(event) {
    ////debugger
    document.getElementById(event).removeAttribute("disabled");
  }
  adddisabledchange(event) {
    ////debugger
    // alert("add")
    document.getElementById(event).setAttribute("disabled", "disabled");
  }
  editAppConfig(name, id, event, fieldname) {
    ////debugger
    
    // this.cookie.set('appconfiglist',name);
    console.log("test.....",name)
    localStorage.setItem("appconfiglist", name);
    if (fieldname === "MAX_RANGE") {
      event = event * 1000;
    }
    this.appconfiglist = new AppConfigModelList(id, event);
    this.service.appconfigEdit(this.appconfiglist).subscribe((res) => {
      if (res["error"]) {
        environment.swalalert("nodata", res["msg"]);
        this.appspinner.spinnerAlert("hide");
      } else {
        if (res.body["error"] === false) {
          environment.swalalert("success", res.body["msg"]).then((value) => {
            if (value) {
              this.router.navigate(["appConfigView"]);
            }
          });
        } else {
          environment.swalalert("error", res.body["msg"]);
        }
      }
    });
  }
  viewpassword(type) {
    if (type === "vs") {
      this.eyeview = true;
      this.eyeviews = false;
    } else {
      this.eyeview = false;
      this.eyeviews = true;
    }
    var x: any = document.getElementById("passwordmyInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  type(data: String) {
    this.exportType = data;
    if (data === "gen") {
      this.exportData = [this.userresult, this.providerresult].map(
        (data, index) => {
          var temp = index + 1;
          var finaldata: any = {
            SNo: temp,
            FieldName: data.FieldName,
            Value: data.Value,
            Type: data.Type,
            CreateAt: data.CreateAt,
            UpdateAt: data.UpdateAt,
            authname: data.authname,
          };
          return finaldata;
        }
      );
    }
    if (data === "email") {
      this.exportData = this.emailresult.map((data, index) => {
        var temp = index + 1;
        var finaldata: any = {
          SNo: temp,
          FieldName: data.FieldName,
          Value: data.Value,
          Type: data.Type,
          CreateAt: data.CreateAt,
          UpdateAt: data.UpdateAt,
          authname: data.authname,
        };
        return finaldata;
      });
    }
  }

  exportJsonAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
  }
  cancel(){

  }
  doctypeedit()
  {

  }




  onCheckboxChange(checked: boolean) {
    // Do something with the checked value
    if (checked) {
      // this.refstatus = 'true';
      // alert('Checkbox is checked');
      // Perform additional actions
    } else {
      // this.refstatus = 'false';
      // alert('Checkbox is unchecked');
      // Perform other actions
    }
  }
  appConfifAdd() {
////debugger
if(this.userReferralAmount>0 && this.userReferralThreshold>0 && this.descriptionFlag===false ){
    if (this.AppconfigAddForm.value) {
      
      // var s=0
      // if (s==0) {
var sF = this.AppconfigAddForm.value;
var status = '';

      // if (sF.IsRequired === true) {
      //   status += 'Active';
      // } else {
      //   status += 'InActive';
      // }
    
      if (sF.P_PROVIDER_APPROVAL_STATUS == 1) {
        status = 'Active';
      } else {
        status = 'InActive';
      }
     
      if (sF.U_REFERRAL_ENABLED == 1) {
        // alert("lllll")
       this. refstatus = true;
      } else {
        // alert("nnnnn")
       this. refstatus = false;
      }
      
      // alert("data"+this.userReferralEnabled)
      // if (this.userReferralEnabled==="true") {
      //   // alert("true")
      //   refstatus += 'true';
      // } else {
      //   // alert("false")
      //   refstatus += 'false';
      // }
      // console.log(sF.validfrom.getFullYear() + "-" + sF.validfrom.getMonth() + 1 + "-" +sF.validfrom.getDate());
     
      
     
      this.AppConfigData = new APPConfigData(
        
        sF.U_AUTH_TYPE,
        sF.U_OTP_TIMER,
        sF.U_MAP_API_KEY,
        sF.U_WAITING_TIME,
        sF.U_MAX_RANGE,
        sF.U_TWILIO_AUTH_KEY,
// sF.U_REFERRAL_ENABLED,
String(this.refstatus),
sF.U_REFERRAL_AMOUNT,
sF.U_REFERRAL_DESCRIPTION,
sF.U_REFERRAL_THRESHOLD,
sF.U_ADMIN_ID,
        sF.P_AUTH_TYPE,
        sF.P_OTP_TIMER,
        sF.P_MAP_API_KEY,
        sF.P_WAITING_TIME,
        sF.P_PICKUP_DISTANCE,
        sF.P_Due_Amount_Threshold,
        sF.P_TWILIO_AUTH_KEY,
        status
        // sF.A_SMTP_SERVER,
        // sF.A_SMTP_PORT,
        // sF.A_SMTP_SECURE,
        // sF.A_SMTP_USER,
        // sF.A_SMTP_PASSWORD
       );

// alert("ffff"+this.AppConfigData.P_TWILIO_AUTH_KEY)
           console.log("FormData:::",this.AppConfigData);
      
      this.service.appconfigEdit(this.AppConfigData).subscribe(res => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              if (value) {
                // this.AppconfigAddForm.reset();
                this.router.navigate(['appConfigView']);
              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    
    }
    else {
      environment.swalalert('warning', 'All Fields Required');
    }
  }
  else{
    environment.swalalert('warning', 'Referral Amount and Threshold and Description should not be empty');
  }
  }
  appConfifEmailAdd() {

    if (this.AppconfigAddEmailForm.valid) {
var Ref = this.AppconfigAddEmailForm.value;
 this.AppConfigEmailData = new APPConfigEmailData(
        
 Ref.servername,
  Ref.portnumber,
   Ref.secureid,
     Ref.username,
    Ref.password,
        

       
       );


           console.log("FormData:::",this.AppConfigEmailData);
      
      this.service.appconfigAdd(this.AppConfigEmailData).subscribe(res => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          if (res.body['error'] === false) {
            environment.swalalert('success', res.body['msg']).then(value => {
              if (value) {
                this.AppconfigAddForm.reset();
                this.router.navigate(['promoCodes']);
              }
            });
          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    
    }
    else {
      environment.swalalert('warning', 'All Fields  Required');
    }
  }


  checkAmount(value: number) {
    if (value > 0) {
      this.walletFlag=false
      // Amount is greater than zero
      // Perform desired actions here
    }
    else if(!value)
    {
      this.walletFlag=false
    }
    else{
      this.walletFlag=true
    }
  }
  checkThreshold(value: number) {
    if (value > 0) {
      this.thresholdFlag=false
      // Amount is greater than zero
      // Perform desired actions here
    }
    else if(!value)
    {
      this.thresholdFlag=false
    }
    else{
      this.thresholdFlag=true
    }
  }
  checkDescription(value: string) {
    if (value.length <=250 && value) {
      
      this.descriptionFlag = false;
      // The string length is greater than 250
      // Perform desired actions here
    } else if (value.length<=0) {
      this.descriptionFlag = true;
    } else {
      this.descriptionFlag = true;
  }
}
checkdueThreshold(value: any) {
  if (value < 0 && value) {
    this.duethresholdFlag = false;
    // The string length is greater than 250
    // Perform desired actions here
  } 
  // else if (value<=0) {
  //   this.descriptionFlag = true;
  // } 
  else {
    this.duethresholdFlag = true;
}




// const negativeNumberRegex = /^-?[0-9]+$/;
  
// if (!negativeNumberRegex.test(value)) {
//   // Input is not a negative number
//   this.descriptionFlag = true;
// } else {
//   this.descriptionFlag = true;
// }
}

}