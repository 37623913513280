import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supportticket',
  templateUrl: './supportticket.component.html',
  styleUrls: ['./supportticket.component.css']
})
export class SupportticketComponent implements OnInit {
  page:number;
  pages:number;
  
  searchdata:String;
  constructor() { }

  ngOnInit() {
  }
  getUsersSerchPage(event)
  {

  }

}
