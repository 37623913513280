import { Component, OnInit,ViewChildren } from '@angular/core';
import { Router, ActivatedRoute,RouterLinkActive } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { reject } from 'q';
@Component({
  selector: 'app-otpscreen',
  templateUrl: './otpscreen.component.html',
  styleUrls: ['./otpscreen.component.css']
})
export class OtpscreenComponent implements OnInit {

  otpverificationForm : FormGroup;
 
  forgotemail:any
    formInput = ['input1', 'input2', 'input3', 'input4'];
  @ViewChildren('formRow') rows: any;
  el:any;
  otp:any;
  constructor(private formBuilder : FormBuilder,
    private router : Router,
    private route : ActivatedRoute,
    private service : LoginService,
    ) { }

  ngOnInit() {

    this.forgotemail=sessionStorage.getItem('otpemail');
    this.otpverificationForm =   this.toFormGroup(this.formInput);
  }
  toFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
   }
   keyUpEvent(event, index) {
     console.log(event,index);
     
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
     pos = index - 1 ;
    } else {
     pos = index + 1 ;
    }
    if (pos > -1 && pos < this.formInput.length ) {
     this.rows._results[pos].nativeElement.focus();
    }
   }
  validateotp(){
    // console.log(this.userLoginForm.controls);
console.log('otp');

this.otp =`${this.otpverificationForm.value.input1}${this.otpverificationForm.value.input2}${this.otpverificationForm.value.input3}${this.otpverificationForm.value.input4}`


const apidata={Email:this.forgotemail,otp:this.otp}
console.log(apidata); 

      if(this.otpverificationForm.valid)
      {
       
        
        this.service.otpVerify(apidata).subscribe((result) =>{
        
          if(result.body['error'] == false)
          {
          
  
            environment.swalalert('success',result.body['otp']).then(value => {
              if(value){
                sessionStorage.setItem('otp',this.otp)
                this.router.navigate(['/resetpassword'])
              }
            });
          }else {
            environment.swalalert('error',result.body['error']);
            // environment.swalalert('error',result.body['error_message']);
            }
          
        },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      }else{
        environment.swalalert('error','Validation Required');  
      }
    }
}
