import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { adminviewservies } from './admin.service';
import { AdminViewModel } from './admin.model';
import { AdminModelList } from './admin-list.model'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  usermodel : AdminModelList;
  adminmodel:AdminViewModel;
  page: number = 1;
  pages: any;
  searchdata:any;
  balanceamount:any;
  amount:any;
  searchstatus:String='active';
  adminviewmodel : AdminViewModel;
  result: any;
  currentTab:String='active';
  noDataFlag:Boolean=false;



   ////////////////////////
   searchStatus:String;
   statusGlobal:String;
   startdate:String;
   enddate:String;
   fulldate:String;
   filterkeyFlag:Boolean=false;
   isMenuOpen = false;
   dateFlag:Boolean=false;
   filterFlag:Boolean=false;
  selectedOption1: string; 
  searchflag:Boolean=true;
  noDataFlag1:Boolean=false;
   @ViewChild('menuElementRef') menuElementRef: ElementRef;
// @ViewChild('.menu') menuElementRef: ElementRef;
 selectedValue1: string;
 showDropdown: boolean = false;
 dropdownOptions: string[] = ['Today', 'Week', 'Month','Customrange','Name','Id'];
 dataFlag:Boolean=true;
 maxDate: Date;
  constructor(
    private appspinner: AppComponent,
    private service: adminviewservies,
    private router:Router,

  ) {

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate()); 
   }

  ngOnInit() {
    this.filterFlag=true;
    sessionStorage.setItem('orguser','0')

    this.appspinner.spinnerAlert('show');
    
    this.service.getAdminListView(this.currentTab,this.page).subscribe((res) => {
      console.log("data result",res.body['data'])
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      this.adminviewmodel = new AdminViewModel(res.body['error'], res.body['msg'], res.body['data']);
      console.log("result ",this.adminviewmodel['data'])
      if (res.body['data'].length > 0 ) {
        // this.pages = this.adminviewmodel['data'][0].Count;
      
        // this.result = this.adminviewmodel['data'] ;
        this.pages = Math.ceil(this.adminviewmodel['data'][1].Count/2);
          this.result = this.adminviewmodel['data'][0].data;
      
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
         ///   localStorage.removeItem('Token');
          //  this.router.navigate(['login']);
        }
      });


  }


//............................
getProviders1(page,status) {
this.statusGlobal=status
  ////debugger
  // alert(status)
  this.searchstatus=status;
  // this.cookie.set('userlist',page);
  // alert("jjjj"+this.selectedValue)
var pendingpage;
var activepage;
  sessionStorage.setItem('userlistpage',page)
  if(status=='active'){
this.currentTab='active';
sessionStorage.setItem('usertab','active')
   //this.page = page;
   this.page=1;
   activepage=page;
   
  }
 if(status=='pending'){
 this.currentTab='pending';
 sessionStorage.setItem('usertab','pending')
//  this.page=1;
// this.pendingPage=page;
pendingpage=page   


}
  var pen = [];
  var reslt = [];
  this.result=[];
    this.appspinner.spinnerAlert('show');
    // var currentPage= status=='active'? activepage:pendingpage;
    var currentPage= status=='active'? activepage:pendingpage;
          sessionStorage.setItem('userlistpage',`${currentPage}`);

    //console.log("CurrentPage::",currentPage);
    console.log(currentPage,status);
    this.service.getAdminListView(currentPage,status).subscribe((res) => {
      console.log("data result",res.body['data'])
      if (res.body['error']) {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      this.adminviewmodel = new AdminViewModel(res.body['error'], res.body['msg'], res.body['data']);
      console.log("result ",this.adminviewmodel['data'])
      if (res.body['data'].length > 0 ) {
        // this.pages = this.adminviewmodel['data'][0].Count;
      
        // this.result = this.adminviewmodel['data'] ;
        this.pages =Math.ceil( this.adminviewmodel['data'][1].Count/2);
          this.result = this.adminviewmodel['data'][0].data;
      
        this.appspinner.spinnerAlert('hide');
      } else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
         ///   localStorage.removeItem('Token');
          //  this.router.navigate(['login']);
        }
      });
  // this.service.usersListView1(currentPage,status).subscribe((res) => {
//     this.service.getAdminListView().subscribe((res) => {
  
//     console.log(res);

//     if (res.body['error']) {
//       environment.swalalert('nodata', res.body['msg']);
//       this.appspinner.spinnerAlert('hide');
//     } else {
//       if(sessionStorage.getItem('userlistpage')){
//         if(status=='active'){
//         this.page=parseInt(sessionStorage.getItem('userlistpage'))
//         }else{
//         this.pendingPage=parseInt(sessionStorage.getItem('userlistpage'))
//         }
//       }else{

//       }
//       this.usermodel = new UserListModel(res.body['error'],res.body['msg'], res.body['data']);
//     if (res.body['data'].length > 0 && this.usermodel['data'][0].data.length > 0) {
//       //this.page = page;
//       ////debugger
//       this.pages = this.usermodel['data'][1].Count;
//       this.result = this.usermodel['data'][0].data;
//       this.appspinner.spinnerAlert('hide');
//       console.log("result list in user list check......",this.result[0].Status)
//     } else {
//       environment.swalalert('nodata', 'No Data Available ');
//       this.appspinner.spinnerAlert('hide');
//     }
//   }
// },
//   (err) => {
//     console.log(err);
//     if (err['status'] === 401) {
//       environment.swalalert('nodata', err['msg']);
//       // this.cookie.delete('Token');
//       localStorage.removeItem('Token');
//       this.router.navigate(['login']);
//     }
//   })




}

//..........................................................





  getAdminView(){
    this.statusGlobal=this.currentTab
  this.service.getAdminListView(this.currentTab,this.page).subscribe((res) => {
    console.log("data result",res.body['data'])
    if (res.body['error']) {
      environment.swalalert('nodata', res.body['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
    this.adminviewmodel = new AdminViewModel(res.body['error'], res.body['msg'], res.body['data']);
    console.log("result ",this.adminviewmodel['data'])
    if (res.body['data'].length > 0 ) {
      // this.pages = this.adminviewmodel['data'][0].Count;
    
      // this.result = this.adminviewmodel['data'] ;
      this.pages = Math.ceil(this.adminviewmodel['data'][1].Count/2);
          this.result = this.adminviewmodel['data'][0].data;
    
      this.appspinner.spinnerAlert('hide');
    } else {
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
  },
    (err) => {
      console.log(err);
      if (err['status'] === 401) {
        environment.swalalert('nodata', err['msg']);
       ///   localStorage.removeItem('Token');
        //  this.router.navigate(['login']);
      }
    });
  }

  deleteAdmin(id)
  {
    ////debugger
    var data  = {
      Id : id
    }
    this.service.deleteAdmin(data).subscribe(res => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      if(res.body['error'] === false ) {
        environment.swalalert('success',res.body['msg']).then(value => {
          if(value) {
        //    this.peakchargeedit.reset();
            //this.router.navigate(['/adminview']);
            this.ngOnInit();
          }
          });
      } else {
        environment.swalalert('error',res.body['msg']);
      }
    }
    });
    
  }
  findname(search) {
    ////debugger
    // alert(this.searchstatus)
    var data = {search: search, typename:'adminRoleView', status:this.searchstatus,page: this.page}
    this.service.searchdataView(data).subscribe((res) => {
          if (res['error']) {
            this.noDataFlag=true;
            this.pages=0
            this.result=[]
            // environment.swalalert('nodata', res['msg']);
            // this.appspinner.spinnerAlert('hide');
          } else {
            this.noDataFlag=false
            this.adminmodel = new AdminViewModel(res['error'], res['msg'], res['data']);

            if (res['data'].length > 0 && this.adminmodel['data'][0].data.length > 0) {
              // this.pages = this.usermodel['data'][1].Count;
              this.result = this.adminmodel['data'][0].data;
              this.pages = Math.ceil(this.adminviewmodel['data'][1].Count/2);
          // this.result = this.adminviewmodel['data'][0].data;
              console.log("gggggggg",this.adminmodel['data'][0].data)
            
            // console.log("fffff",res['data'][0].data)
            // if (res['data'].length > 0 && this.usermodel['data'][0].data.length > 0) {
            //   this.pages = this.adminmodel['data'][1].Count;
            //   // this.result = this.adminmodel['data'][0].data;
            //   this.result =   res['data'][0].data




              // if (res['data'].length > 0 && this.usermodel['data'][0].data[0].length > 0) { 
              //   this.pages = this.adminmodel['data'][0].Count;
              //     this.result = this.adminmodel['data'][0].data[0];
              this.appspinner.spinnerAlert('hide');
            } else {
              // environment.swalalert('nodata', 'No Data Available ');
              // this.appspinner.spinnerAlert('hide');
            }
          }
        },
          (err) => {
            console.log(err);
          });
      }  



      adminUpdate(id, status, r, i) {
        ////debugger
        var data = { Id: { Id: id }, updata: { IsBlocked: status } };
        if(status === 'reject' ) {
          ////debugger
          environment.swalalert('Disabled', 'Disabled Successfully').then(value => {
         
            if (value) {
             this. getAdminView()
              // this.getProviders1(1,'active')
              //   this.getProviders1(1,'pending')
              this.service.adminStatusUpdate(data).subscribe((res) => {
                
                if (res['error']) {
                  environment.swalalert('nodata', res['msg']);
                  this.appspinner.spinnerAlert('hide');
    
                } else {
                if(res.body['error'] === false ) {
                  ////debugger
                  this.getAdminView();
                //   this.getProviders1(1,'active')
                // this.getProviders1(1,'pending')
                  //this. getUsersSerchPage(this.page);
                  // this.getUsers(this.page);
                } else {
                  environment.swalalert('error',res.body['msg']);
                }
              }
              },
                (err) => {
                  console.log(err);
                });
                //this. getUsersSerchPage(this.page);
            }
          });
    
          } else {
          this.service.adminStatusUpdate(data).subscribe((res) => {
            if (res['error']) {
              environment.swalalert('nodata', res['msg']);
              this.appspinner.spinnerAlert('hide');
    
            } else {
            if(res.body['error'] === false ) {
              environment.swalalert('success', status=='active'?'Enabled Successfully':'Disabled Succesfully').then(value => {
                // this.getProviders1(1,'pending')
                // this.getProviders1(1,'active')
               this.getAdminView()
                if(value) {
                  ////debugger
                  this.getAdminView();
                  // this.getProviders1(1,'pending')
                  // this.getProviders1(1,'active')
                  this.appspinner.spinnerAlert('show');
                  // this. getUsersSerchPage(this.page);
                  // this.getUsers(1)
                  //this. getUsersSerchPage(this.page);
                 
                }
                ////debugger
                this.appspinner.spinnerAlert('show');
                // this. getUsersSerchPage(this.page);
                // this.getUsers(1)
                    //this.getUsers(this.page)
    
                });
            } else {
              environment.swalalert('error',res.body['msg']);
            }
          }
          },
    
          (err) => {
            console.log(err);
          });
          }
        }
        checkrouteractive(page){
          // alert("gffffddd")
          sessionStorage.setItem('orguser',page)
         }






         //////////////////////////////////

getallproviderssearch(page,search) {
  ////debugger
  this.appspinner.spinnerAlert('show');

 this.noDataFlag=false;

const data={
  page:page,
  type:this.selectedOption1,
  // searchkey:this.searchdata
  searchkey:search,
  status:this.searchStatus
}
  if(!search)
  {
    // this.searchflag=false;
    this.noDataFlag=false;
    this.getallusers(1,this.searchStatus);
    //this.getprovidersSearch(this.currentpage);

  }
  else{
  // alert(data)
  this.service.userFilterListView(data).subscribe((res) => {
    if (res.body['error']) {
      
      this.noDataFlag=true;
      this.pages = 0
      this.result = []
      environment.swalalert('nodata', res['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
  var a=0
 
      if (res.body['data'][0].data.length > 0 ) {
        this.noDataFlag=false;
        
        this.pages = Math.ceil(res.body['data'][1].Count/2);
        this.result = res.body['data'][0].data;
        console.log("filtercheck",res.body['data'][0].data)
        this.appspinner.spinnerAlert('hide'); 
      }
      else {
       
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
    },
    (err) => {
      console.log(err);
  })
}
}

reloadWindow() {
  this.filterFlag=true
  this.filterkeyFlag=false
  this.searchflag=true
  window.location.reload();
}
 onChangeSearchkeytype(data){
  //  this.searchdata=''
 
  this.dateFlag=false;
  this.filterFlag=false;
  this.selectedOption1=data;
  if(this.selectedOption1=="Month"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
    this.filterFlag=false
    this.dateFlag=false;
    this.searchflag=false
  this.selectedOption1="doj"
  this.searchdata="month"
  this. getallusers(1,"month")
  
  this.selectedOption1=data;
  }
  if(this.selectedOption1=="Week"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
    this.filterFlag=false
    this.dateFlag=false;
    this.searchflag=false
  this.selectedOption1="doj"
  this.searchdata="week"
  this. getallusers(1,"week")
  this.selectedOption1=data;
  }
  if(this.selectedOption1=="Today"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
    this.filterFlag=false
    this.dateFlag=false;
    this.searchflag=false
  this.selectedOption1="doj"
  this.searchdata="today"
  this. getallusers(1,"today")
  this.selectedOption1=data;
  }
  if(this.selectedOption1=="zeroride"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
    this.filterFlag=false
    this.dateFlag=false;
    this.searchflag=false
  this.selectedOption1="ride"
  this.searchdata="0"
  this.getallusers(1,"0")
  this.selectedOption1=data;
  }
  if(this.selectedOption1=="Customrange"){
    this.isMenuOpen=false
   
    this.dataFlag=false;
     this.toggleMenu()
    this.filterFlag=false
    this.searchflag=false
  this.selectedOption1="customrange"
  
  this.dateFlag=true;
  this.searchdata=this.fulldate
  this.selectedOption1="customrange";
  // this.getallproviders(1,this.fulldate)
  }
  if(this.selectedOption1=="Name"){
    this.isMenuOpen=false
   
    this.dataFlag=true;
     this.toggleMenu()
    this.dateFlag=false;
    this.filterFlag=true
    
    // this.searchflag=false
    this.filterkeyFlag=true
    
  this.searchflag=false
    this.selectedOption1="name"
    this.getallusers(1,this.searchdata)
    // this.selectedOption1=data;
    // this.getallproviders(1,"name")
  // this.selectedOption1="customrange";
  }
  if(this.selectedOption1=="Id"){
    this.isMenuOpen=false
   
    this.dataFlag=true;
     this.toggleMenu()
    this.dateFlag=false;
    this.filterFlag=true
    this.filterkeyFlag=true
  this.searchflag=false
    
    this.selectedOption1="id"
    this.getallusers(1,this.searchdata)
    // this.selectedOption1=data;
  // this.selectedOption1="customrange";
  }
  //   searchkey:"2023-06-07,"2023-06-014
// type: "customrange"
  
  
// this.filterFlag=true;
  
}

checksearch(searchdata){
  if(this.filterkeyFlag===true){
this.getallproviderssearch(1,this.searchdata)
// this.searchdata="Name"
// alert("success")
  }
  else if(this.searchflag===true){
this.findname(this.searchdata)
  }
}

          toggleDropdown() {
            this.showDropdown = !this.showDropdown;
          }
          
          selectOption(option: string) {
            // alert(this.isMenuOpen)
            this.selectedValue1 = option;
            this.isMenuOpen=false
          
            // if(option=="Name")
            // {
              
            //   // this.searchdata="name"
            // }
            this.onChangeSearchkeytype(option)
            // this.toggleDropdown();
          }
          
          
          
          
          toggleMenu() {
            // alert("hi")
            this.isMenuOpen = !this.isMenuOpen;
            
          }
          
          menuItemClicked(item: string) {
            console.log('Clicked:', item);
            // Perform desired actions with the clicked item
          }
          
          
          
          onDateRangeChange(event: any) {
            const startDate = this.formatDate(event.startDate);
            const endDate =this.formatDate( event.endDate);
            if(startDate=="1970-01-01"){
              this.fulldate=" "
              this.searchdata=this.fulldate
                }
                else{
            this.startdate=startDate
            this.enddate=endDate
            this.fulldate=this.startdate+","+this.enddate
            this.searchdata=this.fulldate
            this.getallusers(1,this.fulldate)
            this.dateFlag=true
            // alert(this.fulldate)
          
            // Use the start and end dates as needed
                }
          }
          formatDate(date) {
            var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
          
            return [year, month, day].join('-');
          }
          
          
          
          
          // Open the menu when the button is clicked
          openMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            
            // this.isMenuOpen = true;
            document.addEventListener('click', this.closeMenuOutsideClick.bind(this));
          }
          
          // Close the menu when clicking outside of the menu area
          closeMenuOutsideClick(event: MouseEvent) {
            
          
            if (!this.menuElementRef.nativeElement.contains(event.target)) {
              
          //  this.isMenuOpen = false;
              document.removeEventListener('click', this.closeMenuOutsideClick.bind(this));
            }
          }

          getallusers(page,search) {
            this.appspinner.spinnerAlert('show');
           // sessionStorage.setItem('doctypelist',page);
        
           this.noDataFlag=false;
            const data={
              page:page,
              type:this.selectedOption1,
              // searchkey:this.searchdata
              // searchkey:search,
              searchkey:this.searchdata,
              status:this.statusGlobal
            }
            
            
            if(!search)
            {
              // this.searchflag=false;
              this.noDataFlag=false;
              this.getProviders1(1,this.statusGlobal) 
              // this.getProviders1(1,'active')
              //this.getprovidersSearch(this.currentpage);
          
            }
            else{
            // alert(data)
            this.service.userFilterListView(data).subscribe((res) => {
              if (res.body['error']) {
               
                this.noDataFlag=true;
                this.pages = 0
                this.result = []
                environment.swalalert('nodata', res['msg']);
                this.appspinner.spinnerAlert('hide');
              } else {
            var a=0
                if (res.body['data'][0].data.length > 0 ) {
                  this.noDataFlag=false;
                  
                  this.pages = Math.ceil(res.body['data'][1].Count/2);
                  this.result = res.body['data'][0].data;
                  console.log("filtercheck",res.body['data'][0].data)
                  this.appspinner.spinnerAlert('hide'); 
                }
                else {
                 
                environment.swalalert('nodata', 'No Data Available ');
                this.appspinner.spinnerAlert('hide');
              }
            }
              },
              (err) => {
                console.log(err);
            })
          }
          }

          hideModel()
          {
            
          }
          payout(){

          }

}
